import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from '../../../services/dashboard.service';
import { CdTimerComponent } from 'angular-cd-timer';
import { LawyerService } from '../../../services/lawyer.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { StorageService } from 'src/app/shared/services/storage.service';
@Component({
  selector: 'app-lawyer-file-management-platform',
  templateUrl: './lawyer-file-management-platform.component.html',
  styleUrls: ['./lawyer-file-management-platform.component.css']
})
export class LawyerFileManagementPlatformComponent implements OnInit {
  @ViewChild('basicTimer', { static: true }) basicTimer: CdTimerComponent;
  timerInfo = '';
  disable_menus: any;
  data: any;
  Datachart = {
    data_cases_complete: 0,
    data_cases_in_process: 0
  };
  arrayCase: any; LawSelect; condSelectLaw; LawSelectDataUser;
  arrayTask: any; condSelecTask; taskSelect; taskSelectDataUser;
  condListTask: boolean = false;
  modal: any;
  parentSubject: Subject<any> = new Subject();
  lawyerType: string;
  lawyerCaseTasks: any;
  actualTimerSeconds: number = 0;
  Task_pending = 0;
  Type = "Platform";
  condTimer: boolean = false;
  responseCase; listCases: any; dataPagination; page; dataPaginationTaskPrincipal: any;
  responseTask; lisTask: any; dataPaginationTask; pageTask; pageTaskCases: number;
  idCaseSelect; condStop: boolean = false; condStart: boolean = false;
  idTaskSelect; sendTimeTask; noTask: boolean = false;



  constructor(
    private dashboadService: DashboardService,
    private LawyerService: LawyerService,
    titlePageService: TitlePageService,
    private storage: StorageService,
  ) {
    titlePageService.titlePageObservableData = { name: 'Inicio' };
    this.page = 1;
    this.pageTask = 1;
    this.pageTaskCases = 1;
    this.timerInfo = '';
    let disable_menus = this.storage.getItem('disable_menus');
    if (disable_menus) {
      this.disable_menus = disable_menus.join(" - ");
    } else {
      this.disable_menus = '';
    }
  }

  ngOnInit(): void {
    this.getDashboardInfo();
    this.loadPageTaskCasesPrincipal();
  }

  navigateTo(url) {
    const newUrl = new URL(window.location.href).pathname = url;
    window.location.href = newUrl;
  }

  getDashboardInfo(): void {
    this.dashboadService.get().subscribe(
      response => {
        this.data = response.data.external_cases;

        console.log(this.data);

      }
    );
  }

  getTaskCasesPrincipal(page: number) {
    let user = this.storage.getItem('ln_user') as any;
    let lawyer = user.lawyer
    this.LawyerService.getTaskCasesByLawyer(lawyer.id, page).subscribe(
      async (response) => {
        this.lawyerCaseTasks = response.data.map((c: any) => {
          return {
            name_task: c.name,
            name_case: c.cases.name,
            date_start: c.date_start_original ?? c.date_start,
            date_end: c.date_end_original ?? c.date_end
          }
        });
        // if (this.lawyerCaseTasks.length === 0) {
        //   this.noTask = true;
        // } else {
        //   this.noTask = false;
        // }
        this.dataPaginationTaskPrincipal = response?.meta?.page;
      },
      (error) => {

      }
    );
  }

  loadPageTaskCasesPrincipal(page: number = 1) {
    this.pageTaskCases = page;
    this.lawyerCaseTasks = [];
    this.getTaskCasesPrincipal(this.pageTaskCases);
  }

  isDisable(val): boolean {
    return !(this.disable_menus.indexOf(val) > -1);
  }

}
