import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Country } from '../../../interfaces/country';
import { MustMatch } from '../../../helpers/must-match.validator';
import { CommonService } from '../../../services/common.service';
import { Province } from '../../../interfaces/province';
import { City } from '../../../interfaces/city';
import { Township } from '../../../interfaces/township';

@Component( {
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: [ './company-form.component.css' ]
} )
export class CompanyFormComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  constitutionDate: any;
  provinces: Province[] = [];
  cities: City[] = [];
  townships: Township[] = [];
  fileName = 'Seleccione archivo';
  typePass = 'password';
  iconPass = 'visibility_off';


  private _imgFile = '';

  @Input() countries: Country[];
  @Output() submitEvent = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    private common: CommonService,
    public formatter: NgbDateParserFormatter,

  ) {
    this.constitutionDate = this.calendar.getToday();
    this.loadProvinces();
    this.createForm();
  }

  get f(): any { return this.form.controls; }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    if ( this.form.valid ) {
      const data = { ...this.form.value };
      data.dni_file = this._imgFile;
      data.constitution_date = this.formatter.format( this.constitutionDate );
      this.submitEvent.emit( data );
    }
  }

  onChange( id: number, type: string ): void {
    if ( type === 'province' ) { this.loadCities( id ); }
    if ( type === 'city' ) { this.loadTownships( id ); }
    if ( type === 'township' ) { this.form.controls.township_id.patchValue( id ); }
  }

  loadProvinces(): void {
    this.common.provinces( 171 ).subscribe( response => {
      this.provinces = [ ...response.data ];
      this.loadCities( this.provinces[ 0 ].id );
    } );
  }

  loadCities( id: number ): void {
    this.common.cities( id ).subscribe( response => {
      this.cities = [ ...response.data ];
      this.townships = [];
       this.loadTownships( this.cities[ 0 ].id );
    } );
  }

  loadTownships( id: number ): void {
    this.common.townships( id ).subscribe( response => {
      this.townships = [ ...response.data ];
      // this.form.controls.township_id.patchValue( this.townships[ 0 ].id );
    } );
  }

  onImageChange( e ): void {
    const reader = new FileReader();
    if ( e.target.files && e.target.files.length ) {
      const [ file ] = e.target.files;
      this.fileName = file.name;
      reader.readAsDataURL( file );

      reader.onload = () => {
        this._imgFile = reader.result as string;
      };
    }
  }

  private createForm(): void {
    this.form = this.fb.group(
      {
        business_name: [ '', [ Validators.required ] ],
        business_reason: [ '', [ Validators.required ] ],
        phone: [ '', [ Validators.required ] ],
        township_id: [ '' ],
        address: [ '', [ Validators.required ] ],
        name: [ '', [ Validators.required, Validators.minLength( 3 ) ] ],
        legal_agent: [ '', [ Validators.required, Validators.minLength( 3 ) ] ], // Representante legal
        email: [ '', [ Validators.required, Validators.email , Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")] ],
        dni: [ '', [ Validators.required ] ],
        taxpayer: [ '', [ Validators.required ] ],
        operations_notice: [ '', [ Validators.required ] ],
        country_of_constitution_id: [ 171 ],
        constitution_date: [ '', [ Validators.required ] ],
        operation_countries: [ '', [ Validators.required ] ],
        main_image: [ '' ],
        dni_file: [ '', [ Validators.required ] ],
        password: [ '', [ Validators.required, Validators.minLength( 8 ) ] ],
        password_confirmation: [ '', [ Validators.required ] ],
      }, {
      validator: MustMatch( 'password', 'password_confirmation' )
    } );

  }

  onChangePass(){
    if(this.typePass=='password') {
      this.typePass = 'text';
      this.iconPass = 'visibility';
    } else if(this.typePass=='text') {
      this.typePass = 'password';
      this.iconPass = 'visibility_off';
    }
  }

  refreshAddress() {
    this.loadProvinces();
  }

}
