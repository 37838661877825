import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { HostListener } from '@angular/core';
import { BRAND_TITLE, LOGO, LOGO_H, LOGO_V } from '../../data/constants';


@Component( {
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: [ './nav-header.component.css' ]
} )
export class NavHeaderComponent implements OnInit {

  logo = LOGO;
  logo_h = LOGO_H;
  brand = BRAND_TITLE;
  hamburgerClass = false;
  screenHeight: number;
  screenWidth: number;
  showMini = false;

  constructor( private sharedService: SharedService ) {
    this.getScreenSize();
  }

  ngOnInit(): void {

  }

  toggleHamburgerClass(): void {
    this.hamburgerClass = this.sharedService.toggleHamburgerClass();
    if(this.screenWidth!= null && this.screenWidth>699)this.showMini = (this.showMini) ? false : true;
  }

  @HostListener( 'window:resize', [ '$event' ] )

  getScreenSize( event?): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    const div = document.getElementById( 'main-wrapper' );
    if ( this.screenWidth <= 699 ) {
      document.body.setAttribute( 'data-sidebar-style', 'overlay' );
      this.showMini = true;
    } else {
      document.body.setAttribute( 'data-sidebar-style', 'full' );
      if(div!=null){
        this.showMini = (div.classList.length > 1) ? true : false ;
      }else{
        this.showMini = false;
      }
    }
  }

}
