<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>{{titlePage}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-header d-block pb-0">
            <form class="">
              <div class="form-row col-12">
                <div class="form-group hidden">
                  <div class="input-group">
                    <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                      [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                      [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end align-items-end">
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Desde</strong></label>
                    <div class="input-group">
                      <input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Hasta</strong></label>
                    <div class="input-group">
                      <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Tipo de Servicio</strong></label>
                    <select class="form-control form-filter" name="type" id="type" [(ngModel)]="searchFilter.type">
                      <option selected value="">Todos</option>
                      <option [value]="type.name" *ngFor="let type of types">{{type.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Rama</strong></label>
                    <select class="form-control form-filter" name="branch" id="branch"
                      [(ngModel)]="searchFilter.branch">
                      <option selected value="">Todos</option>
                      <option [value]="branch.name" *ngFor="let branch of branches">{{branch.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end align-items-end">
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Nombre de Abogado</strong></label>
                    <input class="form-control form-filter" name="lawyer_name" id="lawyer_name" [(ngModel)]="searchFilter.lawyer_name">
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Numero de Expediente</strong></label>
                    <input class="form-control form-filter" name="number" id="number" [(ngModel)]="searchFilter.file_number">
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Estatus</strong></label>
                    <select class="form-control form-filter" name="status" id="status"
                      [(ngModel)]="searchFilter.status">
                      <option selected value="">Todos</option>
                      <option [value]="status.name" *ngFor="let status of statuses">{{status.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <div class="input-group">
                      <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                        <i class="fa fa-search"></i>
                      </button>
                      <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <ng-container *ngIf="list.length; else nodata">
              <div class="row justify-content-center">
                <div class="col mt-3">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">F. Solicitud</th>
                          <!--<th scope="col"># de expediente</th>--> 
                          <th scope="col">Rama</th>
                          <th scope="col">Tipo de Servicio</th>
                          <th scope="col">Nombre de Servicio</th>
                         <!--<th scope="col">Nombre de Abogado</th>--> 
                          <th scope="col">Estatus</th>
                          <th scope="col">Fase Actual</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of list; let i=index">
                          <td>{{item.creation_date}}</td>
                          <!--<td>{{item.file_number}}</td> -->
                          <td>{{item.service.rama_del_derecho}}</td>
                          <td>{{item.service.tipo_de_servicio}}</td>
                          <td>{{item.service.servicio}}</td>
                         <!-- <td>{{item.lawyer.name}}</td> -->
                          <td>{{item.status.name}}</td>
                          <td>{{item.current_phase}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row mt-2 justify-content-end align-items-end">
                    <fieldset>
                      <p *ngFor="let info of info_object" class="mb-0"><b>{{info.service_status}}:</b> {{info.total}}</p>
                    </fieldset>
                  </div>
                  <div class="row mt-4 float-right">
                    <div class="col-12 custom-div-pagination justify-content-center">
                      <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                        [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="dataPagination.currentPage" aria-label="Paginación">
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <p>Servicios</p>
              </div>
              <div id="chart">
                <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                  [dataLabels]="chartOptions.dataLabels" [legend]="chartOptions.legend" [colors]="chartOptions.colors">
                </apx-chart>
              </div>
              <div class="text-center">
                <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
              </div>
            </ng-container>
            <ng-template #nodata>
              <div class="alert alert-info" role="alert">
                <strong>No hay data disponible</strong>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

