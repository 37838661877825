import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Country } from '../interfaces/country';
import { DataResponse } from '../interfaces/response';
import { Province } from '../interfaces/province';
import { City } from '../interfaces/city';
import { Township } from '../interfaces/township';
import { Bank, BranchesLaw, StudyLevel, Product, ServiceType } from '../interfaces/common';
import Swal from 'sweetalert2';

export type Icons = 'success' | 'error' | 'warning' | 'info' | 'question';
@Injectable( {
  providedIn: 'root'
} )
export class CommonService {

  constructor(
    private http: HttpService
  ) { }

  /**
   * @description Obtiene el listado de paises
   * @returns Listado de paises
   */
  countries(): Observable<DataResponse<Country[]>> {
    return this.http.get( `countries` );
  }

  /**
   * @description Obtiene el listado de provincias de un pais
   * @param id Id del País donde se encuentra la Ciudad
   * @returns Listado de provincias
   */
  provinces( id: number ): Observable<DataResponse<Province[]>> {
    return this.http.get( `provinces?country_id=${id}` );
  }

  /**
   * @description Obtiene el listado de ciudates de una provincia
   * @param id Id de pa provincia donde se encuentra la ciudad
   * @returns Lisato de ciudades
   */
  cities( id: number ): Observable<DataResponse<City[]>> {
    return this.http.get( `cities?province_id=${id}` );
  }

  /**
   * @description Obtiene el listado de corregimientos de una ciudad
   * @param id Id de la Ciudad donde se encuentra el Corregimiento
   * @returns Listado de corregimientos
   */
  townships( id: number ): Observable<DataResponse<Township[]>> {
    return this.http.get( `townships?city_id=${id}` );
  }

  banks(): Observable<DataResponse<Bank[]>> {
    return this.http.get( 'banks' );
  }

  brachLawyer(): Observable<DataResponse<BranchesLaw[]>> {
    return this.http.get( 'branch-laws' );
  }

  studyLevel(): Observable<DataResponse<StudyLevel[]>> {
    return this.http.get( 'study-levels' );
  }

  products(): Observable<DataResponse<Product[]>> {
    return this.http.get( `products` );
  }

  typeService(): Observable<DataResponse<ServiceType[]>> {
    return this.http.get( `type-services` );
  }

  getallrol(search): Observable<DataResponse<any[]>> {
    return this.http.get( `roles?search=${search}` );
  }

  GetPayment_Methods(): Observable<DataResponse<any[]>>{
    return this.http.get( `payment-methods` );
  }

  GetAccountBank(): Observable<DataResponse<any[]>>{
    return this.http.get( `admin-banks?includes[]=bank&principal=1` );
  }
}
