import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { DataResponse } from '../interfaces/response';
import { Observable } from 'rxjs';


@Injectable( {
  providedIn: 'root'
} )
export class PaymentsService {

  constructor(
    private http: HttpService
  ) { }


  /**
   * @description Lista de los metodos de pago
   * @return lista
   */
   getPaymentMethods(): Observable<any> {
    return this.http.get( `payment-methods`);
  }

  /**
   * @description Gestiona el pago con tarjeta por credicorp
   * @param data data de la tarjeta
   * @return reference_number
   */
  credicorp( data: any ): Observable<any> {
    return this.http.post( `credicorp`, data );
  }

  /**
 * @description Registra un pago en la base de datos
 * @param data ref + monto
 * @return mensaje de exito
 */
  registerPay( data: any ): Observable<any> {
    return this.http.post( `order-payments`, data );
  }

  registerPayProducts( data: any ): Observable<any> {
    return this.http.post( `lawyer-products-payments`, data );
  }




}
