<div class="content-body d-flex">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>{{titlePage}}</h3>
        <div>
          Crear servicio
          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
            (click)="add(serviceModal)" placement="top" ngbTooltip="Nuevo servicio">
            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color:#212F54">
              add_circle_outline
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="services.length; else elseTemplate">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Servicio</th>
                  <th scope="col">Tarifa</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let service of services; let i=index">
                  <td class="text-capitalize">{{service.name}}</td>
                  <td class="text-capitalize">{{service.comission_price | currency}}</td>
                  <td>
                    <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                      (click)="edit(serviceModal, service)" placement="top" ngbTooltip="Editar">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                        edit
                      </mat-icon>
                    </button><button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                      (click)="delete(service.id)" placement="top" ngbTooltip="Eliminar">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #dc3545;">
                        delete
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
          <div class="row justify-content-end" *ngIf="dataPagination?.lastPage>1">
            <div class="col-auto custom-div-pagination justify-content-center">
              <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
                  [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
                  aria-label="Default pagination"></ngb-pagination>
            </div>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>

<ng-template #serviceModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isEdit ? 'Editar' : 'Agregar'}} Servicio</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-valide" action="#" method="post" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Nombre</strong></label>
            <input type="text" class="form-control" formControlName="name">
            <div *ngIf="submitted && f.name?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.name.errors.required">
                Nombre requerido.
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Tarifa</strong></label>
            <input type="number" class="form-control" formControlName="comission_price">
            <div *ngIf="submitted && f.comission_price?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.comission_price.errors.required">
                Tarifa requerida.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Rama del derecho</strong></label>
            <select class="form-control" name="branch_law_id" id="branch_law_id" formControlName="branch_law_id">
              <option *ngFor="let branch of branchs" [value]="branch.id">{{branch.name}}</option>
            </select>
            <div *ngIf="submitted && f.branch_law_id?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.branch_law_id.errors.required">
                Rama del derecho requerido.
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Tipo de servicio</strong></label>
            <select class="form-control" name="type_service_id" id="type_service_id" formControlName="type_service_id">
              <option *ngFor="let type of typesServices" [value]="type.id">{{type.name}}</option>
            </select>
            <div *ngIf="submitted && f.type_service_id?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.type_service_id.errors.required">
                Tipo de servicio requerido.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-right">
        <button type="button" class="btn btn-outline-danger mr-2" (click)="modal.dismiss()">Cancelar</button>
        <button type="submit" class="btn btn-primary">{{isEdit ? 'Editar' : 'Agregar'}}</button>
      </div>
    </form>
  </div>
</ng-template>