import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../shared/services/storage.service';
import { LAWYER_TYPE } from '../../../shared/data/constants';

@Component( {
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.css' ]
} )
export class DashboardComponent implements OnInit {

  lawyerType: string;

  constructor(
    private storage: StorageService,
  ) {
    this.lawyerType = this.storage.getItem( LAWYER_TYPE );
  }

  ngOnInit(): void {
  }

}
