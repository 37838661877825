import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { DataResponse } from '../interfaces/response';

@Injectable( {
  providedIn: 'root'
} )
export class DashboardService {

  constructor(
    private http: HttpService
  ) { }

  get(): Observable<DataResponse<any>> {
    return this.http.get( `dashboard` );
  }

  getDashboardAdmin(): Observable<DataResponse<any>> {
    return this.http.get( `dashboard` );
  }
}
