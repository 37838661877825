<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>{{titlePage}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-header d-block pb-0">
            <form class="">
              <div class="form-row col-12">
                <div class="form-group hidden">
                  <div class="input-group">
                    <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                      [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                      [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end align-items-end">
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Desde</strong></label>
                    <div class="input-group">
                      <input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Hasta</strong></label>
                    <div class="input-group">
                      <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Rama</strong></label>
                    <select class="form-control form-filter" name="type" id="type"  [(ngModel)]="searchFilter.branch">
                      <option selected value="">Todos</option>
                      <option [value]="branch.id" *ngFor="let branch of branches">{{branch.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Tipo de Servicio</strong></label>
                    <select class="form-control form-filter" name="type" id="type"  [(ngModel)]="searchFilter.type">
                      <option selected value="">Todos</option>
                      <option [value]="type.id" *ngFor="let type of types">{{type.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row row justify-content-start align-items-end">
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Nombre de Servicio</strong></label>
                    <input class="form-control form-filter" name="service_name" id="service_name" [(ngModel)]="searchFilter.service_name">
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Abogado</strong></label>
                    <input class="form-control form-filter" name="lawyer_name" id="lawyer_name" [(ngModel)]="searchFilter.lawyer_name">
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <div class="input-group">
                      <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                        <i class="fa fa-search"></i>
                      </button>
                      <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <ng-container *ngIf="list.length; else nodata">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Abogado</th>
                      <th scope="col">concepto</th>
                      <th scope="col">Mesualidad/Anualidad</th>
                      <th scope="col">Categoria</th>
                      <th scope="col">Impuesto</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of list; let i=index">
                      <td>{{item.user.creation_date}}</td>
                      <td>{{item.user.full_name}}</td>
                      <td>{{item.plan.name}}</td>
                      <td>{{(item.plan_price == 0)?'Gratis': "$ "+item.plan_price}} /{{(item.plan_price == 0)?'Gratis': "$ "+item.plan_price*12}} </td>
                      <td>
                        <div *ngFor="let product of item.products; let i=index" >
                            <p> {{ product.name }}</p>
                        </div>
                      </td>
                      <td>{{ (item.plan?.is_free == 1)? 0:"$ "+item.plan_tax }}</td>
                      <td>{{ (item.plan?.is_free == 1)? 0:"$ "+ (item.plan_tax + item.plan_price) }}</td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="list.length" class="col-12 mr-2 text-right">
                  <p><b>Total Ingresos Brutos:</b> $ {{info_object.total_gross_income}}</p>
                  <p><b>Total impuestos:</b> $ {{info_object.total_comission_price}}</p>
                  <p><b>Total Ingresos:</b> $ {{info_object.total_net_income}}</p>
              </div>
              </div>
             <div id="chart">
                <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                  [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                  [yaxis]="chartOptions.yaxis" [xaxis]="chartOptions.xaxis" [legend]="chartOptions.legend"
                  [colors]="chartOptions.colors" [grid]="chartOptions.grid"></apx-chart>
              </div>

              <div class="row justify-content-end">
                <div class="col-auto custom-div-pagination justify-content-center">
                  <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                    [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="dataPagination.currentPage" aria-label="Paginación">
                  </ngb-pagination>
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
              </div>
            </ng-container>
            <ng-template #nodata>
              <div class="alert alert-info" role="alert">
                <strong>No hay data disponible</strong>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
