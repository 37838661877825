import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { DataResponse } from '../interfaces/response';
import { Consultance } from '../interfaces/consultance';

@Injectable( {
  providedIn: 'root'
} )
export class ConsultanciesService {

  constructor(
    private http: HttpService
  ) { }

  list( page = 1 ): Observable<DataResponse<Consultance[]>> {
    const params = {
      'includes[]': [ 'service', 'userApplicant' ],
      page
    };
    return this.http.get( 'consultancies/requestsForConsultancies', { params } );
  }


  listForLawnyer( page = 1 ): Observable<DataResponse<Consultance[]>> {
    const params = {
      'includes[]': [ 'service', 'userApplicant'  ],
      lawyer_id : JSON.parse( localStorage.getItem("ln_user") ).lawyer.id ,
      page
    };
    return this.http.get( 'consultancies/requestsForConsultancies', { params } );
  }



  details( id: number ): Observable<any> {
    return this.http.get( `consultancies/getDetailConsultancy/${id}?includes[]=service.branchLaw&includes[]=userApplicant` );
  }

  moreInfo( id: number, data: any ): Observable<any> {
    return this.http.post( `orders/requestForMoreInfo/${id}`, data );
  }

  /**
   *
   * @param id Id del usuario
   * @param decision 1 aprobado | 0 rechazado
   * @param noConflict 1 sin conflictos | 0 con conflicto
   * @returns Result
   */
  registerDecision( id: number, decision: number, noConflict: number ): Observable<any> {
    return this.http.put( `consultancies/registerDecisionOnRequestForConsultancy/${id}`,
      { approved_by_lawyer: decision, no_conflict_interest: noConflict }
    );
  }

  /**
   *
   * @param id Id del cliente
   * @returns Listado de asesorias por pagar
   */

   getConsultanciesClient( id: number, page: number): Observable<any> {
    return this.http.get( `consultancies/requestsForConsultancies?includes[0]=userApplicant&page=${page}&user_id=${id}&includes[1]=service&includes[2]=userApplicant` );
   }

   /**
   *
   * @param id Id del cliente
   * @returns Listado de asesorias por aprobar
   */

  getConsultanciesLawyer( id: number, page: number): Observable<any> {
    return this.http.get( `consultancies/requestsForConsultancies?includes[0]=userApplicant&page=${page}&lawyer_id=${id}&includes[1]=service&includes[2]=userApplicant` );
  }


}
