<div class="row justify-content-center mt-2" >
    <div class="col-12" *ngIf="!ShowChart">
            <h5>No day Datos Disponibles</h5>

    </div>
    <div class="col-auto" id="pieChart">
    
        <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [labels]="chartOptions.labels"
            [responsive]="chartOptions.responsive"
            [dataLabels]="chartOptions.dataLabels"
            [stroke]="chartOptions.stroke"
            [colors]="chartOptions.colors"
            [legend]="chartOptions.legend"
        ></apx-chart>
        
    </div>
    <div class="col-12"></div>
    <div class="col-auto pt-3" *ngIf="ShowChart && TypeChart === 'Platform'">
        <div class="d-flex mb-4">
            <svg class="mr-sm-3 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" rx="4" fill="#5EE173"/>
            </svg>
            <div>
                <p class="fs-14 text-black mb-0">Completados</p>
                <span class="fs-20 text-black font-w600">{{Serie1}}%</span>
            </div>
        </div>
        <div class="d-flex">
            <svg class="mr-sm-3 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" rx="4" fill="#3A82EF"/>
            </svg>
            <div>
                <p class="fs-14 text-black mb-0">Pendientes</p>
                <span class="fs-20 text-black font-w600">{{Serie2}}%</span>
            </div>
        </div>
    </div>
    <!--  -->
    <div class="col-12"></div>
    <div class="col-auto"  *ngIf="ShowChart && TypeChart === 'Marketplace'">
        <div class="d-flex flex-row">
                <div class="mr-4">
                    <div class="d-flex mb-4">
                        <svg class="mr-sm-3 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="14" height="14" rx="4" fill="#3A82EF"/>
                        </svg>
                        <div>
                            <p class="fs-14 text-black mb-0">Penal</p>
                            <span class="fs-20 text-black font-w600">{{Serie1}}%</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <svg class="mr-sm-3 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="14" height="14" rx="4" fill="#EE3CD2"/>
                        </svg>
                        <div>
                            <p class="fs-14 text-black mb-0">Mercantil</p>
                            <span class="fs-20 text-black font-w600">{{Serie2}}%</span>
                        </div>
                    </div>
                </div>
                <!--  -->
                <div >
                    <div class="d-flex mb-4">
                        <svg class="mr-sm-3 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="14" height="14" rx="4" fill="#FFB038"/>
                        </svg>
                        <div>
                            <p class="fs-14 text-black mb-0">Civil</p>
                            <span class="fs-20 text-black font-w600">{{Serie3}}%</span>
                        </div>
                    </div>
                    <div class="d-flex ">
                        <svg class="mr-sm-3 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="14" height="14" rx="4" fill="#FF495F"/>
                        </svg>
                        <div>
                            <p class="fs-14 text-black mb-0">Familia</p>
                            <span class="fs-20 text-black font-w600">{{Serie4}}%</span>
                        </div>
                    </div>
                </div>
        </div>
    
    
    </div>
</div>

<div class="d-flex mt-4" *ngIf="ShowChart && TypeChart === 'Marketplace'">

    <div class="d-flex mr-3">
        <svg class="mr-sm-3 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="14" height="14" rx="4" fill="#5EE173"/>
        </svg>
        <div>
            <p class="fs-14 text-black mb-0">Migratorio</p>
            <span class="fs-20 text-black font-w600">{{Serie5}}%</span>
        </div>
    </div>
    <div class="d-flex mr-3">
        <svg class="mr-sm-3 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="14" height="14" rx="4" fill="#212F54"/>
        </svg>
        <div>
            <p class="fs-14 text-black mb-0">Administrativo</p>
            <span class="fs-20 text-black font-w600">{{Serie6}}%</span>
        </div>
    </div>
    <div class="d-flex">
        <svg class="mr-sm-3 mr-2 mt-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="14" height="14" rx="4" fill="#CABAAB"/>
        </svg>
        <div>
            <p class="fs-14 text-black mb-0">Sucesiones</p>
            <span class="fs-20 text-black font-w600">{{Serie7}}%</span>
        </div>
    </div>
</div>