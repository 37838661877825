<!--**********************************
    Content body start
***********************************-->
<div class="content-body d-flex align-items-center">
    <div class="container-fluid p-4">
        <div class="d-grid g-style-1">
            <div class="card b-d-shadow">
                <div class="card-body p-4">
                    <h3 class="card-title">Perfil</h3>
                    <div class="profile-photo text-center">
                        <img *ngIf="data.user?.image" src="{{data.user?.image}}" class="img-c rounded-circle w-100 mw-300" [alt]="data.user?.name">
                        <svg *ngIf="!data.user?.image" class="img-not-found" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" fill="#231f40"/></svg>
                    </div>
                    <div class="pd-2 mt-3 fs-14 d-grid g-style-4">
                        <div>
                            <p class="m-0"><b>Nombre</b></p>
                            <p class="m-0">{{data.user?.name}}</p>
                        </div>
                        <div>
                            <p class="m-0"><b>Rol</b></p>
                            <p class="m-0">{{data.user?.type}}</p>
                        </div>
                        <div>
                            <p class="m-0"><b>Cédula</b></p>
                            <p class="m-0">{{dni}}</p>
                        </div>
                        <div>
                            <p class="m-0"><b>Teléfono</b></p>
                            <p class="m-0">{{phone}}</p>                            
                        </div>
                        <div>
                            <p class="m-0"><b>Dirección</b></p>
                            <p class="m-0">{{address}}</p>
                        </div>
                    </div>
                    <button (click)="onProfile()" class="btn btn-primary btn-lg btn-block mt-3 fs-16">
                        Actualizar Perfil						
                    </button>
                </div>
            </div>
            <div class="card b-d-shadow p-4">
                <div class="card-body d-contents">
                    <h3 class="card-title">Trámites Recientes</h3>
                    <div class="d-block">
                        <div class="d-grid g-style-2">
                            <div class="widget-stat card b-d-shadow-sm">
                                <div class="card-body p-3">
                                    <div class="media d-grid g-style-3">
                                        <div class="media-body text-left">
                                            <p class="mb-1">Servicios<br> Activos</p>
                                            <h3 class="text-nowrap text-center">{{data.active_service}}</h3>
                                        </div>
                                        <span class="pd-0 span-circle">
                                            <i class="las la-hand-holding-usd"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-stat card b-d-shadow-sm">
                                <div class="card-body p-3">
                                    <div class="media d-grid g-style-3">
                                        <div class="media-body text-left">
                                            <p class="mb-1">Nuevas<br> cotizaciones</p>
                                            <h3 class="text-nowrap text-center">{{data.active_orders}}</h3>
                                        </div>
                                        <span class="pd-0 span-circle">
                                            <i class="las la-newspaper"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="widget-stat card b-d-shadow-sm">
                                <div class="card-body p-3">
                                    <div class="media d-grid g-style-3">
                                        <div class="media-body text-left">
                                            <p class="mb-1">Monto por<br> Servicios</p>
                                            <h3 class="text-nowrap text-center">${{data.payment_invert_in_service | number : '1.2-2'}}</h3>
                                        </div>
                                        <span class="pd-0 span-circle">
                                            <i class="la la-dollar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card b-d-shadow-sm m-0 o-hidden">
                        <div class="card-body p-0">
                            <div class="table-responsive recentOrderTable h-100">
                                <table class="table bg-info-hover m-0 tr-rounded table-sm verticle-middle table-responsive-md">
                                    <thead>
                                        <tr>
                                            <th scope="col">Servicio</th>
                                            <th scope="col">Abogado</th>
                                            <th scope="col">Fecha solicitud</th>
                                            <th scope="col">Fase</th>
                                            <th scope="col">Costo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let d of data.order_last; let i = index" class="{{ (i%2 == 0 || i==0) ? 'tr-darker' : '' }}">
                                            <td>{{d.service}}</td>
                                            <td>{{d.lawyer}}</td>
                                            <td class="text-center">{{d.date | date:'dd/MM/yyyy' }}</td>
                                            <td>
                                                <span class="badge badge-rounded badge-blue">
                                                {{d.phase.description}}
                                                </span>
                                            </td> 
                                            <td>${{d.cost}}</td>
                                        </tr>                                   
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--**********************************
    Content body end
***********************************-->