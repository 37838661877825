import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { Company, Person } from '../interfaces/user';
import { Lawyer } from '../interfaces/user';
import { DataResponse } from '../interfaces/response';
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpService) {}

  updateNaturalPerson(
    id: number,
    body: Omit<Person, 'id' | 'created_at' | 'update_at' | 'user_id'>
  ): Observable<any> {
    return this.http.put(`persons/${id}`, body);
  }

  updateLegalPerson(
    id: number,
    body: Omit<
      Company,
      'id' | 'created_at' | 'update_at' | 'dni_file' | 'user_id' | 'taxpayer'
    >
  ): Observable<any> {
    return this.http.put(`companies/${id}`, body);
  }
  getLawyer(id: number): Observable<DataResponse<Lawyer>>{
    return this.http.get(`lawyers/${id}?includes[]=user&includes[]=bank&includes[]=branchLaws&includes[]=studyLevels&includes[]=township`);
  }
  updateLawyer(lawyer: Lawyer): Observable<DataResponse<Lawyer>>{
    return this.http.put(`lawyer/${lawyer.id}`, lawyer);
  }
}
