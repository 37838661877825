import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../../../../shared/services/quote.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import Swal from 'sweetalert2';

@Component( {
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: [ './quotes.component.css' ]
} )
export class QuotesComponent implements OnInit {

  items = [];
  item: any;

  submitted = false;
  miForm: FormGroup;
  docsFormData: FormData;

  modalDetail: NgbModalRef;
  moreInfoModal: NgbModalRef;
  moreInfo = null;
  noConflict = null;

  linkUrl: string;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private quoteService: QuoteService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: 'Solicitudes de cotizaciones'};
    this.loadData();
    this.createForm();
  }

  // tslint:disable-next-line: typedef
  get g() { return this.miForm.controls; }

  ngOnInit(): void {
    this.docsFormData = new FormData();
  }

  registerDecision( id: number ): void {
    this.docsFormData.set( 'no_conflict_interest', this.noConflict );
    this.docsFormData.set( 'approved_by_lawler', '1' );


    console.log(this.docsFormData.get('quote_file'))

    if(this.docsFormData.get('quote_file')){

      this.quoteService.accept( id, this.docsFormData ).subscribe( response => {
        this.modalDetail.dismiss();
        this.noConflict = null;
        this.loadData();
      } );
    }else{
      Swal.fire( { text: "Es obligatorio cargar el documento de cotización", icon: 'warning' } );
    }


  }

  onSubmitInfo(): void {
    this.submitted = true;
    if ( this.miForm.valid ) {
      this.quoteService.moreInfo( this.item.id, this.miForm.value ).subscribe( response => {
        this.submitted = false;
        if ( response.status === 'Success' ) {
          this.moreInfo = this.miForm.controls.body.value;
        }
        this.closeMoreInfoModal();
      } );
    }
  }

  getDocTemplate(){
    this.quoteService.generateTemplate(this.item.id).subscribe(
      response =>{
        let data = response.data

        if(data.generate){
          window.open(data.url, "_blank").focus();
      
        }

      }
    )


  }

  closeDetailModal(): void {
    this.noConflict = null;
    this.modalDetail.dismiss();
  }

  openDetailModal( modalDetail: any, id: number ): void {
    forkJoin( [ this.quoteService.details( id ), this.quoteService.getQuoteFormat() ] ).subscribe( ( [ response, result ] ) => {
      this.item = { ...response.data };
      this.modalDetail = this.modalService.open( modalDetail, { backdrop: 'static', size: 'lg' } );
      this.linkUrl = result.data.file_url;
    } );
  }

  openMoreInfoModal( moreInfoModal: any, item ): void {
    this.item = item;
    this.moreInfoModal = this.modalService.open( moreInfoModal, { backdrop: 'static' } );
  }

  closeMoreInfoModal(): void {
    this.moreInfoModal.dismiss();
  }

  loadDoc( event: any ): void {
    const file: File = event.target.files[ 0 ];
    this.docsFormData.set( 'quote_file', file, file.name );
  }

  private loadData( page = 1 ): void {
    this.quoteService.list( page ).subscribe( response => {
      this.items = [ ...response.data ];
    } );
  }

  private createForm(): void {
    this.miForm = this.fb.group( {
      body: [ '', [ Validators.required ] ],
    } );
  }

}
