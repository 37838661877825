import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-service-completed',
  templateUrl: './service-completed.component.html',
  styleUrls: ['./service-completed.component.css']
})
export class ServiceCompletedComponent implements OnInit {
  Object = [];
  active = 1;
  constructor(
    titlePageService: TitlePageService
  ) { 
    titlePageService.titlePageObservableData = { name: 'Servicios Culminados'};
  }

  ngOnInit(): void {
  }

}
