import { Role } from './../../../shared/interfaces/user';
import { CommonService } from './../../../shared/services/common.service';
import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'src/app/shared/services/admin.service';
import {  NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-management-user',
  templateUrl: './management-user.component.html',
  styleUrls: ['./management-user.component.css']
})
export class ManagementUserComponent implements OnInit {
  TitleModal = "Registrar Usuario";
  form: FormGroup;
  submitted = false;
  RolList = [];
  UsersList = [];
  RolValueSelected = '0';
  ButtonTextModal = true;
  IdUserEdit= '';

  Filters = {
    name : '',
    rol : ''
  }
  Filter1 = '';
  Filter2 = '';
  titlePage = 'Usuarios Disponibles';
  constructor(
    private modalService: NgbModal,     
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private commons:CommonService,
    private sadmin:AdminService,
    titlePageService: TitlePageService
    ) {
      titlePageService.titlePageObservableData = { name: this.titlePage};
      this.GetRolAll();
   }

  ngOnInit(): void {
    this.Filter();
    this.createForm();
  }


  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }


  GetListUser(){
    this.sadmin.listUser(this.Filter2+this.Filter1).subscribe(
      response=>{
        // console.log(response);
        this.UsersList = response.data;
      }
    );
  }


  GetRolAll(){
    this.commons.getallrol('admin').subscribe(
      response=>{
        // console.log(response);
        this.RolList = response.data;
      }
    );
  }
  
  get f(): any { return this.form.controls; }

  private createForm(): void {
    this.form = this.fb.group(
      {
        name: [ '', [ Validators.required ] ],
        last_name: [ '', [ Validators.required ] ],
        role_id: [ '', [  ] ],
        email: [ '', [ Validators.required, Validators.email ] ],
        password: [ '', [ Validators.required, Validators.minLength( 8 ) ] ]
      }
    );
  }

  onSubmit(event){
      this.submitted = true;
      // this.spinner.show();
      this.form.value.role_id = this.RolValueSelected;
      console.log(event);
      if(this.form.valid){
        if(event === 'edit'){
          this.EditUser();
        }else{
          this.AddUser();
        }
    
      }
  }


  AddUser(){
    this.sadmin.addUser( this.form.value).subscribe(
      response=>{
        // this.spinner.hide();
        //this.toastsService.show( response.message, { classname: 'bg-success text-white' } );
        Swal.fire( { text: response.message, icon: 'success' } );
        this.modalService.dismissAll();
        this.GetListUser();
        this.CleanForm();
      },
      error=>{
        // this.spinner.hide();
        console.log(error);
       
         
      }
    );
  }

  EditUser(){
    this.sadmin.editUser( this.IdUserEdit, this.form.value).subscribe(
      response=>{
        // this.spinner.hide();
        //this.toastsService.show( response.message, { classname: 'bg-success text-white' } );
        Swal.fire( { text: response.message, icon: 'success' } );
        this.modalService.dismissAll();
        this.GetListUser();
        this.CleanForm();
        this.IdUserEdit = '';
      },
      error=>{
        // this.spinner.hide();
        console.log(error);
       
         
      }
    );
  }

  onChange(value){
    console.log(value);
    this.RolValueSelected = value;
  } 


  CleanForm(){
    this.form.reset();
    this.submitted = false;
    this.ButtonTextModal = !this.ButtonTextModal;
  }


  ReturnRolUser(object,dato){
    if(dato==='id'){
      return object.roles[0]["id"];
    }else{
        if(object.hasOwnProperty('roles')){
          return object.roles[0]["translated_name"];
      }else{
        return 'Sin rol';
      }
    }
     
  }


  EditUserForm(user,content){
    this.ButtonTextModal= false;
    this.form.patchValue(user);
    this.IdUserEdit = user.id;
    this.RolValueSelected = this.ReturnRolUser(user,'id');
    console.log( this.IdUserEdit);
    this.open(content);
  }


  DeleteUser(user){
      let userid = user.id;
      this.sadmin.deleteUser(userid).subscribe(
        response=>{
          //this.toastsService.show( response.message, { classname: 'bg-success text-white' } );
          Swal.fire( { text: response.message, icon: 'success' } );
          this.GetListUser();
        }
      );
  }

  Filter(time=null){
      if(this.Filters.name != ''){
          this.Filter1 = '&search='+this.Filters.name;
      }else{
        this.Filter1 = '';
      }

      if(this.Filters.rol != ''){
        this.Filter2 = '&roles[]='+this.Filters.rol ;
      }else{
        this.Filter2 = '&roles[]=admin&roles[]=admin.finance&roles[]=admin.settings';
      }

   
     
      if(time != null && time){
        this.GetListUser();
      }else{
        setTimeout( () => {
          this.GetListUser();
        }, 1000 );
      }
  }

  CleanFilter(){
    this.Filters.name = ''; this.Filters.rol = '';
    this.Filter(true);
  }
}
