import { Component, OnInit } from '@angular/core';
import { Consultance } from 'src/app/shared/interfaces/consultance';
import { ConsultanciesService } from 'src/app/shared/services/consultancies.service';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '../../../../shared/interfaces/user';
import { AuthService } from '../../../../shared/services/auth.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component( {
  selector: 'app-consultancies',
  templateUrl: './consultancies.component.html',
  styleUrls: [ './consultancies.component.css' ]
} )
export class ConsultanciesComponent implements OnInit {

  items: Consultance[] = [];
  modalDetail: NgbModalRef;
  moreInfoModal: NgbModalRef;

  item: any = {};
  submitted = false;

  miForm: FormGroup;
  noConflict = null;

  moreInfo = '';

  private _user: User;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private modalService: NgbModal,
    private consService: ConsultanciesService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: 'Solicitudes de asesorías'};
    this.loadData();
    this.createForm();
  }

  // tslint:disable-next-line: typedef
  get g() { return this.miForm.controls; }

  ngOnInit(): void {
    this._user = this.auth.getUserActive();
  }

  registerDecision( id: number, decision: number ): void {
    this.consService.registerDecision( id, decision, +this.noConflict ).subscribe( response => {
      this.modalDetail.dismiss();
      this.noConflict = null;
      this.loadData();
    } );
  }

  onSubmitInfo( item ): void {
    this.submitted = true;
    if ( this.miForm.valid ) {
      this.consService.moreInfo( this.item.id, this.miForm.value ).subscribe( response => {
        this.submitted = false;
        if ( response.status === 'Success' ) {
          this.moreInfo = this.miForm.controls.body.value;
        }
        this.closeMoreInfoModal();
      } );
    }
  }

  openMoreInfoModal( moreInfoModal: any, item ): void {
    this.item = item;
    this.moreInfoModal = this.modalService.open( moreInfoModal, { backdrop: 'static' } );
  }

  closeMoreInfoModal(): void {
    this.moreInfoModal.dismiss();
  }

  openDetailModal( modalDetail: any, item: Consultance ): void {
    this.consService.details( item.id ).subscribe( response => {
      this.item = { ...response.data };
      this.modalDetail = this.modalService.open( modalDetail, { backdrop: 'static', size: 'lg' } );
    } );
  }

  closeDetailModal(): void {
    this.noConflict = null;
    this.modalDetail.dismiss();
  }

  private createForm(): void {
    this.miForm = this.fb.group( {
      body: [ '', [ Validators.required ] ]
    } );
  }

  private loadData( page = 1 ): void {
    this.consService.listForLawnyer( page ).subscribe( response => {
      this.items = [ ...response.data ];
    } );
  }

}
