import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { BranchesLaw } from '../../../shared/interfaces/common';
import { MyDocumentsService } from 'src/app/shared/services/my-documents.service';
import { USER } from '../../../shared/data/constants';
import { forkJoin, from } from 'rxjs';
import { StorageService } from '../../../shared/services/storage.service';
import Swal from '../../../shared/helpers/sweetalert';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-my-documents-platform',
  templateUrl: './my-documents-platform.component.html',
  styleUrls: ['./my-documents-platform.component.css']
})
export class MyDocumentsPlatformComponent implements OnInit {
  List = [];
  Lista = [
  {
    name: 'sdfsf 1',
    creation_date: '2021/12/01'
  },
  {
    name: 'sdfsf 2',
    creation_date: '2021/12/12'
  }
  ];
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  
  lawyerId='';
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    name: null
  }
  fileName = 'Seleccione archivo';
  private _docFile = '';
  formDoc: FormGroup;
  docFormData: FormData;
  modal: any;
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private myDocumentsService: MyDocumentsService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private storage: StorageService,
    titlePageService: TitlePageService,
  ) { 
     titlePageService.titlePageObservableData = { name: 'Mis Documentos'};
      this.formDoc = this.fb.group({
        file_url: ['', [Validators.required]],
      });

      this.lawyerId =  this.storage.getItem(USER).lawyer.id;

    
  }

  get d(): any { return this.formDoc.controls; } 

  onDocChange( e ): void {
    const reader = new FileReader();
    if ( e.target.files && e.target.files.length ) {
      const [ file ] = e.target.files;
      this.fileName = file.name;
      reader.readAsDataURL( file );
      reader.onload = () => {
        this._docFile = reader.result as string;
      };
    }
  }

  ngOnInit(): void {
    this.loadDoc();
  }

  clearFilter() {
    this.loadDoc();
    this.toDate = null;
    this.fromDate = null;
  }

  search(){
    this.searchFilter.dateStart = this.formatter.format( this.fromDate );
    this.searchFilter.dateEnd  = this.formatter.format( this.toDate );
    let cadena = '';
 
    if(this.searchFilter.dateStart!=='') {
      cadena = cadena+'&start_date='+this.searchFilter.dateStart;
    }
    
    if( this.searchFilter.dateEnd!=='') {
      cadena = cadena+'&end_date='+this.searchFilter.dateEnd;
    }

    if( this.searchFilter.name!=='') {
      cadena = cadena+'&search='+this.searchFilter.name;
    }

    //console.log(cadena);
    if (cadena !== '') {
      this.myDocumentsService.documentsLaywerFilter(cadena).subscribe( response => {
        this.List = [ ...response.data ];
        //console.log(response.data);
      });
    }
  }

  openUploadDocModal( uploadDocModal: any ): void {
    this.modal = this.modalService.open( uploadDocModal );
  }

  onSubmit() : void {
    //console.log(this.formDoc.value);
    if ( this.formDoc.valid ) {

      const formDoc = document.getElementById('formDoc') as HTMLFormElement;
      this.docFormData = new FormData(formDoc);
      this.docFormData.append('name', this.fileName);
      this.docFormData.append('lawyer_id', this.lawyerId.toString());
      this.myDocumentsService.uploadDocumentsLaywerExternal(this.docFormData).subscribe( response => {
          Swal.fire( { text: 'Registro de documento exitoso', icon: 'success' } );
          this.fileName = '';
          this.modal.dismiss();
          this.loadDoc();
      });
      
    } else {
      Swal.fire( { text: 'Debe cargar un archivo', icon: 'success' } );

    }
 
  }


  loadDoc() {
    this.myDocumentsService.documentsLaywer().subscribe( response => {
      this.List = [ ...response.data ];
      //console.log(response.data);
    });
  }

  deleteDoc(id: number) {
    //console.log(id);
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea eliminar este documento?',
      icon: 'warning',
      showCancelButton: true,
    //  confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, eliminar!',
      cancelButtonText: 'Cancelar'
    } ).then( ( result ) => {
      if ( result.isConfirmed ) { 

        this.myDocumentsService.deleteDocumentsLaywerExternal(id).subscribe( response => {
          this.loadDoc();
          Swal.fire( { text: 'Documento eliminado con exito', icon: 'success',showConfirmButton: false, } );
        });
        
      }
    } );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
