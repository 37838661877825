import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AVATAR } from 'src/app/shared/data/constants';
import { AdminService } from 'src/app/shared/services/admin.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-lawyers-report',
  templateUrl: './lawyers-report.component.html',
  styleUrls: ['./lawyers-report.component.css']
})
export class LawyersReportComponent implements OnInit {

  avatar = AVATAR;
  modal: any;
  params = '';
  branches = [];
  listPomotion: any = [];
  planList: any = [];
  types = [];
  list = [];
  showLawyer: any;
  page; dataPagination;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    name: null,
    branch: '',
    plan: '',
  }
  form_plan = {
    plan_id: 0,
    subscription_type: '',
  } 
  plan_input = '';
  current_plan = 0;
  selected_lawyer = null;
  hoveredDate: NgbDate | null = null;
  titlePage='Reporte de Abogados';
  constructor(
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private adminService: AdminService,
    titlePageService: TitlePageService
  ) { 
    titlePageService.titlePageObservableData = { name: this.titlePage};
  }

  ngOnInit(): void {
    this.loadData();
    this.loadFilters();
  }

  loadData(page = 1) {
    console.log(this.params);
    this.adminService.getLawyersReport(page, this.params).subscribe(
      response => {
        this.list = response.data;
        this.dataPagination = response.meta.page;
    });
  }

  loadFilters() {
    this.adminService.listBranchs().subscribe(
      response => {
        this.branches = response.data;
      },
      (error) => {
        console.error(error)
      }
    );
    this.adminService.listPlans().subscribe(
      response => {
        this.listPomotion = response.data;
        this.planList     = this.listPomotion.map(function(item){
          if(item.id == 1) item.disabled = true; else item.disabled = false;
          return item;
        })
      },
      (error) => {
        console.error(error)
      }
    );
  }

  downloadPDF() {
     this.adminService.getLawyersReport(null, this.params, true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Reporte de Abogados.pdf";
        link.click();
    });
  }

  disableLawyer(id) {
    Swal.fire({
      title: 'Atención!',
      text: "¿Seguro que desea continuar?",
      icon: 'warning',
      showConfirmButton: true,
     }).then((result) => {
       if (result.isConfirmed) {
         this.adminService.disableLawyer(id).subscribe(
           response => {

           }
         );
       }
     }
    );
  }

  openModal(detailModal: any, lawyer) {
    this.adminService.getLawyer(lawyer.id).subscribe(response => {
      this.showLawyer = response.data;
    })
    this.modal = this.modalService.open(detailModal, { size: 'lg' });
  }

  openLawyerPlanModal(modal: any, lawyer) {
    this.form_plan = {
      plan_id: lawyer.plan.id,
      subscription_type: (lawyer?.plan_type && lawyer?.plan_type != null) ? lawyer.plan_type : '',
    }
    this.current_plan = lawyer.plan.id;
    this.selected_lawyer = lawyer;
    this.modal = this.modalService.open(modal, { centered: true, animation: true });
  }

  saveLawyerPlan(modal){
    let date = moment().format("YYYY-MM-DD hh:mm:ss");
    if(this.form_plan.plan_id == 0){
      Swal.fire( { text: 'Debe seleccionar un plan', icon: 'warning' } );
      return;
    }
    if(('1-2-4'.indexOf(''+this.form_plan.plan_id) > -1) && this.form_plan.subscription_type == ''){
      Swal.fire( { text: 'Debe seleccionar un tipo de suscripción', icon: 'warning' } );
      return;
    }
    if(this.form_plan.plan_id == this.current_plan && (this.selected_lawyer.plan_expired_at == null  || moment(this.selected_lawyer.plan_expired_at, "YYYY-MM-DD hh:mm:ss").unix() >= moment(date, "YYYY-MM-DD hh:mm:ss").unix())){
      Swal.fire( { text: "Este abogado ya posee este plan activo", icon: 'warning' } );
      return;
    }
    this.adminService.changePlan(this.selected_lawyer.id, this.form_plan).subscribe(response => {      
      Swal.fire( { text: response.message, icon: 'success' } );
      this.form_plan = {
        plan_id: 0,
        subscription_type: '',
      }
      this.current_plan = 0;
      this.selected_lawyer = null;
      this.modal = this.modalService.dismissAll('');
      this.loadData(this.page);
    })
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.params = '';
    this.loadData();
    this.searchFilter = {
      dateStart: null,
      dateEnd: null,
      name: null,
      branch: '',
      plan: ''
    }
    this.toDate = null;
    this.fromDate = null;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search() {
    this.searchFilter.dateStart = this.formatter.format(this.fromDate);
    this.searchFilter.dateEnd = this.formatter.format(this.toDate);
    this.params = '';

    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }

    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.searchFilter.branch && this.searchFilter.branch !== '') {
      this.params += '&branch_Law_name=' + this.searchFilter.branch.toLowerCase();
    }

    if (this.searchFilter.plan && this.searchFilter.plan !== '') {
      this.params += '&plan_id=' + this.searchFilter.plan.toLowerCase();
    }

    if (this.searchFilter.name && this.searchFilter.name !== '') {
      this.params += '&lawyer_name=' + this.searchFilter.name;
    }

    this.loadData(1);
  }

  getDate(date){
    return moment(date).format("DD-MM-YYYY");
  }

  isActive(){    
    let date = moment().format("YYYY-MM-DD hh:mm:ss");
    if(this.selected_lawyer.plan_expired_at == null) return true;
    if(moment(this.selected_lawyer.plan_expired_at, "YYYY-MM-DD hh:mm:ss").unix() >= moment(date, "YYYY-MM-DD hh:mm:ss").unix()){
      return true;
    }else{
      return false;
    }
  }
}
