<div class="content-body d-flex">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>Clientes</h3>
        <div>
          Crear cliente
          <button
            mat-icon-button
            color="accent"
            aria-label="Example icon button with a menu icon"
            (click)="add(clientModal)"
            placement="top"
            ngbTooltip="Nueva cliente"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Example home icon"
              style="color: #212f54"
            >
              add_circle_outline
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="clients.length; else nodata">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Cliente</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let client of clients; let i = index">
                  <td class="text-capitalize">{{ client.type }}</td>
                  <td>
                    {{
                      client.type === "Natural"
                        ? client.data_client.full_name
                        : client.data_client.business_name
                    }}
                  </td>
                  <td>{{ client.data_client.email }}</td>
                  <td>
                    <button
                      mat-icon-button
                      color="accent"
                      aria-label="Example icon button with a menu icon"
                      (click)="edit(clientModal, client)"
                      placement="top"
                      ngbTooltip="Editar"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        style="color: #212f54"
                      >
                        edit
                      </mat-icon></button
                    ><button
                      mat-icon-button
                      color="accent"
                      aria-label="Example icon button with a menu icon"
                      (click)="delete(client.id)"
                      placement="top"
                      ngbTooltip="Eliminar"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        style="color: #dc3545"
                      >
                        delete
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-template #nodata>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #clientModal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ isEdit ? "Editar" : "Agregar" }} Cliente
      </h4>
    </div>
    <div class="modal-body">
      <div class="form-row" *ngIf="!isEdit">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="mb-1"><strong>Tipo de cliente</strong></label>
            <select
              class="form-control"
              name="type"
              id="type"
              (change)="onChangeType()"
              [(ngModel)]="selectedType"
            >
              <option selected>Seleccione una opción</option>
              <option [value]="type.value" *ngFor="let type of types">
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <ng-container *ngIf="selectedType === 'Natural'">
        <form
          class="form-valide"
          action="#"
          method="post"
          [formGroup]="formNatural"
          (ngSubmit)="onSubmit()"
          novalidate
        >
          <input
            type="hidden"
            formControlName="type"
            [(ngModel)]="selectedType"
          />
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Nombre Completo</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="full_name"
                />
                <div
                  *ngIf="submitted && n.full_name?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="n.full_name.errors.required">
                    Campo requerido.
                  </div>
                  <div *ngIf="n.full_name.errors.minlength">
                    Campo debe ser de mínimo 5 caracteres
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Cédula de Identidad</strong></label>
                <input type="text" class="form-control" formControlName="dni" />
                <div
                  *ngIf="submitted && n.dni?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="n.dni.errors.required">Campo requerido.</div>
                  <div *ngIf="n.dni.errors.minlength">
                    Campo debe ser de mínimo 7 caracteres
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Email</strong></label>
                <input
                  type="email"
                  class="form-control"
                  formControlName="email"
                />
                <div
                  *ngIf="submitted && n.email?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="n.email.errors.required">Campo requerido.</div>
                  <div *ngIf="n.email.errors.email">Email inválido.</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Número de Teléfono</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="phone"
                />
                <div
                  *ngIf="submitted && n.phone?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="n.phone.errors.required">Campo requerido.</div>
                  <div *ngIf="n.phone.errors.minlength">
                    Campo debe ser de mínimo 7 caracteres
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row align-items-end">
            <div class="col">
              <div class="form-group">
                <label for=""><strong>Provincia</strong></label>
                <select
                  class="form-control default-select"
                  name=""
                  id=""
                  (change)="onChange($event.target.value, 'province')"
                  [(ngModel)]="provinceId"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option disabled selected>Seleccione una opción</option>
                  <option [value]="p.id" *ngFor="let p of provinces">
                    {{ p.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && n.township_id?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for=""><strong>Distrito</strong></label>
                <select
                  class="form-control"
                  name=""
                  id=""
                  (change)="onChange($event.target.value, 'city')"
                  [(ngModel)]="cityId"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option disabled selected>Seleccione una opción</option>
                  <option [value]="city.id" *ngFor="let city of cities">
                    {{ city.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && n.township_id?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for=""
                  ><strong>Corregimiento {{ townshipId }}</strong></label
                >
                <select
                  class="form-control"
                  name="township_id"
                  id="township_id"
                  (change)="onChange($event.target.value, 'township')"
                  [(ngModel)]="townshipId"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option disabled selected value="0">
                    Seleccione una opción
                  </option>
                  <option
                    [value]="township.id"
                    *ngFor="let township of townships"
                  >
                    {{ township.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && townshipId == 0"
                  class="text-danger fs-13 mt-1"
                >
                  Campo requerido.
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <button
                  class="btn btn-primary btn-refresh"
                  (click)="refreshAddress()"
                  placement="top"
                  ngbTooltip="refrescar"
                >
                  <mat-icon aria-hidden="false"> refresh </mat-icon>
                </button>
                <div
                  *ngIf="submitted && n.township_id?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Dirección</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="address"
                />
                <div
                  *ngIf="submitted && n.address?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="n.address.errors.required">Campo requerido.</div>
                  <div *ngIf="n.address.errors.minlength">
                    Campo debe ser de mínimo 5 caracteres
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-outline-danger ml-2 mr-2"
              (click)="modal.dismiss()"
            >
              Cancelar
            </button>
            <button type="submit" class="btn btn-primary mr-2">
              {{ isEdit ? "Editar" : "Guardar" }}
            </button>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="selectedType === 'Jurídico'">
        <form
          class="form-valide"
          action="#"
          method="post"
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
          novalidate
        >
          <input
            type="hidden"
            formControlName="type"
            [(ngModel)]="selectedType"
          />

          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Razón Social</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="business_name"
                />
                <div
                  *ngIf="submitted && f.business_name?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.business_name.errors.required">
                    Campo requerido.
                  </div>
                  <div *ngIf="f.business_name.errors.minlength">
                    Campo debe ser de mínimo 5 caracteres
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Razón Comercial</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="business_reason"
                />
                <div
                  *ngIf="submitted && f.business_reason?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.business_reason.errors.required">
                    Campo requerido.
                  </div>
                  <div *ngIf="f.business_reason.errors.minlength">
                    Campo debe ser de mínimo 5 caracteres
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"
                  ><strong>Registro Único de Contribuyente</strong></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="taxpayer"
                />
                <div
                  *ngIf="submitted && f.taxpayer?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.taxpayer.errors.required">Campo requerido.</div>
                  <div *ngIf="f.taxpayer.errors.minlength">
                    Campo debe ser de mínimo 5 caracteres
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1"
                  ><strong>Aviso de Operaciones</strong></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="operations_notice"
                />
                <div
                  *ngIf="submitted && f.operations_notice?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.operations_notice.errors.required">
                    Campo requerido.
                  </div>
                  <div *ngIf="f.operations_notice.errors.minlength">
                    Campo debe ser de mínimo 5 caracteres
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"
                  ><strong>País de Constitución</strong></label
                >
                <select
                  [(ngModel)]="countryConstitutionId"
                  class="form-control"
                  name=""
                  id=""
                  formControlName="country_of_constitution_id"
                >
                  <option
                    [value]="country.id"
                    *ngFor="let country of countries; let i = index"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && f.country_of_constitution_id?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.country_of_constitution_id.errors.required">
                    Campo requerido.
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label class="mb-1"
                  ><strong>Fecha de Constitución</strong></label
                >
                <div class="input-group">
                  <input
                    class="form-control"
                    name="inicio"
                    ngbDatepicker
                    #di="ngbDatepicker"
                    formControlName="constitution_date"
                    [(ngModel)]="constitutionDate"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar h-100"
                      (click)="di.toggle()"
                      type="button"
                    ></button>
                  </div>
                </div>
                <div
                  *ngIf="submitted && f.constitution_date?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.constitution_date.errors.required">
                    Campo requerido.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>País de Operaciones</strong></label>
                <select
                  [(ngModel)]="operationCountries"
                  class="form-control"
                  name=""
                  id=""
                  formControlName="operation_countries"
                  multiple
                >
                  <option
                    [value]="country.id"
                    *ngFor="let country of countries; let i = index"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && f.operation_countries?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.operation_countries.errors.required">
                    Campo requerido.
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Representante Legal</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="representative"
                />
                <div
                  *ngIf="submitted && f.representative?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.representative.errors.required">
                    Campo requerido.
                  </div>
                  <div *ngIf="f.representative.errors.minlength">
                    Campo debe ser de mínimo 5 caracteres.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Persona Autorizada</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="authorized_person"
                />
                <div
                  *ngIf="submitted && f.authorized_person?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.authorized_person.errors.required">
                    Campo requerido.
                  </div>
                  <div *ngIf="f.authorized_person.errors.minlength">
                    Campo debe ser de mínimo 5 caracteres.
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Cédula de Identidad</strong></label>
                <input type="text" class="form-control" formControlName="dni" />
                <div
                  *ngIf="submitted && f.dni?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.dni.errors.required">Campo requerido.</div>
                  <div *ngIf="f.dni.errors.minlength">
                    Campo debe ser de mínimo 7 caracteres.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Número de Teléfono</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="phone"
                />
                <div
                  *ngIf="submitted && f.phone?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.phone.errors.required">Campo requerido.</div>
                  <div *ngIf="f.phone.errors.minlength">
                    Campo debe ser de mínimo 7 caracteres.
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Email</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="email"
                />
                <div
                  *ngIf="submitted && f.email?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.email.errors.required">Campo requerido.</div>
                  <div *ngIf="f.email.errors.email">Email inválido.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row align-items-end">
            <div class="col">
              <div class="form-group">
                <label for=""><strong>Provincia</strong></label>
                <select
                  class="form-control default-select"
                  name=""
                  id=""
                  (change)="onChange($event.target.value, 'province')"
                  [(ngModel)]="provinceId"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option selected>Seleccione una opción</option>
                  <option [value]="p.id" *ngFor="let p of provinces">
                    {{ p.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && f.township_id?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for=""><strong>Distrito</strong></label>
                <select
                  class="form-control"
                  name=""
                  id=""
                  (change)="onChange($event.target.value, 'city')"
                  [(ngModel)]="cityId"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <!-- <option selected>Seleccione una opción</option> -->
                  <option [value]="city.id" *ngFor="let city of cities">
                    {{ city.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && f.township_id?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for=""><strong>Corregimiento</strong></label>
                <select
                  class="form-control"
                  name="township_id"
                  id="township_id"
                  (change)="onChange($event.target.value, 'township')"
                  [(ngModel)]="townshipId"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <!-- <option selected>Seleccione una opción</option> -->
                  <option
                    [value]="township.id"
                    *ngFor="let township of townships"
                  >
                    {{ township.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && townshipId == 0"
                  class="text-danger fs-13 mt-1"
                >
                  Campo requerido
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <button
                  class="btn btn-primary btn-refresh"
                  (click)="refreshAddress()"
                  placement="top"
                  ngbTooltip="refrescar"
                >
                  <mat-icon aria-hidden="false"> refresh </mat-icon>
                </button>
                <div
                  *ngIf="submitted && f.township_id?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label class="mb-1"><strong>Dirección</strong></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="address"
                />
                <div
                  *ngIf="submitted && f.address?.errors"
                  class="text-danger fs-13 mt-1"
                >
                  <div *ngIf="f.address.errors.required">Campo requerido.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-outline-danger ml-2"
              (click)="modal.dismiss()"
            >
              Cancelar
            </button>
            <button type="submit" class="btn btn-primary mr-2">
              {{ isEdit ? "Editar" : "Agregar" }}
            </button>
          </div>
        </form>
      </ng-container>
    </div>
  </ng-template>
</div>
