<section class="content-body d-flex align-items-center">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>{{titlePage}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-header d-block pb-0">
            <form class="">
              <div class="form-row col-12">
                <div class="form-group hidden">
                  <div class="input-group">
                    <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                      [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                      [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end align-items-end">
                <div class="col-4">
                  <div class="form-group">
                    <label class="mb-1"><strong>Desde</strong></label>
                    <div class="input-group">
                      <input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="mb-1"><strong>Hasta</strong></label>
                    <div class="input-group">
                      <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="mb-1"><strong>Caso</strong></label>
                    <input class="form-control form-filter" name="case" id="case" [(ngModel)]="searchFilter.case">
                  </div>
                </div>
              </div>
              <div class="row justify-content-start align-items-end">
                <div class="col-4">
                  <div class="form-group">
                    <label class="mb-1"><strong>Cliente</strong></label>
                    <input class="form-control form-filter" name="client" id="client" [(ngModel)]="searchFilter.client">
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <div class="input-group">
                      <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                        <i class="fa fa-search"></i>
                      </button>
                      <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Nro de Caso</th>
                    <th scope="col">Nombre Cliente</th>
                    <th scope="col">Fase Cancelada</th>
                    <th scope="col">Monto</th>
                    <th scope="col">Detalle</th>
                  </tr>
                </thead>
                <ng-container *ngIf="list.length; else nodata">
                  <tbody>
                    <tr *ngFor="let item of list; let i=index">
                      <td>{{item.payment_date}}</td>
                      <td>{{item.case_id}}</td>
                      <td>{{item.case.client}}</td>
                      <td>{{item.case.phase}}</td>
                      <td>${{item.amount}}</td>
                      <td>
                        <div>
                          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                            (click)="openModal(detailModal, item)" placement="top" ngbTooltip="Ver">
                            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                              visibility
                            </mat-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4"></td>
                      <td>Total ${{total | number : '1.2-2'}}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </ng-container>
                <ng-template #nodata>
                  <div class="alert" role="alert">
                    <strong>No hay data disponible</strong>
                  </div>
                </ng-template>
              </table>
            </div>
            <div class="text-center">
              <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #detailModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detalle de Caso</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <fieldset>
      <p class="ml-3 mb-2"><strong>Abogado: </strong>{{currentItem.lawyer?.user.full_name}}</p>
      <p class="ml-3 mb-2"><strong>Cédula: </strong>{{currentItem.lawyer.dni}}</p>
      <p class="ml-3 mb-2"><strong>Identidad: </strong>{{currentItem.lawyer.suitability_number}}</p>
      <p class="ml-3 mb-2 mt-3"><strong>Cliente: </strong>{{currentItem.client.data_client.full_name}}</p>
      <p class="ml-3 mb-2"><strong>Cédula: </strong>{{currentItem.client.data_client.dni}}</p>
      <p class="ml-3 mb-2">{{currentItem.description}}</p>
      <p class="ml-3 mb-2 text-right"><strong>Monto: </strong>${{currentItem.price}}</p>
    </fieldset>
    <div class="text-center">
      <button class="btn btn-primary my-4 py-2" *ngIf="currentItem" (click)="downloadCasePDF()">Descargar</button>
    </div>
  </div>
</ng-template>
