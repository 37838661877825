import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { DataResponse } from '../interfaces/response';
import { Entity, Lawyer, MyLawyers } from '../interfaces/user';


@Injectable( {
  providedIn: 'root'
} )
export class AdvisoryLawyerService {

  constructor(
    private http: HttpService
  ) { }

 /**
   * @description Lista los abogados asesores
   * @returns Listado
   */
  lawyers(): Observable<DataResponse<MyLawyers[]>> {
    return this.http.get( `MyLawyers?includes[]=user&includes[]=orders.service` );
  }

  /**
   * @description Detalle del abogado asesore
   * @returns Detalle
   */
   showLawyers(id: number): Observable<any> {
    return this.http.get( `lawyers/${id}?includes[]=user&includes[]=studyLevels&includes[]=bank.bankData&includes[]=comments.user&includes[]=township` );
  }



}

