<div>
	<div class="d-flex flex-cluem align-items-center justify-content-start">
		<div *ngIf="!showMode">
			<button *ngIf="!condTimer" (click)="openModalSelect(payModal)" class="btn btn-primary mr-3">
				Comenzar
			</button>
			<!--<button *ngIf="condStart && condTimer" (click)="doActionBasicTimer('start')"
          class="btn btn-success mr-3">Iniciar </button>-->
			<button *ngIf="condStop" (click)="doActionBasicTimer('stop')"
				class="btn btn-success mr-3 custom-button-stop">
				Detener
			</button>
		</div>
		<h5 class="mb-0" *ngIf="!showMode">Cronometra tus tareas</h5>
		<div [hidden]="(showMode && actualTimerSeconds == undefined)" :[ngClass]="{'show-mode': showMode, 'ml-3': !showMode, 'mr-3': showMode}">
			<span style="display: flex; align-items: center;">
				<i *ngIf="showMode" class="icon-clock mr-1"></i>
				<cd-timer #basicTimer [startTime]="actualTimerSeconds" class="bootstrap-timer" format="hms"
					(onTick)="onTick($event)" [autoStart]="false">
				</cd-timer>
			</span>
		</div>
	</div>

</div>


<ng-template #payModal>
	<div class="modal-header h-auto border-0">
		<div class="form-row w-100 custom-top">
			<!-- <h4 class=" col-12 modal-title" id="modal-basic-title">Datos de actividad</h4> -->
			<div *ngIf="!condSelectLaw" class="col-12 custom-alig-content">
				<h5 class="mb-4 modal-title" id="modal-basic-title">
					Seleccione el caso
				</h5>
				<mat-nav-list class="custom-aling-list border-pay">
					<mat-list-item *ngFor="let item of listCases" (click)="selectCase(item)">
						<a matLine>
							<mat-icon class="custom-left-icon-list"><i class="flaticon-381-newspaper"></i></mat-icon>
							<span class="link-caption">{{ item?.case_name }} </span>
						</a>
					</mat-list-item>
					<div class="mt-4 custom-div-pagination justify-content-center">
						<ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
							[collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
							aria-label="Default pagination"></ngb-pagination>
					</div>
				</mat-nav-list>
			</div>

			<div class="form-row col-12 custom-top" *ngIf="condSelectLaw">
				<div class="col-6 custom-alig-left">
					<span class="link-caption custom-title">
						<i class="flaticon-381-newspaper"></i>
						{{ LawSelectDataUser }}
					</span>
				</div>
				<div class="col-6 custom-alig-rigth">
					<button type="submit" class="btn btn-primary custom-change-but" (click)="chageCase()">
						<i class="icon-loop"></i>
						Cambiar caso
					</button>
				</div>
			</div>

			<div *ngIf="noTask" class="col-12 custom-alig-content custom-top">
				<h5 class="col-12 modal-title" id="modal-basic-title">
					No hay actividades asociadas a este caso
				</h5>
			</div>

			<div *ngIf="!condSelecTask && condListTask && !noTask" class="col-12 custom-alig-content custom-top">
				<h5 class="col-12 modal-title" id="modal-basic-title">
					Seleccione la actividad
				</h5>
				<mat-nav-list class="custom-aling-list border-pay">
					<mat-list-item *ngFor="let item of lisTask" (click)="selectTask(item)">
						<a matLine>
							<mat-icon class="custom-left-icon-list"><i class="flaticon-381-note"></i></mat-icon>
							<span class="link-caption">{{ item?.name }} </span>
						</a>
					</mat-list-item>
					<div class="col-12 custom-div-pagination justify-content-center">
						<ngb-pagination [pageSize]="12" (pageChange)="loadPageTask($event)"
							[collectionSize]="dataPaginationTask?.total" [maxSize]="5" [(page)]="pageTask"
							aria-label="Default pagination"></ngb-pagination>
					</div>
				</mat-nav-list>
			</div>

			<div class="form-row col-12 custom-top" *ngIf="condSelecTask">
				<div class="col-6 custom-alig-left">
					<span class="link-caption custom-title">
						<i class="flaticon-381-note"></i>
						{{ taskSelect }}
					</span>
				</div>
				<div class="col-6 custom-alig-rigth">
					<button type="submit" class="btn btn-primary custom-change-but" (click)="chageTask()">
						<i class="icon-loop"></i>
						Cambiar actividad
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="form-row custom-bottom-1">
		<div class="col-6 text-center">
			<button type="submit" class="btn btn-outline-light my-2" (click)="cancel()">
				Cancelar
			</button>
		</div>
		<div class="col-6 text-center">
			<button *ngIf="condSelecTask && condSelectLaw" type="submit" class="btn btn-primary"
				(click)="doActionBasicTimer('start')">
				Aceptar
			</button>
		</div>
	</div>
</ng-template>
