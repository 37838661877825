import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminService } from '../../../shared/services/admin.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BranchesLaw, Service } from 'src/app/shared/interfaces/common';
import { forkJoin } from 'rxjs';
import { CommonService } from '../../../shared/services/common.service';
import { ServiceType } from '../../../shared/interfaces/common';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component( {
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: [ './services.component.css' ]
} )
export class ServicesComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  isEdit = false;
  modal: any;
  services: Service[] = [];
  service: Service;
  branchs: BranchesLaw[] = [];
  typesServices: ServiceType[] = [];
  page; dataPagination;
  titlePage='Configurar Servicios';
  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private modalService: NgbModal,
    private adminService: AdminService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
    this.createForm();
    forkJoin( [ this.adminService.listBranchs(), this.common.typeService() ] )
      .subscribe( ( [ branchsResponse, typesResponse ] ) => {
        this.branchs = [ ...branchsResponse.data ];
        this.typesServices = [ ...typesResponse.data ];
      } );
    this.page = 1;  
    this.loadData();
    
  }

  get f(): any { return this.form.controls; }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    if ( this.form.valid ) {
      if ( this.isEdit ) {
        this.updateService();
        return;
      }
      this.addService();
    }
  }

  add( serviceModal: any ): void {
    this.form.reset();
    this.isEdit = false;
    this.modal = this.modalService.open( serviceModal, { backdrop: 'static', size: 'lg' } );
  }

  edit( serviceModal: any, service: any ): void {
    this.isEdit = true;
    this.service = { ...service };
    console.log(this.service);
    this.createForm();
    this.modal = this.modalService.open( serviceModal, { backdrop: 'static', size: 'lg' } );
  }

  delete( id: number ): void {
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea eliminar esta servicio?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, eliminar!'
    } ).then( ( result ) => {
      if ( result.isConfirmed ) { this.deleteService( id ); }
    } );
  }

  private loadData(): void {
    this.adminService.listServiceTotal(this.page).subscribe( response => {
      this.services = [ ...response.data ];
      this.dataPagination = response.meta.page;
    } );
  }

  private addService(): void {
    this.adminService.addService( this.form.value ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Servicio creado', icon: 'success' } );
    } );
  }

  private updateService(): void {
    this.adminService.updateService( this.form.value, this.service.id ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Serivicio actualizado', icon: 'success' } );
    } );
  }

  private deleteService( id: number ): void {
    this.adminService.deleteService( id ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Servicio eliminado', icon: 'success' } );
    }, (error) => {
      //console.log(error.error.text);
      Swal.fire( { text: error.error.text, icon: 'warning' } );
    }); 
  }

  private createForm(): void {
    this.form = this.fb.group( {
      name: [ this.service ? this.service.name : '', [ Validators.required ] ],
      branch_law_id: [ this.service ? this.service.branch_law_id : '', [ Validators.required ] ],
      type_service_id: [ this.service ? this.service.type_service_id : '', [ Validators.required ] ],
      comission_price: [ this.service ? this.service.comission_price : '', [ Validators.required ] ]
    } );
  }

  loadPage(page) {
    this.page = page;
    this.loadData();
  }

}
