<div class="content-body d-flex">
    <div class="container-fluid">
        <div class="card">
            <div class="p-4">
                <div class="d-flex justify-content-between ml-1">
                    <h3>{{titlePage}}</h3>
                    <div>
                        Crear cotización
                        <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                            (click)="add(entityModal)" placement="top" ngbTooltip="Nueva cotización">
                            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color:#212F54">
                                add_circle_outline
                            </mat-icon>
                        </button>
                    </div>
                </div>
                <hr>
                <!--                   <div class="filter">
                    <div class="search mx-2 d-flex align-items-center flex-row">
                      <button type="button " class="btn btn-filter"><i class="flaticon-381-search-2"></i></button>
                        <input type="text" class="form-control mx-1 shadow-none"
                        placeholder="Nro de cotización" aria-label="Nro de cotización" aria-describedby="basic-addon1" >
                    </div>
                  </div> -->
            </div>
            <div class="card-body">
                <ng-container *ngIf="quotes.length; else nodata">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Nro de cotización</th>
                                    <th scope="col">Nombre de caso</th>
                                    <!-- <th scope="col">Precio por hora</th> -->
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Emisión</th>
                                    <th style="width: 100px;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of quotes; let i=index">
                                    <td *ngIf="item.quote_id !== null" class="text-capitalize">{{item.quote_id}}</td>
                                    <!-- <td *ngIf="item.id !== null" class="text-capitalize">{{item.id}}</td> -->
                                    <td *ngIf="item.id === null" class="text-capitalize">Sin data</td>
                                    <td>{{item.name}}</td>
                                    <!-- <td>{{item?.price | number: '1.2-2':'en-US'}}</td> -->
                                    <td>{{item.client_name}}</td>
                                    <td>{{item.emitted_at | date: 'dd/MM/yyyy h:mm a'}}</td>
                                    <td>
                                        <button mat-icon-button color="accent"
                                            aria-label="Example icon button with a menu icon"
                                            (click)="goDetail(detailModal,item.quote_id)" placement="top"
                                            ngbTooltip="Ver detalle">
                                            <mat-icon aria-hidden="false" aria-label="Example home icon"
                                                style="color: #212F54;">
                                                assignment
                                            </mat-icon>
                                        </button><button mat-icon-button color="accent"
                                            aria-label="Example icon button with a menu icon"
                                            (click)="alertDelete(item.id)" placement="top" ngbTooltip="Eliminar">
                                            <mat-icon aria-hidden="false" aria-label="Example home icon"
                                                style="color: #dc3545;">
                                                delete
                                            </mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row custom-div-pagination justify-content-end">
                        <div class="col-auto">
                            <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
                                [collectionSize]="dataPagination3?.total" [maxSize]="5" [(page)]="page3"
                                aria-label="Default pagination"></ngb-pagination>
                        </div>
                    </div>
                </ng-container>
                <ng-template #nodata>
                    <div class="alert alert-warning" role="alert">
                        <strong>No hay data disponible</strong>
                    </div>
                </ng-template>
            </div>
            <div class="text-center">
              <button class="btn btn-primary my-4" *ngIf="quotes.length" (click)="downloadExcel()">Descargar</button>
          </div>
        </div>
    </div>
</div>

<ng-template #entityModal>
    <div class="modal-header">
        <h4 class="modal-title">{{isEdit ? 'Editar' : 'Agregar'}} cotización</h4>
        <button type="button" class="close" aria-label="Close" (click)="addModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="caseSelected?.case_name">
            <div class="col-12">
                <div class="custom-div-client p-2 mb-3">
                    <label class="mb-0"><strong>Caso: </strong>{{caseSelected?.case_name}}</label><br>
                    <label class="mb-0"><strong>Cliente: </strong>{{caseSelected?.client?.client_name}}</label><br>
                    <label class="mb-0"><strong>Cédula / Ruc:
                    </strong>{{caseSelected?.client?.data_client?.dni}}</label>
                </div>
            </div>
            <!--
            <div class="col-md">
                <div class="custom-div-client p-2 mb-3">
                    <label class="mb-0"><strong>Caso: </strong>{{caseSelected?.case_name}}</label>
                </div>

            </div>
            <div class="col-md">
                <div class="custom-div-client p-2 mb-3">
                    <label class="mb-0"><strong>Cliente: </strong>{{caseSelected?.client?.client_name}}</label><br>
                </div>
            </div>
            <div class="col-md">
                <div class="custom-div-client p-2 mb-3">
                    <label class="mb-0"><strong>Cédula / Ruc:
                        </strong>{{caseSelected?.client?.data_client?.dni}}</label>
                </div>
            </div>
        -->
        </div>
        <div class="row align-items-end justify-content-center mr-3">
            <div class="col-auto mb-3">
                <div *ngIf="caseSelected === undefined || caseSelected === [] || caseSelected === null">
                    <button type="submit" class="btn btn-primary custom-change-but" (click)="selectCase(payModal)">
                        <i class="flaticon-381-newspaper"></i>
                        Seleccionar caso
                    </button>
                </div>
                <div *ngIf="caseSelected !== undefined ">
                    <button type="submit" class="btn btn-primary custom-change-but" (click)="selectCase(payModal)">
                        <i class="flaticon-381-newspaper"></i>
                        Seleccionar caso
                    </button>
                </div>
            </div>
            <div class="col-auto mb-3">
                <!-- tarifa por hora  -->
                <div  *ngIf="caseSelected?.client?.data_client?.dni !== null && caseSelected?.client?.data_client?.dni !== '' && caseSelected?.client?.data_client?.dni !== undefined">
                    <label class="mb-1"><strong>Tarifa por hora </strong></label>
                    <input type="number" class="form-control" name="amount" [(ngModel)]="priceHour" />
                </div>
            </div>
            <div class="col-auto mb-3">
                <div *ngIf="priceHour !== '' && priceHour !== null && priceHour !== undefined && priceHour !== 0 ">
                    <button class="btn btn-primary custom-change-but" (click)="addNewTask()">
                        <i class="flaticon-381-newspaper"></i>
                        Adicionar tarea
                    </button>
                </div>
            </div>
        </div>
        <form [formGroup]="myForm">
            <div class="table-responsive" *ngIf="condFormTask">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Descripción</th>
                            <th style="width: 100px;">Horas</th>
                            <th style="width: 100px;">Precio</th>
                            <th scope="col">Fecha Inicio</th>
                            <th scope="col">Fecha Fin</th>
                            <th style="width: 50px;"></th>
                        </tr>
                    </thead>
                    <tbody formArrayName="listTaksData">
                        <tr *ngFor="let comp of myForm.get('listTaksData').controls; let i=index">
                            <td [formGroupName]="i">
                                <input class="form-control" formControlName="name" />
                            </td>
                            <td [formGroupName]="i">
                                <input type="number" class="form-control" formControlName="hour" />
                            </td>
                            <td [formGroupName]="i">
                                <input type="text" [ngClass]="{disabledDiv: !isActiveDiv}" class="form-control"
                                formControlName="price" />
                            </td>
                            <td [formGroupName]="i">
                                <input matInput type="date" class="form-control" formControlName="date_start">
                            </td>
                            <td [formGroupName]="i">
                                <input matInput type="date" class="form-control" formControlName="date_end">
                            </td>
                            <td>
                                <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                                (click)="deleteTask(i)" placement="top" ngbTooltip="Eliminar">
                                    <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #dc3545;">
                                        delete
                                    </mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>

        <div class="row">
            <div class="col-12 custom-div-label custom-alig-rigth" *ngIf="totalHour > 0">
                <div class=" col-3 custom-margin-top ">
                    <label class="form-control custom-label">Total horas: <strong>{{totalHour}}</strong></label>
                </div>
                <div class=" col-3 custom-margin-top ">
                    <label class="form-control">Total precio: <strong>$ {{totalPrice | number : '1.2-2':'en-US'}}</strong> </label>
                </div>
            </div>
        </div>
        <div class="row mt-4" *ngIf="condFormTask">
            <div class="col text-right">
                <button type="button" class="btn btn-outline-danger mr-2" (click)="reseteForm()">
                    Cancelar
                </button>
                <button type="button" [disabled]="!myForm.valid" class="btn btn-primary"
                    (click)="sendQuote(fileModal)">
                    Guardar
                </button>

            </div>
        </div>


        <ng-template #fileModal>
            <!--<div class="modal-header custom-bg-color">
            </div>-->
            <div class="modal-body">
                <div class="form-row col-12 custom-top">
                    <div class="col-12 custom-alig-content">
                        <h5 class=" col-12 modal-title">Seleccione una opción</h5>
                    </div>
                    <div class="col-6 text-center  custom-top">
                        <button type="submit" [disabled]="pdfSended" class="btn btn-outline-danger" (click)="getPdf()">
                            <!-- <mat-icon *ngIf="pdfSended" style="color:#dc3545">check</mat-icon> -->
                            {{pdfSended ? 'Pdf enviado' : 'Descargar Pdf'}}
                        </button>
                    </div>
                    <div class="col-6 text-center  custom-top">
                        <button type="submit" [disabled]="emailSended" class="btn btn-primary " (click)="sendFile()">
                            <!-- <mat-icon *ngIf="emailSended" style="color:#212F54">check</mat-icon> -->
                            {{emailSended ? 'Email enviado' : 'Enviar por email'}}
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

</ng-template>

<ng-template #payModal>
    <div class="modal-header">
        <h5 class=" col-12 modal-title">Seleccione el caso</h5>
    </div>
    <div class="modal-body">
        <div class="form-row col-12 custom-top">
            <div *ngIf="condSelectLaw" class="col-12 custom-alig-content">

                <mat-nav-list class="custom-aling-list border-pay">
                    <mat-list-item *ngFor="let item of listCases" (click)="selectCaseList(item)">
                        <a matLine>
                            <mat-icon class="custom-left-icon-list"><i class="flaticon-381-newspaper"></i></mat-icon>
                            <span class="link-caption">{{ item?.case_name }} </span>
                        </a>
                    </mat-list-item>
                    <div class="col-12 custom-div-pagination justify-content-center">
                        <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
                            [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
                            aria-label="Default pagination"></ngb-pagination>
                    </div>
                </mat-nav-list>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #detailModal>
    <div class="modal-header">
        <h4 class="modal-title">Detalle de cotización</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>General</a>
                <ng-template ngbNavContent>
                    <div class="form-row">
                      <div class="col-9">
                        <div class="col-12">
                            <label class="custom-top" for=""><strong>Caso</strong></label> <span
                                class="span-info">{{detailQuotes?.name}}</span>
                        </div>
                        <div class="col-12">
                            <label class="" for=""><strong>Cliente</strong></label> <span
                                class="span-info">{{detailQuotes?.client_name}}</span>
                        </div>
                        <div class="col-12">
                            <label class="" for=""><strong>Cédula / Ruc</strong></label> <span
                                class="span-info">{{detailQuotes?.client_dni}}</span>
                        </div>
                        <div class="col-12">
                            <label class="" for=""><strong>Nro de fases</strong></label> <span
                                class="span-info">{{detailQuotes?.phases_number}}</span>
                        </div>
                        <div class="col-12">
                            <label class="" for=""><strong>Emitido en</strong></label> <span
                                class="span-info">{{detailQuotes?.emitted_at | date: 'dd/MM/yyyy h:mm a'}}</span>
                        </div>
                        <div class="col-12">
                            <label class="" for=""><strong>Precio</strong></label> <span
                                class="span-info"> ${{detailQuotes?.price | number: '1.2-2':'en-US'}}</span>
                        </div>
                        <div class="col-12">
                            <label class="" for=""><strong>Porcentaje de avance</strong></label> <span
                                class="span-info"> {{advancePercent | number: '1.2-2':'en-US'}}%</span>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-row col-12 custom-top">
                            <div class="col-6 text-left custom-top">
                                <button type="submit" [disabled]="pdfSended" class="btn btn-outline-danger btn-sm" (click)="getPdf(detailQuotes.quote_id)">
                                    <mat-icon>file_download</mat-icon>
                                    <!-- <mat-icon *ngIf="pdfSended" style="color:#dc3545">check</mat-icon> -->
                                    <!-- {{pdfSended ? 'Pdf enviado' : 'Descargar Pdf'}} -->
                                </button>
                            </div>
                            <div class="col-6 text-right  custom-top">
                                <button type="submit" [disabled]="emailSended" class="btn btn-outline-primary btn-sm" (click)="sendFile(detailQuotes.quote_id)">
                                    <!-- <mat-icon *ngIf="emailSended" style="color:#212F54">check</mat-icon> -->
                                    <mat-icon>email</mat-icon>
                                    <!-- {{emailSended ? 'Email enviado' : 'Enviar por email'}} -->
                                </button>
                            </div>
                        </div>
                      </div>
                    </div>

					<div *ngIf="detailQuotes?.phases" class="table-responsive">
						<table class="table table-striped">
							<thead>
								<tr>
									<th scope="col">Fase</th>
									<th scope="col">Porcentaje</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let phase of detailQuotes?.phases">
									<td>{{phase.phase}}</td>
									<td>{{phase.percent}}</td>
								</tr>
							</tbody>
						</table>
					</div>

                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>Actividades</a>
                <ng-template ngbNavContent>

                    <ng-container *ngIf="detailQuotes.case_tasks.length; else nodata">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Fecha Inicio</th>
                                        <th scope="col">Fecha Fin</th>
                                        <th scope="col">Precio</th>
                                        <th scope="col">Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of detailQuotes.case_tasks; let i=index">
                                        <td>{{item.name}}</td>
                                        <td>{{item.date_start | date: 'dd/MM/yyy'}}</td>
                                        <td>{{item.date_end | date: 'dd/MM/yyy'}}</td>
                                        <td>${{item.price_per_hour}}</td>
                                        <td>
                                          <div class="d-flex justify-content-center align-items-center">
                                            {{item.status}}
                                            <app-task-status [task]="item"></app-task-status>
                                          </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                </ng-template>
            </li>

        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</ng-template>
