import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaseTaskService } from './../../../../shared/services/case-task.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.css']
})
export class TaskStatusComponent implements OnInit {
  @Input() task: any;
  @ViewChild("editModal", { static: true }) editModal: ElementRef;

  modal: any;
  status: string;
  statuses = [
    'Completado',
    'Pendiente'
  ];
  constructor(
    private CaseTaskService: CaseTaskService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.status = this.task.status;
  }

  editTask() {
    this.modal = this.modalService.open(this.editModal, { size: 'sm', centered: true });
  }

  update() {
    this.CaseTaskService.update(this.task.id,{status: this.status}).subscribe(async (response) => {
      this.modal.dismiss();
      this.task.status = this.status;
      this.task.status_id = response.id;
    }, (error) => {
      console.error(error)
    });
  }
}
