import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../../../shared/services/clients.service';
import { NgbModal, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-foll-services-co',
  templateUrl: './foll-services-co.component.html',
  styleUrls: ['./foll-services-co.component.css']
})
export class FollServicesCoComponent implements OnInit {
  modal: any;
  active = 1;
  IsPublic = 0;
  documentListForService: any;
  docsFormData!: FormData;
  NameLawyer = ''; namePhaseCurrent;
  id_order;
  listTramAct = [];
  listTramC = [];
  ListDocument = []; detailService;

  page; dataPagination;
  page2; dataPagination2;
  page3; dataPagination3;
  constructor(
    private ClientsService: ClientsService,
    private modalService: NgbModal,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: 'Seguimiento de servicios'};
    this.page = 1;
    this.page2 = 1;
    this.page3 = 1;
  }

  ngOnInit(): void {
    this.docsFormData = new FormData();
    this.getActivos('Activo', this.page);
    this.getCulminados('Culminado', this.page2);
  }

  getActivos(type, page) {
    this.ClientsService.geMonitoringService('Activo', page).subscribe(response => {
      this.listTramAct = response.data;
      this.dataPagination = response.meta.page;
    });
  }
  getCulminados(type, page) {
    this.ClientsService.geMonitoringService('Culminado', page).subscribe(response => {
      this.listTramC = response.data;
      this.dataPagination2 = response.meta.page;
    });
  }

  loadPage(page) {
    this.listTramAct = [];
    this.getActivos(2, page);
  }

  loadPage2(page) {
    this.listTramC = [];
    this.getCulminados(3, page);
  }

  openDetail(serviceModal: any, id_order, item, page): void {



    this.id_order = id_order;
    this.ClientsService.getDetailMonitoringService(id_order).subscribe(response => {
      this.detailService = {...response.data};

      let valid_payments = this.detailService.order_payments.filter(e=> e.status == "Aprobado")


      if(valid_payments.length > 0){

        this.getDocumentsForService(this.id_order, page);

        let control = this.detailService.phases.filter( phase =>
          phase.phase == this.detailService.current_phase
        )
        this.namePhaseCurrent = control[0].description

        this.openModal(serviceModal);
      }else{
        this.alrtNoPayment(item);
      }


    });
    //  this.GetDataDetail(id_order);


  }

  alrtNoPayment(data) {

    let message = data.payment_status


    if(message == "No existen pagos" || message == "Faltan pagos por realizar" ){

      message = "En espera del pago del Trámite"

      let valid_approved_lawyer = data.order_lawyer.filter(e=> e.approved_by_lawler == 1)

      let valid_payments = data.order_payments.filter(e=> e.status != "Aprobado")

      console.log(valid_approved_lawyer)
      console.log(valid_payments)



      if(valid_approved_lawyer.length < 1) message = "En espera de aprobación del abogado"
      else if(valid_payments.length > 0) message = "En espera de aprobación de su pago"


    }

    console.log(message)

    Swal.fire({
      icon: 'warning',
      text: ' ',
      title: message,
    })



  }

  private openModal(serviceModal: any): void {

    this.modal = this.modalService.open(serviceModal, { backdrop: 'static', size: 'lg' });
  }

  LoadFileDocument() {
    let object = this.documentListForService;
    if (object) {
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const elements = object[key];
          let index = parseInt(key) + 1;
          let item = {
            date: elements['created_date'],
            name: elements['name'],
            //  user : elements['user']['full_name'],
            //   name : 'Document ' + String(index),
            is_public: 0,
            order_id: this.detailService.id,
            file_url: elements['file_url'],
            upload: 'no'
          }
          this.ListDocument.push(item);
        }
      }
    }

  }

  onFileSelect(e: any): void {
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      let name = file['name'].split('.');

      let item = {
        date: Date.now(),
        user: this.NameLawyer,
        name: name[0],
        is_public: 0,
        file_url: file,
        order_id: this.detailService.id,
        'includes[]': 'user',
        upload: 'yes'
      }
      this.ListDocument.push(item);
      this.IsPublic = 0;

    }


  }

  ShowPdf(document, upload) {
    if (upload == 'no') {
      window.open(document['file_url'], '_blank');
    } else {
      this.viewpdf(document['file_url'], document['name']);
    }
  }

  viewpdf(downloadedFile, name) {
    var downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.download = name + '.pdf';
    var blob = new Blob([downloadedFile], { type: 'application/pdf' });
    var URL = window.URL;
    var downloadUrl = URL.createObjectURL(blob);
    downloadLink.href = downloadUrl;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadUrl);
  }

  DocumentServiceData() {
    this.ClientsService.loadDocuments(this.docsFormData).subscribe(
      Response => {
        console.log(Response);
      },
      error => {
        console.log(error);
      }
    );
  }

  async LoadDocument() {
    let object = this.ListDocument;
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const documents = object[key];
        let upload = object[key]['upload'];
        if (upload === 'yes') {
          for (const keyd in documents) {
            if (Object.prototype.hasOwnProperty.call(documents, keyd)) {
              const value = documents[keyd];
              if (keyd != 'date' && keyd != 'user') {
                this.docsFormData.set(keyd, value);
              }
            }
          }
          this.DocumentServiceData();
        }
      }
    }
  }

  CancelModal() {
    this.modalService.dismissAll();
    this.ListDocument = [];
  }

  async UploadInfo() {
    try {
      await this.LoadDocument();

    } catch (error) {
      console.log(error);
    } finally {
      // console.log('finish');
      // this.modalService.dismissAll();
      this.ListDocument = [];
      Swal.fire({ text: 'Actualizacion exitosa', icon: 'success' });
    }
    this.active = 1;
    this.getDocumentsForService(this.id_order, this.page3);

  }

  ReturnNamePhase(id_phase) {
    const array1 = this.detailService.phases;
    const number = id_phase.toString();
    const namePhase = array1.find(element => element.phase === number);
    this.namePhaseCurrent = namePhase;
  }

  getDocumentsForService(id_order, page) {
    this.ClientsService.getDocumentsForService(id_order, page).subscribe(
      Response => {
        this.documentListForService = Response.data;
        this.dataPagination3 = Response.meta.page;
        //console.log(this.documentListForService);
        this.LoadFileDocument();
      },
      error => {
        console.log(error);
      }
    );
  }

  loadPage3(page) {
    this.page3 = page;
    this.documentListForService = [];
    this.ListDocument = [];
    this.getDocumentsForService(this.id_order, this.page3);
  }


}
