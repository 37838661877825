<div class="nav-header">
    <a [routerLink]="['/admin/index']" class="brand-logo">
        <img [src]="logo" alt="" class="ln-brand-logo img-logo-mini h-100-xs" *ngIf="showMini">
        <img [src]="logo_h" alt="" class="ln-brand-logo w-100 img-logo-full" *ngIf="!showMini">
        <!-- <img [src]="brand" alt="" class="brand-title"> -->
    </a>


    <div class="nav-control">
        <div [ngClass]="{'is-active': hamburgerClass, '': !hamburgerClass}" class="hamburger"
            (click)="toggleHamburgerClass()">
            <span class="line"></span><span class="line"></span><span class="line"></span>
        </div>
    </div>
</div>