<!--**********************************
    Content body start
***********************************-->
<!-- line-height: 2rem; -->
<!-- style=" height: calc(100vh - 5.5rem); " -->
<div class="content-body d-flex align-items-center" 

    [style]="lawyerType==='Plataforma' ? ' ' : ' '"
    >
    <div class="container-fluid">
        <ng-container *ngIf="lawyerType === 'Marketplace'">
            <app-lawyer-marketplace></app-lawyer-marketplace>
        </ng-container>

        <ng-container *ngIf="lawyerType==='Plataforma'">
            <app-lawyer-file-management-platform></app-lawyer-file-management-platform>
        </ng-container>

    </div>
</div>

<!--**********************************
    Content body end
***********************************-->