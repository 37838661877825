<section class="content-body d-flex">
    <div class="container-fluid">
        <div class="row justify-content-center mb-3">
        <div class="text-center">
            <h3>Facturas</h3>
        </div>
        </div>
        <div class="row">
        <div class="col-12">
            <div class="card border-0">
            <div class="card-header d-block pb-0">
                <form class="">
                <div class="form-row col-12">
                    <div class="form-group hidden">
                    <div class="input-group">
                        <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                        [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                        [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                        <ng-template #t let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                            (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                        </span>
                        </ng-template>
                    </div>
                    </div>
                </div>
                <div class="row justify-content-end align-items-end">
                    <div class="col-6 col-md-5">
                    <div class="form-group">
                        <label class="mb-1"><strong>Desde</strong></label>
                        <div class="input-group">
                        <input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
                            [value]="formatter.format(fromDate)"
                            (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                        <div class="input-group-append">
                            <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                            type="button"></button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-6 col-md-5">
                    <div class="form-group">
                        <label class="mb-1"><strong>Hasta</strong></label>
                        <div class="input-group">
                        <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                            [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                        <div class="input-group-append">
                            <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                            type="button"></button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-2">
                    <div class="form-group">
                        <div class="input-group">
                        <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                            <i class="fa fa-search"></i>
                        </button>
                        <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                            <i class="fa fa-refresh"></i>
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
                </form>
            </div>
            <div class="card-body">
                <ng-container *ngIf="invoices.length; else nodata">
                <div class="table-responsive">
                    <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">Nro de Factura</th>
                        <th scope="col">Nombre del Servicio</th>
                        <th scope="col">Fecha de Emision</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Total</th>
                        <th style="width: 60px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of invoices">
                        <td>{{item.reference_number}}</td>
                        <td>{{item.service_name}}</td>
                        <td>{{item.creation_date}}</td>
                        <td>{{item.client}}</td>
                        <td>${{item.total}}</td>
                        <td>
                            <div ngbDropdown container="body">
                                <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                        </svg>
                                </button>
                                <div ngbDropdownMenu>
                                    <a ngbDropdownItem href="#">Descargar </a>
                                  <button ngbDropdownItem (click)="openEmail(item.id,item.email,emailModal)">Enviar Correo</button>
                                  <button ngbDropdownItem (click)="openCancel(item.id,item.document,cancelModal)">Anular</button>

                                </div>
                              </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div class="row justify-content-end mt-3">
                    <div class="col-auto custom-div-pagination">
                        <!--
                    <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                        [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="dataPagination.currentPage" aria-label="Paginación">
                    </ngb-pagination>-->
                    </div>
                </div>
                </ng-container>
                <ng-template #nodata>
                <div class="alert" role="alert">
                    <strong>No hay data disponible</strong>
                </div>
                </ng-template>
            </div>
            </div>
        </div>
        </div>
    </div>
</section>

<!-- Modal Email-->
<ng-template #emailModal>
    <div class="modal-header">
        <h5 class="modal-title">Envio de Email</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

    <form class="row mb-0" [formGroup]="formEmail" (ngSubmit)="onSubmitEmail()">
        <div class="form-group col-lg-12">
        <label>Introduzca la dirección de correo de destino:</label>
        <input class="form-control" type="email"  formControlName="email">
        <div *ngIf="submittedEmail && e.email?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="e.email.errors.required">Campo requerido.</div>
            <div *ngIf="e.email.errors.email">Email inválido.</div>
        </div>
        </div>
        <div class="col-lg-12 text-right pt-3">
            <a (click)="formReset()"  class="btn btn-outline-danger mr-2">Cancelar</a>
            <button type="submit" class="btn btn-primary">Enviar</button>
        </div>

    </form>

    </div>
</ng-template>

<!-- Modal Cancel -->
<ng-template #cancelModal>
    <div class="modal-header">
        <h5 class="modal-title">Anular Documento Nº{{dataCancel.num}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <form class="row mb-0" [formGroup]="formAnular" (ngSubmit)="onSubmitAnular()">
        <div class="form-group col-lg-12">
            <label>Razón de la Anulación:</label>
            <textarea class="form-control" formControlName="canceled_reason"></textarea>
            <div *ngIf="submittedAnular && a.canceled_reason?.errors" class="text-danger fs-13 mt-1">
                <div *ngIf="a.canceled_reason.errors.required">Campo requerido.</div>
                <div *ngIf="a.canceled_reason.errors.minlength">Campo debe ser de mínimo 15 caracteres</div>
            </div>
        </div>
        <div class="col-lg-12 text-right pt-3">
            <a (click)="formResetAnular()"  class="btn btn-outline-danger mr-2">Cancelar</a>
            <button type="submit" class="btn btn-primary">Anular</button>
        </div>

        </form>

    </div>
</ng-template>

