<div class="container-fluid form-container">
  <form class="form-valide" [formGroup]="form" novalidate>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1 custom-text-info"
            ><strong>Información de la asesoría </strong></label
          >
          <div class="custom-text-info">Texto informativo de la asesoría</div>
          <div class="form-row col-12">
            <div class="col-6 custom-top">
              <label class="mb-1"><strong>Tipo de servicio </strong></label>
              <mat-form-field appearance="fill">
                <mat-label>Seleccione una opción</mat-label>
                <mat-select formControlName="typeServ">
                  <mat-option
                    (click)="getTypeServ()"
                    [value]="option.name"
                    *ngFor="let option of lisTypeServ"
                    >{{ option.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6 custom-top" *ngIf="condFormAtt == 'Asesoría'">
              <label class="mb-1"><strong>Tipo de asesoria</strong></label>
              <mat-form-field appearance="fill">
                <mat-label>Seleccione una opción</mat-label>
                <mat-select formControlName="FormaAtt">
                  <mat-option
                    (click)="getFormaAtt()"
                    [value]="option.name"
                    *ngFor="let option of lisFormaAtt"
                    >{{ option.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <div
              class="col-6 custom-top-c"
              *ngIf="condTypeAtt == 'Videollamada'"
            >
              <label class="mb-1"><strong>Fecha</strong></label>
              <div class="input-group">
                <input
                  class="form-control"
                  name="available_date"
                  ngbDatepicker
                  #di="ngbDatepicker"
                  formControlName="available_date"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="di.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
              <div
                *ngIf="submitted && f.available_date?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.available_date.errors.required">
                  Campo requerido.
                </div>
              </div>
            </div>

            <div
              class="col-6 custom-top-c"
              *ngIf="condTypeAtt == 'Videollamada'"
            >
              <label class="mb-1"><strong>Hora</strong></label>
              <div class="input-group">
                <input
                  class="form-control"
                  type="time"
                  formControlName="hora"
                />
              </div>
              <div
                *ngIf="submitted && f.available_date?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.available_date.errors.required">
                  Campo requerido.
                </div>
              </div>
            </div>

            <div
              class="col-6 custom-top"
              *ngIf="
                condFormAtt == 'Asesoría' &&
                (condTypeAtt == 'Escrita' || condTypeAtt == 'Videollamada')
              "
            >
              <label class="mb-1"><strong>Asignación de abogado</strong></label>
              <mat-form-field appearance="fill">
                <mat-label>Seleccione una opción</mat-label>
                <mat-select formControlName="asigLaw">
                  <mat-option
                    (click)="getAsigLaw()"
                    [value]="option.name"
                    *ngFor="let option of lisAsigLaw"
                    >{{ option.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <div
              class="form-row col-12 custom-top"
              *ngIf="
                condFormAtt == 'Asesoría' &&
                (condTypeAtt == 'Escrita' || condTypeAtt == 'Videollamada')
              "
            >
              <div class="col-12 custom-alig-content">
                <label class="mb-1"
                  ><strong>Seleccione precio a pagar</strong></label
                >
              </div>
              <div class="col-12 custom-alig-content">
                <ngx-slider
                  [(value)]="value"
                  [options]="options"
                  (userChangeEnd)="changePrice()"
                >
                </ngx-slider>
              </div>
            </div>
            <div
              class="form-row col-12 custom-top"
              *ngIf="condFormAtt == 'Cotización'"
            >
              <div class="col-12 custom-alig-content">
                <label class="mb-1"
                  ><strong>Seleccione precio a pagar</strong></label
                >
              </div>
              <div class="col-12 custom-alig-content">
                <ngx-slider
                  [(value)]="value"
                  [options]="options"
                  (userChangeEnd)="changePrice()"
                >
                </ngx-slider>
              </div>
            </div>

            <div
              class="form-row col-12 custom-top"
              *ngIf="
                lisLawyerForPrice.length > 0 &&
                !condSelectLaw &&
                !condAsigLaw &&
                condFormAtt !== 'Cotización'
              "
            >
              <div class="col-12 custom-alig-content">
                <label class="mb-1"
                  ><strong>Abogados dísponibles</strong></label
                >
              </div>
              <div class="col-12 custom-alig-content">
                <mat-nav-list class="custom-aling-list border-pay">
                  <mat-list-item
                    *ngFor="let item of lisLawyerForPrice"
                    (click)="selectLaw(item)"
                  >
                    <a matLine>
                      <mat-icon class="custom-left-icon-list"
                        ><i class="icon-user"></i
                      ></mat-icon>
                      <span class="link-caption"
                        >{{ item?.user?.name }} - {{ item?.user?.last_name }}
                      </span>
                    </a>
                  </mat-list-item>
                  <div
                    class="col-12 custom-div-pagination justify-content-center"
                  >
                    <ngb-pagination
                      [pageSize]="12"
                      (pageChange)="loadPage($event)"
                      [collectionSize]="dataPagination?.total"
                      [maxSize]="5"
                      [(page)]="page"
                      aria-label="Default pagination"
                    ></ngb-pagination>
                  </div>
                </mat-nav-list>
              </div>
            </div>

            <!-- Prueba ------------- -->
            <div
              class="form-row col-12 custom-top"
              *ngIf="
                lisLawyerForPrice.length > 0 &&
                !condSelectLaw &&
                !condAsigLaw &&
                condFormAtt == 'Cotización'
              "
            >
              <div class="col-12 custom-alig-content">
                <label class="mb-1"
                  ><strong>Abogados dísponibles</strong></label
                >
              </div>
            </div>
            <div
              class="col-12 custom-alig-content"
              *ngIf="
                lisLawyerForPrice.length > 0 &&
                !condSelectLaw &&
                !condAsigLaw &&
                condFormAtt == 'Cotización'
              "
              sfxLayout="column"
              fxLayoutAlign="start start"
              style="min-height: min-content"
            >
              <mat-divider style="align-self: stretch"></mat-divider>
              <mat-selection-list #shoes>
                <mat-list-option
                  class="custom-list-option"
                  *ngFor="let item of lisLawyerForPrice"
                  (click)="filterListLaw(item)"
                >
                  <span class="link-caption">
                    <i class="icon-user"></i>
                    {{ item?.user?.name }} -{{ item?.user?.last_name }}
                  </span>
                </mat-list-option>
                <div
                  class="col-12 custom-div-pagination justify-content-center"
                >
                  <ngb-pagination
                    [pageSize]="12"
                    (pageChange)="loadPage($event)"
                    [collectionSize]="dataPagination?.total"
                    [maxSize]="5"
                    [(page)]="page"
                    aria-label="Default pagination"
                  ></ngb-pagination>
                </div>
              </mat-selection-list>

              <mat-divider style="align-self: stretch"></mat-divider>
            </div>

            <div
              class="form-row col-12 custom-top custom-aling-l"
              *ngIf="condSelectLaw"
            >
              <div class="col-12 custom-alig-content">
                <label class="mb-1"
                  ><strong>Abogado seleccionado</strong></label
                >
              </div>
              <div class="col-6 custom-alig-content custom-top">
                <span class="link-caption">
                  <i class="icon-user"></i>
                  {{ LawSelectDataUser?.name }} -
                  {{ LawSelectDataUser?.last_name }}
                </span>
              </div>
              <div class="col-6 text-center custom-top custom-aling-r">
                <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="chageLaw()"
                >
                  <i class="icon-loop"></i>
                  Cambiar abogado
                </button>
              </div>
            </div>

            <div
              class="form-row col-12 custom-top"
              *ngIf="
                lisLawyerForPrice.length > 0 &&
                !condSelecPay &&
                condFormAtt === 'Asesoría'
              "
            >
              <div class="col-12 custom-alig-content">
                <label class="mb-1"
                  ><strong>Seleccione método de pago</strong></label
                >
              </div>
              <div class="col-12 custom-alig-content">
                <mat-nav-list class="custom-aling-list border-pay">
                  <mat-list-item
                    *ngFor="let item of lisTypePay"
                    (click)="verifPayMode(payModal, item)"
                  >
                    <a matLine>
                      <mat-icon class="custom-left-icon-list"
                        ><i [class]="item.icon"></i>
                      </mat-icon>
                      <span class="link-caption">{{ item.name }}</span>
                    </a>
                  </mat-list-item>
                </mat-nav-list>
              </div>
            </div>

            <div class="form-row col-12 custom-top" *ngIf="condSelecPay">
              <div class="col-12 custom-alig-content">
                <label class="mb-1"
                  ><strong>Método de pago seleccionado</strong></label
                >
              </div>
              <div class="col-12 custom-alig-content">
                <span class="link-caption">
                  <i [class]="paySelect.icon"></i>
                  {{ paySelect?.name }}
                </span>
              </div>
            </div>

            <label class="mb-1 custom-top" *ngIf="lisLawyerForPrice.length > 0"
              ><strong>Descripción de la asesoría </strong></label
            >
            <textarea
              type="text"
              class="form-control"
              formControlName="descripcion"
              rows="3"
              *ngIf="lisLawyerForPrice.length > 0"
            ></textarea>
            <div
              *ngIf="isSubmitted && controls.descripcion?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="controls.descripcion.errors?.required">
                Campo requerido.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="form-row" *ngIf="lisLawyerForPrice.length > 0">
    <div class="col-6 text-center">
      <button
        *ngIf="condSelecPay || condNoPayReq"
        type="submit"
        class="btn btn-primary"
        (click)="sendData()"
      >
        Aceptar
      </button>
    </div>
    <div class="col-6 text-center">
      <button type="submit" class="btn btn-outline-danger">Cancelar</button>
    </div>
  </div>
</div>

<ng-template #payModal>
  <div class="modal-header" *ngIf="payment_method_id === 1">
    <h4 class="modal-title" id="modal-basic-title">Registro de pago</h4>
    <div>
      <div class="col-12 custom-alig-content custom-top">
        <mat-nav-list class="custom-aling-list border-pay">
          <mat-list-item>
            <a matLine>
              <label class="mb-1 custom-line-label custom-top"
                ><strong>Titular: </strong></label
              >
              <span class="link-caption"> Adrea Jimenez</span>
            </a>
          </mat-list-item>
          <mat-list-item>
            <a matLine>
              <label class="mb-1 custom-line-label custom-top"
                ><strong>Banco: </strong></label
              >
              <span class="link-caption"> General</span>
            </a>
          </mat-list-item>
          <mat-list-item>
            <a matLine>
              <label class="mb-1 custom-line-label custom-top"
                ><strong>Tipo de cuenta: </strong></label
              >
              <span class="link-caption"> Corriente</span>
            </a>
          </mat-list-item>
          <mat-list-item>
            <a matLine>
              <label class="mb-1 custom-line-label custom-top"
                ><strong>Nro de cuenta: </strong></label
              >
              <span class="link-caption"> 1234567891234567</span>
            </a>
          </mat-list-item>
        </mat-nav-list>
      </div>

      <form
        class="form-valide"
        action="#"
        method="post"
        [formGroup]="formPay"
        enctype="multipart/form-data"
        id="formPay"
        (ngSubmit)="onSubmit()"
        novalidate
      >
        <div class="form-row">
          <div class="col-6">
            <label class="mb-1 custom-line-label custom-top"
              ><strong>Monto</strong></label
            >
            <input
              type="number"
              class="form-control"
              name="amount"
              formControlName="amount"
            />
            <div
              *ngIf="submitted && f.amount?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="f.name.amount.required">Campo requerido.</div>
            </div>
          </div>
          <div class="col-12">
            <label class="mb-1 custom-line-label custom-top"
              ><strong>Nro de referencia</strong></label
            >
            <input
              type="text"
              class="form-control"
              name="reference_number"
              formControlName="reference_number"
            />
            <div
              *ngIf="submitted && f.reference_number?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="f.name.reference_number.required">
                Campo requerido.
              </div>
            </div>
          </div>
          <div class="col-12 custom-seccion-file custom-top">
            <span class="span-input-file"
              ><strong>Adjuntar recibo</strong></span
            >
            <input
              type="file"
              formControlName="reference_file"
              name="reference_file"
              (change)="upload($event.target.files)"
            />
          </div>
        </div>
      </form>

      <div class="form-row custom-top">
        <div class="col-6 text-center">
          <button
            type="submit"
            class="btn btn-outline-danger"
            (click)="closed()"
          >
            Cancelar
          </button>
        </div>
        <div class="col-6 text-center">
          <button type="submit" class="btn btn-primary" (click)="sendDataPay()">
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-header" *ngIf="payment_method_id === 2">
    <div class="col-12"></div>
    <h4 class="modal-title" id="modal-basic-title">
      Seleccione una tarjeta para realizar su pago
    </h4>
    <div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let item of ItemExample">
          <input
            type="radio"
            [(ngModel)]="radioSelected"
            name="list_name"
            value="{{ item.card_token }}"
            (change)="onItemChange(item)"
          />
          <strong> {{ item.card_type }} </strong> - {{ item.card_token }}
        </li>
      </ul>
    </div>

    <div class="form-row custom-top">
      <div class="col-6"></div>
      <div class="col-6">
        <button type="submit" class="btn btn-primary" (click)="addNewCard()">
          <i class="ti-plus"></i>
          Registrar nueva tarjeta
        </button>
      </div>
      <div class="col-6 text-center custom-top">
        <button type="submit" class="btn btn-outline-danger" (click)="closed()">
          Cancelar
        </button>
      </div>
      <div class="col-6 text-center custom-top">
        <button
          *ngIf="condAcceptCard"
          type="submit"
          class="btn btn-primary"
          (click)="accepSelectCard()"
        >
          Aceptar
        </button>
      </div>
    </div>
  </div>
  <div class="form-row custom-top custom-padding">
    <div class="col-12" *ngIf="condFormAddCard">
      <form
        class="form-valide"
        action="#"
        method="post"
        [formGroup]="formNewCard"
        id="formNewCard"
        (ngSubmit)="onSubmit()"
        novalidate
      >
        <div class="form-row">
          <div class="col-6">
            <label for=""><strong>Tipo de tarjeta</strong></label>
            <select
              class="form-card_type"
              formControlName="card_type"
              [ngModelOptions]="{ standalone: true }"
            >
              <option disabled selected value="0">Seleccione una opcion</option>
              <option
                [value]="option.name"
                *ngFor="let option of card_type_list"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label for=""><strong>Nro de seguridad</strong></label>
            <input
              type="text"
              class="form-control"
              name="masked_card_number"
              formControlName="masked_card_number"
            />
            <div
              *ngIf="submitted && p.masked_card_number?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="p.name.masked_card_number.required">
                Campo requerido.
              </div>
            </div>
          </div>

          <div class="col-12">
            <label for=""><strong>Nro de tarjeta</strong></label>
            <input
              type="text"
              class="form-control"
              name="card_token"
              formControlName="card_token"
            />
            <div
              *ngIf="submitted && p.card_token?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="p.name.card_token.required">Campo requerido.</div>
            </div>
          </div>
        </div>
      </form>
      <div class="form-row custom-top">
        <div class="col-6 text-center">
          <button
            type="submit"
            class="btn btn-outline-danger"
            (click)="closedaddCard()"
          >
            Cancelar
          </button>
        </div>
        <div class="col-6 text-center">
          <button type="submit" class="btn btn-primary" (click)="regNewCard()">
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
