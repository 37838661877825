<div class="custom-div-filter">

  <div class="form-row">
    <div class="col-6">

    </div>
    <div class="col-4">
      <input type="text" (keyup)="onKey($event)" [(ngModel)]="search" placeholder="Nombre de tramite"
        class="form-control" name="search" />
    </div>
    <div class="col-2" class="custom-icon-search">
      <i (click)="GetData(1)" class="flaticon-381-search-1" href="javascript:void(0);" role="button"></i>
    </div>
  </div>
</div>
<div class="mt-4">
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Nombre del Tramite</th>
          <th scope="col">Nombre del Solicitante</th>
          <th scope="col">Fecha ultimo pago</th>
          <!-- <th scope="col" *ngIf="TypeTab == '3'">Fecha Agendada/Entrega</th>
              <th scope="col" *ngIf="TypeTab == '2'">Juez/Fiscal(Penal)</th>
              <th scope="col" *ngIf="TypeTab == '2'">Apoderado</th> -->
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of Data">
          <th scope="row">{{data.id}}</th>
          <td>{{data.service.name}}</td>
          <td class="text-capitalize">{{data.userApplicant.full_name}}</td>
          <td class="text-capitalize">{{ dateLastPayment(data.order_payments) }}</td>
          <!-- <td *ngIf="TypeTab == '3'">@mdo</td>
              <td *ngIf="TypeTab == '2'">Lorem ipsum dolor sit amet consectetur adipisicing elit. </td>
              <td *ngIf="TypeTab == '2'">Lorem ipsum dolor sit amet consectetur adipisicing elit. </td> -->
          <td><button class="btn btn-primary" (click)="add(serviceModal,data.id)">Detalle</button> </td>
        </tr>

      </tbody>
    </table>
  </div>
  <div class="row justify-content-end">
    <div class="col-auto">
    <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
        [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
        aria-label="Default pagination"></ngb-pagination>
    </div>
  </div>
</div>


<ng-template #serviceModal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title">Detalle del Servicio Activo</h4> -->
    <div class="col-12">
      <div class="row">
        <div class="col-6 d-flex ">
          <h4 class="modal-title" id="modal-basic-title">Fecha de Solicitud:</h4>

          <p class="ml-2 mb-0"> {{DataDetail?.created_at}}</p>
        </div>

        <div class="col-6 d-flex justify-content-end text-right">
          <h4 *ngIf="DataDetail.type_service.id === 2" class="modal-title" id="modal-basic-title">
            Numero de Proceso:</h4>
          <h4 *ngIf="DataDetail.type_service.id === 3" class="modal-title" id="modal-basic-title">
            Numero de Asesoria:</h4>
          <h4 *ngIf="DataDetail.type_service.id === 1" class="modal-title" id="modal-basic-title">
            Numero de Trámite:</h4>

          <p class="ml-2 mb-0"> {{DataDetail?.service.id}}</p>
        </div>

      </div>
    </div>

  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>General</a>
        <ng-template ngbNavContent>
          <div id="content_1" class="mt-4 p-3">
            <div class="row ">

              <div class="col-4 d-flex justify-content-start align-items-center mb-2">
                <label for="" class="font-weight-bold mb-0">Rama:</label>
                <p class="ml-2 mb-0">{{DataDetail?.branch_law.name}}</p>
              </div>
              <div class="col-8 d-flex justify-content-start align-items-center mb-2">
                <label for="" class="font-weight-bold mb-0">Numero de Expediente:</label>
                <p class="ml-2 mb-0">{{DataDetail?.file_number != null ? DataDetail?.file_number :'N/A'}}</p> 
                <div class="col-6">
                  <input class="form-control form-control-sm " type="text" [(ngModel)]="DataDetail.file_number"
                    [ngModelOptions]="{standalone: true}">
                </div>

              </div>
              <!--  -->
              <div class="col-12 d-flex justify-content-start align-items-center mb-2">
                <label for="" class="font-weight-bold mb-0">Nombre del Tramite:</label>
                <p class="ml-2 mb-0">{{DataDetail?.service.name}}</p>
              </div>
              <div class="col-12 d-flex justify-content-start align-items-center mb-2">
                <label for="" class="font-weight-bold mb-0">Nombre del Solicitante:</label>
                <p class="ml-2 mb-0">{{DataDetail?.user.full_name}}</p>
              </div>
              <div class="col-8 d-flex justify-content-start align-items-center mb-2">
                <label for="" class="font-weight-bold mb-0">Nombre de Contraparte:</label>
                 <p class="ml-2 mb-0">{{DataDetail?.counterpart_name}}</p> 

                <div class="col-6">
                  <input class="form-control form-control-sm " type="text" [(ngModel)]="DataDetail.counterpart_name"
                    [ngModelOptions]="{standalone: true}">
                </div>
              </div>
               <hr> 

            </div>
            <!--  -->
            <div class="row mt-2">
              <div class="col-8 d-flex justify-content-start align-items-center mb-2">
                <label for="" class="font-weight-bold">Despacho:</label>
                <!-- <p class="ml-2 mb-2">{{DataDetail?.office}}</p> -->

                <div class="col">
                  <input class="form-control form-control-sm " type="text" [(ngModel)]="DataDetail.office"
                    [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <!--  -->
              <div class="col-8 d-flex justify-content-start align-items-center mb-3">
                <label for="" class="font-weight-bold">Entidad:</label>
                <!-- <p class="ml-2 mb-2">{{DataDetail?.entity }}</p> -->

                <div class="col">
                  <input class="form-control form-control-sm " type="text" [(ngModel)]="DataDetail.entity"
                    [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <!--  -->
              <div class="col-8 d-flex justify-content-start align-items-center mb-3">
                <label for="" class="font-weight-bold">Jurisdicción:</label>
                <!-- <p class="ml-2 mb-2">{{DataDetail?.jurisdiction }}</p> -->
                <div class="col">
                  <input class="form-control form-control-sm " type="text" [(ngModel)]="DataDetail.jurisdiction"
                    [ngModelOptions]="{standalone: true}">
                </div>

              </div>
              <div class="col-6 d-flex justify-content-start align-items-center mb-2">
                <label for="" class="font-weight-bold mb-0">Fases del Tramite:</label>
                <p class="ml-2  mb-0">
                  <ng-container *ngFor="let phase of DataDetail?.phases">
                    {{ phase.description }}
                  </ng-container>
                </p>
              </div>

              <div class="col-6 d-flex justify-content-start align-items-center mb-2">
                <label for="" class="font-weight-bold m-0">Fase Actual:</label>
                <div class="col-5">
                  <select name="" id="" class="form-control" [(ngModel)]="DataDetail.current_phase">
                    <option *ngFor="let phase of DataDetail?.phases" [value]="phase.phase"
                      selected="phase.phase == DataDetail?.current_phase">
                      {{ phase.description }}
                    </option>

                  </select>
                </div>

              </div>




            </div>
          </div>

        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Documentos</a>
        <ng-template ngbNavContent>
          <div id="content_2" class="mt-4 p-2">
            <div class="row ">

              <div class="col-12 px-3 my-3 d-flex justify-content-center align-items-center ">
                <div class="col-9 d-flex justify-content-around align-items-center  alert-dark py-2 px-4 rounded mb-2">
                  <label for="" class="font-weight-bold m-0">Añadir Documentos:</label>
                  <div class="col-5">
                    <select name="" id="" class="form-control" [(ngModel)]="IsPublic"
                      [ngModelOptions]="{standalone: true}">
                      <option value="3" disabled>Seleccione una opcion</option>
                      <option value="1">Compartido</option>
                      <option value="0">Privado</option>
                    </select>
                  </div>
                  <div class="col-2">
                    <div class="image-upload">
                      <label for="file-input">
                        <i class="flaticon-381-upload-1" style="font-size: 20px;"></i>
                      </label>

                      <input id="file-input" type="file" accept="application/pdf" (change)="onFileSelect($event)" />
                    </div>
                  </div>
                </div>




              </div>
              <div class="col-12 px-3 d-flex justify-content-start align-items-center ">
                <div class="col-8 ">Ver Documentos:</div>
              </div>

              <div class="col-12 px-3 my-3  align-items-center ">
                <div class="row d-flex justify-content-center">
                  <div class="col-6 mb-2" *ngFor="let document of ListDocument">

                    <div class="list-group">
                      <!-- [href]="document.file_url" target="_blank"  -->
                      <a (click)="ShowPdf(document,document.upload)" href="javascript:void(0)"
                        class="list-group-item list-group-item-action flex-column align-items-start p-2">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1 text-capitalize">{{document.name}}</h5>
                          <small>{{document.date}}</small>
                        </div>
                        <!-- <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p> -->
                        <div style="width: 100%;" class="d-flex justify-content-end">
                          <small>{{document.user}}</small>
                        </div>

                      </a>


                    </div>
                  </div>
                </div>


              </div>

            </div>
          </div>

        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Pagos</a>
        <ng-template ngbNavContent>
          <div class="mt-4 p-2" id="content_3">
            <div class="row">
              <div
                class="col-12 px-3 d-flex justify-content-start align-items-center"
              >
                <div class="col-8">Pagos Realizados:</div>
              </div>

                <div
                  class="col-6 mb-2 mt-2"
                  *ngFor="let payment of DataDetail?.order_payments"
                >
                  <div class="list-group">
                    <a
                      [href]="payment.reference_file"
                      target="_blank"
                      class="list-group-item flex-column align-items-start p-2"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <p class="mb-1">{{ payment.amount }}$</p>
                        <small>{{ payment.reference_number }}</small>
                      </div>
                    </a>
                  </div>
                </div>

            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink>Historial de Cambios</a>
        <ng-template ngbNavContent>
          <div class="mt-4 p-2" id="content_4">
            <div class="row ">
              <div class="col-12 px-3 d-flex justify-content-start align-items-center ">
              </div>
              <div class="col-12 px-3 my-3 d-flex justify-content-center align-items-center ">
                <div class="col-12">
                  <div class="card-body">
                      <ng-container *ngIf="dataHistory.length; else nodatahistory">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Hecho por</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let historyData of dataHistory">
                                <td>{{historyData?.created_at  | date: 'dd/MM/yyyy h:mma' }}</td>
                                <td> {{historyData?.user?.full_name}}</td>
                                <td>
                                  <div>
                                    <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                                    (click)="openModalDetailHistory(detailModalHistory,historyData)" placement="top" ngbTooltip="Ver cambios">
                                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212f54;">
                                        visibility
                                      </mat-icon>
                                    </button>
                                  </div>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                        <div class="row justify-content-end">
                          <div class="col-auto custom-div-pagination justify-content-center">
                            <ngb-pagination [pageSize]="dataHistoryPagination?.perPage" (pageChange)="loadDataHistory($event)"
                              [collectionSize]="dataHistoryPagination?.total" [maxSize]="5" [(page)]="dataHistoryPagination.currentPage" aria-label="Paginación">
                            </ngb-pagination>
                          </div>
                        </div>

                      </ng-container>
                      <ng-template #nodatahistory>
                        <div class="alert alert-info" role="alert">
                          <strong>No hay historial disponible</strong>
                        </div>
                      </ng-template>
                    </div>
                  <!-- <div class="list-group">
                    <a class="list-group-item d-flex flex-row  justify-content-around align-items-start p-2">
                      <p class="mb-1 font-weight-bold ">Módulo en desarrollo</p>
                    </a>
                  </div> -->
                </div>
              </div>
            </div>
          </div>


        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink>Comentarios</a>
        <ng-template ngbNavContent>
          <div class="mt-4 p-2" id="content_4">
            <div class="row ">
              <div class="col-12 ">
                  <div class="d-flex flex-row-reverse bd-highlight">
                    <div class="p-2 bd-highlight">
                      Agregar comentario
                      <a class="ml-3 font-weight-bold cursor-pointer" (click)="addComment()" title="Agregar Documentos">
                        <i class="flaticon-381-add-2"></i>
                      </a>
                </div>

                  </div>
              </div>

              <div class="col-12 px-3  justify-content-start align-items-center ">

                  <label for="" class="font-weight-bold mb-0">Descripción:</label>
                    <br>
                    <input class="form-control form-control-sm " type="text" [(ngModel)]="comment"   >
                    <br>
                    <div class="card-body">
                      <ng-container *ngIf="listComment.length; else nodata">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Comentario</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let commentData of listComment">
                                <td>{{commentData.created_at  | date: 'dd/MM/yyyy' }}</td>
                                <td> {{commentData.comment}}</td>
                                <td>
                                  <div>
                                    <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                                    (click)="deleteComment(commentData.id)" placement="top" ngbTooltip="Eliminar">
                                    <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #dc3545;">
                                      delete
                                    </mat-icon>
                                  </button>
                                  </div>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                        <div class="row justify-content-end">
                          <div class="col-auto custom-div-pagination justify-content-center">
                            <ngb-pagination [pageSize]="dataPaginationComment?.perPage" (pageChange)="loadDataComment($event)"
                              [collectionSize]="dataPaginationComment?.total" [maxSize]="5" [(page)]="dataPaginationComment.currentPage" aria-label="Paginación">
                            </ngb-pagination>
                          </div>
                        </div>

                      </ng-container>
                      <ng-template #nodata>
                        <div class="alert alert-info" role="alert">
                          <strong>No hay comentarios disponibles</strong>
                        </div>
                      </ng-template>
                    </div>

                </div>


              </div>

            </div>


        </ng-template>
      </li>


    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <!-- <pre>Active: {{ active }}</pre> -->


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="CancelModal()">Cancelar</button>
    <button type="button" class="btn btn-outline-primary" (click)="UploadInfo()">Aceptar</button>

  </div>
</ng-template>

<ng-template #detailModalHistory>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cambios</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <mat-list>
        <!-- <h4 class="modal-title" id="modal-basic-title">Cambios</h4> -->
        <div *ngIf="selectedHistoryChanges && selectedHistoryChanges.length > 0">
          <mat-list-item *ngFor="let change of selectedHistoryChanges">
            <div class="d-flex">
              <strong>{{change.key}}</strong>:
              <span class="mx-3">{{change?.old ? change?.old : '       '}}</span>
              <mat-icon style="color: #212F54;">arrow_right_alt</mat-icon>
              <span class="mx-3">{{change?.new ? change?.new : '       '}}</span>
            </div>
          </mat-list-item>
        </div>
      </mat-list>
    </div>
    <!-- <div class="text-center">
      <h4>{{currentItem.lawyer.name}}</h4>
      <p>{{currentItem.lawyer.suitability_number}}</p>
      <p>{{currentItem.lawyer.address}}</p>
    </div>
    <fieldset>
      <p class="ml-3 mb-2"><strong>Nombre Cliente: </strong>{{currentItem.client?.name}}</p>
      <p class="ml-3 mb-2"><strong>Doc. Identidad: </strong>{{currentItem.client?.dni}}</p>
      <p class="ml-3 mb-2"><strong>Servicio: </strong>{{currentItem.service.name}}</p>
    </fieldset> -->
  </div>
</ng-template>
