<div class="container-fluid form-container"></div>
<div class="content-body d-flex custom-top-list">
  <div class="container-fluid">
    <div class="card">
      <div class="p-4">
        <div class="d-flex justify-content-between ml-1">
          <h3>Listado de planes</h3>
          <div>
            Crear plan
            <button
              mat-icon-button
              color="accent"
              aria-label="Example icon button with a menu icon"
              (click)="add(entityModal)"
              placement="top"
              ngbTooltip="Nuevo plan"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
                style="color: #212f54"
              >
                add_circle_outline
              </mat-icon>
            </button>
          </div>
        </div>
        <hr />
        <div class="filter">
          <div class="search mx-2 d-flex align-items-center flex-row">
            <button type="button " class="btn btn-filter">
              <i class="flaticon-381-search-2"></i>
            </button>
            <input
              type="text"
              class="form-control mx-1 shadow-none"
              placeholder="Nombre del plan"
              aria-label="Nombre del plan"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="listPomotion.length; else nodata">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Id de plan</th>
                  <th scope="col">Nombre del plan</th>
                  <th scope="col">Precio</th>
                  <th scope="col">ITBMS</th>

                  <th style="width: 100px"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let plan of listPomotion; let i = index">
                  <td class="text-capitalize">{{ plan.id }}</td>
                  <td>{{ plan.name }}</td>
                  <td>{{ plan.amount }}</td>
                  <td>{{ plan.ITBMS }}</td>
                  <td>
                    <button
                      mat-icon-button
                      color="accent"
                      aria-label="Example icon button with a menu icon"
                      (click)="showCase(entityModal, plan)"
                      placement="top"
                      ngbTooltip="Editar"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        style="color: #212f54"
                      >
                        edit
                      </mat-icon></button
                    ><button
                      mat-icon-button
                      color="accent"
                      aria-label="Example icon button with a menu icon"
                      (click)="alertDelete(plan.id)"
                      placement="top"
                      ngbTooltip="Eliminar"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        style="color: #dc3545"
                      >
                        delete
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-template #nodata>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #entityModal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ isEdit ? "Editar" : "Agregar" }} plan
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="closed()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form
        class="form-valide"
        action="#"
        method="post"
        [formGroup]="docsForm"
        enctype="multipart/form-data"
        id="form"
        novalidate
      >
        <div class="form-row">
          <div class="col-6">
            <label for=""><strong>Nombre del plan</strong></label>
            <input
              type="text"
              class="form-control"
              name="name"
              formControlName="name"
            />
            <div
              *ngIf="submitted && f.name?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="f.name.errors.required">Campo requerido.</div>
            </div>
          </div>
          <div class="col-12">
            <label for="" class="custom-top"
              ><strong>Descripción</strong></label
            >
            <textarea
              type="text"
              class="form-control"
              name="description"
              formControlName="description"
              rows="5"
              maxlength="255"
            ></textarea>
            <div
              *ngIf="submitted && f.description?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="f.description.errors.required">Campo requerido.</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="custom-top"><strong>Precio</strong></label>
            <input
              type="number"
              class="form-control"
              name="amount"
              formControlName="amount"
            />
            <div
              *ngIf="submitted && f.amount?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="f.amount.errors.required">Campo requerido.</div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="custom-top"><strong>ITBMS</strong></label>
            <input
              type="number"
              class="form-control"
              name="ITBMS"
              formControlName="ITBMS"
            />
            <div
              *ngIf="submitted && f.ITBMS?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="f.ITBMS.errors.required">Campo requerido.</div>
            </div>
          </div>
          <div class="col-6" *ngIf="!isEdit || isValidate ">
            <label for="" class="custom-top"><strong>Imagen</strong></label>
            <input
              type="file"
              class="form-control"
              name="image_plan"
              formControlName="image_plan"
              [(ngModel)]="image_load"
              (change)="loadImage($event)"
            />
          </div>
          <div class="col-12 custom-div-image mt-5" *ngIf="isEdit && !isValidate ">
            <label for="" class="custom-top"><strong>Imagen</strong></label>
            <img class="custom-img-plan" [src]="imageLink"  (click)="changeState()"  />
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-outline-danger mr-2"
              (click)="closed()"
            >
              Cancelar
            </button>
            <button type="submit" class="btn btn-primary" (click)="OnSubmit()">
              {{ isEdit ? "Editar" : "Guardar" }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>
