import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaseTaskService {
  constructor(private http: HttpService) {}

  update(id: number, data: object): Observable<any> {
    return this.http.put(`case-task/${id}`, data);
  }
}
