import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { DataResponse } from '../interfaces/response';
//import { Task } from '../interfaces/common';
import { Observable } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class ScheduleService {

  constructor(
    private http: HttpService
  ) { }

  /**
   * @description listado de tareas
   * @returns Listado 
   */
  listTask(): Observable<DataResponse<any[]>> {
    return this.http.get( 'schedule?type=Tasks' );
  }

  /**
   * @description Agrega una nueva tarea a la lista
   * @param data data de la tarea
   * @returns Nueva tarea
   */
  addTask( data: any ): Observable<any> {
    return this.http.post( `schedule`, data );
  }

  /**
   * @description Actualización de la información de la tarea
   * @param data Data de la tarea
   * @param id id de la tarea
   * @returns tarea actualizada
   */
  editTask( data: any, id: number ): Observable<any> {
    return this.http.put( `schedule/${id}`, data );
  }

  /**
   * @description Elimina una tarea
   * @param id id de la tarea
   * @returns tarea eliminada
   */
   deleteTask(id: number ): Observable<any> {
    return this.http.delete( `schedule/${id}`);
  }

 
}
