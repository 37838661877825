<!--**********************************
    Content body start
***********************************-->
<section class="content-body d-flex">
  <div class="container-fluid p-20">
    <div class="d-grid g-tc-1">
      <div class="">
        <div class="card border-0 b-shadow m-0 h-auto">
          <div class="card-body p-15">
            <h4 class="mb-3">Estatus de Casos</h4>
            <div class="d-grid g-tc-2">
              <div class="d-grid g-rg-15 mb-15-sm g-tr-1">
                <div class="widget-stat card card-sm bg-primary border-0 b-shadow m-0">
                  <div class="card-body p-15">
                    <div class="box-sm">
                      <div class="text-white text-left">
                        <p class="mb-1 txt-nowrap">Casos Activos</p>
                        <h2 class="text-white text-center">{{this.openCases}}</h2>
                      </div>
                      <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100%">
                          <path d="M176 56V96H336V56c0-4.4-3.6-8-8-8H184c-4.4 0-8 3.6-8 8zM128 96V56c0-30.9 25.1-56 56-56H328c30.9 0 56 25.1 56 56V96v32V480H128V128 96zM64 96H96V480H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64zM448 480H416V96h32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64z" fill="white"/>
                          <circle cx="440" cy="128" r="77" fill="white"/>
                          <circle cx="440" cy="128" r="72" fill="darkgoldenrod"/>
                          <path d="M 433 165 l -22 -45" stroke="white" stroke-width="20"/>
                          <path d="M 473 95 l -40 75" stroke="white" stroke-width="20"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="widget-stat card card-sm bg-blue-en border-0 b-shadow m-0">
                  <div class="card-body p-15">
                    <div class="box-sm">
                      <div class="text-white text-left">
                        <p class="mb-1 txt-nowrap">Casos Cerrados</p>
                        <h2 class="text-white text-center">{{this.closeCases}}</h2>
                      </div>
                      <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100%">
                          <path d="M176 56V96H336V56c0-4.4-3.6-8-8-8H184c-4.4 0-8 3.6-8 8zM128 96V56c0-30.9 25.1-56 56-56H328c30.9 0 56 25.1 56 56V96v32V480H128V128 96zM64 96H96V480H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64zM448 480H416V96h32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64z" fill="white"/>
                          <circle cx="440" cy="128" r="77" fill="white"/>
                          <circle cx="440" cy="128" r="72" fill="darkgoldenrod"/>
                          <path d="M 408 95 l 65 65" stroke="white" stroke-width="20"/>
                          <path d="M 473 95 l -65 65" stroke="white" stroke-width="20"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="widget-stat card card-sm border-0 b-shadow m-0 h-100">
                  <div class="card-body p-15" media="">
                    <div class="chart-donut-container">
                      <canvas id="canvas"></canvas>
                    </div>
                    <div class="canvas-footer">
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100%">
                          <circle cx="256" cy="256" r="256" fill="#212F54"></circle>
                        </svg>
                      </div>
                      <div>Activos</div>
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100%">
                          <circle cx="256" cy="256" r="256" fill="#5b7edc"></circle>
                        </svg>
                      </div>
                      <div>Cerrados</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="card m-0 height-auto-sm border-0 b-shadow h-100">
          <div class="card-body h-auto p-15">            
            <h4 class="mb-3">Abogados</h4>
            <div class="d-grid g-rg-15 h-lawyer g-tc-3">
              <div class="card card-sm border-0 b-shadow m-0">
                <div class="card-body p-15">
                  <div class="box-sm">
                    <div class="text-left">
                      <p class="mb-1 txt-nowrap">Red Legal</p>
                      <h2 class="text-center">{{this.lawNetwork}}</h2>
                    </div>
                    <div class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100%">
                        <path d="M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z" fill="#212F54"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-sm border-0 b-shadow m-0">
                <div class="card-body p-15">
                  <div class="box-sm">
                    <div class="text-left">
                      <p class="mb-1 txt-nowrap">Despacho Virtual</p>
                      <h2 class="text-center">{{this.virtualDsk}}</h2>
                    </div>
                    <div class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="100%">
                        <path d="M384 96V320H64L64 96H384zM64 32C28.7 32 0 60.7 0 96V320c0 35.3 28.7 64 64 64H181.3l-10.7 32H96c-17.7 0-32 14.3-32 32s14.3 32 32 32H352c17.7 0 32-14.3 32-32s-14.3-32-32-32H277.3l-10.7-32H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm464 0c-26.5 0-48 21.5-48 48V432c0 26.5 21.5 48 48 48h64c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H528zm16 64h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H544c-8.8 0-16-7.2-16-16s7.2-16 16-16zm-16 80c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H544c-8.8 0-16-7.2-16-16zm32 224c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" fill="#212F54"/>
                        <path d="M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z" transform="scale(0.4) translate(290 265)" fill="#212F54"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pdt-20">
      <div class="">
        <div class="card m-0 border-0 b-shadow">
          <div class="card-body p-15">
            <h4 class="mb-3">Ingresos del mes</h4>
            <div class="chart-container">
              <canvas id="canvas2"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--**********************************
    Content body end
***********************************-->
