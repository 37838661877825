import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { CommonModule } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { AdminComponent } from './pages/admin/admin.component';
import { DashboardComponent } from './pages/lawyer/dashboard/dashboard.component';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { Error400Component } from './pages/errors/error400/error400.component';
import { Error403Component } from './pages/errors/error403/error403.component';
import { Error404Component } from './pages/errors/error404/error404.component';
import { Error500Component } from './pages/errors/error500/error500.component';
import { Error503Component } from './pages/errors/error503/error503.component';
import { Index1Component } from './pages/dashboard/index1/index1.component';
import { AdminDashboardComponent } from './pages/dashboard/admin/dashboard.component';
import { ElementsComponent } from './pages/forms/elements/elements.component';
import { FormValidateComponent } from './pages/forms/form-validate/form-validate.component';
import { UpdatePersonProfileComponent } from './pages/person/update-profile/update-profile.component';
import { UpdateCompanyProfileComponent } from './pages/company/update-profile/update-profile.component';
import { AuthGuard } from './shared/guards/auth.guard';
// Person
import { AdvisoryLawyerPersonComponent } from './pages/person/advisory-lawyer-person/advisory-lawyer-person.component';
import { MyDocumentsPersonComponent } from './pages/person/my-documents-person/my-documents-person.component';

import { ProcessComponent } from './pages/person/process/process.component';
import { ProcServicesComponent } from './pages/person/proc-services/proc-services.component';
import { AdvisoryComponent } from './pages/person/advisory/advisory.component';
// Company
import { AdvisoryLawyerCompanyComponent } from './pages/company/advisory-lawyer-company/advisory-lawyer-company.component';
import { MyDocumentsCompanyComponent } from './pages/company/my-documents-company/my-documents-company.component';
import { ProcServicesCoComponent } from './pages/company/proc-services-co/proc-services-co.component';
import { RegproccoComponent } from './pages/company/regprocco/regprocco.component';
import { LawyerProfileCoComponent } from './pages/company/lawyer-profile-co/lawyer-profile-co.component';
import { InvoicesCompanyComponent } from './pages/company/invoices-company/invoices-company.component';
import { NextPaymentsCompanyComponent } from './pages/company/next-payments-company/next-payments-company.component';

//Lawyer
//import { MyDocumentsLawyerComponent } from './pages/lawyer/my-documents-lawyer/my-documents-lawyer.component';
import { ServiceActiveComponent } from './pages/lawyer/services/service-active/service-active.component';
import { ServiceCompletedComponent } from './pages/lawyer/services/service-completed/service-completed.component';

import { MyDocumentsMarketplaceComponent } from './pages/lawyer/my-documents-marketplace/my-documents-marketplace.component';
import { MyDocumentsPlatformComponent } from './pages/lawyer/my-documents-platform/my-documents-platform.component';
import { MyScheduleComponent } from './pages/lawyer/my-schedule/my-schedule.component';
import { MyClientsLawyerComponent } from './pages/lawyer/my-clients/my-clients-lawyer.component';
import { TaksTimeComponent } from './pages/lawyer/taks-time/taks-time.component';
import { ExecutionTimeComponent } from './pages/lawyer/execution-time/execution-time.component';
import { UpdateLawyerProfileComponent } from './pages/lawyer/update-profile/update-profile.component';
import { PlanningComponent } from './pages/lawyer/planning/planning.component';
import { NewQuoteGeneratorComponent } from './pages/lawyer/new-quote-generator/new-quote-generator.component';


//Admin
import { ServiceConfigurationComponent } from './pages/lawyer/services/service-configuration.component';
import { LawyerCasesComponent } from './pages/lawyer/cases/cases.component';
import { ApprovalsComponent } from './pages/approvals/approvals.component';
import { PersonComponent } from './pages/person/person.component';
import { CompanyComponent } from './pages/company/company.component';
import { LawyerComponent } from './pages/lawyer/lawyer.component';
import { BanksComponent } from './pages/config/banks/banks.component';
import { EntitiesComponent } from './pages/config/entities/entities.component';
import { ManagementUserComponent } from './pages/admin/management-user/management-user.component';   // Gestion de usuario admin
import { RequirementsComponent } from './pages/config/requirements/requirements.component';
import { BranchesLawComponent } from './pages/config/branches-law/branches-law.component';
import { ServicesComponent } from './pages/config/services/services.component';
import { ClientsComponent } from './pages/lawyer/clients/clients.component';
import { RegtramComponent } from './pages/person/regtram/regtram.component';
import { LawyerProfileComponent } from './pages/person/lawyer-profile/lawyer-profile.component';
import { RegprocComponent } from './pages/person/regproc/regproc.component';
import { RegadvisoryComponent } from './pages/person/regadvisory/regadvisory.component';
import { FollServicesComponent } from './pages/person/foll-services/foll-services.component';
import { ConsultanciesComponent } from './pages/lawyer/requests/consultancies/consultancies.component';
import { QuotesComponent } from './pages/lawyer/requests/quotes/quotes.component';
import { ProcessCoComponent } from './pages/company/process-co/process-co.component';
import { RegtramCoComponent } from './pages/company/regtram-co/regtram-co.component';
import { RegadvisoryCoComponent } from './pages/company/regadvisory-co/regadvisory-co.component';
import { AdvisoryCoComponent } from './pages/company/advisory-co/advisory-co.component';
import { FollServicesCoComponent } from './pages/company/foll-services-co/foll-services-co.component';
import { ScheduleCoComponent } from './pages/company/schedule-co/schedule-co.component';
import { ChatsComponent } from './shared/components/chats/chats.component';
import { ChatComponent } from './shared/components/chat/chat.component';
import { NotificationsPanelComponent } from './shared/components/notifications-panel/notifications-panel.component';
import { MyFinancesComponent } from './pages/lawyer/my-finances/my-finances.component';
import { MyReviewsComponent } from './pages/lawyer/my-reviews/my-reviews.component';
import { StatementComponent } from './pages/lawyer/statement-account/statement.component';
import { ReportsComponent } from './pages/lawyer/reports/reports.component';
import { OpReportsComponent } from './pages/lawyer/op-reports/op-reports.component';
import { InvoicesComponent } from './pages/person/invoices/invoices.component';
import { NextPaymentsComponent } from './pages/person/next-payments/next-payments.component';
import { ServiceRequestComponent } from '././shared/components/service-request/service-request.component';
import { ServiceRequestAdvisoryComponent } from '././shared/components/service-request-advisory/service-request-advisory.component';
import { ApprovedQuotesComponent } from './pages/lawyer/my-finances/approved-quotes/approved-quotes.component';
import { PendingAccountsComponent } from './pages/lawyer/pending-accounts/pending-accounts.component';
import { IncomeComponent } from './pages/lawyer/income/income.component';
import { ExpensesComponent } from './pages/lawyer/expenses/expenses.component';
import { ServiceMonitoringComponent } from './pages/admin/service-monitoring/service-monitoring.component';
import { FinancialStateComponent } from './pages/admin/financial-state/financial-state.component';
import { AccountsPayableComponent } from './pages/admin/accounts-payable/accounts-payable.component';
import { LawyersReportComponent } from './pages/admin/lawyers-report/lawyers-report.component';
import { CustomerReportComponent } from './pages/admin/customer-report/customer-report.component';
import { FilesComponent } from './pages/admin/files/files.component';
import { FileDetailsComponent } from './pages/admin/file-details/file-details.component';
import { LawyerPaymentComponent } from './pages/lawyer-payment/lawyer-payment.component';
import { ApprovalsPersonComponent } from './pages/person/approvals-person/approvals-person.component';
import { ApprovalsCompanyComponent } from './pages/company/approvals-company/approvals-company.component';

import { InvoicesAdminComponent } from './pages/admin/invoices-admin/invoices-admin.component';
import { PaymentsAppoveComponent } from './pages/admin/payments-appove/payments-appove.component';
import { PromotionComponent } from './pages/admin/promotion/promotion.component';

const routes: Routes = [
  { path: '', redirectTo: 'admin/index', pathMatch: 'full' },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', component: Index1Component },
      { path: 'index', component: AdminDashboardComponent },
      { path: 'index-1', component: AdminDashboardComponent },
      { path: 'dashboard', component: AdminDashboardComponent },
      { path: 'form-element', component: ElementsComponent },
      { path: 'form-validate', component: FormValidateComponent },
      { path: 'approvals', component: ApprovalsComponent },
      { path: 'management-user', component: ManagementUserComponent },
      { path: 'service-monitoring', component: ServiceMonitoringComponent },
      { path: 'account-status', component: FinancialStateComponent },
      { path: 'accounts-payable', component: AccountsPayableComponent },
      { path: 'lawyers-report', component: LawyersReportComponent },
      { path: 'customers-report', component: CustomerReportComponent },
      { path: 'files', component: FilesComponent },
      { path: 'file-details', component: FileDetailsComponent },
      { path: 'config/requirements', component: RequirementsComponent },
      { path: 'config/services', component: ServicesComponent },
      { path: 'config/branches-law', component: BranchesLawComponent },
      { path: 'notifications-panel', component: NotificationsPanelComponent },

      { path: 'invoices', component: InvoicesAdminComponent },
      { path: 'payments-appove', component: PaymentsAppoveComponent},
      { path: 'promotion', component: PromotionComponent }
    ],
    canActivate: [ AuthGuard ],
    data: { role: 'admin' },
  },
  {
    path: 'person',
    component: PersonComponent,
    children: [
      { path: '', component: Index1Component },
      { path: 'index', component: Index1Component },
      { path: 'index-1', component: Index1Component },
      { path: 'dashboard', component: Index1Component },
      { path: 'advisory-lawyer-person', component: AdvisoryLawyerPersonComponent },
      { path: 'documents', component: MyDocumentsPersonComponent },
      { path: 'update-profile', component: UpdatePersonProfileComponent },
      { path: 'process', component: ProcessComponent },
      { path: 'regtram', component: RegtramComponent },
      { path: 'proc-services', component: ProcServicesComponent },
      { path: 'advisory', component: AdvisoryComponent },
      { path: 'lawyer-profile', component: LawyerProfileComponent },
      { path: 'regproc', component: RegprocComponent },
      { path: 'regadvisory', component: RegadvisoryComponent },
      { path: 'schedule', component: MyScheduleComponent },
      { path: 'foll-services', component: FollServicesComponent },
      { path: 'chat', component: ChatsComponent },
      { path: 'chat/:id', component: ChatComponent },
      { path: 'notifications-panel', component: NotificationsPanelComponent },
      { path: 'invoices', component: InvoicesComponent },
      { path: 'next-payments', component: NextPaymentsComponent },
      { path: 'service-request/:id', component: ServiceRequestComponent },
      { path: 'service-request-advisory', component: ServiceRequestAdvisoryComponent },
      { path: 'approvals', component: ApprovalsPersonComponent }
    ],
    canActivate: [ AuthGuard ],
    data: { role: 'person' },
  },
  {
    path: 'company',
    component: CompanyComponent,
    children: [
      { path: '', component: Index1Component },
      { path: 'index', component: Index1Component },
      { path: 'index-1', component: Index1Component },
      { path: 'dashboard', component: Index1Component },
      { path: 'update-profile', component: UpdateCompanyProfileComponent },
      { path: 'advisory', component: AdvisoryLawyerCompanyComponent },
      { path: 'documents', component: MyDocumentsCompanyComponent },
      { path: 'proc-services-co', component: ProcServicesCoComponent},
      { path: 'regprocco', component: RegproccoComponent},
      { path: 'lawyer-profile-co', component: LawyerProfileCoComponent},
      { path: 'process-co', component: ProcessCoComponent},
      { path: 'regtram-co', component: RegtramCoComponent},
      { path: 'regadvisory-co', component: RegadvisoryCoComponent},
      { path: 'foll-services-co', component: FollServicesCoComponent},
      { path: 'schedule-co', component: ScheduleCoComponent},
      { path: 'schedule', component: MyScheduleComponent },
      { path: 'advisory-co', component: AdvisoryCoComponent},
      { path: 'notifications-panel', component: NotificationsPanelComponent },
      { path: 'service-request/:id', component: ServiceRequestComponent },
      { path: 'service-request-advisory', component: ServiceRequestAdvisoryComponent },
      { path: 'approvals', component: ApprovalsCompanyComponent },
      { path: 'invoices', component: InvoicesCompanyComponent },
      { path: 'next-payments', component: NextPaymentsCompanyComponent },
    ],
    canActivate: [ AuthGuard ],
    data: { role: 'company' },
  },
  {
    path: 'lawyer',
    component: LawyerComponent,
    children: [
      { path: '', component: Index1Component },
      { path: 'index', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'services/configuration', component: ServiceConfigurationComponent },
      { path: 'services/active', component: ServiceActiveComponent },
      { path: 'services/completed', component: ServiceCompletedComponent },
      { path: 'config/entities', component: EntitiesComponent },
      { path: 'config/clients', component: ClientsComponent },
      { path: 'cases', component: LawyerCasesComponent },
//      { path: 'documents', component: MyDocumentsLawyerComponent },
      { path: 'requests/consultancies', component: ConsultanciesComponent },
      { path: 'requests/quotes', component: QuotesComponent },
      { path: 'documents', component: MyDocumentsMarketplaceComponent },
      { path: 'my-documents', component: MyDocumentsPlatformComponent },
      { path: 'schedule', component: MyScheduleComponent },
      { path: 'my-clients', component: MyClientsLawyerComponent },
      { path: 'management/task-time', component: TaksTimeComponent },
      { path: 'management/execution-time', component: ExecutionTimeComponent },
      { path: 'update-profile', component: UpdateLawyerProfileComponent },
      { path: 'finances', component: MyFinancesComponent },
      { path: 'reviews', component: MyReviewsComponent },
      { path: 'notifications-panel', component: NotificationsPanelComponent },
      { path: 'statement', component: StatementComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'op-reports', component: OpReportsComponent },
      { path: 'approved-quotes', component: ApprovedQuotesComponent },
      { path: 'new-quote-generator', component: NewQuoteGeneratorComponent },
      { path: 'planning', component: PlanningComponent},
      { path: 'pending-accounts', component: PendingAccountsComponent },
      { path: 'income', component: IncomeComponent },
      { path: 'expenses', component: ExpensesComponent },
      { path: 'chat', component: ChatsComponent },
      { path: 'chat/:id', component: ChatComponent },
      { path: 'notifications-panel', component: NotificationsPanelComponent }
    ],
    canActivate: [ AuthGuard ],
    data: { role: 'lawyer' },
  },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent, data: { isAdmin: false } },
  { path: 'login/admin', component: LoginComponent, data: { isAdmin: true } },
  { path: 'lock-screen', component: LockScreenComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'error-400', component: Error400Component },
  { path: 'error-403', component: Error403Component },
  { path: 'error-404', component: Error404Component },
  { path: 'error-500', component: Error500Component },
  { path: 'error-503', component: Error503Component },
  { path: 'lawyer-payment', component: LawyerPaymentComponent },
  { path: '**', component: Error404Component },
];

@NgModule( {
  imports: [ RouterModule.forRoot( routes ), CommonModule, BrowserModule ],
  exports: [ RouterModule ],
} )
export class AppRoutingModule { }
/* comentario para que detente cmabios en rutas*/
