import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { Province } from '../../../interfaces/province';
import { City } from '../../../interfaces/city';
import { Township } from '../../../interfaces/township';
import { MustMatch } from '../../../helpers/must-match.validator';

@Component( {
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: [ './person-form.component.css' ]
} )
export class PersonFormComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  provinces: Province[] = [];
  cities: City[] = [];
  townships: Township[] = [];
  typePass = 'password';
  iconPass = 'visibility_off';

  @Output() submitEvent = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
  ) {
    this.loadProvinces();
    this.createForm();
  }

  get f(): any { return this.form.controls; }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    if ( this.form.valid ) { this.submitEvent.emit( this.form.value ); }
  }

  onChange( id: number, type: string ): void {
    if ( type === 'province' ) { this.loadCities( id ); }
    if ( type === 'city' ) { this.loadTownships( id ); }
    if ( type === 'township' ) { this.form.controls.township_id.patchValue( id ); }
  }

  loadProvinces(): void {
    this.common.provinces( 171 ).subscribe( response => {
      this.provinces = [ ...response.data ];
      this.loadCities( this.provinces[ 0 ].id );
    } );
  }

  loadCities( id: number ): void {
    this.common.cities( id ).subscribe( response => {
      this.cities = [ ...response.data ];
      this.townships = [];
      this.loadTownships( this.cities[ 0 ].id );
    } );
  }

  loadTownships( id: number ): void {
    this.common.townships( id ).subscribe( response => {
      this.townships = [ ...response.data ];
      //this.form.controls.township_id.patchValue( this.townships[ 0 ].id );
    } );
  }

  private createForm(): void {
    this.form = this.fb.group( {
      phone: [ '', [ Validators.required ] ],
      township_id: [ '', [ Validators.required ] ],
      address: [ '', [ Validators.required ] ],
      dni: [ '', [ Validators.required ] ],
      name: [ '', [ Validators.required, Validators.minLength( 3 ) ] ],
      last_name: [ '', [ Validators.required, Validators.minLength( 3 ) ] ],
      email: [ '', [ Validators.required, Validators.email , Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")] ],
      password: [ '', [ Validators.required, Validators.minLength( 8 ) ] ],
      password_confirmation: [ '', [ Validators.required ] ],
      main_image: [ '' ]
    }, {
      validator: MustMatch( 'password', 'password_confirmation' )
    } );
  }

  onChangePass(){
    if(this.typePass=='password') {
      this.typePass = 'text';
      this.iconPass = 'visibility';
    } else if(this.typePass=='text') {
      this.typePass = 'password';
      this.iconPass = 'visibility_off';
    }
  }

  refreshAddress() {
    this.loadProvinces();
  }

}
