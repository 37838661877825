import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AVATAR } from 'src/app/shared/data/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from '../../../shared/services/clients.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-lawyer-profile',
  templateUrl: './lawyer-profile.component.html',
  styleUrls: ['./lawyer-profile.component.css']
})
export class LawyerProfileComponent implements OnInit {
  dataLawyer; dataLawyerProfile;
  avatar = AVATAR;
  constructor(
    private ClientsService: ClientsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _location: Location
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.dataLawyer = params['dataLawyer'];
    });
  }

  ngOnInit(): void {
    this.getProfileLawyer();
  }

  getProfileLawyer() {
    this.ClientsService.getProfileLawyer(this.dataLawyer).subscribe(response => {
      this.dataLawyerProfile = response.data;
    });
  }
  backPage() {
    this._location.back();
  }    

}
