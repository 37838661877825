
<section class="content-body d-flex align-items-center" >
    <div class="container-fluid" >
        <div class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
               <h3>Agenda </h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">

                <div class="card border-0">
        
                    <ul ngbNav #nav="ngbNav" [activeId]="active" class="nav-tabs nav nav-pills nav-fill">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Eventos</a>
                            <ng-template ngbNavContent>
                              
                                <div class="p-4">

                                  <app-my-calendar></app-my-calendar>
            
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2" *ngIf="condViewListTask">
                            <a ngbNavLink>Tareas</a>
                            <ng-template ngbNavContent>
                                <div class="p-4">

                                    <div class="card border-0">
                                        <div class="card-header">
                                          <h3>&nbsp;</h3>
                                          <div>
                                            Crear tarea
                                            <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                                              (click)="addTaskModal(taskModal)" placement="top" ngbTooltip="Nueva tarea">
                                              <mat-icon aria-hidden="false" aria-label="Example home icon" style="color:#212F54">
                                                add_circle_outline
                                              </mat-icon>
                                            </button>
                                          </div>
                                        </div>
                                        <div class="card-body">
                                          <ng-container *ngIf="listTask.length; else nodata">
                                            <div class="">
                                              <table class="table table-condensed table-striped">
                                                <thead>
													<tr>
														<th>Tareas</th>
														<th colspan="2">Estatus</th>
													</tr>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor="let task of listTask; let i=index">

                                                    <td>
                                                      <div class="row align-items-center">
                                                        <div class="col">
                                                          <p class="mb-0">{{task.description}}</p>
                                                        </div>
                                                      </div>
                                                    </td>

													<td>
                                                      <div class="row align-items-center">
                                                        <div class="col">
                                                          <p class="mb-0">{{task.status}}</p>
                                                        </div>
                                                      </div>
													</td>

													<td>
                                                      <div *ngIf="task.status != 'Culminado' " class="row align-items-center">
                                                        <div class="col-auto">
                                                          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                                                          (click)="editTaskModal(taskModal, task)" placement="top" ngbTooltip="Editar">
                                                              <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                                                              edit
                                                              </mat-icon>
                                                          </button>
                                                          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                                                          (click)="deleteTask(task.id)" placement="top" ngbTooltip="Eliminar">
                                                            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #dc3545;">
                                                              delete
                                                            </mat-icon>
                                                          </button>
                                                        </div>
                                                      </div>
													</td>

                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </ng-container>
                                          <ng-template #nodata>
                                            <div class="alert alert-warning" role="alert">
                                              <strong>No hay data disponible</strong>
                                            </div>
                                          </ng-template>
                                  
                                        </div>
                                    </div>

                                
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
             
                </div>    

            </div>
               
        </div>
    </div>
</section>

<ng-template #taskModal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{isEdit ? 'Editar' : 'Agregar'}} Tarea</h4>
    </div>
    <div class="modal-body">


      <div class="hidden position-absolute">
      <div class="input-group">
        <input name="datepicker" 
               class="form-control border-0"
               ngbDatepicker
               #datepicker="ngbDatepicker"
               [autoClose]="'outside'"
               (dateSelect)="onDateSelection($event)"
               [displayMonths]="2"
               [dayTemplate]="t"
               outsideDays="hidden"
               [startDate]="fromDate!"
               tabindex="-1">
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>


      <form class="form-valide" action="#" method="post" [formGroup]="formTaskGroup" (ngSubmit)="onSubmitTask()" novalidate>
          
        <div class="form-row">
          
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Fecha Inicio</strong></label>
              <div class="input-group">
                <input #dpFromDate disabled
                       class="form-control" placeholder="dd-mm-yyyy"
                       name="dpFromDate" 
                       [value]="formatter.format(fromDateTask)" 
                       (input)="fromDateTask = validateInput(fromDateTask, dpFromDate.value)"  >
                <div class="input-group-append">       
                  <button class="btn btn-outline-secondary calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                </div>  
              </div>
              <div *ngIf="submitted && !fromDateTask" class="text-danger fs-13 mt-1">
                  Campo requerido.
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Fecha Fin</strong></label>
              <div class="input-group">
                <input #dpToDate disabled
                       class="form-control" placeholder="yyyy-mm-dd"
                       name="dpToDate"   
                       [value]="formatter.format(toDateTask)"
                       (input)="toDateTask = validateInput(toDateTask, dpToDate.value)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                </div>
              </div>
              <div *ngIf="submitted && !toDateTask" class="text-danger fs-13 mt-1">
        
                  Campo requerido.
            
              </div>
            </div>
          </div>
        </div>
          <div class="form-row">
              <div class="col">
                  <div class="form-group">
                    <label class="mb-1"><strong>Descripción</strong></label>
                    <textarea maxlength="500" type="text" rows="5" class="form-control" formControlName="description"></textarea>
                    <div *ngIf="submitted && f.description?.errors" class="text-danger fs-13 mt-1">
                        <div *ngIf="f.description.errors.required">Campo requerido.</div>
                        <div *ngIf="f.description.errors.minlength">Campo debe tener al menos 5 caracteres.</div>
                    </div>
                  </div>
              </div>
          </div>
          
          <!--<div class="form-row align-items-center">
              <div class="col">
                  <div class="form-group">
                    <label class="mb-1"><strong>Fecha</strong></label>
                    <div class="input-group">
                      <input class="form-control" name="inicio" ngbDatepicker #di="ngbDatepicker"
                        formControlName="date" [(ngModel)]="dateTask">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar h-100" (click)="di.toggle()" type="button"></button>
                      </div>
                    </div>
                    <div *ngIf="submitted && f.date?.errors" class="text-danger fs-13 mt-1">
                      <div *ngIf="f.date.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
              </div>
          </div>-->
          <div class="form-row align-items-center">
              <div class="col" *ngIf="isEdit">
                  <div class="form-group">
                    <label class="mb-1"><strong>Estatus</strong></label>

                    <div class="btn-group btn-group-toggle d-block" [(ngModel)]="taskSelected.status" ngbRadioGroup name="radioBasic" formControlName="status">
                      <label ngbButtonLabel class="btn-primary" *ngFor="let s of statusTypes">
                        <input ngbButton type="radio" [value]="s.value"> {{s.value}}
                      </label>
                    </div>
                
                  </div>
              </div>
          </div>

          <div class="text-center pt-3">
              <button type="button" class="btn btn-outline-danger mr-2" (click)="modal.dismiss()">Cancelar</button>
              <button type="submit" class="btn btn-primary ml-2">{{isEdit ? 'Editar' : 'Agregar'}}</button>
          </div>

      </form>
    </div>
</ng-template>
