<div id="top" class="d-flex align-items-center">
  <div class="container-fluid custom-top">

    <container-element [ngSwitch]="pagesView">
      <some-element *ngSwitchCase="1">

        <div class="row justify-content-center mb-3">
          <div class="col-xl-12 col-sm-12 text-center">
            <h3 class="">Planes disponibles</h3>
            <p>Seleccione el plan de su preferencia</p>
          </div>
        </div>
        <div class="row justify-content-center">

          <div class="col-lg-3" *ngFor="let data of lisTypeServ">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">{{data.name}}</h5>
              </div>
              <div class="card-body">
                <p class="card-text text-justify">{{data.description}}</p>
              </div>
              <img [src]="data.image_plan" class="img-card" [alt]="data.title">
              <div class="card-footer">
                <p (click)="openPay(payM, data)" class="card-text mb-0 d-inline cursor-pointer"><strong>precio: $ {{data.total_service}} </strong></p>
                <a (click)="openPay(payM, data)" class="card-link float-right cursor-pointer"><i class="fa fa-angle-right fa-2x text-primary"></i></a>
              </div>
            </div>
          </div>
        </div>


      </some-element>

      <div class="row justify-content-center mb-3">
        <div class="col-xl-12 col-sm-12 text-center">
          <button type="button" class="btn btn-light" (click)="logout()">Volver al inicio</button>
        </div>
      </div>

    </container-element>


  </div>
</div>
<ng-template #payM>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Métodos de Pagos</h4>
    <button type="button" class="close" aria-label="Close" (click)="closerModalPay()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-payment-method [data]="info"></app-payment-method>
</ng-template>