<section class="content-body d-flex align-items-center">
    <div class="container-fluid">
        <div class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
                <h3>Planificación</h3>
            </div>
        </div>
        <div class="row  mb-3" *ngIf="condSelectLaw && condViewGantt">
            <div class="col-xl-12 col-sm-12 custom-change-case">
                <button type="submit" class=" btn btn-primary custom-margin-top" (click)="openModalSelect(content)">
                    Cambiar caso
                </button>
            </div>
        </div>
        <div *ngIf="condSelectLaw && condViewGantt" class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
                <div style="height:100%;">
                    <ng-gantt [options]="editorOptions" [data]="data" #editor></ng-gantt>
                </div>
            </div>
        </div>
        <div *ngIf="!condSelectLaw || !condViewGantt" class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
                <div style="height:300px;">
                    <h5 class=" col-12 modal-title" id="modal-basic-title">Debe seleccionar un caso para visualizar la
                        planificación</h5>
                    <button type="submit" class=" btn btn-primary custom-margin-top" (click)="openModalSelect(content)">
                        Seleccionar un caso
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>


<ng-template #content let-modal>
    <div class="modal-body">
        <div class="form-row col-12 custom-top">
            <div class="col-12 custom-alig-content">
                <h5 class=" col-12 modal-title" id="modal-basic-title"><strong>Seleccione un caso</strong></h5>
                <mat-nav-list class="custom-aling-list border-pay">
                    <mat-list-item *ngFor="let item of pocedures" (click)="selectCase(item)">
                        <a matLine>
                            <mat-icon class="custom-left-icon-list"><i class="flaticon-381-newspaper"></i></mat-icon>
                            <span class="link-caption">{{ item?.case_name }} </span>
                        </a>
                    </mat-list-item>
                    <div class="col-12 custom-div-pagination justify-content-center">
                        <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
                            [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
                            aria-label="Default pagination"></ngb-pagination>
                    </div>
                </mat-nav-list>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="modal.close('Close click')">Cancelar</button>
    </div>
</ng-template>