<div class="deznav nav-shadow">
  <div class="deznav-scroll" [perfectScrollbar]>
    <ul class="metismenu" id="menu" metis-menu>

      <!-- Admin routes -->
      <ng-container *ngIf="role === 'admin'">
        <li class="{{currentHref == '/admin/index' || currentHref == '/admin'? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/admin/index']" class="ai-icon {{currentHref == '/admin/index' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-home"></i>
            <span class="nav-text">Inicio</span>
          </a>
        </li>
        <li class="{{ currentHref == '/admin/approvals' ? 'mm-active' : '' }}" (click)="collapseList()">
          <a [routerLink]="['/admin/approvals']"
            class="ai-icon {{currentHref == '/admin/approvals' ? 'mm-active' : ''}}" aria-expanded="false">
            <i class="flaticon-381-success"></i>
            <span class="nav-text">Aprobaciones</span>
          </a>
        </li>
        <li class="{{ currentHref == '/admin/management-user' ? 'mm-active' : '' }}" (click)="collapseList()">
          <a [routerLink]="['/admin/management-user']"
            class="ai-icon {{currentHref == '/admin/management-user' ? 'mm-active' : ''}}" aria-expanded="false">
            <i class="flaticon-381-user-9"></i>
            <span class="nav-text">Gestion de Usuario</span>
          </a>
        </li>
        <li class="{{ currentHref == '/admin/promotion' ? 'mm-active' : '' }}" (click)="collapseList()">
          <a [routerLink]="['/admin/promotion']"
            class="ai-icon {{currentHref == '/admin/promotion' ? 'mm-active' : ''}}" aria-expanded="false">
            <i class="flaticon-381-notebook-4"></i>
            <span class="nav-text">Gestion de planes</span>
          </a>
        </li>
        <li class="{{ currentHref == '/admin/service-monitoring' ? 'mm-active' : '' }}" (click)="collapseList()">
          <a [routerLink]="['/admin/service-monitoring']"
            class="ai-icon {{currentHref == '/admin/service-monitoring' ? 'mm-active' : ''}}" aria-expanded="false">
            <i class="flaticon-381-search-3"></i>
            <span class="nav-text">Monitoreo de Servicios</span>
          </a>
        </li>
        <li [ngClass]="{'mm-active': configArray.indexOf(currentHref) > -1}">
          <a class="has-arrow ai-icon {{(currentHref.indexOf('/admin/config/requirements') > -1 || currentHref.indexOf('/admin/config/branches-law') > -1 || currentHref.indexOf('/admin/config/services') > -1) ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-settings-2"></i>
            <span class="nav-text">Configuración</span>
          </a>
          <ul aria-expanded="false" [ngClass]="{'mm-show': configArray.indexOf(currentHref) > -1}">
            <li>
              <a [routerLink]="['/admin/config/requirements']" [class]="(currentHref.indexOf('/admin/config/requirements') > -1) ? 'a-active' : ''">Requisitos</a>
              <a [routerLink]="['/admin/config/branches-law']" [class]="(currentHref.indexOf('/admin/config/branches-law') > -1) ? 'a-active' : ''">Ramas del Derecho</a>
              <a [routerLink]="['/admin/config/services']" [class]="(currentHref.indexOf('/admin/config/services') > -1) ? 'a-active' : ''">Configurar Servicios</a>
            </li>
          </ul>
        </li>
        <li [ngClass]="{'mm-active': configArray.indexOf(currentHref) > -1}">
          <a class="has-arrow ai-icon {{(currentHref.indexOf('/admin/account-status') > -1 || currentHref.indexOf('/admin/accounts-payable') > -1 || currentHref.indexOf('/admin/invoices') > -1 || currentHref.indexOf('/admin/payments-appove') > -1) ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-calculator"></i>
            <span class="nav-text">Finanzas</span>
          </a>
          <ul aria-expanded="false" [ngClass]="{'mm-show': configArray.indexOf(currentHref) > -1}">
            <li>
              <a [routerLink]="['/admin/account-status']" [class]="(currentHref.indexOf('/admin/account-status') > -1) ? 'a-active' : ''">Estado de cuentas</a>
            </li>
            <li>
              <a [routerLink]="['/admin/accounts-payable']" [class]="(currentHref.indexOf('/admin/accounts-payable') > -1) ? 'a-active' : ''">Cuentas por Pagar</a>
            </li>
            <li>
              <a [routerLink]="['/admin/invoices']" [class]="(currentHref.indexOf('/admin/invoices') > -1) ? 'a-active' : ''">Facturas</a>
            </li>
            <li>
              <a [routerLink]="['/admin/payments-appove']" [class]="(currentHref.indexOf('/admin/payments-appove') > -1) ? 'a-active' : ''">Pagos por aprobar</a>
          </ul>
        </li>
        <li [ngClass]="{'mm-active': configArray.indexOf(currentHref) > -1}">
          <a class="has-arrow ai-icon {{(currentHref.indexOf('/admin/lawyers-report') > -1 || currentHref.indexOf('/admin/customers-report') > -1) ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-file-1"></i>
            <span class="nav-text">Reportes</span>
          </a>
          <ul aria-expanded="false" [ngClass]="{'mm-show': configArray.indexOf(currentHref) > -1}">
            <li>
              <a [routerLink]="['/admin/lawyers-report']" [class]="(currentHref.indexOf('/admin/lawyers-report') > -1) ? 'a-active' : ''">De Abogados</a>
            </li>
            <li>
              <a [routerLink]="['/admin/customers-report']" [class]="(currentHref.indexOf('/admin/customers-report') > -1) ? 'a-active' : ''">De Clientes</a>
            </li>
          </ul>
        </li>
      </ng-container>

      <!-- Company routes -->
      <ng-container *ngIf="role === 'company'">
        <li class="{{currentHref == '/company/index' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/company/index']" class="ai-icon {{currentHref == '/company/index' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-home"></i>
            <span class="nav-text">Inicio</span>
          </a>
        </li>
        <li class="{{currentHref == '/company/advisory' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/company/advisory']"
            class="ai-icon {{ currentHref == '/company/advisory' ? 'mm-active' : '' }}" aria-expanded="false">
            <i class="flaticon-381-user-7"></i>
            <span class="nav-text">Directorio de abogados</span>
          </a>
        </li>
        <!--<li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-newspaper"></i>
            <span class="nav-text">Solicitud de servicios</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/company/process-co']">Trámites</a>
            </li>
            <li>
              <a [routerLink]="['/company/proc-services-co']">Procesos</a>
            </li>
            <li>
              <a [routerLink]="['/company/advisory-co']">Asesorías</a>
            </li>
          </ul>
        </li>-->
        <li>
          <a class="has-arrow ai-icon {{(currentHref.indexOf('/company/service-request') > -1 || currentHref == '/company/service-request-advisory') ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-newspaper"></i>
            <span class="nav-text">Solicitud de servicios</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/company/service-request', 1]" [class]="(currentHref.indexOf('/company/service-request/1') > -1) ? 'a-active' : ''">Trámites</a>
            </li>
            <li>
              <a [routerLink]="['/company/service-request', 2]" [class]="(currentHref.indexOf('/company/service-request/2') > -1) ? 'a-active' : ''">Procesos</a>
            </li>
            <!-- <li>
              <a [routerLink]="['/company/service-request', 3]" [class]="(currentHref.indexOf('/company/service-request/3') > -1) ? 'a-active' : ''">Asesorías</a>
            </li> -->
          </ul>
        </li>
        <li class="{{currentHref == '/company/foll-services-co' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/company/foll-services-co']"
            class="ai-icon {{currentHref == '/company/foll-services-co' ? 'mm-active' : ''}}" aria-expanded="false">
            <i class="flaticon-381-list-1"></i>
            <span class="nav-text">Seguimiento de servicios</span>
            </a>
        </li>
        <li>
          <a class="has-arrow ai-icon {{(currentHref == '/company/invoices' || currentHref == '/company/next-payments') ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-calculator"></i>
            <span class="nav-text">Finanzas</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/company/invoices']" [class]="(currentHref.indexOf('/company/invoices') > -1) ? 'a-active' : ''">Facturas</a>
            </li>
            <li>
              <a [routerLink]="['/company/next-payments']" [class]="(currentHref.indexOf('/company/next-payments') > -1) ? 'a-active' : ''">Próximos pagos</a>
            </li>
          </ul>
        </li>
        <li class="{{currentHref == '/company/schedule' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/company/schedule']" class="ai-icon {{currentHref == '/company/schedule' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-calendar-7"></i>
            <span class="nav-text">Agenda</span>
            </a>
        </li>
        <li class="{{currentHref == '/company/approvals' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/company/approvals']" class="ai-icon {{ currentHref == '/company/approvals' ? 'mm-active' : '' }}"
            aria-expanded="false">
            <i class="flaticon-381-bookmark-1"></i>
            <span class="nav-text">Aprobaciones</span>
          </a>
        </li>
        <li class="{{currentHref == '/company/documents' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/company/documents']"
            class="ai-icon {{ currentHref == '/company/documents' ? 'mm-active' : '' }}" aria-expanded="false">
            <i class="flaticon-381-folder"></i>
            <span class="nav-text">Mis Documentos</span>
          </a>
        </li>
        <li>
          <a class="has-arrow ai-icon {{currentHref == '/company/update-profile' ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-id-card-1"></i>
            <span class="nav-text">Perfil</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/company/update-profile']" [class]="(currentHref.indexOf('/company/update-profile') > -1) ? 'a-active' : ''">Actualizar Perfil</a>
            </li>
          </ul>
        </li>
        <!-- <li class="{{currentHref == '/company/schedule-co' ? 'mm-active': ''}}" >
          <a [routerLink]="['/company/schedule-co']" class="ai-icon {{currentHref == '/company/schedule-co' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-calendar-7"></i>
            <span class="nav-text">Agenda</span>
          </a>
        </li>-->
      </ng-container>

      <!-- Lawyer routes -->
      <ng-container *ngIf="role === 'lawyer'">
        <li class="{{currentHref == '/lawyer/index' || currentHref == '/lawyer'? 'mm-active' : ''}}" (click)="collapseList()">
          <a [routerLink]="['/lawyer/index']" class="ai-icon {{
              currentHref == '/lawyer/index' ? 'mm-active' : ''}}" aria-expanded="false">
            <i class="flaticon-381-home"></i>
            <span class="nav-text">Inicio</span>
          </a>
        </li>
        <li
          class="{{currentHref == '/lawyer/cases' ? 'mm-active': ''}}"
          *ngIf="lawyerType === 'Plataforma' && isDisable('cases')"
          (click)="collapseList()"
        >
          <a [routerLink]="['/lawyer/cases']" class="ai-icon {{currentHref == '/lawyer/cases' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-notepad"></i>
            <span class="nav-text">Casos</span>
          </a>
        </li>
        <li
          class="{{currentHref == '/lawyer/my-clients' ? 'mm-active': ''}}"
          *ngIf="lawyerType === 'Plataforma' && isDisable('my-clients')"
          (click)="collapseList()"
        >
          <a [routerLink]="['/lawyer/my-clients']" class="ai-icon {{currentHref == '/lawyer/my-clients' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-user-9"></i>
            <span class="nav-text">Clientes</span>
          </a>
        </li>
        <li
          class="{{currentHref == '/lawyer/documents' ? 'mm-active': ''}}"
          *ngIf="lawyerType === 'Marketplace' && isDisable('documents')"
          (click)="collapseList()"
        >
          <a [routerLink]="['/lawyer/documents']" class="ai-icon {{currentHref == '/lawyer/documents' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-folder"></i>
            <span class="nav-text">Mis Documentos</span>
          </a>
        </li>
        <li
          class="{{currentHref == '/lawyer/my-documents' ? 'mm-active': ''}}"
          *ngIf="lawyerType === 'Plataforma' && isDisable('documents')"
          (click)="collapseList()"
        >
          <a [routerLink]="['/lawyer/my-documents']" class="ai-icon {{currentHref == '/lawyer/my-documents' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-folder"></i>
            <span class="nav-text">Mis Documentos</span>
          </a>
        </li>
        <li
          class="{{currentHref == '/lawyer/schedule' ? 'mm-active': ''}}"
          *ngIf="isDisable('agendas')"
          (click)="collapseList()"
        >
          <a [routerLink]="['/lawyer/schedule']" class="ai-icon {{currentHref == '/lawyer/schedule' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-calendar-7"></i>
            <span class="nav-text">Agenda</span>
          </a>
        </li>
        <!-- <li
          class="{{currentHref == '/lawyer/finances' ? 'mm-active': ''}}"
          *ngIf="isDisable('finance')"
          (click)="collapseList()"
        >
          <a [routerLink]="['/lawyer/finances']" class="ai-icon {{currentHref == '/lawyer/finances' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-calculator"></i>
            <span class="nav-text">Finanzas</span>
          </a> -->
        <li>
          <a class="has-arrow ai-icon {{ ['/lawyer/finances', '/lawyer/income', '/lawyer/pending-accounts', '/lawyer/expenses'].includes(currentHref) ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-calculator"></i>
            <span class="nav-text">Finanzas</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/lawyer/finances']" [class]="(currentHref.indexOf('/lawyer/finances') > -1) ? 'a-active' : ''">Gestión de finanzas</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/income']" [class]="(currentHref.indexOf('/lawyer/income') > -1) ? 'a-active' : ''">Ingresos</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/pending-accounts']" [class]="(currentHref.indexOf('/lawyer/pending-accounts') > -1) ? 'a-active' : ''">Cuentas por Cobrar</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/expenses']" [class]="(currentHref.indexOf('/lawyer/expenses') > -1) ? 'a-active' : ''">Egresos</a>
            </li>
          </ul>
        </li>
        <!--
         <li [ngClass]="{ 'mm-active': configArray.indexOf(currentHref) > -1 }" *ngIf="lawyerType==='Plataforma'">
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-calculator"></i>
            <span class="nav-text">Finanzas</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/lawyer/index']">Registrar Pagos</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/statement']">Ver Estado de Cuenta</a>
            </li>
          </ul>
        </li> -->
        <li
          [ngClass]="{ 'mm-active': configArray.indexOf(currentHref) > -1 }"
          *ngIf="lawyerType==='Marketplace' && isDisable('requests')"
        >
          <a class="has-arrow ai-icon {{(currentHref == '/lawyer/requests/consultancies' || currentHref == '/lawyer/requests/quotes') ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-layer-1"></i>
            <span class="nav-text">Solicitudes</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/lawyer/requests/consultancies']" [class]="(currentHref.indexOf('/lawyer/requests/consultancies') > -1) ? 'a-active' : ''">De asesorías</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/requests/quotes']" [class]="(currentHref.indexOf('/lawyer/requests/quotes') > -1) ? 'a-active' : ''">De cotización</a>
            </li>
          </ul>
        </li>
        <li
          [ngClass]="{ 'mm-active': configArray.indexOf(currentHref) > -1 }"
          *ngIf="lawyerType==='Marketplace' && isDisable('services')"
        >
          <a class="has-arrow ai-icon {{(currentHref == '/lawyer/services/configuration' || currentHref == '/lawyer/services/active' || currentHref == '/lawyer/services/completed') ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-layer-1"></i>
            <span class="nav-text">Servicios</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/lawyer/services/configuration']" [class]="(currentHref.indexOf('/lawyer/services/configuration') > -1) ? 'a-active' : ''">Configurar</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/services/active']" [class]="(currentHref.indexOf('/lawyer/services/active') > -1) ? 'a-active' : ''">Activos</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/services/completed']" [class]="(currentHref.indexOf('/lawyer/services/completed') > -1) ? 'a-active' : ''">Culminados</a>
            </li>
          </ul>
        </li>
        <li
          [ngClass]="{ 'mm-active': configArray.indexOf(currentHref) > -1 }"
          *ngIf="lawyerType==='Plataforma' && isDisable('management_control')"
        >
          <a class="has-arrow ai-icon {{(currentHref == '/lawyer/new-quote-generator' || currentHref == '/lawyer/planning' || currentHref == '/lawyer/management/task-time' || currentHref == '/lawyer/management/execution-time') ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-settings-4"></i>
            <span class="nav-text">Control de Gestión</span>
          </a>
          <ul aria-expanded="false" [ngClass]="{ 'mm-show': configArray.indexOf(currentHref) > -1 }">
            <li>
              <a [routerLink]="['/lawyer/new-quote-generator']" [class]="(currentHref.indexOf('/lawyer/new-quote-generator') > -1) ? 'a-active' : ''">Cotizaciones</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/planning']" [class]="(currentHref.indexOf('/lawyer/planning') > -1) ? 'a-active' : ''">Planificación</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/management/task-time']" [class]="(currentHref.indexOf('/lawyer/management/task-time') > -1) ? 'a-active' : ''">Tiempos de tareas</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/management/execution-time']" [class]="(currentHref.indexOf('/lawyer/management/execution-time') > -1) ? 'a-active' : ''">Control de Ejecución</a>
            </li>
          </ul>
        </li>
        <li
          [ngClass]="{ 'mm-active': configArray.indexOf(currentHref) > -1 }"
          *ngIf="lawyerType==='Plataforma' && isDisable('reports')"
        >
          <a class="has-arrow ai-icon {{(currentHref == '/lawyer/reports' || currentHref == '/lawyer/op-reports') ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-layer-1"></i>
            <span class="nav-text">Reportes</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/lawyer/reports']" [class]="(currentHref.indexOf('/lawyer/reports') > -1) ? 'a-active' : ''">Financiero</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/op-reports']" [class]="(currentHref.indexOf('/lawyer/op-reports') > -1) ? 'a-active' : ''">Operativos</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/pending-accounts']" [class]="(currentHref.indexOf('/lawyer/pending-accounts') > -1) ? 'a-active' : ''">De Cuentas por Cobrar</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/expenses']" [class]="(currentHref.indexOf('/lawyer/expenses') > -1) ? 'a-active' : ''">Relación de Casos</a>
            </li>
          </ul>
        </li>
        <li
          [ngClass]="{ 'mm-active': configArray.indexOf(currentHref) > -1 }"
          *ngIf="lawyerType==='Plataforma' && isDisable('config')"
        >
          <a class="has-arrow ai-icon {{(currentHref == '/lawyer/config/clients' || currentHref == '/lawyer/config/entities') ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-settings-2"></i>
            <span class="nav-text">Configuración</span>
          </a>
          <ul aria-expanded="false" [ngClass]="{ 'mm-show': configArray.indexOf(currentHref) > -1 }">
            <li>
              <a [routerLink]="['/lawyer/config/clients']" [class]="(currentHref.indexOf('/lawyer/config/clients') > -1) ? 'a-active' : ''">Clientes</a>
            </li>
            <li>
              <a [routerLink]="['/lawyer/config/entities']" [class]="(currentHref.indexOf('/lawyer/config/entities') > -1) ? 'a-active' : ''">Entidades</a>
            </li>
          </ul>
        </li>
        <li
          class="{{currentHref == '/lawyer/chat' ? 'mm-active': ''}}"
          *ngIf="isDisable('chat')"
          (click)="collapseList()"
        >
          <a [routerLink]="['/lawyer/chat']"
            class="ai-icon {{ currentHref == '/lawyer/chat' ? 'mm-active' : '' }}"
            aria-expanded="false">
            <i class="flaticon-381-notification"></i>
            <span class="nav-text">Mensajería</span>
          </a>
        </li>
        <li *ngIf="isDisable('profile')">
          <a class="has-arrow ai-icon {{currentHref == '/lawyer/update-profile' ? 'mm-active': '' }}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-id-card-1"></i>
            <span class="nav-text">Perfil</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/lawyer/update-profile']" [class]="(currentHref.indexOf('/lawyer/update-profile') > -1) ? 'a-active' : ''">Actualizar Perfil</a>
            </li>
            <!--<li>
              <a [routerLink]="['/lawyer/reviews']"
                class="{{currentHref == '/lawyer/reviews' ? 'mm-active' : ''}}">Mi Reputación y Calificaciones</a>
            </li> -->
          </ul>
        </li>
      </ng-container>

      <!-- Person routes -->
      <ng-container *ngIf="role === 'person'">
        <li class="{{currentHref == '/person/index' || currentHref == '/person'? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/person/index']" class="ai-icon {{currentHref == '/person/index' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-home"></i>
            <span class="nav-text">Inicio</span>
          </a>
        </li>
        <li class="{{currentHref == '/person/advisory-lawyer-person' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/person/advisory-lawyer-person']"
            class="ai-icon {{ currentHref == '/person/advisory-lawyer-person' ? 'mm-active' : '' }}"
            aria-expanded="false">
            <i class="flaticon-381-user-7"></i>
            <span class="nav-text">Directorio de abogados</span>
          </a>
        </li>
        <!--<li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-newspaper"></i>
            <span class="nav-text">Solicitud de servicios</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/person/process']">Trámites</a>
            </li>
            <li>
              <a [routerLink]="['/person/proc-services']">Procesos</a>
            </li>
            <li>
              <a [routerLink]="['/person/advisory']">Asesorías</a>
            </li>
          </ul>
        </li> -->
        <li>
          <a class="has-arrow ai-icon {{(currentHref.indexOf('/person/service-request-advisory')>-1 || currentHref.indexOf('/person/service-request') > -1) ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-newspaper"></i>
            <span class="nav-text">Solicitud de servicios</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/person/service-request', 1]" [class]="(currentHref.indexOf('/person/service-request/1') > -1) ? 'a-active' : ''">Trámites</a>
            </li>
            <li>
              <a [routerLink]="['/person/service-request', 2]" [class]="(currentHref.indexOf('/person/service-request/2') > -1) ? 'a-active' : ''">Procesos</a>
            </li>
            <!-- <li>
              <a [routerLink]="['/person/service-request', 3]" [class]="(currentHref.indexOf('/person/service-request/3') > -1) ? 'a-active' : ''">Asesorías</a>
            </li> -->
          </ul>
        </li>
        <li class="{{currentHref == '/person/foll-services' || currentHref == '/person'? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/person/foll-services']"
            class="ai-icon {{currentHref == '/person/foll-services' ? 'mm-active' : ''}}" aria-expanded="false">
            <i class="flaticon-381-list-1"></i>
            <span class="nav-text">Seguimiento de servicios</span>
          </a>
        </li>
        <li>
          <a class="has-arrow ai-icon {{(currentHref.indexOf('/person/invoices')>-1 || currentHref.indexOf('/person/next-payments') > -1) ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-calculator"></i>
            <span class="nav-text">Finanzas</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/person/invoices']" [class]="(currentHref.indexOf('/person/invoices') > -1) ? 'a-active' : ''">Facturas</a>
            </li>
            <li>
              <a [routerLink]="['/person/next-payments']" [class]="(currentHref.indexOf('/person/next-payments') > -1) ? 'a-active' : ''">Próximos pagos</a>
            </li>
          </ul>
        </li>
        <li class="{{currentHref == '/person/schedule' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/person/schedule']" class="ai-icon {{currentHref == '/person/schedule' ? 'mm-active' : ''}}"
            aria-expanded="false">
            <i class="flaticon-381-calendar-7"></i>
            <span class="nav-text">Agenda</span>
          </a>
        </li>
        <li class="{{currentHref == '/person/approvals' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/person/approvals']" class="ai-icon {{ currentHref == '/person/approvals' ? 'mm-active' : '' }}"
            aria-expanded="false">
            <i class="flaticon-381-bookmark-1"></i>
            <span class="nav-text">Aprobaciones</span>
          </a>
        </li>
        <li class="{{currentHref == '/person/chat' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/person/chat']"
            class="ai-icon {{ currentHref == '/person/chat' ? 'mm-active' : '' }}"
            aria-expanded="false">
            <i class="flaticon-381-notification"></i>
            <span class="nav-text">Mensajería</span>
          </a>
        </li>
        <li class="{{currentHref == '/person/documents' ? 'mm-active': ''}}" (click)="collapseList()">
          <a [routerLink]="['/person/documents']"
            class="ai-icon {{ currentHref == '/person/documents' ? 'mm-active' : '' }}" aria-expanded="false">
            <i class="flaticon-381-folder"></i>
            <span class="nav-text">Mis Documentos</span>
          </a>
        </li>
        <li>
          <a class="has-arrow ai-icon {{(currentHref.indexOf('/person/update-profile')>-1) ? 'mm-active': ''}}" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-id-card-1"></i>
            <span class="nav-text">Perfil</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a [routerLink]="['/person/update-profile']" [class]="(currentHref.indexOf('/person/update-profile') > -1) ? 'a-active' : ''">Actualizar Perfil</a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<div class="sidebar-backdrop"></div>
