<mat-horizontal-stepper #stepper [linear]="true">
  <!-- Paso 1 -->
  <mat-step [stepControl]="firstFormGroup" errorMessage="Campos requeridos.">
    <ng-template matStepLabel>Datos básicos</ng-template>
    <form class="form-valide" action="#" method="post" [formGroup]="firstFormGroup">
      <input type="hidden" class="form-control" formControlName="main_image" value="{{avatar}}" >
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Nombre(s)</strong></label>
            <input type="text" class="form-control" formControlName="name" required/>
            <div *ngIf="submitted && f.name?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.name.errors.required">Campo requerido.</div>
              <div *ngIf="f.name.errors.minlength">
                Nombre debe tener al menos 3 caracteres.
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Apellido(s)</strong></label>
            <input type="text" class="form-control" formControlName="last_name" required/>
            <div *ngIf="submitted && f.last_name?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.last_name.errors.required">Campo requerido.</div>
              <div *ngIf="f.last_name.errors.minlength">
                Apellido debe tener al menos 3 caracteres.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Número de contacto</strong></label>
            <input type="number" class="form-control" formControlName="phone" required/>
            <div *ngIf="submitted && f.phone?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.phone.errors.required">Campo requerido.</div>
              <div *ngIf="f.phone.errors.pattern">Campo debe tener minimo 7 números.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Cédula de Identidad</strong></label>
            <input type="text" class="form-control" formControlName="dni" required/>
            <div *ngIf="submitted && f.dni?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.dni.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Número de idoneidad</strong></label>
            <input type="number" class="form-control" formControlName="suitability_number" required/>
            <div *ngIf="submitted && f.suitability_number?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.suitability_number.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Email</strong></label>
            <input type="email" class="form-control" formControlName="email" required/>
            <div *ngIf="submitted && f.email?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.email.errors.required">Campo requerido.</div>
              <div *ngIf="f.email.errors.email">Email inválido.</div>
              <div *ngIf="f.email.errors.pattern">Email inválido.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Breve perfil profesional</strong></label>
          <!-- <input type="text" class="form-control" formControlName="description" required/> -->
          <textarea placeholder="Describe tus habilidades y destrezas" rows="5" type="text" class="form-control" formControlName="description" class="md-textarea form-control" mdbInput></textarea>
          <div *ngIf="submitted && f.description?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.description.errors.required">Campo requerido.</div>
          </div>
        </div>
      </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Área de Práctica</strong></label>
            <ng-multiselect-dropdown
              formControlName="branch_laws"
              [placeholder]="'Seleccione'"
              [settings]="dropdownSettings"
              [data]="dropdownList"
              [(ngModel)]="selectedItems">
            </ng-multiselect-dropdown>

            <div *ngIf="submitted && f.branch_laws?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.branch_laws.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
      </div>
      <div formArrayName="study_levels">
        <div class="form-row align-items-end" *ngFor="let item of study_levels.controls; let i = index" [formGroupName]="i">

          <div class="col-12">
            <div class="row">
              <div class="col-md-4">
            <div class="form-group">
              <label class="mb-1" for="item-{{ i }}"><strong>Nivel de Estudio</strong></label>
              <select class="form-control" id="item-{{ i }}" formControlName="study_level_id" required>
                <option selected disabled value="0">
                  Seleccione una opción
                </option>
                <option [value]="level.id" *ngFor="let level of studylevel; let i = index">
                  {{ level.name }}
                </option>
              </select>
            </div>
            <div *ngIf="submitted && f.study_level_id?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="f.study_level_id.errors.required">
                Campo requerido.
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="mb-1" for="item-t{{ i }}"><strong>Título Obtenido</strong></label>
              <input type="text" class="form-control" formControlName="obtained_title" id="item-t{{ i }}" required/>
              <div *ngIf="submitted && f.obtained_title?.errors" class="text-danger fs-13 mt-1">
                <div *ngIf="f.obtained_title.errors.required">
                  Campo requerido.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="mb-1" for="item-c{{ i }}"><strong>Institución</strong></label>
              <input type="text" class="form-control" formControlName="college" id="item-c{{ i }}" required/>
              <div *ngIf="submitted && f.collage?.errors" class="text-danger fs-13 mt-1">
                <div *ngIf="f.college.errors.required">
                  Campo requerido.
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
          <div class="col-1" *ngIf="i>0">
            <div class="form-group">
              <button mat-icon-button color="accent" (click)="deleteGrade(i)" placement="top" ngbTooltip="Eliminar">
                <mat-icon aria-hidden="false"  style="color: #dc3545;">
                  delete
                </mat-icon>
              </button>
            </div>
          </div>

        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="text-center mt-3">
            <button type="button" class="btn btn-rounded btn-info btn-add-study" (click)="addGrades()">
              <span class="btn-icon-left text-info">
                <i class="fa fa-plus color-info"></i>
              </span>
              Agregar nivel de estudio
            </button>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="text-center mt-4">
        <button class="btn btn-primary ml-2" (click)="nextStep(1)" mat-button matStepperNext>Siguiente</button>
      </div>
      <!--  -->
    </form>
  </mat-step>
  <!-- Paso 2 -->
  <mat-step [stepControl]="secondFormGroup" errorMessage="Campos requeridos.">
    <form class="form-valide" action="#" method="post" [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Dirección</ng-template>
      <div class="form-row align-items-end">
        <div class="col">
          <div class="form-group">
            <label for="">Provincia</label>
            <select class="form-control" name="" id="" (change)="onChange($event.target.value, 'province')">
              <option disabled selected>Seleccione una opción</option>
              <option [value]="p.id" *ngFor="let p of provinces">
                {{ p.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="">Distrito</label>
            <select class="form-control" name="" id="" (change)="onChange($event.target.value, 'city')">
              <option disabled selected>Seleccione una opción</option>
              <option [value]="city.id" *ngFor="let city of cities">
                {{ city.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="">Corregimiento</label>

            <select class="form-control" name="township_id" id="township_id"
              (change)="onChange($event.target.value, 'township')" formControlName="township_id">
              <option disabled selected [value]="0">
                Seleccione una opción
              </option>
              <option [value]="township.id" *ngFor="let township of townships">
                {{ township.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <div class="form-group">
            <button class="btn btn-primary btn-refresh"  (click)="refreshAddress()" placement="top" ngbTooltip="refrescar">
              <mat-icon aria-hidden="false">
                refresh
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Dirección</strong></label>
            <input type="text" class="form-control" formControlName="address" />
            <div *ngIf="submitted && s.address?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="s.address.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="row">
        <div class="col">
          <h3 class="text-center my-3">Datos Bancarios</h3>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Nombre del Titular</strong></label>
            <input type="text" class="form-control" formControlName="owner_name" />
            <div *ngIf="submitted && s.owner_name?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="s.owner_name.errors.required">Campo requerido.</div>
              <div *ngIf="s.owner_name.errors.minlength">
                Nombre debe tener al menos 3 caracteres.
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Cédula del Titular</strong></label>
            <input type="text" class="form-control" formControlName="owner_dni" />
            <div *ngIf="submitted && s.owner_dni?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="s.owner_dni.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Tipo de Cuenta</strong></label>
            <select class="form-control" name="" id="" formControlName="account_type">
              <option value="Corriente">Corriente</option>
              <option value="Ahorro">Ahorro</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Entidad Bancaria</strong></label>
            <select class="form-control" name="" id="" formControlName="bank_id" [(ngModel)]="bankId">
              <option [value]="bank.id" *ngFor="let bank of banks">
                {{ bank.name }}
              </option>
            </select>
            <div *ngIf="submitted && s.bank_id?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="s.bank_id.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Número de cuenta</strong></label>
            <input type="text" class="form-control" formControlName="account_number" />
            <div *ngIf="submitted && s.account_number?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="s.account_number.errors.required">
                Campo requerido.
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
      <div class="text-center mt-4">
        <button class="btn btn-primary " mat-button matStepperPrevious>Anterior</button>
        <button class="btn btn-primary ml-2" mat-button matStepperNext (click)="nextStep(2)">Siguiente</button>
      </div>
      <!--  -->
    </form>
  </mat-step>
  <!-- Paso 3 -->
  <!--
  <mat-step [stepControl]="thirdFormGroup" errorMessage="Campos requeridos.">
    <ng-template matStepLabel>Productos</ng-template>
    <form class="form-valide" action="#" method="post" [formGroup]="thirdFormGroup">
      <div class="form-row">
        <ng-container *ngFor="let p of products; let i = index">
          <div class="col-12 col-sm-12">
            <div class="custom-control custom-checkbox mb-2 checkbox-info">
              <input type="checkbox" class="custom-control-input" [value]="p.id" [id]="p.id"
                formControlName="lawyer_products" (change)="onProductCheck($event)" />

              <label class="custom-control-label" [for]="p.id">
                {{p.name}} <i class="fa fa-exclamation-circle cursor-pointer" (mouseenter)="p.collapse=!p.collapse"></i>
              </label>
            </div>
            <div *ngIf="submitted && t.lawyer_products?.errors" class="text-danger fs-13 mb-2">
              <div *ngIf="t.lawyer_products.errors.required">
                Campo requerido.
              </div>
            </div>


            <div  *ngIf="p?.collapse">
              <div class="basic-list-group calendar-list ml-4 mb-4">
                <div class="list-group-item list-group-item-action p-3 text-justify">{{p?.description}}</div>
              </div>
            </div>



          </div>


        </ng-container>



      </div>
      <div class="text-center mt-4">
        <button class="btn btn-primary " mat-button matStepperPrevious>Anterior</button>
        <button class="btn btn-primary ml-2" mat-button matStepperNext (click)="nextStep(3)">Siguiente</button>
      </div>
    </form>
  </mat-step>
  -->
  <!-- Paso 4 -->
  <mat-step [stepControl]="fourthFormGroup" errorMessage="Campos requeridos.">
    <ng-template matStepLabel>Contraseña y docs</ng-template>
    <form class="form-valide" [formGroup]="fourthFormGroup">
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Contraseña</strong></label>
            <!-- <input type="password" class="form-control" formControlName="password"> -->
            <div class="group-btn-left">
              <input class="form-control" [type]="typePass" formControlName="password">
              <button class="btn btn-link btn-visible" (click)="onChangePass()" type="button">
                <mat-icon aria-hidden="false">
                  {{iconPass}}
                  </mat-icon>
              </button>
            </div>
            <div *ngIf="submitted && fo.password?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="fo.password.errors.required">Campo requerido.</div>
              <div *ngIf="fo.password.errors.minlength">
                Contraseña debe ser de mínimo 8 caracteres.
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Confirmar Contraseña</strong></label>
            <!-- <input type="password" class="form-control" formControlName="password_confirmation"> -->
            <div class="group-btn-left">
              <input class="form-control" [type]="typePass" formControlName="password_confirmation">
              <button class="btn btn-link btn-visible" (click)="onChangePass()" type="button">
                <mat-icon aria-hidden="false">
                  {{iconPass}}
                  </mat-icon>
              </button>
            </div>
            <div *ngIf="submitted && fo.password_confirmation?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="fo.password_confirmation.errors.required">
                Campo requerido.
              </div>
              <div *ngIf="fo.password_confirmation.errors.mustMatch">
                Contraseñas no coinciden.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <pdf-viewer [src]="'./assets/contrato_de_confidencialidad.pdf'" [original-size]="false"></pdf-viewer>
        </div>
      </div>
      <!--  -->
      <div class="text-center mt-4">
        <button class="btn btn-primary " mat-button matStepperPrevious (click)="ShowRegister('back')">Anterior</button>
        <!-- <button class="btn btn-primary ml-2" mat-button matStepperNext>Siguiente</button> -->
      </div>
      <!--  -->
    </form>

  </mat-step>
</mat-horizontal-stepper>
<div class="text-center mt-3" *ngIf="buttonshowregister">
  <button type="buttton" class="btn btn-primary btn-block" (click)="onSubmit()">
    Registrarse
  </button>
</div>
