<div class="content-body d-flex">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>Configurar Servicios</h3>
        <div>
          Crear servicio
          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
            (click)="add(serviceModal)" placement="top" ngbTooltip="Nuevo servicio">
            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color:#212F54">
              add_circle_outline
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="custom-div-filter m-4">
        <div class="form-row">

          <div class="col-4">
            <div class="form-group">
              <label class="mb-1"><strong>Nombre de Servicios</strong></label>
              <select class="form-control" id="service" name="service_id" [(ngModel)]="search.service_id"
                (change)="onChange($event.target.value )">
                <option value=""> -- Seleccione --</option>
                <option [value]="service.id" *ngFor="let service of services">
                  {{ service.name }}
                </option>
              </select>
            </div>
          </div>


          <div class="col-4">
            <div class="form-group">
              <label class="mb-1"><strong></strong></label>
              <input type="number" min="1" (keyup)="onKey($event)" [(ngModel)]="search.phases" placeholder="Fases"
                class="form-control" name="search" />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="mb-1"><strong>Precio (Inicio)</strong></label>
              <input type="number" min="1" step="0.01" (keyup)="onKey($event)" [(ngModel)]="search.start_price" placeholder="0.00"
                class="form-control" name="search" />
            </div>
          </div>

        </div>
        <div class="form-row">

          <div class="col-4">
            <div class="form-group">
              <label class="mb-1"><strong>Precio (final)</strong></label>
              <input type="number" min="1" step="0.01" (keyup)="onKey($event)" [(ngModel)]="search.end_price" placeholder="0.00"
                class="form-control" name="search" />
            </div>
          </div>
          <div class="col-2" class="custom-icon-search">
            <div class="form-group">
              <label class="mb-1 mr-2"><strong></strong></label>
              <button (click)="loadPage(1)" class="btn btn-primary mt-4 ml-2 mr-2 flaticon-381-search-1" href="javascript:void(0);" role="button"></button>
            </div>
          </div>
          <div class="col-2" class="custom-icon-search">
            <div class="form-group">
              <label class="mb-1"><strong></strong></label>
              <button (click)="clear()" class="btn btn-primary mt-4" href="javascript:void(0);" role="button"><i class="fa fa-refresh"></i></button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <ng-container *ngIf="lawyerServs.length; else nodata">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Servicio</th>
                  <th scope="col">Honorarios Profesionales</th>
                  <th scope="col">Honorarios por Asesorias</th>
                  <th scope="col">Fases</th>
                  <th style="width: 100px;text-align:center">Acciones</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let ls of lawyerServs; let i=index">
                  <td class="text-capitalize">{{ls.service.name}}</td>
                  <td class="text-capitalize">${{ls.price | number: '1.2-2':'en-US'}}</td>
                  <td class="text-capitalize">${{ls.price_consulting | number: '1.2-2':'en-US'}}</td>
                  <td class="text-capitalize">{{ls.phases_number}}</td>
                  <td>
                    <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                      (click)="edit(serviceModal, ls)" placement="top" ngbTooltip="Editar">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                        edit
                      </mat-icon>
                    </button><button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                      (click)="delete(ls.id)" placement="top" ngbTooltip="Eliminar">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #dc3545;">
                        delete
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row custom-div-pagination justify-content-end" *ngIf="dataPagination?.lastPage>1">
            <div class="col-auto">
            <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
                [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
                aria-label="Default pagination"></ngb-pagination>
            </div>
          </div>
        </ng-container>
        <ng-template #nodata>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>
      </div>

    </div>
  </div>

  <ng-template #serviceModal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{isEdit ? 'Editar' : 'Agregar'}} Servicio</h4>
    </div>
    <div class="modal-body">
      <form class="form-valide" action="#" method="post" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-row">
          <div class="col-12">
            <div class="form-group">
              <label class="mb-1"><strong>Nombre de Servicios</strong></label>
              <select class="form-control" id="service" name="service_id" formControlName="service_id"
                (change)="onChange($event.target.value )">
                <option [value]="service.id" *ngFor="let service of services">
                  {{ service.name }}
                </option>
              </select>
              <div *ngIf="isSubmitted && f.service_id?.errors" class="text-danger">
                <div *ngIf="f.service_id.errors.required">Campo requerido.</div>
              </div>
            </div>
          </div>
          <div class="col-6">
             <div class="form-group">
              <label class="mb-1"><strong>Honorarios Profesionales</strong></label>
              <input type="number" class="form-control" formControlName="price" />
              <div *ngIf="isSubmitted && f.price?.errors" class="text-danger ">
                <div *ngIf="f.price.errors.required">Campo requerido.</div>
              </div>
            </div>
          </div>
          <div class="col">
             <div class="form-group">
              <label class="mb-1"><strong>Honorarios por Asesorias</strong></label>
              <input type="number" class="form-control" formControlName="price_consulting" />
              <div *ngIf="isSubmitted && f.price_consulting?.errors" class="text-danger ">
                <div *ngIf="f.price_consulting.errors.required">Campo requerido.</div>
              </div>
            </div>
          </div>
        </div>
<!--         <div class="form-row">
          <div class="col-12">
            <label class="mb-1"><strong>Honorarios Profesionales</strong></label>
            </div>
            <div class="col-6">
              <label class="mb-1"><strong> Monto mínimo</strong></label>
              <input type="number" class="form-control" formControlName="priceLow" />
              <div *ngIf="isSubmitted && f.priceLow?.errors" class="text-danger ">
                <div *ngIf="f.priceLow.errors.required">Campo requerido.</div>
              </div>
              </div>
              <div class="col-6">
                <label class="mb-1"><strong> Monto máximo</strong></label>
                <input type="number" class="form-control" formControlName="priceHigh" />
                <div *ngIf="isSubmitted && f.priceHigh?.errors" class="text-danger ">
                  <div *ngIf="f.priceHigh.errors.required">Campo requerido.</div>
                </div>
                </div>
            </div> -->
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Rama del Derecho</strong></label>
              <select class="form-control" id="branch" name="branch" [(ngModel)]="branchID"
                [ngModelOptions]="{standalone: true}" disabled style="width:100%;-webkit-appearance: none;">
                <option [value]=" branch.id" *ngFor="let branch of branchs">
                  {{ branch.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Tipo de Servicios</strong></label>
              <select class="form-control" id="service_type" name="service_type" [(ngModel)]="serviceTypeID"
                [ngModelOptions]="{standalone: true}" disabled style="width:100%;-webkit-appearance: none;">
                <option [value]="ts.id" *ngFor="let ts of typeServices">
                  {{ ts.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Número de fases</strong></label>
              <input type="number" class="form-control" min="1" formControlName="phases_number" readonly />
              <div *ngIf="isSubmitted && f.phases_number?.errors" class="text-danger">
                <div *ngIf="f.phases_number.errors.required">
                  Campo requerido.
                </div>
              </div>
            </div>
          </div>
          <div class="col d-flex align-items-center">
            <div>
              <button type="button" mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                (click)="addPhase()" placement="top" ngbTooltip="Agregar fase">
                <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                  add_circle_outline
                </mat-icon>
              </button>
              <button type="button" mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                (click)="removePhase()" placement="top" ngbTooltip="Eliminar fase">
                <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #dc3545;">
                  remove_circle_outline
                </mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div formArrayName="phases">
          <div *ngFor="let item of phases.controls; let i = index">
            <div [formGroupName]="i" class="form-row">
              <div class="col-9">
                <div class="form-group">
                  <label class="mb-1"><strong>Fase {{ i + 1 }}</strong></label>
                  <input id="phase-{{ i }}" type="text" class="form-control" formControlName="description" />
                  <div *ngIf="isSubmitted && item.controls['description']?.errors" class="text-danger ">
                    <div *ngIf="item.controls['description'].errors.required">Campo requerido.</div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label id="percent-{{ i }}" class="mb-1"><strong>Porcentaje</strong></label>
                  <input type="number" class="form-control" min="0" max="100" formControlName="percent" />
                  <div *ngIf="isSubmitted && item.controls['percent']?.errors" class="text-danger ">
                    <div *ngIf="item.controls['percent'].errors.required">Campo requerido.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button type="button" class="btn btn-outline-danger mr-2" (click)="modal.dismiss()">Cancelar</button>
          <button type="submit" class="btn btn-primary">{{isEdit ? 'Editar' : 'Agregar'}}</button>

        </div>
      </form>
    </div>
  </ng-template>
