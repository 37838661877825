import { Component, OnInit,ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ChartComponent } from "ng-apexcharts";
import { AdminService } from 'src/app/shared/services/admin.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-financial-state',
  templateUrl: './financial-state.component.html',
  styleUrls: ['./financial-state.component.css']
})
export class FinancialStateComponent implements OnInit {

  modal: any;
  list = [];
  params = '';
  info_object : any;
  page; dataPagination;
  branches = [];
  types = [];
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    branch: null,
    type: null,
    service_name: null,
    lawyer_name: null
  };
  hoveredDate: NgbDate | null = null;
  public chartOptions;
  titlePage='Estado de cuentas';
  constructor(
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private adminService: AdminService,
    titlePageService: TitlePageService
  ) { 
    titlePageService.titlePageObservableData = { name: this.titlePage};
  }

  ngOnInit(): void {
    this.loadData();
    this.loadFilters();
  }

  loadData(page = 1) {
    this.adminService.getLawyers(page, this.params).subscribe(
      response => {
        console.log(response);
        this.list = response.data;
        let subtotal = this.list.reduce((accumulator, lawyer) => {
          return accumulator + lawyer.plan_price; 
        }, 0);
        let taxes = this.list.reduce((accumulator, lawyer) => {
          return accumulator + lawyer.plan_tax; 
        }, 0);

        this.info_object = {
          total_gross_income: subtotal,
          total_comission_price: taxes,
          total_net_income: subtotal + taxes
        };
        
        this.dataPagination = response.meta.page;
        this.chartOptions = {
          series: [
            {
              name: "distributed",
              data: [this.info_object.total_gross_income, this.info_object.total_comission_price, this.info_object.total_net_income]
            }
          ],
          chart: {
            height: 350,
            type: "bar",
            events: {
              click: function(chart, w, e) {
              }
            }
          },
          colors: [
            "#008FFB",
            "#00E396",
            "#FEB019",
            "#FF4560",
            "#775DD0",
            "#546E7A",
            "#26a69a",
            "#D10CE8"
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            position: "bottom"
          },
          grid: {
            show: false
          },
          xaxis: {
            categories: [
              ["Ingresos Brutos"],
              ["Impuestos"],
              ["Ingresos Totales"]
            ],
            labels: {
              style: {
                colors: [
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#546E7A",
                  "#26a69a",
                  "#D10CE8"
                ],
                fontSize: "12px"
              }
            }
          }
        }; 
    });
  }

  loadFilters(page = 1) {
    this.adminService.listBranchs().subscribe(
      response => {
        this.branches = response.data;
    });
    this.adminService.listService().subscribe(
      response => {
        this.types = response.data;
    });
  }

  getTotal() {
    return this.info_object.number_of_clients;
  }

  downloadPDF() {
     this.adminService.getFinancialState(null, this.params, true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Estado Financiero.pdf";
        link.click();
    });
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.loadData();
    this.params = '';
    this.searchFilter = {
      dateStart: null,
      dateEnd: null,
      branch: null,
      type: null,
      service_name: null,
      lawyer_name: null
    };
    this.toDate = null;
    this.fromDate = null;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search() {
    this.searchFilter.dateStart = this.formatter.format(this.fromDate);
    this.searchFilter.dateEnd = this.formatter.format(this.toDate);
    this.params = '';

    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }

    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.searchFilter.lawyer_name) {
      this.params += '&lawyer_name=' + this.searchFilter.lawyer_name;
    }

    if (this.searchFilter.service_name) {
      this.params += '&service_name=' + this.searchFilter.service_name;
    }

    if (this.searchFilter.type !== '') {
      this.params += '&type_service_id=' + this.searchFilter.type;
    }

    if (this.searchFilter.branch !== '') {
      this.params += '&branch_law_id=' + this.searchFilter.branch;
    }
    if (this.params !== '') {
      this.loadData(1);
    }
  }
}
