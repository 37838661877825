<div class="content-body d-flex align-items-center">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-xl-12 col-sm-12 text-center">
        <h3>Mensajería</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body chat-body">
        <div *ngIf="dataChat" class="form-row">
          <div class="col-12 custom-alig-content messages-container">
            <ul>
              <li *ngFor="let message of dataChat.chatMessages" class="m-2 p-3">
                <small>{{ message.user.full_name }}</small> <small class="message-date">{{ message.created_at}}</small><br>
                {{ message.messages }}
              </li>
            </ul>
          </div>
        </div>
        <div class="no-data-char" *ngIf="condNoChat" >
          <h3><strong>
            Aún no hay mensajes registrados para este trámite
          </strong></h3>
        </div>
      </div>

      <div class="card-footer chat-footer">
        <form [formGroup]="formChat" (ngSubmit)="sendMessage()">
          <div class="row">
            <div class="col-12">
              <textarea type="text" class="form-control"  formControlName="message" rows="5"></textarea>
            </div>
            <div class="col-12" class="div-button">
              <button type="submit" class="btn btn-primary custom-button">
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
