import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LOGO } from 'src/app/shared/data/constants';
import { AuthService } from '../../shared/services/auth.service';
import { StorageService } from '../../shared/services/storage.service';
import { TOKEN, USER, LAWYER_TYPE } from '../../shared/data/constants';
import { LawyerProduct } from '../../shared/interfaces/user';
import Swal from 'sweetalert2';

@Component( {
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.css' ]
} )
export class LoginComponent implements OnInit {

  logo = LOGO;
  form: FormGroup;
  submitted = false;
  isAdmin: boolean;
  isLawyer: boolean;
  products: LawyerProduct[];
  product: number = 0;
  typePass = 'password';
  iconPass = 'visibility_off';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute,
    private storage: StorageService,
  ) {
    this.route.data.subscribe( data => this.isAdmin = data.isAdmin );
    this.createForm();
    this.auth.getLawyerProducts().subscribe( response => this.products = response.data );
  }

  get f(): any { return this.form.controls; }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted ||= true;
    if ( this.form.valid ) {
      const userType = this.form.controls.loginType.value;

      if ( userType === 'lawyer' && this.product == 0){
        Swal.fire( { text: "Debe seleccionar un producto", icon: 'warning' } );
        return;
      }

      this.auth.login( this.form.controls.loginType.value, this.form.value ).subscribe(response => {
        this.auth.authSubject( response.user );
        this.storage.setItem( TOKEN, response.data );
        this.storage.setItem( USER, response.user );

        if ( userType === 'lawyer' ) {
          // Almacenamos el tipo de abogado Marketplace | Gestion de expediente

          let lawyer = response.user.lawyer as any;

          if(lawyer.plan_id == 2){
            this.storage.setItem( 'disable_menus', ["finance", "documents", "services"] );
          } else if(lawyer.plan_id == 3){
            this.storage.setItem( 'disable_menus', ["management_control", "documents", "reports"] );
          }else{
            this.storage.setItem( 'disable_menus', [""] );
          }

          // Se valida nuevamente con el back
          this.auth.verifyLawyerProduct( this.product ).subscribe( resp => {
            if ( resp.enabled ) {

              if( this.product == 1) {
                this.storage.setItem( LAWYER_TYPE, 'Marketplace' );
              } else {
                this.storage.setItem( LAWYER_TYPE, 'Plataforma' );
              }
              this.router.navigateByUrl( 'lawyer/index' );


              return;
            }
            Swal.fire( { text: resp.message, icon: 'warning' } );
            this.storage.clearAll();
          }, (error) => {
            Swal.fire( { text: error.message, icon: 'warning' } );
            this.storage.clearAll();
          } );
        }

        if ( userType === 'admin' ) {
          this.router.navigateByUrl( 'admin/index' );
        }

        if ( userType === 'company' ) {
          this.router.navigateByUrl( 'company/index' );
        }
        if ( userType === 'person' ) {
          this.router.navigateByUrl( 'person/index' );
        }

      } );
    }
  }

  fisLawyer( perfil: any ): void {
    perfil === 'lawyer' ? this.isLawyer = true : this.isLawyer = false;
  }

  setProduct( product: any ): void {
    this.product = product;
  }
  private createForm(): void {
    this.form = this.fb.group(
      {
        loginType: [ this.isAdmin ? 'admin' : 'person' ],
        LawyerProduct: [ this.isLawyer ? 'lawyer' : 'person' ],
        email: [ '', [ Validators.required, Validators.email ] ],
        password: [ '', [ Validators.required, Validators.minLength( 8 ) ] ]
      }
    );
  }

  onChangePass(){
    if(this.typePass=='password') {
      this.typePass = 'text';
      this.iconPass = 'visibility';
    } else if(this.typePass=='text') {
      this.typePass = 'password';
      this.iconPass = 'visibility_off';
    }
  }

}
