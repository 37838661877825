import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailservices',
  templateUrl: './detailservices.component.html',
  styleUrls: ['./detailservices.component.css']
})
export class DetailservicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
