import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component( {
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: [ './loading.component.css' ]
} )
export class LoadingComponent implements OnInit {

  constructor( private spinner: NgxSpinnerService ) { }

  ngOnInit(): void {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout( () => {
      /** spinner ends after 2 seconds */
      this.spinner.hide();
    }, 2000 );
  }

}
