import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css']
})
export class ExpensesComponent implements OnInit {
  modal = {};
  list = {
    'Tramite': [],
    'Proceso': []
  };
  params = {
    'Tramite': '',
    'Proceso': ''
  };
  info_object = {
    'Tramite': null,
    'Proceso': null
  };
  currentItem = {};
  dataPagination = {
    'Tramite': null,
    'Proceso': null
  };

  searchFilter = {
    'Tramite': {
      dateStart: null,
      dateEnd: null,
      case_number: ''
    },
    'Proceso': {
      dateStart: null,
      dateEnd: null,
      case_number: ''
    }
  };
  fromDate = {
    'Tramite': null,
    'Proceso': null
  };
  toDate = {
    'Tramite': null,
    'Proceso': null
  };
  hoveredDate = {
    'Tramite': null,
    'Proceso': null
  };
  // titlePage='Relación de Casos';
  titlePage='Egresos';
  constructor(
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private lawyerService: LawyerService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
  }

  ngOnInit(): void {
    this.loadData('Tramite');
    this.loadData('Proceso');
  }

  loadData(type, page = 1) {
    this.lawyerService.getExpenses(type, page, this.params[type]).subscribe(
      response => {
        this.list[type] = response.data;
        this.info_object[type] = response.info_object;
        this.dataPagination[type] = response.meta.page;
    });
  }

  openModal(detailModal: any, item) {
    this.lawyerService.getExpense(item.id).subscribe(
      response => {
        this.currentItem = response.data;
      }
    );
    this.modal = this.modalService.open(detailModal, { size: 'md' });
  }

  downloadPDF(type) {
    this.lawyerService.getExpenses(type, null, this.params[type], true).subscribe(
     response => {
       var downloadURL = window.URL.createObjectURL( response );
       var link = document.createElement( 'a' );
       link.href = downloadURL;
       link.download = "Gastos - " + type + ".pdf";
       link.click();
    });
  }

  downloadExpensePDF(id) {
    this.lawyerService.getExpense(id, true).subscribe(
     response => {
       var downloadURL = window.URL.createObjectURL( response );
       var link = document.createElement( 'a' );
       link.href = downloadURL;
       link.download = "Gasto - " + id + ".pdf";
       link.click();
    });
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter(type) {
    this.loadData(type);
    this.params[type] = '';
    this.searchFilter[type] = {
      dateStart: null,
      dateEnd: null,
      case_number: null
    }
    this.toDate[type] = null;
    this.fromDate[type] = null;
  }

  onDateSelection(type, date: NgbDate) {
    if (!this.fromDate[type] && !this.toDate[type]) {
      this.fromDate[type] = date;
    } else if (this.fromDate[type] && !this.toDate[type] && date && date.after(this.fromDate[type])) {
      this.toDate[type] = date;
    } else {
      this.toDate[type] = null;
      this.fromDate[type] = date;
    }
  }

  isHovered(type, date: NgbDate) {
    return this.fromDate[type] && !this.toDate[type] && this.hoveredDate[type] && date.after(this.fromDate[type]) && date.before(this.hoveredDate[type]);
  }

  isInside(type, date: NgbDate) {
    return this.toDate[type] && date.after(this.fromDate[type]) && date.before(this.toDate[type]);
  }

  isRange(type, date: NgbDate) {
    return date.equals(this.fromDate[type]) || (this.toDate[type] && date.equals(this.toDate[type])) || this.isInside(type, date) || this.isHovered(type, date);
  }

  search(type){
    this.searchFilter[type].dateStart = this.formatter.format(this.fromDate[type]);
    this.searchFilter[type].dateEnd = this.formatter.format(this.toDate[type]);
    this.params[type] = '';
    if (this.searchFilter[type].dateStart !== '') {
      this.params[type] += '&start_date=' + this.searchFilter[type].dateStart;
    }

    if (this.searchFilter[type].dateEnd !== '') {
      this.params[type] += '&end_date=' + this.searchFilter[type].dateEnd;
    }

    if (this.searchFilter[type].case_number !== '') {
      this.params[type] += '&case_number=' + this.searchFilter[type].case_number;
    }
    if (this.params[type]) {
      this.loadData(type);
    }
  }
}
