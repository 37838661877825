<div class="row">
  <div class="col-12">
    <div class="card border-0">
      <div class="card-header d-block pb-0">
        <form class="">

          <div class="form-group hidden">
            <div class="input-group">
              <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
              <ng-template #t let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>

          <div class="row align-items-end">

            <div class="col-4 col-md-4">
              <div class="form-group">
                <label class="mb-1"><strong>Desde</strong></label>
                <div class="input-group">
					<input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
				    [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                  <div class="input-group-append">
                    <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4 col-md-4">
              <div class="form-group">
                <label class="mb-1"><strong>Hasta</strong></label>
                <div class="input-group">
					<input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
				     [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                  <div class="input-group-append">
                    <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-md-3">
              <div class="item-filter">
                <label class="mb-1"><strong>Tipo de Servicio</strong></label>
                <select class="form-control form-filter" name="type" id="type" [(ngModel)]="searchFilter.service_type">
                  <option selected value="">Todos</option>
                  <option value="Proceso">Proceso</option>
                  <option value="Trámite">Trámite</option>
                </select>
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="form-group">
                <label class="mb-1"><strong>Cliente</strong></label>
                <!-- <input class="form-control form-filter" name="service_name" id="service_name" [(ngModel)]="searchFilter.service_name"> -->
                <select class="form-control form-filter" name="client_id" id="client_id" [(ngModel)]="searchFilter.client_id">
                  <option selected value="">Todos</option>
                  <option [value]="client.id" *ngFor="let client of clients">{{client.data_client.full_name}}</option>
                </select>
              </div>
            </div>
            <!-- <div class="col-6 col-md-3">
              <div class="form-group">
                <label class="mb-1"><strong>Nombre de Servicio</strong></label>
                <select class="form-control form-filter" name="service_name" id="service_name" [(ngModel)]="searchFilter.service_name">
                  <option selected value="">Todos</option>
                  <option [value]="type.name" *ngFor="let type of types">{{type.name}}</option>
                </select>
              </div>
            </div> -->
            <div class="col-4 col-md-3">
              <div class="form-group">
                <div class="input-group">
                  <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                    <i class="fa fa-search"></i>
                  </button>
                  <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                    <i class="fa fa-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-body">
        <ng-container *ngIf="list.length; else nodata">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Fecha</th>
                  <th scope="col">N Cotización</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Cargo</th>
                  <th scope="col">Abono</th>
                  <th scope="col">Saldo</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of list; let i=index">
                  <td>{{item.date}}</td>
                  <td>{{item.quote_id}}</td>
                  <td>{{item.client}}</td>
                  <td>$ {{item.charge | number:'1.2-2':'en-US'}}</td>
                  <td>$ {{item.amount | number:'1.2-2':'en-US'}}</td>
                  <td>$ {{item.remaining | number:'1.2-2':'en-US'}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4"></td>
                  <td colspan="2" align="right">
                    <div><strong>Total cargado:  </strong> ${{total_charges | number:'1.2-2':'en-US'}}</div>
                    <div><strong>Total abonado:  </strong> ${{total_amount | number:'1.2-2':'en-US'}}</div>
                    <div><strong>Total pendiente:  </strong> ${{total_remaining | number:'1.2-2':'en-US'}}</div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="row justify-content-end mt-3">
            <div class="col-auto custom-div-pagination">
              <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="dataPagination.currentPage" aria-label="Paginación">
              </ngb-pagination>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
          </div>
        </ng-container>
        <ng-template #nodata>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<ng-template #detailModal>
  <div class="modal-header">
    <div class="col-6 d-flex text-left">
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h4>Comprobante de Pago</h4>
      <h4>{{md.company}}</h4>
    </div>
    <div class="row mt-4">
      <fieldset class="pl-3">
        <p class="mb-0"><b>Nombre:</b> {{md.name}}</p>
        <p class=""><b>Cédula:</b> {{md.identification_card}}</p>
        <p class="mb-0"><b>Servicios Profesionales:</b> {{md.professional_services}}</p>
        <p class="mb-0"><b>Monto:</b> {{md.amount}}</p>
      </fieldset>
    </div>
  </div>
</ng-template>
