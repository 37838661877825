import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexDataLabels, ApexStroke, ApexLegend } from "ng-apexcharts";
import { Subject } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  colors: string[];
  legend: ApexLegend;
};

@Component({
  selector: 'app-service-monitoring',
  templateUrl: './service-monitoring.component.html',
  styleUrls: ['./service-monitoring.component.css']
})
export class ServiceMonitoringComponent implements OnInit {
  list = [];
  branches = [];
  statuses = [];
  types = [];
  params = '';
  info_object : any;
  page; dataPagination;
  counts = {
    active: 0,
    closed: 0
  };
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    lawyer_name: null,
    status_name: null,
    file_number: null,
    type: null,
    branch: null,
    status: null
  }
  hoveredDate: NgbDate | null = null;
  public chartOptions: Partial<ChartOptions>;
  titlePage='Monitoreo de Servicios';
  constructor(
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private adminService: AdminService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
  }

  ngOnInit() {
    this.loadData();
    this.loadFilters();
  }

  loadData(page = 1) {
    this.adminService.getServiceMonitoring(page, this.params).subscribe(
      response => {
        this.list = response.data;
        this.info_object = response.info_object;
        this.dataPagination = response.meta.page;
        this.chartOptions = {
          series:[ this.info_object[0].total, this.info_object[1].total, this.info_object[2].total],
          chart: {
              type: 'donut',
              height: 350
          },
          legend: {
            position: "bottom"
          },
          labels: ['Pendientes', 'Activos', 'Cerrados'],
          stroke: {
            width: 3,
          },
          colors:['#5EE173', '#3A82EF', "#775DD0"],
          dataLabels: {
            enabled: true
          },
        };
    });
  }

  loadFilters(page = 1) {
    this.adminService.listBranchs().subscribe(
      response => {
        this.branches = response.data;
    });
    this.adminService.listService().subscribe(
      response => {
        this.types = response.data;
    });
    this.adminService.listOrderStatuses().subscribe(
      response => {
        this.statuses = response.data;
    });
  }

  downloadPDF() {
     this.adminService.getServiceMonitoring(null, this.params, true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Monitoreo de Servicios.pdf";
        link.click();
    });
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.loadData();
    this.params = '';
    this.searchFilter = {
      dateStart: null,
      dateEnd: null,
      lawyer_name: null,
      status_name: null,
      file_number: null,
      type: null,
      branch: null,
      status: null
    }
    this.toDate = null;
    this.fromDate = null;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search() {
    this.searchFilter.dateStart = this.formatter.format(this.fromDate);
    this.searchFilter.dateEnd = this.formatter.format(this.toDate);
    this.params = '';

    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }

    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.searchFilter.file_number) {
      this.params += '&file_number=' + this.searchFilter.file_number;
    }

    if (this.searchFilter.status) {
      this.params += '&status_name=' + this.searchFilter.status;
    }

    if (this.searchFilter.lawyer_name) {
      this.params += '&lawyer_name=' + this.searchFilter.lawyer_name;
    }

    if (this.searchFilter.type !== '') {
      this.params += '&typeService_name=' + this.searchFilter.type;
    }

    if (this.searchFilter.branch !== '') {
      this.params += '&branch_law_name=' + this.searchFilter.branch;
    }

    if (this.params !== '') {
      this.loadData(1);
    }
  }
}
