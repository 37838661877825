<section class="content-body d-flex">
    <div class="container-fluid">
      <h3 class="mb-0">Listado de pagos</h3>
      <br>
        <div class="form-row">
            <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let item of payList">
              <div class="card">
                <div class="card custom-card-pay custom-card-secction" >
                    <ul class="list-group list-group-flush custom-text">
                      <li class="list-group-item "><strong>Servicio: </strong>{{item.nameService}}</li>
                      <!-- <li class="list-group-item"><strong>Codigo:</strong></li> -->
                      <li class="list-group-item"><strong>Cliente: </strong>{{item.clientName}}</li>
                      <li class="list-group-item"><strong>Referencia: </strong>{{item.referenceCode}}</li>
                      <li class="list-group-item"><strong>Monto: </strong>$ {{item.amount}}</li>
                      <li class="list-group-item li-file"><strong>Comprobante: </strong>
                        <a  *ngIf="item.fileTransaccion; else message_error"  title="Descargar"  [href]="item.fileTransaccion"    target="_blank"  >
                          <i class="fa fa-download"  ></i>
                        </a>

                      <ng-template #message_error>
                        <a title="Descargar"   (click)="messageError()" >
                          <i class="fa fa-download"  ></i>
                        </a>
                      </ng-template>
                    </ul>
                    <div class="form-row custom-button-card">
                        <!-- div class="col">
                            <button    type="submit" class="btn btn-outline-primary custom-button custom-text-button custom-padding-button">
                                Contactar cliente
                              </button>
                        </div -->
                        <div class="col">
                            <button  (click)="approveOrder(item.id)"  class="btn btn-outline-primary custom-button custom-text-button custom-padding-two">
                                Aprobar
                              </button>
                        </div>
                        <div class="col">
                            <button (click)="cancelOrder(item.id)" class="btn btn-danger custom-button custom-text-button custom-padding-two">
                                Denegar
                              </button>
                        </div>
                    </div>
                  </div>
            </div>

          </div>

            <div class="col-12 custom-div-pagination justify-content-center">
              <ngb-pagination [pageSize]="6" (pageChange)="loadData($event)"
                [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
                aria-label="Default pagination"></ngb-pagination>
            </div>

        </div>
    </div>
</section>
