import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { TOKEN } from '../data/constants';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastsService } from '../services/toasts.service';
import Swal from 'sweetalert2';

const env = environment;
@Injectable()
export class HttpInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private storage: StorageService,
    //private toastService: ToastsService,
    private spinner: NgxSpinnerService
  ) { }

  intercept( request: HttpRequest<unknown>, next: HttpHandler ): Observable<HttpEvent<unknown>> {
    this.spinner.show();
    var validateForm = request.urlWithParams.indexOf('?_method=PUT');

    if ( request.urlWithParams !== env.apiUrl + 'lawyer-documents'
      && request.urlWithParams !== env.apiUrl + 'lawyer-clients-document'
      && request.urlWithParams !== env.apiUrl + 'order-documents/lawyer-ser'
      && request.urlWithParams !== env.apiUrl + 'order-documents'
      && request.urlWithParams !== env.apiUrl + 'payment'
      && request.urlWithParams !== env.apiUrl + 'cases'
      && request.urlWithParams !== env.apiUrl + 'order-payments'
      && request.urlWithParams !== env.apiUrl + 'case-expenses'
      && request.urlWithParams !== env.apiUrl + 'lawyers' && validateForm === -1 ) {

      const token = this.storage.getItem( TOKEN );
      if ( token ) {
        request = request.clone( { headers: request.headers.set( 'Authorization', `Bearer ${token}` ) } );
      }

      if ( !request.headers.has( 'Content-Type' ) ) {
        // request = request.clone( { headers: request.headers.set( 'Content-Type', 'application/json' ) } );
      }
    } else {
      const token = this.storage.getItem( TOKEN );
      request = request.clone( { headers: request.headers.set( 'Authorization', `Bearer ${token}` ) } );
    }


   request = request.clone( { headers: request.headers.set( 'Accept', 'application/json' ) } );

    return next.handle( request ).pipe(
      map( ( event: HttpEvent<any> ) => {
        if ( event instanceof HttpResponse ) { this.spinner.hide(); }
        return event;
      } ),
      catchError( ( response: HttpErrorResponse ) => {
        this.spinner.hide();
        this.errorHandling( response );
        return throwError( response );
      } )
    );
  }

  private errorHandling( res: HttpErrorResponse ): void {
    let exit = false;
    const statusError = [ 400, 403, 500, 503 ];
    const urls = [ 'login', 'new-password', 'recovery-password' ];

    if ( res.error.errors ) {
      const errors = res.error.errors;

      for ( const key in errors ) {
        if ( Object.prototype.hasOwnProperty.call( errors, key ) ) {
          const element = errors[ key ];
          //this.toastService.show( element, { classname: 'bg-danger text-white' } );
          Swal.fire( { text: element, icon: 'warning', confirmButtonColor: '#212f54' } );

        }
      }
      return;
    }

    urls.forEach( url => {

      exit ||= res.url.includes( url );

      if ( res.url.includes( url ) ) {
        //this.toastService.show( res.error.message, { classname: 'bg-danger text-white' } );
        Swal.fire( { text: res.error.message, icon: 'error', confirmButtonColor: '#212f54' } );
      }
    } );

    if ( exit ) { return; }

    if ( statusError.includes( res.status ) ) {
      this.router.navigateByUrl( `error-${res.status}` );
    } else {
      Swal.fire( { text: res.error?.message ?? 'Error', icon: 'error', confirmButtonColor: '#212f54' } );
      throw new Error(res.error?.message);
      return
    }
  }
}
