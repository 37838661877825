import { Component, OnInit } from '@angular/core';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-invoices-admin',
  templateUrl: './invoices-admin.component.html',
  styleUrls: ['./invoices-admin.component.css']
})
export class InvoicesAdminComponent implements OnInit {

  titlePage='Finanzas';
  modal: any;
  invoices: any = [
    {
      id: '1',
      reference_number: '12313',
      service_name: 'asdasd',
      creation_date: '12/03/2022',
      client: 'Adriana Cardoso',
      total: '100',
    },
    {
      id: '2',
      reference_number: '123123',
      service_name: 'asdasdd',
      creation_date: '13/03/2022',
      client: 'Cardoso Adriana',
      total: '150',
    }
  ];
  page; dataPagination;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null
  };
  hoveredDate: NgbDate | null = null;
  params = '';
  formEmail!: FormGroup;
  submittedEmail = false;
  formAnular!: FormGroup;
  submittedAnular = false;
  dataEmail={id:'',email:''};
  loading: boolean = false;
  loadingEmail: boolean = false;
  
  dataCancel={id:'',num:'',reason:''};
  constructor(
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    titlePageService: TitlePageService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) { 
    this.page = 1;
    titlePageService.titlePageObservableData = { name: this.titlePage};
    this.loadData();
    this.createFormEmail();
    this.createFormAnular();
  }

  get e(): any { return this.formEmail.controls; }
  get a(): any { return this.formAnular.controls; }

  ngOnInit(): void {
  }


  private createFormEmail(): void {
    this.formEmail = this.fb.group(
      {
        email: [ '', [ Validators.required, Validators.email ]  ],
      }
    );
  }

  private createFormAnular(): void {
    this.formAnular = this.fb.group(
      {
        canceled_reason: [ '', [ Validators.required, , Validators.minLength( 15 ) ]  ],
      }
    );
  }

  onSubmitEmail() {
    this.submittedEmail = true;
    if(this.formEmail.valid) {
      console.log('debe enviar el email');
      /*this.invoiceService.sendEmail(this.dataEmail.id ,this.formEmail.value ).subscribe( response => {
      
        console.log(response);
        this.modalService.dismissAll();
        Swal.fire( '', response.message, 'success' );
        
      }, err => {
        //this.loadingEmail = false;
      }
      );*/

      
    }

  }

  onSubmitAnular(){
    this.submittedAnular = true;
    if(this.formAnular.valid) {
      console.log('debe anular la factura');

      /*this.invoiceService.invoicesCancel({ id: this.dataCancel.id, data: this.formAnular.value }).subscribe( response => {
      
        console.log(response);
        this.modalService.dismissAll();
        Swal.fire( '', response.message, 'success' );
        this.loadData();
        
      }, err => {
        //this.loadingEmail = false;
      }
      );*/
      

    }
  }


  openCancel(id: string,num: string,modal: any){
    this.dataCancel.id = id;
    this.dataCancel.num = num;
    this.modal = this.modalService.open( modal );
  }

  openEmail(id: string,email: string,modal: any) {
    this.createFormEmail();

    this.dataEmail.email = email;
    this.dataEmail.id = id;
    this.formEmail.controls['email'].setValue(email);
  
    this.modal = this.modalService.open( modal );
  }

  formReset() {
    this.formEmail.reset();
    this.submittedEmail = false;
    this.modalService.dismissAll();
  }

  formResetAnular(){
    this.formAnular.reset();
    this.submittedAnular = false;
    this.modalService.dismissAll();
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.loadData();
    this.searchFilter = {
      dateStart: null,
      dateEnd: null
    }
    this.toDate = null;
    this.fromDate = null;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search(){
    this.searchFilter.dateStart = this.formatter.format( this.fromDate );
    this.searchFilter.dateEnd = this.formatter.format( this.toDate );
    this.params = '';

    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }

    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.params !== '') {
      this.loadData();
    }
  }

  loadData() {
    /*this.clientService.getPayments(this.page, this.params).subscribe(
      response => {
        this.list = response.data;
        this.dataPagination = response.meta.page;
    });*/
  }

}
