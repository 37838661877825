import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from '../../../shared/services/clients.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-proc-services',
  templateUrl: './proc-services.component.html',
  styleUrls: ['./proc-services.component.css']
})
export class ProcServicesComponent implements OnInit {
  verAbogados: boolean = false;
  verServicios: boolean = false;
  modal: any; modalSer: any;
  listBranch: any = [];
  listServices: any = [];

  abogados = [];
  type_service_id=2; page; dataPagination; itemIdPagination;
  idBranhc;

  constructor(
    private modalService: NgbModal,
    private ClientsService: ClientsService,
    private router: Router,
  ) { 
    this.page = 1
  }

  ngOnInit(): void {
    this.loadDataBrach();
  }

  ModalAbogados(lisAboModal: any, item): void {
    this.itemIdPagination = item.id;
    this.getLawForBrach( item.id,this.type_service_id, this.page);
    this.verAbogados = true;
    this.modal = this.modalService.open(lisAboModal);
  }
  ModalServicios(lisServicios: any, item): void {
    this.idBranhc = item.id;
    this.getServForBrach(this.type_service_id, item.id);
    this.verServicios = true;
    this.modalSer = this.modalService.open(lisServicios);
  }

    private loadDataBrach(): void {
    this.ClientsService.getBranchLaw().subscribe(response => {
      this.listBranch = [...response.data];
    });
  }

  private getLawForBrach(branch_law_id, type_service_id, page ): void {
    this.ClientsService.getLawForBrach(branch_law_id, type_service_id,page).subscribe(response => {
     this.abogados = [...response.data];
     this.dataPagination = response.meta.page;
     if ( this.abogados.length === 0 ) {
       this.modalService.dismissAll();
     Swal.fire({
     title: 'Atención!',
     text: "No hay abogados asociados",
     icon: 'warning',
       showConfirmButton: true,
     }).then((result) => {
       if (result.isConfirmed) {
       }
     });
    }
 
   }); 
 }

 private getServForBrach( type_service_id, branch_law_id ): void {
   this.ClientsService.getServForBrach( type_service_id, branch_law_id).subscribe(response => {
    this.listServices = [...response.data];
    if ( this.listServices.length === 0 ) {
       this.modalService.dismissAll();
     Swal.fire({
     title: 'Atención!',
     text: "No hay servicios asociados",
     icon: 'warning',
       showConfirmButton: true,
     }).then((result) => {
       if (result.isConfirmed) {
       }
     });
    }
  }); 
}

regSol(item) {
  this.router.navigate(['/person/regproc'], { queryParams: { dataTypeService: item.id, idBranhc: this.idBranhc } });
  this.modalService.dismissAll();
}

 loadPage(page) {
    this.abogados = [];
    this.getLawForBrach(this.type_service_id, this.itemIdPagination, page);

  }

goProfile(item) {
  this.router.navigate(['/person/lawyer-profile'], { queryParams: { dataLawyer: item.id } });
  this.modalService.dismissAll();
}



}
