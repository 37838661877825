import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ConsultanciesService } from '../../../shared/services/consultancies.service';
import { NgbModal, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { User } from 'src/app/shared/interfaces/user';
import { USER, AVATAR } from 'src/app/shared/data/constants';
import { ClientsService } from '../../../shared/services/clients.service';

@Component({
  selector: 'app-approvals-person',
  templateUrl: './approvals-person.component.html',
  styleUrls: ['./approvals-person.component.css']
})
export class ApprovalsPersonComponent implements OnInit {
  @ViewChild("detailQuote", { static: true }) detailQuote: ElementRef;
  active = 2;
  consultancies = [];
  user: User;
  pageC; pageQ; dataPaginationC; dataPaginationQ;
  modalPay: any;
  detailQuoteModal: any;
  modalDetail: any;
  infoDetail: any;
  info: unknown;
  quoteList: any = [];
  dataQuotes: any = [];
  idOderQuote: number = 0;
  descripQuote: string = '';
  modal: any;
  listPayment: [];

  constructor(
    private consultanciesService: ConsultanciesService,
    private modalService: NgbModal,
    titlePageService: TitlePageService,
    private storage: StorageService,
    private ClientsService: ClientsService,

  ) {
    titlePageService.titlePageObservableData = { name: 'Aprobaciones' };
    this.pageC = 1;
    this.pageQ = 1;
  }

  ngOnInit(): void {
    this.user = this.storage.getItem(USER);
    this.getConsultancies(this.user.id, this.pageC);
    this.getMyquote(1, this.pageQ);
  }

  getConsultancies(id, pageC) {
    this.consultanciesService.getConsultanciesClient(id, pageC).subscribe(response => {
      // lawyer_service
      console.log(response.data)
      this.consultancies = response.data;
      this.dataPaginationC = response.meta.page;
    });

  }

  getMyquote(approved_by_lawler, pageQ) {
    this.ClientsService.getMyQuote(approved_by_lawler, pageQ).subscribe(response => {
      this.quoteList = response.data;
      this.dataPaginationQ = response.meta.page;
    });

  }

  loadPageC(page) {
    this.consultancies = [];
    this.getConsultancies(this.user.id, page);
  }

  loadPageQ(page) {
    this.quoteList = [];
    let pageQ = this.pageQ;
    this.getMyquote(1, pageQ);
  }


  payQuote(modal, data) {
    // confirmar que los datos estan llenos para abrir el modal
    //  if(data.total>0) {

    this.info = {
      amount: data.price,
      order: this.idOderQuote,
      description: this.descripQuote
    }
    this.detailQuoteModal.dismiss();
    this.modalPay = this.modalService.open(modal);
    // this.modalDetail = this.modalService.close(modal);

    //    }
    /*  else {
       Swal.fire({
         icon: 'warning',
         text: ' ',
         title: 'El monto debe ser mayor a 0',
       })
     } */
  }


  pagar(modal, data) {
    // confirmar que los datos estan llenos para abrir el modal
    //  if(data.total>0) {



    this.ClientsService.getNextPayment(data.id).subscribe(
      response => {

        let payment = response.data.phase_to_paid
        // this.listPayment = response.data;


        this.info = {
          amount: payment.total,
          order: data.id,
          description: data.description + " " + payment.description
        }
        this.modalPay = this.modalService.open(modal);
    });


    //    }
    /*  else {
       Swal.fire({
         icon: 'warning',
         text: ' ',
         title: 'El monto debe ser mayor a 0',
       })
     } */
  }

  closerModalPay() {
    //cerrar el modal y recargar
    this.getConsultancies(this.user.id, this.pageC);
    this.modalPay.dismiss();
    this.getMyquote(1, this.pageQ);
  }

  openDetail(modal, item) {
    this.modalDetail = this.modalService.open(modal);
    this.infoDetail = item;
  }

  openDetailQuote(modal, item) {
    this.dataQuotes = item;
    this.idOderQuote = item.id;
    this.descripQuote = item.service_name;
    this.detailQuoteModal = this.modalService.open(modal, { size: 'xl' });
    this.infoDetail = item;
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }


}
