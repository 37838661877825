<!--**********************************
    Content body start
***********************************-->
<div class="content-body d-flex">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>{{ titlePage }}</h3>
      </div>
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Nombre completo</th>
                    <th scope="col">Cédula de Identidad</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of lawyers; let i = index">
                    <td class="text-capitalize">
                      {{ item.user.name }} {{ item.user.last_name }}
                    </td>
                    <td>{{ item.dni }}</td>
                    <td>{{ item.phone }}</td>
                    <td>
                      <div>
                        <button
                          mat-icon-button
                          color="accent"
                          aria-label="Example icon button with a menu icon"
                          (click)="openModalLawyer(lawyerModal, item)"
                          placement="top"
                          ngbTooltip="Detalles"
                        >
                          <mat-icon
                            aria-hidden="false"
                            aria-label="Example home icon"
                            style="color: #212f54"
                          >
                            list
                          </mat-icon>
                        </button>

                        <button
                          mat-icon-button
                          color="accent"
                          aria-label="Example icon button with a menu icon"
                          (click)="approved(item)"
                          placement="top"
                          ngbTooltip="Aprobar"
                        >
                          <mat-icon
                            aria-hidden="false"
                            aria-label="Example home icon"
                            style="color: #68CF29"
                          >
                            verified_user
                          </mat-icon>
                        </button>
                        <button
                          mat-icon-button
                          color="accent"
                          aria-label="Example icon button with a menu icon"
                          (click)="openModalReject(content, item)"
                          placement="top"
                          ngbTooltip="Rechazar"
                        >
                          <mat-icon
                            aria-hidden="false"
                            aria-label="Example home icon"
                            style="color: #dc3545"
                          >
                            highlight_off
                          </mat-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="row justify-content-end"
              *ngIf="dataPagination?.lastPage > 1"
            >
              <div
                class="col-auto custom-div-pagination justify-content-center"
              >
                <ngb-pagination
                  [pageSize]="12"
                  (pageChange)="loadPage($event)"
                  [collectionSize]="dataPagination?.total"
                  [maxSize]="5"
                  [(page)]="page"
                  aria-label="Default pagination"
                ></ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Rechazo de abogado</h4>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for=""><strong>Motivo de rechazo</strong></label>
      <textarea
        class="form-control"
        name=""
        id=""
        rows="3"
        [(ngModel)]="comment"
      ></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger ml-2"
      (click)="modal.dismiss()"
    >
      Cancelar
    </button>

    <button type="button" class="btn btn-primary mr-2" (click)="modal.close()">
      Guardar
    </button>
  </div>
</ng-template>

<!-- Modal Data de abogado  -->
<ng-template #lawyerModal>
  <div class="modal-header">
    <h3 class="modal-title text-capitalize" id="modal-basic-title">
      Perfil del Abogado
    </h3>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modalbody">
    <div class="card">
      <!-- <div class="card-header"></div> -->
      <div class="card-body">
        <div class="col-12 flex-column text-center p-0 mb-3">
          <img [src]="lawyer.main_image" alt="" srcset="" class="ln-avatar" />
        </div>
        <h4 class="text-center mb-2">{{ lawyer.user.full_name }}</h4>
        <p class="text-center mb-3">{{ lawyer.description }}</p>
        <div class="ln-info-card">
          <h3 class="my-3">Datos Básicos</h3>
          <hr />
          <div class="col">
            <strong>Número de Identidad:</strong>
            {{ lawyer.suitability_number }}
          </div>
          <div class="col"><strong>Cédula:</strong> {{ lawyer.dni }}</div>
          <div class="col"><strong>Teléfono:</strong> {{ lawyer.phone }}</div>
          <br />
          <h3 class="my-3">Estudios</h3>
          <hr />
          <div
            class="row"
            *ngFor="let level of lawyer.study_levels; let i = index"
          >
            <div class="col"><strong>Nivel:</strong> {{ level.name }}</div>
            <div class="col">
              <strong>Título obtenido:</strong> {{ level.obtained_title }}
            </div>
            <div class="col">
              <strong>Institución:</strong> {{ level.college }}
            </div>
          </div>
          <br />
          <h3 class="my-3">Dirección</h3>
          <hr />
          <div class="row">
            <div class="col">
              <strong>Provincia:</strong> {{ lawyer.address.province.name }}
            </div>
            <div class="col">
              <strong>Distrito:</strong> {{ lawyer.address.city.name }}
            </div>
            <div class="col">
              <strong>Corregimiento:</strong> {{ lawyer.address.township.name }}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <strong>Dirección:</strong> {{ lawyer.address.address }}
            </div>
          </div>
          <br />
          <h3 class="my-3">Datos Bancarios</h3>
          <hr />
          <div class="row">
            <div class="col">
              <strong>Titular de cuenta:</strong> {{ lawyer.bank?.owner_name }}
            </div>
            <div class="col">
              <strong>Cédula de Identidad:</strong> {{ lawyer.bank?.owner_dni }}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <strong>Entidad Bancaria:</strong>
              {{ lawyer.bank?.bank_data.name }}
            </div>
            <div class="col">
              <strong>Tipo de Cuenta:</strong> {{ lawyer.bank?.account_type }}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <strong>Número de Cuenta:</strong>
              {{ lawyer.bank?.account_number }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--**********************************
    Content body end
***********************************-->
