<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>Finanzas</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <ul ngbNav #nav="ngbNav" [activeId]="active" class="nav-tabs nav nav-pills nav-fill">
            <li [ngbNavItem]="1">
              <a ngbNavLink class="border-sup">Estado de Cuenta</a>
              <ng-template ngbNavContent>
                <app-account-statement></app-account-statement>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink class="border-sup">Facturas</a>
              <ng-template ngbNavContent>
                <app-invoices-marketplace></app-invoices-marketplace>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink class="border-sup">Cotizaciones Aprobadas</a>
              <ng-template ngbNavContent>
                <app-approved-quotes></app-approved-quotes>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</section>
