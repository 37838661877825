import { Component, OnInit } from '@angular/core';
import { ConsultanciesService } from '../../../shared/services/consultancies.service';
import { NgbModal, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { User } from 'src/app/shared/interfaces/user';
import { USER, AVATAR } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-approvals-company',
  templateUrl: './approvals-company.component.html',
  styleUrls: ['./approvals-company.component.css']
})
export class ApprovalsCompanyComponent implements OnInit {

  active = 1;
  consultancies=[];
  user: User;
  pageC; dataPaginationC; 
  modalPay: any;
  modalDetail: any;
  infoDetail:any;
  info:unknown;
  constructor(
    private consultanciesService: ConsultanciesService,
    private modalService: NgbModal,
    titlePageService: TitlePageService,
    private storage: StorageService,
  ) {
    titlePageService.titlePageObservableData = { name: 'Aprobaciones'};
    this.pageC=1;
   }

  ngOnInit(): void {
    this.user = this.storage.getItem( USER );
    this.getConsultancies(this.user.id, this.pageC);
  }

  getConsultancies(id, pageC) {
    this.consultanciesService.getConsultanciesClient(id, pageC).subscribe(response => {
      this.consultancies = response.data;
      console.log(this.consultancies);
      this.dataPaginationC = response.meta.page;
    });

  }

  loadPageC(page) {
    this.consultancies = [];
    this.getConsultancies(this.user.id,page);
  }

  pagar(modal,data){
    // confirmar que los datos estan llenos para abrir el modal
    console.log(data);
    if(data.total>0) {
      this.info = { 
        amount: data.total,
        order: data.id,
        description: data.description
      }

      this.modalPay = this.modalService.open(modal);
    }
    else {
      Swal.fire({
        icon: 'warning',
        text: ' ',
        title: 'El monto debe ser mayor a 0',
      })
    }
    
  }

  closerModalPay(){
    //cerrar el modal y recargar
    this.getConsultancies(this.user.id, this.pageC);
    this.modalPay.dismiss();
  }

  openDetail(modal,item) {
    this.modalDetail = this.modalService.open(modal);
    this.infoDetail=item;
  }


}
