import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { USER } from '../../data/constants';
import { ChatService } from '../../services/chat.service';
import { StorageService } from '../../services/storage.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  id: number;
  formChat: FormGroup;
  dataChat: any;
  user: any;
  condNoChat: boolean = false;
  titlePage: string = 'Mensajería';

  constructor(
    private route: ActivatedRoute,
    private chatService: ChatService,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    titlePageService: TitlePageService
  ) {
    this.formChat = this.formBuilder.group({
      message: ['', [Validators.required]]
    });
    titlePageService.titlePageObservableData = { name: this.titlePage};
  }

  ngOnInit(): void {
    this.user = this.storage.getItem( USER );
    this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.loadData();
    });
  }

  private loadData() {
    this.chatService.getChatHistory(this.id).subscribe(
      res => {
        this.dataChat = res.data;
        this.condNoChat = (this.dataChat.chatMessages.length > 0 ? false : true);
      }
    )
  }

  sendMessage() {
    let params = {
      "chat_id": this.dataChat.id,
      "messages": this.formChat.value.message,
      "view": 0,
      "user_id": this.user.id
    };

    this.chatService.sendMessage(params).subscribe(res => {
      this.formChat.controls.message.setValue('');
      this.loadData();
    });
  }

}

