<div class="content-body d-flex">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>{{titlePage}}</h3>
        <div>
          Crear rama
          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
            (click)="add(branchModal)" placement="top" ngbTooltip="Nueva rama">
            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color:#212F54">
              add_circle_outline
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="branchs.length; else elseTemplate">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Rama</th>
                  <th style="width: 110px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let branch of branchs; let i=index">
                  <td class="text-capitalize">{{branch.name}}</td>
                  <td>
                    <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                      (click)="edit(branchModal, branch)" placement="top" ngbTooltip="Editar">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                        edit
                      </mat-icon>
                    </button><button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                      (click)="delete(branch.id)" placement="top" ngbTooltip="Eliminar">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #dc3545;">
                        delete
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row justify-content-end" *ngIf="dataPagination.lastPage>1">
            <div class="col-auto custom-div-pagination justify-content-center">
              <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
                  [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
                  aria-label="Default pagination"></ngb-pagination>
            </div>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>

<ng-template #branchModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isEdit ? 'Editar' : 'Agregar'}} Rama</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
  </button>
  </div>
  <div class="modal-body">
    <form class="form-valide" action="#" method="post" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
      <div class="form-group">
        <label class="mb-1"><strong>Nombre</strong></label>
        <input type="text" class="form-control" formControlName="name">
        <div *ngIf="submitted && f.name?.errors" class="text-danger fs-13 mt-1">
          <div *ngIf="f.name.errors.required">
            Nombre requerido.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="mb-1"><strong>Comisión</strong></label>
        <input type="number" class="form-control" formControlName="commission">
        <div *ngIf="submitted && f.commission?.errors" class="text-danger fs-13 mt-1">
          <div *ngIf="f.commission.errors.required">
            El campo comisión es requerido.
          </div>
        </div>
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-outline-danger mr-2" (click)="modal.dismiss()">Cancelar</button>
        <button type="submit" class="btn btn-primary">{{isEdit ? 'Editar' : 'Agregar'}}</button>
      </div>
    </form>
  </div>
</ng-template>