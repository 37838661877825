import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

  type = {
    branch: "",
    service_name: "",
    lawyer: "",
    tramit_number: "D-12",
    process_name: "Custodia",
    applicant_name: "Mariana Diaz"
  }

  constructor() { }

  ngOnInit(): void {
  }

}
