import { Component, OnInit } from '@angular/core';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-service-active',
  templateUrl: './service-active.component.html',
  styleUrls: ['./service-active.component.css']
})
export class ServiceActiveComponent implements OnInit {
  Object = [];
  active = 1;

 
  constructor(
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: 'Servicios Activos'};
  }

  ngOnInit(): void {
  }

}
