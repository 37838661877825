import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { DataResponse } from '../interfaces/response';
import { Bank } from '../interfaces/common';
import { Observable } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class BankService {

  constructor(
    private http: HttpService
  ) { }

  /**
   * @description listado de bancos
   * @returns Listado de bancos
   */
  list(): Observable<DataResponse<Bank[]>> {
    return this.http.get( 'banks' );
  }

  /**
   * @description Agrega un nuevo banco a la lista
   * @param data data del banco
   * @returns Nuevo banco
   */
  add( data: any ): Observable<any> {
    return this.http.post( `banks`, data );
  }

  /**
   * @description Actualización de la información del banco
   * @param data Data de banco
   * @param id id del banco a editar
   * @returns Banco actualizado
   */
  edit( data: any, id: number ): Observable<any> {
    return this.http.put( `banks/${id}`, data );
  }

  /**
   * @description Se elimina un banco de la lista
   * @param id id del banco a eliminar
   * @returns Confimación de eliminación del banco
   */
  delete( id: number ): Observable<any> {
    return this.http.delete( `banks/${id}` );
  }
}
