<div class="authincation vh-100">
    <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-md-8">
                <div class="form-input-content text-center error-page">
                    <h1 class="error-text font-weight-bold">404</h1>
                    <h4><i class="fa fa-exclamation-triangle text-warning"></i> ¡No se encuentra la página que buscaba!
                    </h4>
                    <p>Es posible que haya escrito mal la dirección o que la página se haya movido.</p>
                    <div>
                        <a class="btn btn-primary" [routerLink]="['/admin/index']">Volver al inicio</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>