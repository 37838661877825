<div class="content-body d-flex align-items-center" >
    <div class="container-fluid">
        <div class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
               <h3>Aprobaciones</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10">

                <div class="px-3 px-lg-4 border-0">

                    <ul  ngbNav #nav="ngbNav" [activeId]="active" class="nav-tabs nav nav-pills nav-fill">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Cotizaciones</a>
                            <ng-template ngbNavContent>
                                <div class="py-4">



                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>Asesorías</a>
                            <ng-template ngbNavContent>
                                <div class="py-4">

                                    <div *ngIf="consultancies.length > 0">
                                        <div class="card card-tar" *ngFor="let item of consultancies">
                                            <div class="card-body">
                                                <div class="card-text">
                                                    <strong>Solicitud Aprobada:</strong> {{item.service_name}}
                                                </div>
                                                <div class="card-text">
                                                    <strong>Abogado:</strong> {{item.user_name}}
                                                </div>
                                            </div>
                                            <div class="card-footer border-0 pt-0">
                                                <a class="card-link float-right ml-3" (click)="openDetail(detail,item)">
                                                    <i class="icon-list custom-link"></i> Detalle
                                                </a>
                                                <a *ngIf="!item.isPayment" class="card-link float-right" (click)="pagar(payM,item)">
                                                    <i class="las la-cash-register custom-link"></i> Pagar
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-12 justify-content-center">
                                            <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
                                                [collectionSize]="dataPaginationC?.total" [maxSize]="5" [(page)]="pageC"
                                                aria-label="Default pagination"></ngb-pagination>
                                        </div>
                                    </div>
                                    <div *ngIf="consultancies.length === 0" class="  custom-no-item">
                                        <h5> No cuentas con servicios activos</h5>
                                    </div>

                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #detail>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Detalle de la Asesoria</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalDetail.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p class="mb-2"><strong>Nombre Servicio: </strong>{{infoDetail?.service_name}}</p>
        <p class="mb-2"><strong>Fecha de la Solicitud: </strong>{{infoDetail?.created_at}}</p>
        <p class="mb-2"><strong>Tipo de Asesoria: </strong>{{infoDetail?.consulting_type}}</p>
        <p class="mb-2"><strong>Abogado Asesor: </strong>{{infoDetail?.lawyer_name}}</p>
        <p class="mb-2"><strong>Descripción: </strong>{{infoDetail?.description}}</p>
        <p class="mb-2"><strong>Precio: </strong>{{infoDetail?.total}}</p>
    </div>
</ng-template>

<ng-template #payM>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Métodos de Pagos</h4>
      <button type="button" class="close" aria-label="Close" (click)="closerModalPay()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <app-payment-method [data]="info"></app-payment-method>
</ng-template>
