<div class="content-body d-flex align-items-center">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-xl-12 col-sm-12 text-center">
        <h3>Mensajería </h3>
      </div>
    </div>
    <div class="row justify-content-start">
      <ng-container *ngIf="list.length; else noData">
        <div class="col-12 mb-2" *ngFor="let data of list">
          <div class="card mb-2 p-3">
            <div class="row">
              <div class="col p-3">
                <div class="content-document mb-4">
                  <label for="" class="font-weight-bold">Nombre del Trámite:</label>
                  <span class="ml-2">{{ data.service.name }}</span>
                </div>
                <div class="content-lawyer" *ngIf="typeRol==='person'">
                  <label for="" class="font-weight-bold mb-0">Abogado Asesor: </label>
                  <span class="ml-2">{{ data.lawyer.user.full_name }}</span>
                  <p><small>{{ data.created_at }}</small></p> 
                </div>
                <div class="content-lawyer" *ngIf="typeRol!=='person'">
                  <label for="" class="font-weight-bold mb-0">Cliente: </label>
                  <span class="ml-2"> {{ data.userApplicant.full_name }} </span>
                  <p><small>{{ data.created_at }}</small></p> 
                </div>
              </div>
              <div class="col-auto">
                <div *ngIf="typeRol==='person'">
                  <a class="btn btn-link text-center p-0" [routerLink]="['/person/chat', data.id]">
                    <div class="avatar">
                      <img [src]="data.lawyer?.main_image" class="ln-avatar" [alt]="data.name">
                    </div>
                    Iniciar Chat
                  </a>
                </div>
                <div *ngIf="typeRol!=='person'">
                  <a class="btn btn-link text-center p-0" [routerLink]="['/lawyer/chat', data.id]">
                    <div class="avatar">
                      <img [src]="data.userPicture" class="ln-avatar" [alt]="data.name">
                    </div>
                    Iniciar Chat
                  </a>
                </div>

              </div>
              <div [ngClass]="{'pulse-css':hasMessages(data)}"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 custom-div-pagination justify-content-center">
            <ngb-pagination [pageSize]="12" (pageChange)="loadData($event)" [collectionSize]="dataPagination?.total"
              [maxSize]="5" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
          </div>
        </div>
      </ng-container>
      <ng-template #noData>
        <div class="alert alert-info text-center my-4" role="alert">
          No hay registros disponibles
        </div>
      </ng-template>
    </div>
  </div>
</div>