<div class="container-fluid form-container">
  <form
    class="form-valide"
    action="#"
    method="post"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    novalidate
  >
    <div class="col-12 flex-column text-center p-0">
      <img [src]="avatar" alt="" srcset="" class="ln-avatar" />
      <div class="form-group text-left">
        <label class="mb-1"><strong>Foto</strong></label>
        <div class="input-group">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              (change)="onImageChange($event)"
              accept="image/*"
            />
            <label class="custom-file-label">{{ fileName }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Tipo de Usuario</strong></label>
          <input type="text" class="form-control" [value]="userRoleName" disabled />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Razón Social</strong></label>
          <input
            type="text"
            class="form-control"
            formControlName="business_name"
          />
          <div
            *ngIf="isSubmitted && controls.business_name?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.business_name.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Razón Comercial</strong></label>
          <input
            type="text"
            class="form-control"
            formControlName="business_reason"
          />
          <div
            *ngIf="isSubmitted && controls.business_reason?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.business_reason.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="mb-1"
            ><strong>Registro Único de Contribuyente</strong></label
          >
          <input type="text" class="form-control" formControlName="taxpayer" />
          <div
            *ngIf="isSubmitted && controls.taxpayer?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.taxpayer.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Aviso de operaciones</strong></label>
          <input
            type="text"
            class="form-control"
            formControlName="operations_notice"
          />
          <div
            *ngIf="isSubmitted && controls.operations_notice?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.operations_notice.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for=""><strong>País Constitución</strong></label>
          <select
            class="form-control"
            [(ngModel)]="CountrySelected" [ngModelOptions]="{standalone: true}"
            (change)="
              onChange($event.target.value, 'country_of_constitution_id')
            "
          >
            <option disabled selected>Seleccione una opción</option>
            <option [value]="country.id" *ngFor="let country of countries">
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Fecha de Constitución</strong></label>
          <div class="input-group">
            <input
              class="form-control"
              ngbDatepicker
              #di="ngbDatepicker"
              [(ngModel)]="constitutionDate"
              [ngModelOptions]="{ standalone: true }"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="di.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>País de Operaciones</strong></label>
            <select
              class="form-control"
              name=""
              id=""
              formControlName="operation_countries"
              multiple="true"
            >
              <option
                [value]="country.id"
                *ngFor="let country of countries; let i = index"
              >
                {{ country.name }}
              </option>
            </select>
            <div
              *ngIf="isSubmitted && controls.operation_countries?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="controls.operation_countries.errors.required">
                Campo requerido.
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Representante legal</strong></label>
            <input type="text" class="form-control" formControlName="legal_agent" />
            <div
              *ngIf="isSubmitted && controls.legal_agent?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="controls.legal_agent.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Persona Autorizada</strong></label>
            <input type="text" class="form-control" formControlName="name" >
            <div
              *ngIf="isSubmitted && controls.name?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="controls.name.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Cédula de Identidad</strong></label>
            <input type="number" class="form-control" formControlName="dni" />
            <div
              *ngIf="isSubmitted && controls.dni?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="controls.dni.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Teléfono</strong></label>
            <input type="number" class="form-control" formControlName="phone" />
            <div
              *ngIf="isSubmitted && controls.phone?.errors"
              class="text-danger fs-13 mt-1"
            >
              <div *ngIf="controls.phone.errors.required">Campo requerido.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row align-items-end">
      <div class="col">
        <div class="form-group">
          <label for=""><strong>Provincia</strong></label>
          <select
            class="form-control"
            (change)="onChange($event.target.value, 'province')"
            [(ngModel)]="ProvinceSelected" [ngModelOptions]="{standalone: true}"
          >
            <option disabled selected value="0">Seleccione una opción</option>
            <option [value]="province.id" *ngFor="let province of provinces">
              {{ province.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for=""><strong>Distrito</strong></label>
          <select
            class="form-control"
            [(ngModel)]="CitySelected" [ngModelOptions]="{standalone: true}"
            (change)="onChange($event.target.value, 'city')"
          >
            <option disabled selected value="0">Seleccione una opción</option>
            <option [value]="city.id" *ngFor="let city of cities">
              {{ city.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for=""><strong>Corregimiento</strong></label>
          <select
            class="form-control"
            name="township"
            id="township"
            [(ngModel)]="TownshipSelected" [ngModelOptions]="{standalone: true}"
            (change)="onChange($event.target.value, 'township')"
          >
            <option disabled selected value="0">Seleccione una opción</option>
            <option [value]="township.id" *ngFor="let township of townships">
              {{ township.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-group">
          <a class="btn btn-primary btn-refresh"  (click)="refreshAddress()" placement="top" ngbTooltip="refrescar">
            <mat-icon aria-hidden="false">
              refresh
            </mat-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Dirección</strong></label>
          <input type="text" class="form-control" formControlName="address" />
          <div
            *ngIf="isSubmitted && controls.address?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.address.errors.required">Campo requerido.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-4">
      <button type="submit" class="btn btn-primary btn-block">
        Actualizar Perfil
      </button>
    </div>
  </form>
</div>
