<!-- <app-loading></app-loading> -->

<div class="authincation h-100">
	<div class="container vh-100">
		<div class="row justify-content-center h-100 align-items-center">
			<div class="col-lg-6 col-md-8">
				<div class="authincation-content">
					<div class="row no-gutters">
						<div class="col-xl-12">
							<div class="auth-form">
								<div class="text-center mb-3">
									<a [routerLink]="['/admin/index']">
										<img [src]="logo" alt="" class="w-25">
									</a>
								</div>
								<h4 class="text-center mb-4"><!--Inicio de sesión--> {{isAdmin ? 'administrador':''}}</h4>
								<form class="form-valide" action="#" method="post" [formGroup]="form" (ngSubmit)="onSubmit()"
									novalidate>
									<div class="form-group" [hidden]="isAdmin">
										<label class="mb-1"><strong>Perfil Inicio</strong></label>
										<select class="form-control" (change)="fisLawyer($event.target.value)" name="" id="" formControlName="loginType">
											<option value="lawyer">Abogado</option>
											<option value="person">Persona natural</option>
											<option value="company">Persona jurídica</option>
										</select>
									</div>
									<div class="form-group" [hidden]="!isLawyer">
										<label class="mb-1"><strong>Producto</strong></label>
										<select class="form-control" (change)="setProduct($event.target.value)" formControlName="LawyerProduct">
											<option value="0" selected>Seleccione</option>
											<option *ngFor="let product of products" value="{{product.id}}">{{product.name}}</option>
										</select>
									</div>
									<div class="form-group">
										<label class="mb-1"><strong>Email</strong></label>
										<input type="email" class="form-control" formControlName="email">
										<div *ngIf="submitted && f.email?.errors" class="text-danger fs-13 mt-1">
											<div *ngIf="f.email.errors.required">
												Email requerido.
											</div>
											<div *ngIf="f.email.errors.email">
												Email inválido
											</div>
										</div>
									</div>
									<div class="form-group mb-4">
										<label class="mb-1"><strong>Contraseña</strong></label>
										<!-- <input type="password" class="form-control" formControlName="password"> -->
										<div class="group-btn-left">
											<input class="form-control" [type]="typePass" formControlName="password">

											<button class="btn btn-link btn-visible" (click)="onChangePass()" type="button">
												<mat-icon aria-hidden="false">
													{{iconPass}}
												  </mat-icon>
											  </button>
											
										</div>
										<div *ngIf="submitted && f.password?.errors" class="text-danger fs-13 mt-1">
											<div *ngIf="f.password.errors.required">
												Contraseña requerida.
											</div>
											<div *ngIf="f.password.errors.minlength">
												Contraseña debe ser de mínimo 8 caracteres.
											</div>
										</div>
										<div class="new-account  mt-1" [hidden]="isAdmin">
											<p><small>	¿Olvidó su contraseña? <a class="text-primary" [routerLink]="['/forgot-password']">Restablecer</a></small>
											</p>
										</div>
									</div>
									<div class="text-center">
										<button type="submit" class="btn btn-primary btn-block">Iniciar sesión</button>
									</div>
								</form>

								<div class="new-account text-center mt-3" [hidden]="isAdmin">
									<p>¿No tienes cuenta? <a class="text-primary" [routerLink]="['/register']">Regístrate</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
