import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { DataResponse } from '../interfaces/response';
import { BranchesLaw, Promotion, Requirement, Service } from '../interfaces/common';

@Injectable( {
  providedIn: 'root'
} )
export class AdminService {

  constructor(
    private http: HttpService
  ) { }

  listRequirement(): Observable<DataResponse<Requirement[]>> {
    return this.http.get( `requeriments` );
  }

  listRequirementPage(page: number): Observable<DataResponse<Requirement[]>> {
    return this.http.get( `requeriments?page=${page}` );
  }

  addRequirement( data: any ): Observable<any> {
    return this.http.post( `requeriments`, data );
  }

  updateRequirement( data: any, id: number ): Observable<any> {
    return this.http.put( `requeriments/${id}`, data );
  }

  deleteRequirement( id: number ): Observable<any> {
    return this.http.delete( `requeriments/${id}` );
  }

  listBranchs(): Observable<DataResponse<BranchesLaw[]>> {
    return this.http.get( 'branch-laws' );
  }

  listBranchsPage(page: number): Observable<DataResponse<BranchesLaw[]>> {
    return this.http.get( `branch-laws?page=${page}` );
  }

  addBranchs( data: any ): Observable<DataResponse<BranchesLaw>> {
    return this.http.post( `branch-laws`, data );
  }

  updateBranchs( data: any, id: number ): Observable<DataResponse<BranchesLaw>> {
    return this.http.put( `branch-laws/${id}`, data );
  }


  updatePayments(  id: number , data: any ): Observable<DataResponse<any>> {
    return this.http.put( `order-payments/${id}`, data );
  }


  getPaymentsForApprove(page = 1, params = '', pdf = false) {
    if (pdf == true) {
    return this.http.get( `order-payments?includes[]=order&includes[]=paymentMethod&status=Pendiente&is_pdf_report=true${params}`, {responseType: 'blob' as 'json'}  );
    } else {
      return this.http.get( `order-payments?includes[]=order&status=Pendiente&includes[]=paymentMethod&page=${page}${params}` );
    }
  }


  deleteBranchs( id: number ): Observable<DataResponse<BranchesLaw>> {
    return this.http.delete( `branch-laws/${id}` );
  }

  listService(): Observable<DataResponse<Service[]>> {
    return this.http.get( `type-services` );
  }

  listServiceSetting(): Observable<DataResponse<Service[]>> {
    return this.http.get( `services` );
  }

  listServiceTotal(page: number): Observable<DataResponse<Service[]>> {
    return this.http.get( `services?page=${page}` );
  }

  listOrderStatuses(): Observable<DataResponse<Service[]>> {
    return this.http.get( `order-statuses` );
  }

  listPromotion(page: Promotion): Observable<DataResponse<Promotion[]>> {
    return this.http.get( `promotion` );
  }
  addPromotion( data: any  , img:File): Observable<DataResponse<Promotion>> {

    const fd= new FormData;
    console.log(data)
    fd.append('image_plan',img,img.name);
    fd.append('name',data.name);
    fd.append('description',data.description);
    fd.append('amount',data.amount);
    fd.append('ITBMS',data.ITBMS);

    return this.http.post( `promotion`, fd );
  }

  updatePromotion( data: any,  img:File ,  id: number ): Observable<DataResponse<Promotion>> {

    const fd= new FormData;

    fd.append('image_plan',img,img.name);
    fd.append('name',data.name);
    fd.append('description',data.description);
    fd.append('amount',data.amount);
    fd.append('ITBMS',data.ITBMS);
    fd.append('_method' , 'PUT');


    return this.http.post( `promotion/${id}`, fd );
  }

  deletePromotion( id: number ): Observable<DataResponse<Promotion>> {
    return this.http.delete( `promotion/${id}` );
  }

  addService( data: any ): Observable<DataResponse<Service>> {
    return this.http.post( `services`, data );
  }

  updateService( data: any, id: number ): Observable<DataResponse<Service>> {
    return this.http.put( `services/${id}`, data );
  }

  deleteService( id: number ): Observable<DataResponse<Service>> {
    return this.http.delete( `services/${id}` );
  }

  listUser(filter){
    return this.http.get( `users?includes[]=roles`+filter );
  }

  addUser(data){
    return this.http.post( `users`,data );
  }

  editUser(user,data){
    return this.http.put( `users/${user}`,data );
  }

  deleteUser(user){
    return this.http.delete( `users/${user}` );
  }

  getLawyersReport(page = 1, params = '', pdf = false) : Observable<any>{
    if (pdf == true) {
      return this.http.get( `lawyers?includes[]=user&includes[]=branchLaws&is_pdf_report=true${params}`, {responseType: 'blob' as 'json'} );
    } else {
      return this.http.get( `lawyers?includes[]=user&includes[]=branchLaws&includes[]=plan&page=${page}${params}` );
    }
  }

  getClientsReport(page = 1, params = '', pdf = false) : Observable<any> {
    if (pdf == true) {
      return this.http.get( `users/client-report?roles[]=person&roles[]=company&is_pdf_report=true${params}`, {responseType: 'blob' as 'json'} );
    } else {
      return this.http.get( `users/client-report?roles[]=person&roles[]=company&page=${page}${params}` );
    }
  }

  getAccountPayable(page = 1, params = '', pdf = false) : Observable<any> {
    if (pdf == true) {
      return this.http.get( `orders/admin-account-payable?is_pdf_report=true${params}`, {responseType: 'blob' as 'json'} );
    } else {
      return this.http.get( `orders/admin-account-payable?page=${page}${params}` );
    }
  }

  getAccountBankLawyer(id) {
    return this.http.get( `lawyers/${id}?includes[]=bank.bankData` );
  }

  getAccountPayableByLawyer(id, download = null) : Observable<any> {
    if (download == 'pdf') {
      return this.http.get( `orders/admin-account-payable-lawyer?page=1&includes[]=lawyer&includes[]=service&includes[]=orderPayments&includes[]=lawyerService&lawyer_id=${id}&is_pdf_report=true`, {responseType: 'blob' as 'json'} );
    } else if (download == 'excel') {
      return this.http.get( `orders/admin-account-payable-lawyer?page=1&includes[]=lawyer&includes[]=service&includes[]=orderPayments&includes[]=lawyerService&lawyer_id=${id}&is_excel_report=true`, {responseType: 'blob' as 'json'} );
    }
    else {
      return this.http.get( `orders/admin-account-payable-lawyer?page=1&includes[]=lawyer&includes[]=service&includes[]=orderPayments&includes[]=lawyerService&lawyer_id=${id}` );
    }
  }

  getLawyer(id: number) {
    return this.http.get( `lawyers/${id}?includes[]=user&includes[]=studyLevels&includes[]=branchLaws&includes[]=comments` );
  }

  getServiceMonitoring(page = 1, params = '', pdf = false) {
    if (pdf == true) {
      return this.http.get( `orders/admin-monitoring?includes[]=lawyer&includes[]=lawyerService&includes[]=status&includes[]=service.typeService&is_pdf_report=true${params}`, {responseType: 'blob' as 'json'});
    } else {
      return this.http.get( `orders/admin-monitoring?includes[]=lawyer&includes[]=lawyerService&includes[]=status&includes[]=service.typeService&page=${page}${params}` );
    }
  }

  disableLawyer(id) : Observable<any> {
    return this.http.put( `lawyers/${id}?available=disable` );
  }

  getFinancialState(page = 1, params = '', pdf = false) : Observable<any>{
    if (pdf == true) {
      return this.http.get( `orders/admin-statement-acount?includes[]=lawyer&includes[]=service&includes[]=orderPayments&includes[]=lawyerService&is_pdf_report=true${params}`, {responseType: 'blob' as 'json'} );
    } else {
      return this.http.get( `orders/admin-statement-acount?includes[]=lawyer&includes[]=service&includes[]=orderPayments&includes[]=lawyerService&page=${page}${params}` );
    }
  }

  getLawyers(page = 1, params = '') : Observable<any>{
    return this.http.get( `lawyers?includes[]=plan&includes[]=products&includes[]=user&includes[]=orderLawyers&includes[]=promotion&page=${page}${params}` );
  }

  /*---- Nuevos métodos ----*/
  listPlans(): Observable<any> {
    return this.http.get( `plan` );
  }

  changePlan(id, data: any): Observable<any> {
    return this.http.put(`lawyers/change-plan/${id}`, data);
  }
}
