import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import { Bank } from '../../../shared/interfaces/common';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BankService } from '../../../shared/services/bank.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component( {
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: [ './banks.component.css' ]
} )
export class BanksComponent implements OnInit {
  banks: Bank[] = [];
  bank: Bank;
  modal: any;
  isEdit = false;
  titlePage='Entidades Bancarias';
  constructor(
    private banksService: BankService,
    private modalServce: NgbModal,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
    this.loadData();
  }

  ngOnInit(): void {
  }

  add( bankModal: any ): void {
    this.isEdit = false;
    this.bank.name = '';
    this.modal = this.modalServce.open( bankModal );
    this.modal.result.then( () => {
      this.addBank( this.bank );
    } );
  }

  openEditModal( bankModal: any, bank: Bank ): void {
    this.isEdit = true;
    this.bank = { ...bank };
    this.modal = this.modalServce.open( bankModal );
    this.modal.result.then( () => {
      this.edit( this.bank );
    } );
  }

  delete( id: number ): void {
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea eliminar esta entidad bancaria?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, eliminar!'
    } ).then( ( result ) => {
      if ( result.isConfirmed ) { this.deleteBank( id ); }
    } );
  }

  private addBank( data: any ): void {
    this.banksService.add( data ).subscribe( response => {
      Swal.fire( { text: 'Banco agregado', icon: 'success' } );
    } );
  }

  private deleteBank( id: number ): void {
    this.banksService.delete( id ).subscribe( response => {
      Swal.fire( { text: 'Banco eliminado', icon: 'success' } );
    } );
  }

  private edit( bank: Bank ): void {
    this.banksService.edit( this.bank, this.bank.id ).subscribe( response => {
      Swal.fire( { text: 'Banco actualizado', icon: 'success' } );
    } );
  }

  private loadData(): void {
    this.banksService.list().subscribe( res => this.banks = [ ...res.data ] );
  }


}
