import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LawyerService } from '../../shared/services/lawyer.service';
import Swal from 'sweetalert2';
import { Lawyer } from 'src/app/shared/interfaces/user';
import { CommonService } from '../../shared/services/common.service';
import { AVATAR } from 'src/app/shared/data/constants';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component( {
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: [ './approvals.component.css' ]
} )
export class ApprovalsComponent implements OnInit {

  lawyers: any[] = [];
  comment = '';
  modal: any;
  lawyer: Lawyer;
  titlePage='Aprobaciones';
  page; dataPagination;

  constructor(
    private modalService: NgbModal,
    private lawyerService: LawyerService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
    this.page=1;
    this.loadData();
  }

  ngOnInit(): void {
  }

  openModalReject( content: any, lawyer: Lawyer ): void {
    this.modal = this.modalService.open( content, { backdrop: 'static' } );
    this.modal.result.then( () => {
      Swal.fire( {
        title: 'Aviso',
        text: `¿Seguro desea rechazar al abogado ${lawyer.user.name} ${lawyer.user.last_name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#007bff',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Si, rechazar!'
      } ).then( ( result ) => {
        if ( result.isConfirmed ) { this.toPassLawyer( false, lawyer.id ); }
      } );
    } );
  }

  openModalLawyer( lawyerModal: any, item: any ): void {
    if(item.main_image === null){
      item.main_image = AVATAR;
    }
    this.lawyer = { ...item };
    this.modal = this.modalService.open( lawyerModal, { size: 'lg' } );
  }

  approved( lawyer: Lawyer ): void {
    Swal.fire( {
      title: 'Aviso',
      text: `¿Seguro desea aprobar al abogado ${lawyer.user.name} ${lawyer.user.last_name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, aprobar!',
      cancelButtonText: 'Cancelar'
    } ).then( ( result ) => {
      if ( result.isConfirmed ) { this.toPassLawyer( true, lawyer.id ); }
    } );

  }

  /**
   *
   * @description Aprobación/Rechazo de abogado
   * @param approved true si aprueba, false si rechaza
   * @param id Id del abogado
   */
  toPassLawyer( approved: boolean, id: number ): void {
    let message = '¡El abodago ha sido rechazado con exito!';
    if ( approved ) { message = '¡El abodago ha sido aprobado con exito!'; }
    const data = {
      approved_by_admin: approved,
      reason_for_rejection: this.comment
    };
    this.lawyerService.toPass( data, id ).subscribe( () => {
      Swal.fire( { text: message, icon: 'success' } );
      this.loadData();
    } );
  }

  private loadData(): void {
    this.lawyerService.toBeApproved(this.page).subscribe( response => {
      this.lawyers = [ ...response.data ] 
      this.dataPagination = response.meta.page;
    });
  }

  loadPage(page) {
    this.page = page;
    this.loadData();
  }

}
