<div>
  <button (click)="editTask()" *ngIf="task.status != 'Completado'" mat-icon-button color="accent">
    <mat-icon>edit</mat-icon>
  </button>
</div>

<ng-template #editModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cambiar estatus</h4>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="mb-1" for="item-{{ i }}"><strong>Estado</strong></label>
      <select
        class="form-control"
        id="item-{{ i }}"
        [(ngModel)]="status"
        required
      >
        <option selected disabled value="0">Seleccione un estado</option>
        <option [value]="status" *ngFor="let status of statuses; let i = index">
          {{ status }}
        </option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="update()"
      cdkFocusInitial
    >
      Guardar
    </button>
  </div>
</ng-template>
