<div class="container-fluid form-container"></div>
<div class="content-body d-flex custom-top-list">
  <div class="container-fluid">
    <div class="card">
      <div class="p-4">
        <div class="d-flex justify-content-between ml-1">
          <h3>Listado de casos</h3>
          <div>
            Crear caso
            <button
              mat-icon-button
              color="accent"
              aria-label="Example icon button with a menu icon"
              (click)="add(entityModal)"
              placement="top"
              ngbTooltip="Nueva entidad"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
                style="color: #212f54"
              >
                add_circle_outline
              </mat-icon>
            </button>
          </div>
        </div>
        <hr />

        <div class="">
          <div class="search mx-2 d-flex align-items-center flex-row">
            <div class="row">
              <!-- Primera columna -->
              <div class="col-md-4">
                <label for="caseName">Nombre del caso</label>
                <input
                  type="text"
                  class="form-control"
                  id="caseName"
                  placeholder=""
                  aria-label="Nombre del caso"
                  [(ngModel)]="filters.caseName"
                  (ngModelChange)="onFilterCaseChange($event, 'caseName')"
                  aria-describedby="basic-addon1"
                />
              </div>

              <div class="col-md-4">
                <label for="caseName">Nombre del cliente</label>
                <input
                  type="text"
                  class="form-control"
                  id="clientName"
                  placeholder=""
                  aria-label="Nombre del caso"
                  [(ngModel)]="filters.clientName"
                  (ngModelChange)="onFilterCaseChange($event, 'clientName')"
                  aria-describedby="basic-addon1"
                />
              </div>

              <!-- Segunda columna -->
              <div class="col-md-4">
                <div class="filter-row">
                  <label for="statusFilter">Estatus:</label>
                  <select
                    class="form-control mx-1 shadow-none"
                    id="statusFilter"
                    [(ngModel)]="filters.statusFilter"
                    name="statusFilter"
                    aria-label="Estatus"
                    (ngModelChange)="onFilterCaseChange($event, 'statusFilter')"
                    aria-describedby="basic-addon2"
                  >
                    <option value="" default>Todos</option>
                    <option *ngFor="let status of statuses" [value]="status.id">
                      {{ status.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Segunda columna -->
              <div class="col-md-4">
                <div class="filter-row">
                  <label for="typeFilter">Tipo de casos:</label>
                  <select
                    class="form-control shadow-none"
                    id="typeFilter"
                    [(ngModel)]="filters.typeFilter"
                    name="typeFilter"
                    aria-label="Tipo de casos"
                    (ngModelChange)="onFilterCaseChange($event, 'typeFilter')"
                    aria-describedby="basic-addon2"
                  >
                    <option value="" default>Todos</option>
                    <option value="Trámite">
                      Trámite
                    </option>
                    <option value="Proceso">
                      Proceso
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-4">
                <div class="filter-row">
                  <label for="branchFilter">Rama del Derecho:</label>
                  <select
                    class="form-control shadow-none"
                    id="branchFilter"
                    [(ngModel)]="filters.branchFilter"
                    name="branchFilter"
                    aria-label="Rama"
                    (ngModelChange)="onFilterCaseChange($event, 'branchFilter')"
                    aria-describedby="basic-addon2"
                  >
                    <option value="" default>Todos</option>
                    <option *ngFor="let branch of branchLaw" [value]="branch.id">
                      {{ branch.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Tercera columna -->
              <div class="col-md-4">
                <label class="mb-1">Desde</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    name="from"
                    ngbDatepicker
                    #fromDate="ngbDatepicker"
                    [(ngModel)]="filtersDate.fromData"
                    name="fromData"
                    (ngModelChange)="onFilterCaseChange($event, 'fromData')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="fromDate.toggle()"
                      type="button"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label class="mb-1">Hasta</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    name="from"
                    ngbDatepicker
                    #toDate="ngbDatepicker"
                    [(ngModel)]="filtersDate.toDate"
                    name="toDate"
                    (ngModelChange)="onFilterCaseChange($event, 'toDate')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="toDate.toggle()"
                      type="button"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="cases.length; else nodata">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Nro de caso</th>
                  <th scope="col">Fecha de creación</th>
                  <th scope="col">Nombre de caso</th>
                  <th scope="col">Cliente</th>

                  <th style="width: 100px"></th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let case of cases; let i = index">
                  <td class="text-capitalize">{{ case.id }}</td>
                  <td>{{ case.creation_date }}</td>
                  <td>{{ case.case_name }}</td>
                  <td>{{ case.client.client_name }}</td>
                  <td>

					<button
					   #botonConAttrCaseId
					   [attr.case-id]="case.id"
					   attr-button-type="case-edit"
					   mat-icon-button
					   color="accent"
					   aria-label="Example icon button with a menu icon"
					   (click)="showCase(entityModal, case)"
					   placement="top"
					   ngbTooltip="Editar"
					>
					   <mat-icon
						 aria-hidden="false"
						 aria-label="Example home icon"
						 style="color: #212f54"
					   >
						 edit
					   </mat-icon>
					</button>

					<button
                      mat-icon-button
                      color="accent"
                      aria-label="Example icon button with a menu icon"
                      (click)="alertDelete(case.id)"
                      placement="top"
                      ngbTooltip="Eliminar"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        style="color: #dc3545"
                      >
                        delete
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-template #nodata>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #entityModal>

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ isEdit ? "Editar" : "Agregar" }} caso
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="closed()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- (ngSubmit)="onSubmit()"  -->
      <form
        class="form-valide"
        action="#"
        method="post"
        [formGroup]="docsForm"
        enctype="multipart/form-data"
        id="form"
        novalidate
      >
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>General</a>
            <ng-template ngbNavContent>
              <div>
                <div class="form-row">
                  <div class="col-6">
                    <label for=""><strong>Tipo de caso</strong></label>
                    <select
                      class="form-control"
                      formControlName="type"
                      name="type"
                      (change)="onChange($event.target.value, 'typeCase')"
                    >
                      <option disabled selected>Seleccione una opción</option>
                      <option
                        [value]="type.typeString"
                        *ngFor="let type of typeCase"
                      >
                        {{ type.typeString }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && f.type?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.type.errors.required">Campo requerido.</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for=""><strong>Entidad</strong></label>
                    <select
                      class="form-control"
                      formControlName="lawyer_entity_id"
                      name="lawyer_entity_id"
                      (change)="
                        onChangeEntitie($event.target.value.id, 'entitie')
                      "
                    >
                      <option disabled selected>Seleccione una opción</option>
                      <option
                        [value]="entitie.id"
                        *ngFor="let entitie of entities"
                      >
                        {{ entitie.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && f.lawyer_entity_id?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.lawyer_entity_id.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <label class="mb-1"><strong>Rama del Derecho</strong></label>

                  <select
                    class="form-control"
                    formControlName="branch"
                    name="branch"
                  >
                    <option disabled selected>Seleccione una opción</option>
                    <option
                      [value]="item.id"
                      *ngFor="let item of dropdownList"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>



                <div class="form-row">
                  <div class="col-6" *ngIf="extraFields">
                    <label class="mb-1 custom-line-label"
                      ><strong>Tribunal</strong></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="court"
                      formControlName="court"
                    />
                    <div
                      *ngIf="submitted && f.court?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.court.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>

                  <div class="col-6" *ngIf="extraFields">
                    <label class="mb-1 custom-line-label"
                      ><strong>Juez</strong></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="judge"
                      formControlName="judge"
                    />
                    <div
                      *ngIf="submitted && f.judge?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.judge.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>

                  <div class="col-6" *ngIf="extraFields">
                    <label class="mb-1 custom-line-label"
                      ><strong>Fiscal</strong></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="fiscal"
                      formControlName="fiscal"
                    />
                    <div
                      *ngIf="submitted && f.fiscal?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.fiscal.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>

                  <div class="col-6" *ngIf="extraFields">
                    <label class="mb-1 custom-line-label"
                      ><strong>Jurisdicción</strong></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="jurisdiction"
                      formControlName="jurisdiction"
                    />
                    <div
                      *ngIf="submitted && f.jurisdiction?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.jurisdiction.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>

                  <div  formArrayName="contraparte" class="col-6" *ngIf="extraFields">
                    <label class="mb-1 custom-line-label d-flex align-items-center">
                      <strong>Contraparte(s)</strong>
                      <button type="button" mat-icon-button color="accent" (click)="addContraparte()" placement="top" ngbTooltip="Agregar contraparte">
                        <mat-icon aria-hidden="false" style="color: #212f54">
                          add_circle_outline
                        </mat-icon>
                      </button>
                    </label>
                    <div>
                      <div class="mb-2 d-flex align-items-center" *ngFor="let contraparte of contrapartes.controls; let i=index">
                        <!-- The repeated alias template -->
                        <!-- <label for="alias-{{ i }}">Alias:</label> -->
                        <input id="contraparte-{{ i }}" class="form-control" type="text" [formControlName]="i">
                        <button type="button" mat-icon-button (click)="removeContraparte(i)" placement="top" ngbTooltip="Eliminar fase">
                          <mat-icon aria-hidden="false" style="color: #dc3545">
                            remove_circle_outline
                          </mat-icon>
                        </button>
                      </div>
                    </div>
                    <!-- <input
                      type="text"
                      class="form-control"
                      name="contraparte"
                      formControlName="contraparte"
                    /> -->
                    <div
                      *ngIf="submitted && f.contraparte?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.contraparte.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>

                  <div  formArrayName="apoderados" class="col-6" *ngIf="extraFields">
                    <label class="mb-1 custom-line-label d-flex align-items-center">
                      <strong>Apoderado(s)</strong>
                      <button type="button" mat-icon-button color="accent" (click)="addApoderado()" placement="top" ngbTooltip="Agregar apoderado">
                        <mat-icon aria-hidden="false" style="color: #212f54">
                          add_circle_outline
                        </mat-icon>
                      </button>
                    </label>
                    <div class="ml-4">
                      <div class="mb-2 d-flex align-items-center" *ngFor="let apoderado of apoderados.controls; let i=index">
                        <!-- The repeated alias template -->
                        <!-- <label for="apoderado-{{ i }}">Apoderado:</label> -->
                        <input class="form-control" id="apoderado-{{ i }}" type="text" [formControlName]="i">
                        <button type="button" mat-icon-button (click)="removeApoderado(i)" placement="top" ngbTooltip="Eliminar fase">
                          <mat-icon aria-hidden="false" style="color: #dc3545">
                            remove_circle_outline
                          </mat-icon>
                        </button>
                      </div>
                    </div>
                    <!-- <input
                      type="text"
                      class="form-control"
                      name="contraparte"
                      formControlName="contraparte"
                    /> -->
                    <div
                      *ngIf="submitted && f.contraparte?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.contraparte.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <label class="mb-1 custom-line-label"
                      ><strong>{{ docsForm.value.type == 'Proceso' ? 'Número de Expediente' : 'Número de trámite (entidad)'}}</strong></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="fileNumber"
                      formControlName="fileNumber"
                    />
                    <div
                      *ngIf="submitted && f.fileNumber?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.fileNumber.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-6">
                    <label class="mb-1 custom-line-label"
                      ><strong>{{ docsForm.value.type == 'Proceso' ? 'Nombre del servicio' : 'Nombre del caso'}}</strong></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      formControlName="name"
                    />
                    <div
                      *ngIf="submitted && f.name?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.name.errors.required">Campo requerido.</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="mb-1 custom-line-label"
                      ><strong>Descripción</strong></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="description"
                      formControlName="description"
                    />
                    <div
                      *ngIf="submitted && f.description?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.description.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="form-row">
                  <div class="col-6">
                    <label class="mb-1 custom-line-label"
                      ><strong>Nombre del cliente</strong></label
                    >
                    <select
                      class="form-control"
                      formControlName="lawyer_client_id"
                      name="lawyer_client_id"
                      (change)="onChange($event.target.value, 'status')"
                    >
                      <option disabled selected>Seleccione una opción</option>
                      <option
                        [value]="client.id"
                        *ngFor="let client of clients"
                      >
                        {{ client.client_name }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && f.lawyer_client_id?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.lawyer_client_id.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <label for=""><strong>{{ docsForm.value.type == 'Proceso' ? 'Número de proceso' : 'Número de trámite (interno)'}}</strong><strong></strong></label>
                    <input
                      type="text"
                      name="assigned_number"
                      class="form-control"
                      formControlName="assigned_number"
                    />
                    <div
                      *ngIf="submitted && f.assigned_number?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.assigned_number.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="mb-1"><strong>Fecha de solicitud</strong></label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        name="init_date"
                        ngbDatepicker
                        #di="ngbDatepicker"
                        formControlName="init_date"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="di.toggle()"
                          type="button"
                        ></button>
                      </div>
                    </div>
                    <div
                      *ngIf="submitted && f.init_date?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.init_date.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="mb-1"><strong>Despacho</strong></label>
                    <input
                      type="text"
                      class="form-control"
                      name="office"
                      formControlName="office"
                    />
                    <div
                      *ngIf="submitted && f.office?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.office.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="mb-1 custom-line-label"
                      ><strong>Funcionario responsable</strong></label
                    >
                    <input
                      type="text"
                      name="representative"
                      class="form-control"
                      formControlName="representative"
                    />
                    <div
                      *ngIf="submitted && f.representative?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.representative.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="mb-1"
                      ><strong>Honorarios profesionales</strong></label
                    >
                    <input
                      type="number"
                      class="form-control"
                      formControlName="price"
                      name="price"
                    />
                    <div
                      *ngIf="submitted && f.price?.errors"
                      class="text-danger fs-13 mt-1"
                    >
                      <div *ngIf="f.price.errors.required">
                        Campo requerido.
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="mb-1"
                      ><strong>Fase actual</strong>
                    </label>
                    <select
                        [(ngModel)]="actual_phase"
                        [ngModelOptions]="{standalone: true}"
                        class="form-control"
                        id="item-{{ i }}"
                        required
                    >
                        <option selected disabled value="0">Seleccione la fase actual</option>
                        <option [value]="phase?.controls['phase']?.value" *ngFor="let phase of phases_options; let i = index">
                        {{ phase?.controls['description']?.value }}
                        </option>
                    </select>
                  </div>
                  <div class="col-6" *ngIf="isEdit">
                    <label class="mb-1"
                      ><strong>Estado actual</strong>
                    </label>
                    <select
                        class="form-control"
                        formControlName="case_status_id"
                        name="case_status_id"
                    >
                        <option disabled selected>Seleccione una opción</option>
                        <option
                        [value]="item.id"
                        *ngFor="let item of statuses"
                        >
                        {{ item.name }}
                        </option>
                    </select>
                  </div>

                  <div class="col-12 custom-seccion-file mt-2">
                    <div
                      class="col-12 px-0 my-3 d-flex justify-content-center align-items-center"
                    >
                      <div class="col-9 alert-dark py-2 px-4 rounded mb-2">
                        <div
                          data-testing="super-test"
                          class="d-flex justify-content-around align-items-center"
                        >
                          <label for="file-input" class="font-weight-bold m-0">
                            {{
                              f.external_quote_file.value == null
                                ? "Elegir Cotización:"
                                : "Cotización Cargada:"
                            }}
                          </label>

                          <div class="col-2">
                            <div class="image-upload">
                              <label for="file-input">
                                <i
                                  class="flaticon-381-upload-1"
                                  style="font-size: 20px"
                                ></i>
                              </label>

                              <input
                                id="file-input"
                                #external_quote_file
                                type="file"
                                accept="application/pdf"
                                name="external_quote_file"
                                (change)=" onFileSelect($event, 'external_quote_file') "
                              />
                            </div>
                          </div>
                          <div
                            class="col-2"
                            *ngIf="
                              isString(f.external_quote_file.value) && isEdit
                            "
                          >
                            <div class="image-upload">
                              <label for="download">
                                <a
                                  [href]="f.external_quote_file.value"
                                  target="_blank"
                                >
                                  <i
                                    class="flaticon-381-download"
                                    style="font-size: 20px"
                                  ></i>
                                </a>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div
                          *ngIf="selected"
                          class="col-12 d-flex align-items-center justify-content-center"
                        >
                          <p class="text-center m-0">
                            {{ editSelectedFilename.current }}
                          </p>

                          <button
                            mat-icon-button
                            color="accent"
                            aria-label="Example icon button with a menu icon"
                            placement="top"
                            (click)="onDeSelectFile(external_quote_file)"
                            ngbTooltip="Eliminar"
                          >
                            <mat-icon
                              aria-hidden="false"
                              aria-label="Example home icon"
                              style="color: #dc3545"
                            >
                              remove_circle_outline
                            </mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center">
                      <div
                        *ngIf="submitted && f.external_quote_file?.errors"
                        class="text-danger fs-13 mt-1"
                      >
                        <div *ngIf="f.external_quote_file.errors.required">
                          Campo requerido.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <label class="mb-1"
                          ><strong>Número de fases</strong></label
                        >
                        <input
                          type="number"
                          class="form-control"
                          formControlName="phases_number"
                          name="phases_number"
                          readonly="true"
                        />
                        <div
                          *ngIf="submitted && f.phases_number?.errors"
                          class="text-danger fs-13 mt-1"
                        >
                          <div *ngIf="f.phases_number.errors.required">
                            Campo requerido.
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="mb-1"
                          ><strong>Agrega / Elimina</strong></label
                        >
                        <div>
                          <button
                            type="button"
                            mat-icon-button
                            color="accent"
                            aria-label="Example icon button with a menu icon"
                            (click)="addPhase()"
                            placement="top"
                            ngbTooltip="Agregar fase"
                          >
                            <mat-icon
                              aria-hidden="false"
                              aria-label="Example home icon"
                              style="color: #212f54"
                            >
                              add_circle_outline
                            </mat-icon>
                          </button>
                          <button
                            type="button"
                            mat-icon-button
                            color="accent"
                            aria-label="Example icon button with a menu icon"
                            (click)="removePhase()"
                            placement="top"
                            ngbTooltip="Eliminar fase"
                          >
                            <mat-icon
                              aria-hidden="false"
                              aria-label="Example home icon"
                              style="color: #dc3545"
                            >
                              remove_circle_outline
                            </mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-row" style="margin: auto">
                      <div class="col-12" style="text-align: center"></div>
                      <div formArrayName="phases">
                        <div
                          *ngFor="let item of phases.controls; let i = index"
                        >
                          <div [formGroupName]="i" class="form-row">
                            <div class="form-row">
                              <div class="col">
                                <div class="form-group">
                                  <label class="mb-1"
                                    ><strong>Fase {{ i + 1 }}</strong></label
                                  >
                                  <input
                                    id="phase-{{ i }}"
                                    name="phases[{{ i }}][phase]"
                                    type="text"
                                    class="form-control"
                                    formControlName="description"
                                  />
                                  <div
                                    *ngIf="
                                      submitted &&
                                      item.controls['description']?.errors
                                    "
                                    class="text-danger"
                                  >
                                    <div
                                      *ngIf="
                                        item.controls['description'].errors
                                          .required
                                      "
                                    >
                                      Campo requerido.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label id="percent-{{ i }}" class="mb-1"
                                    ><strong>Porcentaje de pago</strong></label
                                  >
                                  <input
                                    type="number"
                                    name="phases[{{ i }}][percent]"
                                    class="form-control"
                                    min="0"
                                    max="100"
                                    formControlName="percent"
                                  />
                                  <div
                                    *ngIf="
                                      submitted &&
                                      item.controls['percent']?.errors
                                    "
                                    class="text-danger"
                                  >
                                    <div
                                      *ngIf="
                                        item.controls['percent'].errors.required
                                      "
                                    >
                                      Campo requerido.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2" *ngIf="isEdit">
            <a ngbNavLink>Documentos</a>
            <ng-template ngbNavContent>
              <div class="form-row">
                <div
                  class="col-12 px-3 my-3 d-flex justify-content-center align-items-center"
                >
                  <div
                    class="col-9 d-flex justify-content-around align-items-center alert-dark py-2 px-4 rounded mb-2"
                  >
                    <label for="" class="font-weight-bold m-0"
                      >Añadir Documentos:</label
                    >

                    <div class="col-2">
                      <div class="image-upload">
                        <label for="file-input">
                          <i
                            class="flaticon-381-upload-1"
                            style="font-size: 20px"
                          ></i>
                        </label>

                        <input
                          id="file-input"
                          type="file"
                          accept="application/pdf,.doc,.docx,.png,.jpg,.jpeg"
                          (change)="onFileSelect($event, 'documents')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="col-12 custom-seccion-file">
                  <span class="span-input-file"
                    ><strong>Documentos</strong></span
                  >
                  <!-- <input type="file" name="documents[0][file]" (change)="upload($event.target.files)" />
                      <input type="file" name="documents[1][file]" (change)="upload2($event.target.files)" /> -->
                </div>
                <div class="col-12 px-3 my-3 align-items-center">
                  <div class="row d-flex justify-content-center">
                    <div
                      class="col-6 mb-2"
                      *ngFor="
                        let document of docsForm.get('documents').value;
                        let index;
                        as: i
                      "
                    >
                      <div class="list-group">
                        <!-- [href]="document.file_url" target="_blank"  -->
                        <a
                          href="javascript:void(0)"
                          class="list-group-item list-group-item-action flex-column align-items-start p-2"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5
                              class="font-12 mb-1 text-capitalize"
                              [title]="document.name"
                              (click)="ShowPdf(document, document.upload)"
                            >
                              {{ document.name }}
                            </h5>
                            <small>
                              <button
                                class="icon-d"
                                type="button"
                                mat-icon-button
                                color="accent"
                                aria-label="Example icon button with a menu icon"
                                placement="top"
                                ngbTooltip="Eliminar Documento"
                                (click)="DeleteDocument(document.id, index)"
                              >
                                <mat-icon
                                  aria-hidden="false"
                                  aria-label="Example home icon"
                                  style="color: #dc3545"
                                >
                                  remove_circle_outline
                                </mat-icon>
                              </button></small
                            >
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" *ngIf="isEdit">
            <a ngbNavLink>Gastos</a>
            <ng-template ngbNavContent>
              <div class="text-right mb-3" *ngIf="!pageExpense">
                Agregar Gasto
                <button
                  mat-icon-button
                  color="accent"
                  (click)="addExpense()"
                  placement="top"
                  ngbTooltip="Nuevo Gasto"
                >
                  <mat-icon aria-hidden="false" style="color: #212f54">
                    add_circle_outline
                  </mat-icon>
                </button>
              </div>
              <div *ngIf="pageExpense">
                <form
                  class="form-valide mt-3"
                  [formGroup]="formExpense"
                  enctype="multipart/form-data"
                  id="formExpense"
                  (ngSubmit)="onSubmitExpense()"
                  novalidate
                >
                  <div class="form-row">
                    <div
                      class="col-12 px-3 my-3 d-flex justify-content-center align-items-center"
                    >
                      <div
                        class="col-9 d-flex justify-content-around align-items-center alert-dark py-2 px-4 rounded mb-2"
                      >
                        <label for="" class="font-weight-bold m-0"
                          >Añadir Comprobante de gasto:</label
                        >

                        <div class="col-2">
                          <div class="image-upload">
                            <label for="file-input-expense">
                              <i
                                class="flaticon-381-upload-1"
                                style="font-size: 20px"
                              ></i>
                            </label>

                            <input
                              id="file-input-expense"
                              type="file"
                              formControlName="expense_reference_file"
                              accept="application/pdf"
                              (change)="onFileExpense($event)"
                            />
                          </div>
                        </div>
                        <div
                          *ngIf="submittedE && e.expense_reference_file?.errors"
                          class="text-danger fs-13 mt-1"
                        >
                          <div *ngIf="e.expense_reference_file.errors.required">
                            Campo requerido.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-lg-4">
                      <label class="mb-1 custom-line-label"
                        ><strong>Referencia</strong></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="expense_reference"
                        formControlName="expense_reference"
                      />
                      <div
                        *ngIf="submittedE && e.expense_reference?.errors"
                        class="text-danger fs-13 mt-1"
                      >
                        <div *ngIf="e.expense_reference.errors.required">
                          Campo requerido.
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <label class="mb-1 custom-line-label"
                        ><strong>Fecha</strong></label
                      >
                      <!--<input type="text" class="form-control" name="date" formControlName="date" />-->
                      <div class="input-group">
                        <input
                          class="form-control"
                          name="date"
                          ngbDatepicker
                          #date="ngbDatepicker"
                          formControlName="date"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="date.toggle()"
                            type="button"
                          ></button>
                        </div>
                      </div>
                      <div
                        *ngIf="submittedE && e.date?.errors"
                        class="text-danger fs-13 mt-1"
                      >
                        <div *ngIf="e.date.errors.required">
                          Campo requerido.
                        </div>
                        <div *ngIf="e.date.errors.ngbDate">Fecha invalida</div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <label class="mb-1 custom-line-label"
                        ><strong>Monto</strong></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="amount"
                        formControlName="amount"
                      />
                      <div
                        *ngIf="submittedE && e.amount?.errors"
                        class="text-danger fs-13 mt-1"
                      >
                        <div *ngIf="e.amount.errors.required">
                          Campo requerido.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-12">
                      <label class="mb-1 custom-line-label"
                        ><strong>Descripción</strong></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="description"
                        formControlName="description"
                      />
                      <div
                        *ngIf="submittedE && e.description?.errors"
                        class="text-danger fs-13 mt-1"
                      >
                        <div *ngIf="e.description.errors.required">
                          Campo requerido.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row mt-4">
                    <div class="col-12 text-center">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-danger mr-2"
                        (click)="closedExpense()"
                      >
                        Cancelar
                      </button>
                      <button type="submit" class="btn btn-sm btn-primary">
                        {{ isEditExpense ? "Editar" : "Guardar" }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div>
                <ng-container *ngIf="dataExpense.length">
                  <div class="custom-seccion-file mt-5 pb-3">
                    <span class="span-input-file"><strong>Gastos</strong></span>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-sm table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Nro ref</th>
                          <th scope="col">Fecha</th>
                          <th scope="col">Monto</th>
                          <th scope="col">Descripción</th>
                          <th class="text-center" scope="col">Comprobante</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let gasto of dataExpense; let i = index">
                          <td>{{ gasto.expense_reference }}</td>
                          <td>{{ gasto.discharge_day | date : "dd/MM/yy" }}</td>
                          <td>{{ gasto.amount | number : "1.2-2" }}</td>
                          <td>{{ gasto.description }}</td>
                          <td class="text-center">
                            <a
                              target="_blank"
                              [href]="gasto.expense_reference_file"
                              *ngIf="gasto.expense_reference_file != null"
                              ><i
                                class="flaticon-381-download"
                                style="font-size: 20px"
                              ></i
                            ></a>
                          </td>

                          <td>
                            <button
                              mat-icon-button
                              color="accent"
                              aria-label="Example icon button with a menu icon"
                              (click)="editExpense(gasto)"
                              placement="top"
                              ngbTooltip="Editar"
                            >
                              <mat-icon
                                style="color: #212f54"
                              >
                                edit
                              </mat-icon>
                            </button>
                            <button
                              mat-icon-button
                              color="accent"
                              aria-label="Example icon button with a menu icon"
                              (click)="deleteExpense(gasto.id)"
                              placement="top"
                              ngbTooltip="Eliminar"
                            >
                              <mat-icon
                                aria-hidden="false"
                                aria-label="Example home icon"
                                style="color: #dc3545"
                              >
                                remove_circle_outline
                              </mat-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="4" *ngIf="isEdit">
            <a ngbNavLink>Comentarios</a>
            <ng-template ngbNavContent>
              <div class="mt-4 p-2" id="content_4">
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex flex-row-reverse bd-highlight">
                      <div class="p-2 bd-highlight">
                        Agregar comentario
                        <a
                          class="ml-3 font-weight-bold cursor-pointer"
                          (click)="addComment()"
                          title="Agregar Documentos"
                        >
                          <i class="flaticon-381-add-2"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 px-3 justify-content-start align-items-center"
                  >
                    <label for="" class="font-weight-bold mb-0"
                      >Descripción:</label
                    >
                    <br />
                    <input
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control form-control-sm"
                      type="text"
                      [(ngModel)]="comment"
                    />
                    <br />
                    <div class="card-body">
                      <ng-container *ngIf="listComment.length; else nodata">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Comentario</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let commentData of listComment">
                                <td>
                                  {{
                                    commentData.created_at | date : "dd/MM/yyyy"
                                  }}
                                </td>
                                <td>{{ commentData.comment }}</td>
                                <td>
                                  <div>
                                    <button
                                      mat-icon-button
                                      color="accent"
                                      aria-label="Example icon button with a menu icon"
                                      (click)="deleteComment(commentData.id)"
                                      placement="top"
                                      ngbTooltip="Eliminar"
                                    >
                                      <mat-icon
                                        aria-hidden="false"
                                        aria-label="Example home icon"
                                        style="color: #dc3545"
                                      >
                                        delete
                                      </mat-icon>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row justify-content-end">
                          <div
                            class="col-auto custom-div-pagination justify-content-center"
                          >
                            <ngb-pagination
                              [pageSize]="dataPaginationComment?.perPage"
                              (pageChange)="loadDataComment($event)"
                              [collectionSize]="dataPaginationComment?.total"
                              [maxSize]="5"
                              [(page)]="dataPaginationComment.currentPage"
                              aria-label="Paginación"
                            >
                            </ngb-pagination>
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #nodata>
                        <div class="alert alert-info" role="alert">
                          <strong>No hay comentarios disponibles</strong>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="5">
            <a ngbNavLink>Historial de Cambios</a>
            <ng-template ngbNavContent>
              <div class="mt-4 p-2" id="content_4">
                <div class="row ">
                  <div class="col-12 px-3 d-flex justify-content-start align-items-center ">
                  </div>
                  <div class="col-12 px-3 my-3 d-flex justify-content-center align-items-center ">
                    <div class="col-12">
                      <div class="card-body">
                          <ng-container *ngIf="dataHistory.length; else nodatahistory">
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Hecho por</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let historyData of dataHistory">
                                    <td>{{historyData?.created_at  | date: 'dd/MM/yyyy h:mma' }}</td>
                                    <td> {{historyData?.user?.full_name}}</td>
                                    <td>
                                      <div>
                                        <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                                        (click)="openModalDetailHistory(detailModalHistory,historyData)" placement="top" ngbTooltip="Ver cambios">
                                          <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212f54;">
                                            visibility
                                          </mat-icon>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                            <div class="row justify-content-end">
                              <div class="col-auto custom-div-pagination justify-content-center">
                                <ngb-pagination [pageSize]="dataHistoryPagination?.perPage" (pageChange)="loadDataHistory($event)"
                                  [collectionSize]="dataHistoryPagination?.total" [maxSize]="5" [(page)]="dataHistoryPagination.currentPage" aria-label="Paginación">
                                </ngb-pagination>
                              </div>
                            </div>

                          </ng-container>
                          <ng-template #nodatahistory>
                            <div class="alert alert-info" role="alert">
                              <strong>No hay historial disponible</strong>
                            </div>
                          </ng-template>
                        </div>
                      <!-- <div class="list-group">
                        <a class="list-group-item d-flex flex-row  justify-content-around align-items-start p-2">
                          <p class="mb-1 font-weight-bold ">Módulo en desarrollo</p>
                        </a>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>


            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>

        <div class="form-row mt-4" *ngIf="active != 3">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-outline-danger mr-2"
              (click)="closed()"
            >
              Cancelar
            </button>
            <button type="submit" class="btn btn-primary" (click)="OnSubmit()">
              {{ isEdit ? "Aceptar" : "Guardar" }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #detailModalHistory>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Cambios</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
          <!-- <h4 class="modal-title" id="modal-basic-title">Cambios</h4> -->
        <div *ngIf="selectedHistoryChanges && selectedHistoryChanges.length > 0">
          <div *ngFor="let change of selectedHistoryChanges">
            <div class="d-flex my-3" >
              <strong>{{change.key}}</strong>:
              <div *ngIf="['phases','apoderados','contraparte'].includes(change.field_name)" class="d-flex">
                <ul>
                  <li *ngFor="let data of change.old" class="px-3">
                    <span *ngIf="change.field_name === 'phases'">{{data.phase}} : {{data.percent}}%</span>
                    <span *ngIf="change.field_name != 'phases'">{{data}}</span>
                  </li>
                </ul>
                <div class="h-100 d-flex justify-content-center align-items-center">
                  <mat-icon style="color: #212F54;">arrow_right_alt</mat-icon>
                </div>
                <ul>
                  <li *ngFor="let data of change.new" class="px-3">
                    <span *ngIf="change.field_name === 'phases'">{{data.phase}} : {{data.percent}}%</span>
                    <span *ngIf="change.field_name != 'phases'">{{data}}</span>
                  </li>
                </ul>
              </div>
              <div *ngIf="!['phases','apoderados','contraparte'].includes(change.field_name)" class="d-flex" >
                <span class="mx-3">{{change?.old ? change?.old : '       '}}</span>
                <mat-icon style="color: #212F54;">arrow_right_alt</mat-icon>
                <span class="mx-3">{{change?.new ? change?.new : '       '}}</span>
              </div>
            </div>
          </div>
        </div>
    </div>
  </ng-template>
</div>
