import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  list = [];
  params = '';
  page; dataPagination; 
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    case: null,
    client: null
  }
  hoveredDate: NgbDate | null = null;
  modal: any;
  currentItem : any;
  titlePage='Reporte Financiero';
  total;
  idPaymentpdf;

  constructor(
    private modalService: NgbModal,
    private lawyerService: LawyerService,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    titlePageService: TitlePageService
  ) { 
    titlePageService.titlePageObservableData = { name: this.titlePage};
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.lawyerService.getIssuedInvoices().subscribe(response => {
      this.list = response.data;
      this.total = response.info_object;
      console.log(response);
      this.dataPagination = response.meta.page;
    });
  }

  downloadPDF() {
    this.lawyerService.getIssuedInvoicesPdf(this.params).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Reporte Financiero.pdf";
        link.click();
      }
    );
  }

  downloadCasePDF() {
    this.lawyerService.getIssuedInvoicesCasePdf(this.idPaymentpdf).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Reporte Financiero.pdf";
        link.click();
      }
    );
  }

  openModal(detailModal: any, item) {
    this.idPaymentpdf=item.id;
    this.lawyerService.getCase(item.case_id).subscribe(response => {
      this.currentItem = response.data;
      this.modal = this.modalService.open(detailModal, { backdrop: 'static', size: 'md' });
    });
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.loadData();
    this.params = '';
    this.searchFilter = {
      dateStart: null,
      dateEnd: null,
      case: null,
      client: null
    }
    this.toDate = null;
    this.fromDate = null;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search() {
    this.searchFilter.dateStart = this.formatter.format(this.fromDate);
    this.searchFilter.dateEnd = this.formatter.format(this.toDate);
    this.params = '';
    
    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }
    
    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.searchFilter.case) {
      this.params += '&case_number=' + this.searchFilter.case;
    }

    if (this.searchFilter.client) {
      this.params += '&client_name=' + this.searchFilter.client;
    }

    if (this.params !== '') {
       this.lawyerService.getIssuedInvoices(1, this.params).subscribe(response => {
        this.list = [...response.data];
      });
    }
  }
}
