import { Directive, Renderer2, ElementRef, Input } from '@angular/core';

@Directive( {
  selector: '[appRole]'
} )
export class RoleDirective {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  @Input() set appRole( role: string ) {
    const el = this.el.nativeElement;
    let text = '';
    if ( role === 'admin' ) {
      text = this.renderer.createText( 'Administrador' );
      this.renderer.appendChild( el, text );
    }
    if ( role === 'person' ) {
      text = this.renderer.createText( 'Persona natural' );
      this.renderer.appendChild( el, text );
    }
    if ( role === 'lawyer' ) {
      text = this.renderer.createText( 'Abogado' );
      this.renderer.appendChild( el, text );
    }
    if ( role === 'company' ) {
      text = this.renderer.createText( 'Persona jurídica' );
      this.renderer.appendChild( el, text );
    }
  }
}
