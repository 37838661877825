<div class="container-fluid form-container">
  <form
    class="form-valide"
  
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    novalidate
  >
    <div class="col-12 flex-column text-center p-0">
      <img [src]="avatar" alt="" srcset="" class="ln-avatar" />
      <div class="form-group text-left">
        <label class="mb-1"><strong>Foto</strong></label>
        <div class="input-group">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              (change)="onImageChange($event)"
              accept="image/*"
            />
            <label class="custom-file-label">{{ fileName }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Tipo de Usuario</strong></label>
          <input type="text" class="form-control" [value]="userRoleName" disabled />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Nombre(s)</strong></label>
          <input type="text" class="form-control" formControlName="name" />
          <div
            *ngIf="isSubmitted && controls.name?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.name.errors.required">Campo requerido.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Apellido(s)</strong></label>
          <input type="text" class="form-control" formControlName="last_name" />
          <div
            *ngIf="isSubmitted && controls.last_name?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.last_name.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Cédula de Identidad</strong></label>
          <input class="form-control" formControlName="dni" />
          <div
            *ngIf="isSubmitted && controls.dni?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.dni.errors.required">Campo requerido.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Teléfono</strong></label>
          <input type="number" class="form-control" formControlName="phone" />
          <div
            *ngIf="isSubmitted && controls.phone?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.phone.errors.required">Campo requerido.</div>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>

    <div class="form-row align-items-end">
      <div class="col">
        <div class="form-group">
          <label for=""><strong>Provincia</strong></label>
          <select
            class="form-control"
            [(ngModel)]="ProvinceSelected" [ngModelOptions]="{standalone: true}"
            (change)="onChange($event.target.value, 'province')"
          >
            <option disabled selected value="0">Seleccione una opción</option>
            <option [value]="province.id" *ngFor="let province of provinces">
              {{ province.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for=""><strong>Distrito</strong></label>
          <select
            class="form-control"
            [(ngModel)]="CitySelected" [ngModelOptions]="{standalone: true}"
            (change)="onChange($event.target.value, 'city')"
          >
            <option disabled selected value="0">Seleccione una opcion</option>
            <option [value]="city.id" *ngFor="let city of cities">
              {{ city.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for=""><strong>Corregimiento</strong></label>
          <select
            class="form-control"
            name="township"
            id="township"
            [(ngModel)]="TownshipSelected" [ngModelOptions]="{standalone: true}"
            (change)="onChange($event.target.value, 'township')"
          >
            <option disabled selected value="0">Seleccione una opción</option>
            <option [value]="township.id" *ngFor="let township of townships">
              {{ township.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-group">
          <a class="btn btn-primary btn-refresh"  (click)="refreshAddress()" placement="top" ngbTooltip="refrescar">
            <mat-icon aria-hidden="false">
              refresh
            </mat-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Dirección</strong></label>
          <input type="text" class="form-control" formControlName="address" />
          <div
            *ngIf="isSubmitted && controls.address?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="controls.address.errors.required">Campo requerido.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-4">
      <button type="submit" class="btn btn-primary btn-block">
        Actualizar Perfil
      </button>
    </div>
  </form>
</div>
