import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AVATAR } from 'src/app/shared/data/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from '../../../shared/services/clients.service';
import {Location} from '@angular/common';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-lawyer-profile-co',
  templateUrl: './lawyer-profile-co.component.html',
  styleUrls: ['./lawyer-profile-co.component.css']
})
export class LawyerProfileCoComponent implements OnInit {
  dataLawyer; dataLawyerProfile;
  avatar = AVATAR;
  constructor(
    private ClientsService: ClientsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: 'Perfil'};
    this.activatedRoute.queryParams.subscribe(params => {
      this.dataLawyer = params['dataLawyer'];
    });
  }

  ngOnInit(): void {
    this.getProfileLawyer();
  }

  getProfileLawyer() {
    this.ClientsService.getProfileLawyer(this.dataLawyer).subscribe(response => {
      this.dataLawyerProfile = response.data;
    });
  }
  backPage() {
    this._location.back();
  }    

}
