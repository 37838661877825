import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface TitlePage {
    name: string;
}

@Injectable( {
  providedIn: 'root'
} )
export class TitlePageService {

  private titlePageObsevablePrivate: BehaviorSubject<TitlePage> =
    new BehaviorSubject<TitlePage>({ name: 'Inicio'});

  get titlePageObservable(){
      return this.titlePageObsevablePrivate.asObservable();
  }  

  set titlePageObservableData(data: TitlePage) {
      this.titlePageObsevablePrivate.next(data);
  }

}
