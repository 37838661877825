<!--**********************************
    Chat box start
***********************************-->
<div [ngClass]="{'': toggleChat, 'active': !toggleChat}" class=" chatbox ">
	<div class="chatbox-close" (click)="togglechatbar()"></div>
	<div class="custom-tab-1">
		<ul ngbNav #nav="ngbNav" (activeId)="3" class="nav nav-tabs bg-primary nav-justified">
			<!--
			<li [ngbNavItem]="1">
				<a ngbNavLink>Notes</a>
				<ng-template ngbNavContent>

					<div class="card mb-sm-3 mb-md-0 note_card">
						<div class="card-header chat-list-header text-center">
							<a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24"
									version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
										<rect fill="#000000" opacity="0.3"
											transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
											x="4" y="11" width="16" height="2" rx="1" />
									</g>
								</svg></a>
							<div>
								<h6 class="mb-1">Notes</h6>
								<p class="mb-0">Add New Nots</p>
							</div>
							<a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24"
									version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24" />
										<path
											d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
											fill="#000000" fill-rule="nonzero" opacity="0.3" />
										<path
											d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
											fill="#000000" fill-rule="nonzero" />
									</g>
								</svg></a>
						</div>
						<div class="card-body contacts_body p-0 pb-3" [perfectScrollbar]>
							<ul class="contacts">
								<li class="active">
									<div class="d-flex bd-highlight">
										<div class="user_info">
											<span>New order placed..</span>
											<p>10 Aug 2020</p>
										</div>
										<div class="ml-auto">
											<a href="javascript:void(0)" class="btn btn-primary btn-xs sharp mr-1"><i
													class="fa fa-pencil"></i></a>
											<a href="javascript:void(0)" class="btn btn-danger btn-xs sharp"><i class="fa fa-trash"></i></a>
										</div>
									</div>
								</li>
								<li>
									<div class="d-flex bd-highlight">
										<div class="user_info">
											<span>Youtube, a video-sharing website..</span>
											<p>10 Aug 2020</p>
										</div>
										<div class="ml-auto">
											<a href="javascript:void(0)" class="btn btn-primary btn-xs sharp mr-1"><i
													class="fa fa-pencil"></i></a>
											<a href="javascript:void(0)" class="btn btn-danger btn-xs sharp"><i class="fa fa-trash"></i></a>
										</div>
									</div>
								</li>
								<li>
									<div class="d-flex bd-highlight">
										<div class="user_info">
											<span>john just buy your product..</span>
											<p>10 Aug 2020</p>
										</div>
										<div class="ml-auto">
											<a href="javascript:void(0)" class="btn btn-primary btn-xs sharp mr-1"><i
													class="fa fa-pencil"></i></a>
											<a href="javascript:void(0)" class="btn btn-danger btn-xs sharp"><i class="fa fa-trash"></i></a>
										</div>
									</div>
								</li>
								<li>
									<div class="d-flex bd-highlight">
										<div class="user_info">
											<span>Athan Jacoby</span>
											<p>10 Aug 2020</p>
										</div>
										<div class="ml-auto">
											<a href="javascript:void(0)" class="btn btn-primary btn-xs sharp mr-1"><i
													class="fa fa-pencil"></i></a>
											<a href="javascript:void(0)" class="btn btn-danger btn-xs sharp"><i class="fa fa-trash"></i></a>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>

				</ng-template>
			</li>
			<li [ngbNavItem]="2">
				<a ngbNavLink>Alerts</a>
				<ng-template ngbNavContent>

					<div class="card mb-sm-3 mb-md-0 contacts_card">
						<div class="card-header chat-list-header text-center">
							<a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24"
									version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24" />
										<circle fill="#000000" cx="5" cy="12" r="2" />
										<circle fill="#000000" cx="12" cy="12" r="2" />
										<circle fill="#000000" cx="19" cy="12" r="2" />
									</g>
								</svg></a>
							<div>
								<h6 class="mb-1">Notications</h6>
								<p class="mb-0">Show All</p>
							</div>
							<a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24"
									version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24" />
										<path
											d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
											fill="#000000" fill-rule="nonzero" opacity="0.3" />
										<path
											d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
											fill="#000000" fill-rule="nonzero" />
									</g>
								</svg></a>
						</div>
						<div class="card-body contacts_body p-0 pb-3" [perfectScrollbar]>
							<ul class="contacts">
								<li class="name-first-letter">SEVER STATUS</li>
								<li class="active">
									<div class="d-flex bd-highlight">
										<div class="img_cont primary">KK</div>
										<div class="user_info">
											<span>David Nester Birthday</span>
											<p class="text-primary">Today</p>
										</div>
									</div>
								</li>
								<li class="name-first-letter">SOCIAL</li>
								<li>
									<div class="d-flex bd-highlight">
										<div class="img_cont success">RU</div>
										<div class="user_info">
											<span>Perfection Simplified</span>
											<p>Jame Smith commented on your status</p>
										</div>
									</div>
								</li>
								<li class="name-first-letter">SEVER STATUS</li>
								<li>
									<div class="d-flex bd-highlight">
										<div class="img_cont primary">AU</div>
										<div class="user_info">
											<span>AharlieKane</span>
											<p>Sami is online</p>
										</div>
									</div>
								</li>
								<li>
									<div class="d-flex bd-highlight">
										<div class="img_cont info">MO</div>
										<div class="user_info">
											<span>Athan Jacoby</span>
											<p>Nargis left 30 mins ago</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div class="card-footer"></div>
					</div>

				</ng-template>
			</li> -->
			<li [ngbNavItem]="3">
				<a ngbNavLink>Mensajería</a>
				<ng-template ngbNavContent>

					<div class="card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box"
						[ngClass]="{'': toggleSingle, 'd-none': !toggleSingle}">
						<div class=" card-header chat-list-header text-center">
							<a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24"
									version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
										<rect fill="#000000" opacity="0.3"
											transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
											x="4" y="11" width="16" height="2" rx="1" />
									</g>
								</svg></a>
							<div>
								<h6 class="mb-1"> Mensajes</h6>
								<p class="mb-0">Todos</p>
							</div>
							<a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24"
									version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<rect x="0" y="0" width="24" height="24" />
										<circle fill="#000000" cx="5" cy="12" r="2" />
										<circle fill="#000000" cx="12" cy="12" r="2" />
										<circle fill="#000000" cx="19" cy="12" r="2" />
									</g>
								</svg></a>
						</div>
						<div id="DZ_W_Contacts_Body" class="card-body contacts_body p-0 pb-3" [perfectScrollbar]>
							<ul class="contacts">
								<li class="name-first-letter">A</li>
								<li class="active dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon"></span>
										</div>
										<div class="user_info">
											<span>Archie Parker</span>
											<p>Kalid is online</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/2.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon offline"></span>
										</div>
										<div class="user_info">
											<span>Alfie Mason</span>
											<p>Taherah left 7 mins ago</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/3.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon"></span>
										</div>
										<div class="user_info">
											<span>AharlieKane</span>
											<p>Sami is online</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/4.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon offline"></span>
										</div>
										<div class="user_info">
											<span>Athan Jacoby</span>
											<p>Nargis left 30 mins ago</p>
										</div>
									</div>
								</li>
								<li class="name-first-letter">B</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/5.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon offline"></span>
										</div>
										<div class="user_info">
											<span>Bashid Samim</span>
											<p>Rashid left 50 mins ago</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon"></span>
										</div>
										<div class="user_info">
											<span>Breddie Ronan</span>
											<p>Kalid is online</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/2.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon offline"></span>
										</div>
										<div class="user_info">
											<span>Ceorge Carson</span>
											<p>Taherah left 7 mins ago</p>
										</div>
									</div>
								</li>
								<li class="name-first-letter">D</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/3.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon"></span>
										</div>
										<div class="user_info">
											<span>Darry Parker</span>
											<p>Sami is online</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/4.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon offline"></span>
										</div>
										<div class="user_info">
											<span>Denry Hunter</span>
											<p>Nargis left 30 mins ago</p>
										</div>
									</div>
								</li>
								<li class="name-first-letter">J</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/5.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon offline"></span>
										</div>
										<div class="user_info">
											<span>Jack Ronan</span>
											<p>Rashid left 50 mins ago</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon"></span>
										</div>
										<div class="user_info">
											<span>Jacob Tucker</span>
											<p>Kalid is online</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/2.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon offline"></span>
										</div>
										<div class="user_info">
											<span>James Logan</span>
											<p>Taherah left 7 mins ago</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/3.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon"></span>
										</div>
										<div class="user_info">
											<span>Joshua Weston</span>
											<p>Sami is online</p>
										</div>
									</div>
								</li>
								<li class="name-first-letter">O</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/4.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon offline"></span>
										</div>
										<div class="user_info">
											<span>Oliver Acker</span>
											<p>Nargis left 30 mins ago</p>
										</div>
									</div>
								</li>
								<li class="dz-chat-user" (click)="singleChatWindow()">
									<div class="d-flex bd-highlight">
										<div class="img_cont">
											<img src="assets/images/avatar/5.jpg" class="rounded-circle user_img" alt="" />
											<span class="online_icon offline"></span>
										</div>
										<div class="user_info">
											<span>Oscar Weston</span>
											<p>Rashid left 50 mins ago</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>


					<div class="card chat dz-chat-history-box d-none" [ngClass]="{'d-none': toggleSingle, '': !toggleSingle}">
						<div class="card-header chat-list-header text-center">
							<a href="javascript:void(0)" class="dz-chat-history-back" (click)="singleChatWindow()">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px"
									height="18px" viewBox="0 0 24 24" version="1.1">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<polygon points="0 0 24 0 24 24 0 24" />
										<rect fill="#000000" opacity="0.3"
											transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) "
											x="14" y="7" width="2" height="10" rx="1" />
										<path
											d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
											fill="#000000" fill-rule="nonzero"
											transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) " />
									</g>
								</svg>
							</a>
							<div>
								<h6 class="mb-1">Chat with Khelesh</h6>
								<p class="mb-0 text-success">Online</p>
							</div>
							<div class="dropdown dropdown-no-icon" ngbDropdown>
								<a href="javascript:void(0)" data-toggle="dropdown" ngbDropdownToggle><svg
										xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px"
										height="18px" viewBox="0 0 24 24" version="1.1">
										<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
											<rect x="0" y="0" width="24" height="24" />
											<circle fill="#000000" cx="5" cy="12" r="2" />
											<circle fill="#000000" cx="12" cy="12" r="2" />
											<circle fill="#000000" cx="19" cy="12" r="2" />
										</g>
									</svg></a>
								<ul class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
									<li class="dropdown-item"><i class="fa fa-user-circle text-primary mr-2"></i> View
										profile</li>
									<li class="dropdown-item"><i class="fa fa-users text-primary mr-2"></i> Add to close
										friends</li>
									<li class="dropdown-item"><i class="fa fa-plus text-primary mr-2"></i> Add to group
									</li>
									<li class="dropdown-item"><i class="fa fa-ban text-primary mr-2"></i> Block</li>
								</ul>
							</div>
						</div>
						<div class="card-body msg_card_body" [perfectScrollbar]>
							<div class="d-flex justify-content-start mb-4">
								<div class="img_cont_msg">
									<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
								<div class="msg_cotainer">
									Hi, how are you samim?
									<span class="msg_time">8:40 AM, Today</span>
								</div>
							</div>
							<div class="d-flex justify-content-end mb-4">
								<div class="msg_cotainer_send">
									Hi Khalid i am good tnx how about you?
									<span class="msg_time_send">8:55 AM, Today</span>
								</div>
								<div class="img_cont_msg">
									<img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
							</div>
							<div class="d-flex justify-content-start mb-4">
								<div class="img_cont_msg">
									<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
								<div class="msg_cotainer">
									I am good too, thank you for your chat template
									<span class="msg_time">9:00 AM, Today</span>
								</div>
							</div>
							<div class="d-flex justify-content-end mb-4">
								<div class="msg_cotainer_send">
									You are welcome
									<span class="msg_time_send">9:05 AM, Today</span>
								</div>
								<div class="img_cont_msg">
									<img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
							</div>
							<div class="d-flex justify-content-start mb-4">
								<div class="img_cont_msg">
									<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
								<div class="msg_cotainer">
									I am looking for your next templates
									<span class="msg_time">9:07 AM, Today</span>
								</div>
							</div>
							<div class="d-flex justify-content-end mb-4">
								<div class="msg_cotainer_send">
									Ok, thank you have a good day
									<span class="msg_time_send">9:10 AM, Today</span>
								</div>
								<div class="img_cont_msg">
									<img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
							</div>
							<div class="d-flex justify-content-start mb-4">
								<div class="img_cont_msg">
									<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
								<div class="msg_cotainer">
									Bye, see you
									<span class="msg_time">9:12 AM, Today</span>
								</div>
							</div>
							<div class="d-flex justify-content-start mb-4">
								<div class="img_cont_msg">
									<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
								<div class="msg_cotainer">
									Hi, how are you samim?
									<span class="msg_time">8:40 AM, Today</span>
								</div>
							</div>
							<div class="d-flex justify-content-end mb-4">
								<div class="msg_cotainer_send">
									Hi Khalid i am good tnx how about you?
									<span class="msg_time_send">8:55 AM, Today</span>
								</div>
								<div class="img_cont_msg">
									<img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
							</div>
							<div class="d-flex justify-content-start mb-4">
								<div class="img_cont_msg">
									<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
								<div class="msg_cotainer">
									I am good too, thank you for your chat template
									<span class="msg_time">9:00 AM, Today</span>
								</div>
							</div>
							<div class="d-flex justify-content-end mb-4">
								<div class="msg_cotainer_send">
									You are welcome
									<span class="msg_time_send">9:05 AM, Today</span>
								</div>
								<div class="img_cont_msg">
									<img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
							</div>
							<div class="d-flex justify-content-start mb-4">
								<div class="img_cont_msg">
									<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
								<div class="msg_cotainer">
									I am looking for your next templates
									<span class="msg_time">9:07 AM, Today</span>
								</div>
							</div>
							<div class="d-flex justify-content-end mb-4">
								<div class="msg_cotainer_send">
									Ok, thank you have a good day
									<span class="msg_time_send">9:10 AM, Today</span>
								</div>
								<div class="img_cont_msg">
									<img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
							</div>
							<div class="d-flex justify-content-start mb-4">
								<div class="img_cont_msg">
									<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt="" />
								</div>
								<div class="msg_cotainer">
									Bye, see you
									<span class="msg_time">9:12 AM, Today</span>
								</div>
							</div>
						</div>
						<div class="card-footer type_msg">
							<div class="input-group">
								<textarea class="form-control" placeholder="Type your message..."></textarea>
								<div class="input-group-append">
									<button type="button" class="btn btn-primary"><i class="fa fa-location-arrow"></i></button>
								</div>
							</div>
						</div>
					</div>


				</ng-template>
			</li>
		</ul>

		<div [ngbNavOutlet]="nav" class="mt-2"></div>

	</div>
</div>
<!--**********************************
    Chat box End
***********************************-->

<!--**********************************
    Header start
***********************************-->
<div class="header">
	<div class="header-content">
		<nav class="navbar navbar-expand">
			<div class="collapse navbar-collapse justify-content-between">
				<div class="header-left">
					<div class="dashboard_bar">
						{{ (titlePage$ | async)?.name }}
					</div>
				</div>

				<ul class="navbar-nav header-right">
					<!-- <li class="nav-item">
						<div class="input-group search-area d-xl-inline-flex d-none">
							<div class="input-group-append">
								<button class="input-group-text"><i class="flaticon-381-search-2"></i></button>
							</div>
							<input type="text" class="form-control" placeholder="Search here...">
						</div>
					</li> -->
					<li class="nav-item" *ngIf="router.url != '/lawyer/index' && enablCronometer">
						<app-cronometer [showMode]="true"></app-cronometer>
					</li>
					<li class="nav-item dropdown notification_dropdown  dropdown-no-icon" ngbDropdown>
						<a class="nav-link  ai-icon" href="javascript:void(0);" role="button" data-toggle="dropdown"
							ngbDropdownToggle>
							<i class="flaticon-381-ring"></i>
							<div [ngClass]="{'pulse-css': hasUnreadNotifications}"></div>
						</a>
						<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
							<div id="DZ_W_Notification1" class="widget-media dz-scroll p-3" [perfectScrollbar]>
								<ul class="timeline" *ngIf="notifications.length != 0; else notnotificacions">
									<ng-container *ngFor="let notification of notifications; let i = index">
										<li *ngIf="i<5">
											<div class="timeline-panel">
												<div class="media mr-2">
													<div class="icon-initial">{{notification.status[0]}}</div>
												</div>
												<div class="media-body">
													<h6 class="mb-1">{{notification.body}} <i *ngIf="notification.status == 'Info'" (click)="redirectChat(notification)" class="ti-arrow-right"></i></h6>
													<small class="d-block">{{notification.creation_date}}</small>
												</div>
											</div>
										</li>
									</ng-container>
								</ul>
								<ng-template #notnotificacions>
									<ul>
										<li>
											<div class="media-body">
												<small class="d-block">Sin notificaciones</small>
											</div>
										</li>
									</ul>
								</ng-template>
							</div>
							<div class="row">
								<div class="col">
									<a class="all-notification" [routerLink]="[notificationsLink]">Ver todas <i class="ti-arrow-right"></i></a>
								</div>
								<div class="col" *ngIf="hasUnreadNotifications">
									<a class="all-notification"  (click)="markAsRead()">Leidos</a>
								</div>
							</div>
						</div>
					</li>
					<!--<li class="nav-item dropdown notification_dropdown" (click)="togglechatbar()">-->
					<li class="nav-item dropdown notification_dropdown" *ngIf="link!='admin'">
						<a class="nav-link bell bell-link cursor-pointer" (click)="onChat()">
							<i class="las la-comments"></i>
							<div [ngClass]="{'pulse-css': hasUnreadMessages}"></div>
						</a>
					</li>
					
					<li class="nav-item dropdown header-profile dropdown-no-icon" ngbDropdown>
						<a class="nav-link" role="button" data-toggle="dropdown" ngbDropdownToggle>
							<img [src]="avatar" width="20" alt="" />
							<div class="header-info">
								<span>{{user.name}}</span>
								<small [appRole]="user.roles[0].name"></small>
							</div>
						</a>
						<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
							<a (click)="onProfile()" class="dropdown-item ai-icon cursor-pointer" *ngIf="link!='admin'">
								<svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18"
									viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round">
									<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
									<circle cx="12" cy="7" r="4"></circle>
								</svg>
								<span class="ml-2">Perfil </span>
							</a>
							<!--<a href="./email-inbox.html" class="dropdown-item ai-icon">
								<svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" class="text-success" width="18" height="18"
									viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round">
									<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
									</path>
									<polyline points="22,6 12,13 2,6"></polyline>
								</svg>
								<span class="ml-2">Bandeja de entrada </span>
							</a>-->
							<a href="javascript:void(0)" class="dropdown-item ai-icon">
								<svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18"
									viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round">
									<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
									<polyline points="16 17 21 12 16 7"></polyline>
									<line x1="21" y1="12" x2="9" y2="12"></line>
								</svg>
								<span class="ml-2" (click)="logout()">Cerrar sesión </span>
							</a>
						</div>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</div>
