<div class="container-fluid form-container">
  <div class="custom-title"><strong> Trámites </strong></div>
  <div class="form-row col-12">
    <div class="col-4" *ngFor="let item of listBranch; let i = index">

      <div class="form-row col-12 custom-div-card">
        <div class=" col-6">
          {{item.name}}
        </div>
        <div class=" col-3 custom-left" (click)="ModalServicios(lisServicios, item)" ngbTooltip="Ver servicios">
          <i class="icon-list custom-link"></i>
        </div>
        <div class=" col-3" (click)="ModalAbogados(lisAboModal, item)" ngbTooltip="Ver abogados">
          <i class="icon-user custom-link"></i>
        </div>
      </div>
    </div>
  </div>

  <ng-template #lisAboModal>
    <div class="modal-header">
      <mat-list>
        <h4 class="modal-title" id="modal-basic-title">Abogados</h4>
        <div *ngIf="abogados.length > 0">
          <mat-list-item (click)="goProfile(item)" *ngFor="let item of abogados">
            <img matListAvatar src="https://material.angular.io/assets/img/examples/shiba1.jpg"
              alt="Photo of a Shiba Inu">
            <a matLine href="javascript:void(0)"> {{item.user.name}} {{item.user.last_name}}</a>
          </mat-list-item>
        </div>
      </mat-list>
      <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)" [collectionSize]="dataPagination?.total"
        [maxSize]="5" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
    </div>
  </ng-template>
  <ng-template #lisServicios class="custom-modal-serv">
    <div class="modal-header">
      <mat-list>
        <h4 class="modal-title" id="modal-basic-title">Servicios</h4>
        <div *ngIf="listServices.length > 0">
          <mat-nav-list>
            <mat-list-item *ngFor="let item of listServices" (click)="regSol(item)">
              <a matLine href="javascript:void(0)"> {{item.name}}</a>
              <button mat-icon-button>
                <mat-icon></mat-icon>
              </button>
            </mat-list-item>
          </mat-nav-list>
        </div>
      </mat-list>
    </div>
  </ng-template>