import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbTimeStruct, NgbTimeAdapter, NgbModal, NgbCalendar,  NgbDateParserFormatter, ModalDismissReasons, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent} from '@ng-bootstrap/ng-bootstrap';
//import { BranchesLaw } from '../../../shared/interfaces/common';
import { ScheduleService } from 'src/app/shared/services/schedule.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-schedule-co',
  templateUrl: './schedule-co.component.html',
  styleUrls: ['./schedule-co.component.css']
})
export class ScheduleCoComponent implements OnInit {
  listTask = [];
  task = {};
  active = 2;
  formTaskGroup: FormGroup;
  isEdit = true;
  modal: any;
  submitted = false;
  dateTask: any;
  statusTask: any;
  timeTask:  {"hour": 13, "minute": 31};
  statusTypes = [{ value: 'Pendiente' }, { value: 'Culminado' }];
  taskSelected: any;
  constructor(
    private scheduleService: ScheduleService, 
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
  ) { 
    this.loadTasks();
    this.createForm();
    this.dateTask = this.calendar.getToday();
  }

  get f(): any { return this.formTaskGroup.controls; } 


  ngOnInit(): void {
  }

  createForm(): void {
    this.formTaskGroup = this.fb.group ({
      description: ['',  [Validators.required, Validators.minLength(5), Validators.maxLength(500)] ],
      type: ['Tasks'],
      date: ['',  Validators.required ],
      status: ['' ]
    });
  }

  onSubmitTask() : void {
    this.submitted = true;
    this.formTaskGroup.value.status = 'Pendiente';

  console.log(this.formTaskGroup.valid);
    if ( this.formTaskGroup.valid ) {
      ( this.isEdit ) ? this.editTask() : this.addTask();
    } 
  }

  private addTask(): void {
    console.log('add');
    let hoy = new Date();
    let hora = hoy.getHours() + ':' + hoy.getMinutes();
    this.formTaskGroup.value.date = this.formatter.format( this.dateTask ) +' '+ hora;
    //console.log(this.formTaskGroup.value);
    this.scheduleService.addTask(this.formTaskGroup.value).subscribe( response => {
      Swal.fire( { text: 'Registro de tarea exitoso', icon: 'success' } );
      this.loadTasks();
      this.modal.dismiss();
    });
  
  }

  private editTask(): void {

    console.log('edit');
    let hoy = new Date();
    let hora = hoy.getHours() + ':' + hoy.getMinutes();
    this.formTaskGroup.value.date = this.formatter.format( this.dateTask ) +' '+ hora;
    this.formTaskGroup.value.status = this.taskSelected.status;
    //console.log(this.formTaskGroup.value);
    this.scheduleService.editTask(this.formTaskGroup.value,this.taskSelected.id).subscribe( response => {
      this.modal.dismiss();
      this.loadTasks();
      Swal.fire( { text: 'Tarea actualizada', icon: 'success' } );
    });
    
  }

  deleteTask(id): void {
    console.log('delete');
    this.scheduleService.deleteTask(id).subscribe( response => {
      this.loadTasks();
      Swal.fire( { text: 'Tarea Eliminada', icon: 'success' } );
    });
  }

  loadTasks() {
    this.scheduleService.listTask().subscribe( response => {
      this.listTask = [ ...response.data ];
      console.log(response.data);
    } );
  }

  open(content, doc): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  async editTaskModal( taskModal: any, task: any ): Promise<void> {
    this.isEdit = true;
    this.taskSelected = task;
    this.formTaskGroup = this.fb.group ({
      description: [ task.description,  [Validators.required, Validators.minLength(5), Validators.maxLength(500)] ],
      type: ['Tasks'],
      date: [task.created_at,  Validators.required ],
      status: [task.status, Validators.required ]
    });
    this.openModal( taskModal );
  }

  addTaskModal( taskModal: any ): void {
    this.createForm();
    this.isEdit = false;
    this.formTaskGroup.value.status = 'Pendiente';
    this.openModal( taskModal );
  }

  private openModal( taskModal: any ): void {
    this.modal = this.modalService.open( taskModal, { backdrop: 'static', size: 'lg' } );
  }

}
