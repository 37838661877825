<div class="content-body d-flex align-items-center" >
  <div class="container-fluid">
      <div class="row justify-content-center mb-3">
          <div class="col-xl-12 col-sm-12 text-center">
             <h3>Seguimiento de servicios</h3>
          </div>
      </div>
      <div class="row justify-content-center">
          <div class="col-lg-10">

            <div class="card">
                <div class="card-header">
                  <span>
                    <i (click)="backPage()" class="icon-arrow-left custom-icon-back cursor-pointer"></i>
                  </span>
                  <h4 class="modal-title" id="modal-basic-title">Perfil del Abogado</h4>
                </div>
                <div class="p-0">
                  <img src="/assets/images/big/img5.jpg" class="modal-banner" alt="banner">
                  <div class="modal-avatar text-center">
                    <img src="{{dataLawyerProfile?.main_image == null ?  this.avatar : dataLawyerProfile.main_image}}">
                  </div>

                  <div class="card-body pt-2">
                    <mat-card class="box-none px-0">
                      <h3 class="text-center mb-4">{{dataLawyerProfile?.user?.name}} {{dataLawyerProfile?.user?.last_name}}</h3>
                      <!-- <p><strong>Especialidad:</strong> Especialidad</p> -->
                      <p><strong>Número de Idoneidad:</strong> {{dataLawyerProfile?.suitability_number}}</p>
                    </mat-card>

                  <div class="my-4">
                      <h5>Estudios Realizados</h5>
                      <div class="ml-5">
                        <mat-card class="box-none">
                          <p *ngFor="let item of dataLawyerProfile?.study_levels">
                            <strong>{{item.name}}</strong> {{item.obtained_title}}
                            <span *ngIf="item?.collage"> - <strong>Institución</strong> {{item?.collage}}</span>
                          </p>
                          <!--  <p>Institucion. Año 2005</p> -->
                        </mat-card>
                      </div>
                    </div>
                    <!--     <div class="my-4">
                        <h5>Experiancia Laboral</h5>
                        <div class="ml-5" >
                            <mat-card>
                                <p>Institucion. Año 2000 - 2005</p>
                        </mat-card>
                        </div>
                    </div> -->

                    <div class="mb-4" *ngIf="dataLawyerProfile?.comments?.length>0">
                      <h5>Comentarios de otros Usuarios</h5>
                      <div class="ml-5">
                        <mat-card class="example-card box-none" *ngFor="let item of dataLawyerProfile?.comments">
                          <mat-card-header>
                            <div mat-card-avatar class="example-header-image">
                              <img class="example-header-image" matListAvatar
                                src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="Photo of a Shiba Inu">
                            </div>
                          <!--  <mat-card-title>Shiba Inu</mat-card-title>
                            <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
                          </mat-card-header>

                          <mat-card-content>
                            <p><strong>{{item.user.full_name}}: </strong> {{item.comment}}</p>
                          </mat-card-content>
                          <mat-card-actions>
                          </mat-card-actions>
                        </mat-card>
                      </div>
                    </div>



                  </div>


                </div>
            </div>

          </div>
      </div>
  </div>
</div>


