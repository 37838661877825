import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/shared/services/admin.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.css']
})
export class PromotionComponent implements OnInit {
  titlePage = 'Gestión de planes';
  page; dataPagination;
  isSubmitted = false;
  submitted = false;
  isEdit = false;
  docsForm: FormGroup;
  modal: any;
  imageLink: string = '';
  listPomotion: any = [ ];
  idDoc: number;
  image_load:any;
  isValidate: boolean = false ;

  constructor(
    private adminService: AdminService,
    titlePageService: TitlePageService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) {
    this.page = 1;
    titlePageService.titlePageObservableData = { name: this.titlePage };
    this.createForm();
  }

  get f(): any { return this.docsForm.controls; }

  ngOnInit(): void {
    this.refresh();
  }

  private createForm(): void {
    this.docsForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      ITBMS: ['', [Validators.required]],
      image_plan: ['',],
    });

  }


  loadImage(event){
    this.image_load=<File>event.target.files[0]
  }


  loadDataList(page) {
    this.adminService.listPromotion(page).subscribe(
      response => {
        this.listPomotion = response.data

      },
      (error) => {
        console.error(error)
      }
    );
  }

  add(entityModal: any) {
    this.clearForm()
    this.isEdit = false;
    this.isValidate = true;
    this.modal = this.modalService.open(entityModal, { size: 'lg' });
  }

  changeState(){
    this.isValidate = true ;
  }

  showCase(modal, item) {
    this.clearForm()
    let data = item;
    this.idDoc = item.id
    this.isEdit = true;
    this.isValidate = false;

    this.imageLink = data.image_plan;
    this.docsForm.patchValue({
      name: data.name,
      description: data.description,
      amount: data.amount,
      ITBMS: data.ITBMS,
      image: data.image_plan
    });
    this.modal = this.modalService.open(modal, { backdrop: 'static', size: 'lg' });

  }

  alertDelete(id) {
    Swal.fire({
      title: 'Atención!',
      text: "Seguro que desea eliminar este plan?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#212F54',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.adminService.deletePromotion(id).subscribe(
          response =>{
            this.refresh();
            Swal.fire("Promocion eliminada", "Promocion eliminada exitosamente" , 'success')
          }
        )
      }
    })
  }

  closed() {
    this.submitted = false;
    this.clearForm()
    this.modalService.dismissAll();

  }

  refresh(){
    this.loadDataList(this.page);
    this.clearForm()

  }


  clearForm(){
    this.docsForm.patchValue({
      name: '',
      description: '',
      amount: '',
      ITBMS: '',
      image: ''
    });
  }
  OnSubmit() {
    console.log(this.isEdit)
    console.log(this.docsForm)

    if(this.docsForm.valid){


        if(!this.isEdit){

           this.adminService.addPromotion( this.docsForm.value , this.image_load).subscribe(
            response =>{
              this.refresh()
              this.modalService.dismissAll();
              Swal.fire("Plan Creado" , "El plan se ha creado exitosamente" , "success");
            } ,
            err => console.error(err)
           )

        }
        else{
           this.adminService.updatePromotion(this.docsForm.value , this.image_load , this.idDoc).subscribe(
            response =>{
              this.refresh()
              this.modalService.dismissAll();
              Swal.fire("Plan actualizado" , "El plan se ha actualizado exitosamente" , "success");
            } ,
            err => console.error(err)
           )

        }

    }else{
      Swal.fire('Alerta' , 'Faltan Campos por llenar' , 'warning')
    }
  }
}
