
<section class="content-body d-flex align-items-center" >
    <div class="container-fluid">
        <div class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
               <h3>Mis Documentos</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">

                <div class="card border-0">
     
                <ul ngbNav #nav="ngbNav" [activeId]="active" class="nav-tabs nav nav-pills nav-fill">
                    <li [ngbNavItem]="link.id" *ngFor="let link of services" (click)="changeService(link.id)">
                        <a ngbNavLink>{{ link.name }}</a>
                        <ng-template ngbNavContent>
                            <div class="p-4">

                                <ng-template [ngTemplateOutlet]="contentRef"></ng-template>
        
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>

                </div>

            </div>
               
        </div>
    </div>
</section>


<ng-template #contentRef>
    
    
    <div class="form-group row align-items-center mb-4">
        <div class="col-auto">
            <label class="mb-0"><strong>Seleccione Rama del Derecho: </strong></label>
        </div>
        <div class="col-auto">
            <select (change)="changeBranch($event.target.value)" class="form-control" id="branch" name="branch" [(ngModel)]="branchID"
          [value]='1'>
            <option [value]="branch.id" *ngFor="let branch of branchs">
                {{ branch.name }}
            </option>
            </select>
        </div>
    </div>
    
    <div class="row justify-content-start"> 
        <ng-container *ngIf="List.length; else noData">
            <div class="col-12 mb-2" *ngFor="let data of List">
                <div class="card p-3 p-lg-4">
                <div class="row">
                    <div class="col">
                            <div class="content-document mb-4">
                                <label for="" class="font-weight-bold">Nombre del Trámite:</label>
                                <span class="ml-2">{{data.service_name }} </span>
                        
                            </div>
                            <div class="content-lawyer" *ngIf="data.lawyer">
                                <label for="" class="font-weight-bold mb-0">Abogado Asesor: </label>
                                <span class="ml-2">{{data.lawyer?.full_name}}</span>
                            </div>
                    </div>
                    <div class="col-auto">   
                        <a class="font-weight-bold cursor-pointer" (click)="open(content, data)">
                            Documentos compartidos 
                            <i class="flaticon-381-folder-6"></i>
                        </a>
                    
                        <a class="ml-3 font-weight-bold cursor-pointer" (click)="openUploadDocModal(uploadDocModal,data.order_id)" title="Agregar Documentos">
                            <i class="flaticon-381-add-2"></i>
                        </a>
                    </div>
                </div>

                </div>
            </div>
        </ng-container>
        <ng-template #noData>
            <div class="alert alert-info text-center my-4" role="alert">
            No hay registros disponibles
            </div>
        </ng-template>

    </div>

</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Documentos Compartidos</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> 
    </div>
    <div class="modal-body p-0">

        <div class="card-body pt-3">

            <ng-container *ngIf="docsSelected.length; else elseBlock">
            <ul class="list-group">
                <li *ngFor="let doc of docsSelected" class="list-group-item">
                    
                        <div class="row align-items-center">

                            <div class="col-12">
                                <h5><strong>
                                    <a [href]="doc.file_url" target="_blank" ngbTooltip="Descargar">  
                                        <i class="fa fa-download mr-2 cursor-pointer"></i>  
                                    </a>
                                    {{doc.name}}</strong>
                                </h5>
                            </div>
                            <div class="col-12 text-right">
                                <p>{{doc.created_at}}</p>
                                {{doc.user.full_name}}
                            </div>
                        </div>
                    
                </li>
            </ul>
            </ng-container>
            <ng-template #elseBlock>
                <div class="alert alert-info text-center my-4" role="alert">
                    No hay documentos compartidos
                </div>
            </ng-template>  
        </div>
        
    </div>

</ng-template>

<ng-template #uploadDocModal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Cargar Documento</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> 
    </div>
    <form class="form-valide" action="#" method="post" [formGroup]="formDoc" enctype="multipart/form-data"
                id="formDoc" (ngSubmit)="onSubmit()" novalidate>
                
        <div class="modal-body p-0">
            <div class="card-body pt-3">
                
                    <div class="form-group text-left">
                        <label class="mb-1"><strong>Documento</strong></label>
               
                            <div class="input-group">
                                <div class="custom-file">
                                    <input
                                    type="file"
                                    class="custom-file-input"
                                    formControlName="file_url" name="file_url"
                                    (change)="onDocChange($event)"
                                    accept=".pdf"
                                    required
                                    />
                                    <label class="custom-file-label">{{ fileName }}</label>
                                </div>
                            </div>
                     
                    </div>
                
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Subir Archivo</button>
        </div>
    </form> 
</ng-template>