import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { User } from 'src/app/shared/interfaces/user';
import { USER, AVATAR } from 'src/app/shared/data/constants';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { Router } from '@angular/router';

@Component( {
  selector: 'app-index1',
  templateUrl: './index1.component.html',
  styleUrls: [ './index1.component.css' ]
} )
export class Index1Component implements OnInit {

  user: User;
  avatar = AVATAR;
  data: any = {};
  dataTable = [];
  //dataTable = [];
  rol='';
  address: string = "";
  phone: string = "";
  dni: string = "";

  constructor(
    private dashboadService: DashboardService,
    private storage: StorageService,
    private router: Router,
    titlePageService: TitlePageService,
  ) {
    titlePageService.titlePageObservableData = { name: 'Inicio'};
    this.dashboadService.get().subscribe( response => {
      
      if ( response.status === 'Success' ) {
        this.data=response.data;
        //console.log(this.data);
      }
    } );
  }

  ngOnInit(): void {
    this.user = this.storage.getItem( USER );
    console.log(this.user);
    if ( this.user.roles[ 0 ].name === 'person' ) { 
      this.rol='person';
      this.address = this.user.person?.address;;
      this.phone = this.user.person?.phone;;
      this.dni = this.user.person?.dni;
    }
    if ( this.user.roles[ 0 ].name === 'company' ) { 
      this.rol='company';
      this.address = this.user.company?.address;;
      this.phone = this.user.company?.phone;;
      this.dni = this.user.company?.dni;
    }
  }

  onProfile() {
    this.router.navigate(['/'+this.rol+'/update-profile']);
  }

}
