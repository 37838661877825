<div class="content-body d-flex custom-top-list">
	  	<div class="container-fluid">
		    <br><br><br>
			<nav ngbNav #nav="ngbNav" class="nav-tabs">
			  <ng-container ngbNavItem>
			    <a ngbNavLink>Datos básicos</a>
			    <ng-template ngbNavContent>
			    	<form class="form-valide" action="#" method="post" [formGroup]="firstFormGroup" (ngSubmit)="onSubmit()">
		    		<input type="hidden" class="form-control" formControlName="main_image">
			    	<div class="form-row">
					        <div class="col"></div>
					        <div class="col text-center">
					        	<img [src]="avatar" alt="" srcset="" class="ln-avatar" />
					        </div>
					        <div class="col"></div>
					    </div>

			    	    <div class="form-row">
					        <div class="col"></div>
					        <div class="col">

					      <div class="form-group text-left">
					        <label class="mb-1"><strong>Foto</strong></label>
					        <div class="input-group">
					          <div class="custom-file">
					            <input
					              type="file"
					              class="custom-file-input"
					              (change)="onImageChange($event)"
					              accept="image/*"
					            />
					            <label class="custom-file-label">{{ fileName }}</label>
					          </div>
					        </div>
					      </div>
					        </div>
					        <div class="col"></div>
					    </div>


					    <div class="form-row">
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Número de Idoneidad</strong></label>
				            <input type="number" class="form-control" formControlName="suitability_number" disabled/>

				          </div>
				        </div>
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Email</strong></label>
				            <input type="email" class="form-control" formControlName="email" disabled/>

				          </div>
				        </div>
				      </div>

				      <div class="form-row">
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Nombre(s)</strong></label>
				            <input type="text" class="form-control" name="" formControlName="name" required/>
				            <div *ngIf="submitted && f.name?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="f.name.errors.required">Campo requerido.</div>
				              <div *ngIf="f.name.errors.minlength">
				                Nombre debe tener al menos 3 caracteres.
				              </div>
				            </div>
				          </div>
				        </div>
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Apellido(s)</strong></label>
				            <input type="text" class="form-control" formControlName="last_name" required/>
				            <div *ngIf="submitted && f.last_name?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="f.last_name.errors.required">Campo requerido.</div>
				              <div *ngIf="f.last_name.errors.minlength">
				                Apellido debe tener al menos 3 caracteres.
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				      <div class="form-row">
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Número de contacto</strong></label>
				            <input type="number" class="form-control" formControlName="phone" required/>
				            <div *ngIf="submitted && f.phone?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="f.phone.errors.required">Campo requerido.</div>
				              <div *ngIf="f.phone.errors.pattern">Campo debe tener minimo 7 números.</div>
				            </div>
				          </div>
				        </div>
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Cédula de Identidad</strong></label>
				            <input type="text" class="form-control" formControlName="dni" required/>
				            <div *ngIf="submitted && f.dni?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="f.dni.errors.required">Campo requerido.</div>
				            </div>
				          </div>
				        </div>
				      </div>

				      <div class="form-row">
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Área de Práctica</strong></label>

                    <ng-multiselect-dropdown
                    [data]="dropdownList"
                    formControlName="branch_laws"
                    [placeholder]="'Seleccione'"
                    [settings]="dropdownSettings"
                    >
                    </ng-multiselect-dropdown>

				            <div *ngIf="submitted && f.branch_laws?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="f.branch_laws.errors.required">Campo requerido.</div>
				            </div>
				          </div>
				        </div>
				      </div>




				      <div formArrayName="study_levels">
						<div *ngFor="let item of study_levels.controls; let i = index" [formGroupName]="i">
							<div class="form-row align-items-end" *ngIf="item.value.delete==0"  >
								<div class="col-11" >
									<div class="row">
									<div class="col">
									<div class="form-group">
									<label class="mb-1" for="item-{{ i }}"><strong>Nivel de Estudio</strong></label>
									<select class="form-control" id="item-{{ i }}" formControlName="study_level_id" required>
										<option selected disabled value="0">
										Seleccione una opción
										</option>
										<option [value]="level.id" *ngFor="let level of studylevel; let i = index">
										{{ level.name }}
										</option>
									</select>
									</div>
									<div *ngIf="submitted && f.study_level_id?.errors" class="text-danger fs-13 mt-1">
									<div *ngIf="f.study_level_id.errors.required">
										Campo requerido.
									</div>
									</div>
								</div>
								<div class="col">
									<div class="form-group">
									<label class="mb-1" for="item-t{{ i }}"><strong>Título Obtenido</strong></label>
									<input type="text" class="form-control" formControlName="obtained_title" id="item-t{{ i }}" required/>
									<div *ngIf="submitted && f.obtained_title?.errors" class="text-danger fs-13 mt-1">
										<div *ngIf="f.obtained_title.errors.required">
										Campo requerido.
										</div>
									</div>
									</div>
								</div>
								<div class="col">
									<div class="form-group">
									<label class="mb-1" for="item-c{{ i }}"><strong>Institución</strong></label>
									<input type="text" class="form-control" formControlName="college" id="item-c{{ i }}" required/>
									<div *ngIf="submitted && f.collage?.errors" class="text-danger fs-13 mt-1">
										<div *ngIf="f.college.errors.required">
										Campo requerido.
										</div>
									</div>
									</div>
								</div>
									</div>
								</div>
								<div class="col-1" *ngIf="i>0">
									<div class="form-group">
									<button type="button" mat-icon-button color="accent" (click)="deleteGrade(i)" placement="top" ngbTooltip="Eliminar">
										<mat-icon aria-hidden="false"  style="color: #dc3545;">
										delete
										</mat-icon>
									</button>
									</div>
								</div>
							</div>
						</div>
				      </div>
				      <div class="form-row">
				        <div class="col">
				          <div class="text-center mt-3">
				            <button type="button" class="btn btn-rounded btn-info" (click)="addGrades(0,' ','')">
				              <span class="btn-icon-left text-info">
				                <i class="fa fa-plus color-info"></i>
				              </span>
				              Agregar nivel de estudio
				            </button>
				          </div>
				        </div>
				      </div>
				      <div class="form-row">
				        <div class="col">
				          <div class="text-center mt-4">
					      <button type="submit" class="btn btn-primary btn-block">
					        Actualizar Perfil
					      </button>
					    </div>
				        </div>
				      </div>
				    </form>

			    </ng-template>
			  </ng-container>
			  <ng-container ngbNavItem>
			    <a ngbNavLink>Dirección y Banco</a>
			    <ng-template ngbNavContent>
			      <form class="form-valide" action="#" method="post" [formGroup]="secondFormGroup" (ngSubmit)="onSubmit2()">
			      	<div class="row">
				        <div class="col">
				          <h3 class="text-center my-3">Dirección</h3>
				        </div>
				      </div>
			      	<div class="form-row align-items-end">

				        <div class="col">
				          <div class="form-group">
				            <label for="">Provincia</label>
				            <select class="form-control" name="" id=""
				            	[(ngModel)]="ProvinceSelected" [ngModelOptions]="{standalone: true}"
				            	(change)="onChange($event.target.value, 'province')">
				              <option disabled selected>Seleccione una opción</option>
				              <option [value]="p.id" *ngFor="let p of provinces">
				                {{ p.name }}
				              </option>
				            </select>
				          </div>
				        </div>
				        <div class="col">
				          <div class="form-group">
				            <label for="">Distrito</label>
				            <select class="form-control" name="" id=""
				            	[(ngModel)]="CitySelected" [ngModelOptions]="{standalone: true}"
				            	(change)="onChange($event.target.value, 'city')">
				              <option disabled selected>Seleccione una opción</option>
				              <option [value]="city.id" *ngFor="let city of cities">
				                {{ city.name }}
				              </option>
				            </select>
				          </div>
				        </div>
				        <div class="col">
				          <div class="form-group">
				            <label for="">Corregimiento</label>

				            <select class="form-control" name="township_id" id="township_id"
				              [(ngModel)]="TownshipSelected" [ngModelOptions]="{standalone: true}"
				              (change)="onChange($event.target.value, 'township')" formControlName="township_id">
				              <option disabled selected [value]="0">
				                Seleccione una opción
				              </option>
				              <option [value]="township.id" *ngFor="let township of townships">
				                {{ township.name }}
				              </option>
				            </select>
				          </div>
				        </div>
				        <div class="col-auto">
				          <div class="form-group">
				            <button class="btn btn-primary btn-refresh"  (click)="refreshAddress()" placement="top" ngbTooltip="refrescar">
				              <mat-icon aria-hidden="false">
				                refresh
				              </mat-icon>
				            </button>
				          </div>
				        </div>
				      </div>
				      <div class="form-row">
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Dirección</strong></label>
				            <input type="text" class="form-control" formControlName="address" />
				            <div *ngIf="submitted && s.address?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="s.address.errors.required">Campo requerido.</div>
				            </div>
				          </div>
				        </div>
				      </div>

				      <div class="row">
				        <div class="col">
				          <h3 class="text-center my-3">Datos Bancarios</h3>
				        </div>
				      </div>
				      <div class="form-row">
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Nombre del Titular</strong></label>
				            <input type="text" class="form-control" formControlName="owner_name" />
				            <div *ngIf="submitted && s.owner_name?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="s.owner_name.errors.required">Campo requerido.</div>
				              <div *ngIf="s.owner_name.errors.minlength">
				                Nombre debe tener al menos 3 caracteres.
				              </div>
				            </div>
				          </div>
				        </div>
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Cédula del Titular</strong></label>
				            <input type="text" class="form-control" formControlName="owner_dni" />
				            <div *ngIf="submitted && s.owner_dni?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="s.owner_dni.errors.required">Campo requerido.</div>
				            </div>
				          </div>
				        </div>
				      </div>
				      <div class="form-row">
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Tipo de Cuenta</strong></label>
				            <select class="form-control" name="" id="" formControlName="account_type">
				              <option value="Corriente">Corriente</option>
				              <option value="Ahorro">Ahorro</option>
				            </select>
				          </div>
				        </div>
				        <div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Entidad Bancaria</strong></label>
				            <select class="form-control" name="bank_id" id="bank_id" formControlName="bank_id" >
				              <option [value]="bank.id" *ngFor="let bank of banks">
				                {{ bank.name }}
				              </option>
				            </select>
				            <div *ngIf="submitted && s.bank_id?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="s.bank_id.errors.required">Campo requerido.</div>
				            </div>
				          </div>
				        </div>
				      </div>
				      <div class="form-row">
				      	<div class="col">
				          <div class="form-group">
				            <label class="mb-1"><strong>Número de cuenta</strong></label>
				            <input type="text" class="form-control" formControlName="account_number" />
				            <div *ngIf="submitted && s.account_number?.errors" class="text-danger fs-13 mt-1">
				              <div *ngIf="s.account_number.errors.required">
				                Campo requerido.
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				      <div class="form-row">
				        <div class="col">
				          <div class="text-center mt-4">
					      <button type="submit" class="btn btn-primary btn-block">
					        Actualizar Perfil
					      </button>
					    </div>
				        </div>
				      </div>


			      </form>

			    </ng-template>
			  </ng-container>

			</nav>

		<div [ngbNavOutlet]="nav" class="mt-2"></div>

    </div>
</div>







