
<section class="content-body d-flex align-items-center" >
    <div class="container-fluid">
        <div class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
               <h3>Tiempos de ejecución de tareas</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">

                <div class="card border-0">
                    <div class="card-header d-block pb-0">
                        <form class="">
                            <div class="form-group hidden">
                                <div class="input-group">
                                <input name="datepicker"
                                        class="form-control"
                                        ngbDatepicker
                                        #datepicker="ngbDatepicker"
                                        [autoClose]="'outside'"
                                        (dateSelect)="onDateSelection($event)"
                                        [displayMonths]="2"
                                        [dayTemplate]="t"
                                        outsideDays="hidden"
                                        [startDate]="fromDate!"
                                        tabindex="-1">
                                <ng-template #t let-date let-focused="focused">
                                    <span class="custom-day"
                                        [class.focused]="focused"
                                        [class.range]="isRange(date)"
                                        [class.faded]="isHovered(date) || isInside(date)"
                                        (mouseenter)="hoveredDate = date"
                                        (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                    </span>
                                </ng-template>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-6 col-md">
                                    <div class="item-filter">
                                        <label class="mb-1"><strong>Caso</strong></label>
                                        <input [(ngModel)]="searchFilter.case" class="form-control form-filter" name="case" id="case"/>
                                    </div>
                                </div>
                                <div class="col-6 col-md">
                                    <div class="item-filter">
                                        <label class="mb-1"><strong>Tarea</strong></label>
                                        <input [(ngModel)]="searchFilter.task" class="form-control form-filter" name="taks" id="taks"/>
                                    </div>
                                </div>
                                <div class="col-6 col-md">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Desde</strong></label>
                                      <div class="input-group">
                                        <input #dpFromDate
                                               class="form-control form-filter-calendar" placeholder=""
                                               name="dpFromDate"
                                               [value]="formatter.format(fromDate)"
                                               (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                                        <div class="input-group-append">
                                          <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Hasta</strong></label>
                                        <div class="input-group">
                                            <input #dpToDate
                                               class="form-control form-filter-calendar" placeholder=""
                                               name="dpToDate"
                                               [value]="formatter.format(toDate)"
                                               (input)="toDate = validateInput(toDate, dpToDate.value)">
                                            <div class="input-group-append">
                                            <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-auto align-self-end">
                                    <div class="form-group" style="margin-bottom: 20px;">
                                        <div class="input-group">
                                            <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                                                <i class="fa fa-search"></i>
                                            </button>
                                            <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                                                <i class="fa fa-refresh"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <ng-container *ngIf="List.length; else nodata">
                            <div class="table-responsive" id="content" #content>
                            <h5 class="text-center my-3">Lista de tareas</h5>
                            <table class="table table-striped" >
                                <thead>
                                <tr>
                                    <th scope="col">Caso</th>
                                    <th class="text-center" scope="col">Tarea</th>
                                    <th class="text-center" scope="col">Tiempos de ejecución</th>
                                    <th class="text-center" scope="col">Tiempo cotizado</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let time of List; let i=index">
                                    <td>{{time.cases.name}}</td>
                                    <td class="text-center">{{time.name}}</td>
                                    <td class="text-center">{{time.hours_employed}}</td>
                                    <td class="text-center">{{time.estimated_hours}}:00:00</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </ng-container>
                        <ng-template #nodata>
                            <div class="alert alert-warning" role="alert">
                            <strong>No hay registros disponibles</strong>
                            </div>
                        </ng-template>
                        <div class="text-center">
                          <button class="btn btn-primary my-4" *ngIf="List.length" (click)="downloadPDF()">Descargar</button>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</section>
