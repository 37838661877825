import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable( {
  providedIn: 'root'
} )
export class QuoteService {

  constructor(
    private http: HttpService
  ) { }

  list( page = 1 ): Observable<any> {
    return this.http.get( `consultants-quotes/requestsForQuotations?includes[]=service&includes[]=userApplicant&page=${page}` );
  }

  details( id: number ): Observable<any> {
    return this.http.get( `consultancies/getDetailConsultancy/${id}?includes[]=service.branchLaw&includes[]=userApplicant` );
  }

  moreInfo( id: number, data: any ): Observable<any> {
    return this.http.post( `orders/requestForMoreInfo/${id}`, data );
  }

  accept( id: number, formData: any ): Observable<any> {
    const params = new HttpParams();

    const options = {
      params,
      reportProgress: true,
    };
    return this.http.post( `order-lawyer/${id}?_method=PUT`, formData, options );
  }

  getQuoteFormat(): Observable<any> {
    return this.http.get( 'consultants-quotes/getQuotationFormat/1' );
  }


  generateTemplate(order_id): Observable<any> {
    return this.http.get( `document-quote/generate?order_id=${order_id}` );
  }

  getTemplate(): Observable<any> {
    const headers= new HttpHeaders().set('content-type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    return this.http.get( 'document-quote/' ,  { 'headers': headers } );



  }
}
