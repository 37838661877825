<div class="container-fluid">
  <form class="form-valide" action="#" method="post" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Razón Social</strong></label>
          <input type="text" class="form-control" formControlName="business_name">
          <div *ngIf="submitted && f.business_name?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.business_name.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Razón Comercial</strong></label>
          <input type="text" class="form-control" formControlName="business_reason">
          <div *ngIf="submitted && f.business_reason?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.business_reason.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Registro Único de Contribuyente</strong></label>
          <input type="text" class="form-control" formControlName="taxpayer">
          <div *ngIf="submitted && f.taxpayer?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.taxpayer.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Aviso de Operaciones</strong></label>
          <input type="text" class="form-control" formControlName="operations_notice">
          <div *ngIf="submitted && f.operations_notice?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.operations_notice.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>País de Constitución</strong></label>
          <select class="form-control" name="" id="" formControlName="country_of_constitution_id">
            <option [value]="country.id" *ngFor="let country of countries; let i=index">{{country.name}}</option>
          </select>
          <div *ngIf="submitted && f.country_of_constitution_id?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.country_of_constitution_id.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Fecha de Constitución</strong></label>
          <div class="input-group">
            <input class="form-control" name="inicio" ngbDatepicker #di="ngbDatepicker"
              formControlName="constitution_date" [(ngModel)]="constitutionDate">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar h-100" (click)="di.toggle()" type="button"></button>
            </div>
          </div>
          <div *ngIf="submitted && f.constitution_date?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.constitution_date.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>País de Operaciones</strong></label>
          <select class="form-control" name="" id="" formControlName="operation_countries" multiple=true>
            <option [value]="country.id" *ngFor="let country of countries; let i=index">{{country.name}}</option>
          </select>
          <div *ngIf="submitted && f.operation_countries?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.operation_countries.errors.required">Campo requerido.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Representante Legal</strong></label>
          <input type="text" class="form-control" formControlName="legal_agent">
          <div *ngIf="submitted && f.legal_agent?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.legal_agent.errors.required">Campo requerido.</div>
            <div *ngIf="f.legal_agent.errors.minlength">Campo debe ser de mínimo 3 caracteres.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row mb-3">
      <div class="col-12">
        <label class="mb-1"><strong>Documento que lo Certifica</strong></label>
        <div class="input-group">
          <div class="custom-file">
            <input type="file" class="custom-file-input" (change)="onImageChange($event)" formControlName="dni_file"
              accept="image/*, .pdf">
            <label class="custom-file-label">{{fileName}}</label>
          </div>
        </div>
        <div *ngIf="submitted && f.dni_file?.errors" class="text-danger fs-13 mt-1">
          <div *ngIf="f.dni_file.errors.required">
            Campo requerido.
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Persona Autorizada</strong></label>
          <input type="text" class="form-control" formControlName="name">
          <div *ngIf="submitted && f.name?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.name.errors.required">Campo requerido.</div>
            <div *ngIf="f.name.errors.minlength">Campo debe ser de mínimo 3 caracteres.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Cédula de Identidad</strong></label>
          <input type="text" class="form-control" formControlName="dni">
          <div *ngIf="submitted && f.dni?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.dni.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Número de Teléfono</strong></label>
          <input type="number" class="form-control" formControlName="phone">
          <div *ngIf="submitted && f.phone?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.phone.errors.required">Campo requerido.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Email</strong></label>
          <input type="text" class="form-control" formControlName="email">
          <div *ngIf="submitted && f.email?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.email.errors.required">Campo requerido.</div>
            <div *ngIf="f.email.errors.email">Email inválido.</div>
            <div *ngIf="f.email.errors.pattern">Email inválido.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row align-items-end">
      <div class="col-md-4">
        <div class="form-group">
          <label for=""><strong>Provincia</strong></label>
          <select class="form-control" name="" id="" (change)="onChange($event.target.value, 'province')">
                <option disabled selected>Seleccione una opción</option>
            <option [value]="p.id" *ngFor="let p of provinces">{{p.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for=""><strong>Distrito</strong></label>
          <select class="form-control" name="" id="" (change)="onChange($event.target.value, 'city')">
                <option disabled selected>Seleccione una opción</option>
            <option [value]="city.id" *ngFor="let city of cities">{{city.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for=""><strong>Corregimiento</strong></label>
          <select class="form-control" name="township_id" id="township_id"
            (change)="onChange($event.target.value, 'township')">
                <option disabled selected value='0'>Seleccione una opción</option>
            <option [value]="township.id" *ngFor="let township of townships">{{township.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-group">
          <button class="btn btn-primary btn-refresh"  (click)="refreshAddress()" placement="top" ngbTooltip="refrescar">
            <mat-icon aria-hidden="false">
              refresh
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <div class="form-group">
          <label class="mb-1"><strong>Dirección</strong></label>
          <input type="text" class="form-control" formControlName="address">
          <div *ngIf="submitted && f.address?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.address.errors.required">Campo requerido.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Contraseña</strong></label>
          <!-- <input type="password" class="form-control" formControlName="password"> -->
          <div class="group-btn-left">
            <input class="form-control" [type]="typePass" formControlName="password">
            <button class="btn btn-link btn-visible" (click)="onChangePass()" type="button">
              <mat-icon aria-hidden="false">
                {{iconPass}}
                </mat-icon>
            </button>
          </div>
          <div *ngIf="submitted && f.password?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.password.errors.required">Campo requerido.</div>
            <div *ngIf="f.password.errors.minlength">Contraseña debe ser de mínimo 8 caracteres.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Confirmar Contraseña</strong></label>
          <!-- <input type="password" class="form-control" formControlName="password_confirmation"> -->
          <div class="group-btn-left">
            <input class="form-control" [type]="typePass" formControlName="password_confirmation">
            <button class="btn btn-link btn-visible" (click)="onChangePass()" type="button">
              <mat-icon aria-hidden="false">
                {{iconPass}}
                </mat-icon>
            </button>
          </div>
          <div *ngIf="submitted && f.password_confirmation?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.password_confirmation.errors.required">Campo requerido.</div>
            <div *ngIf="f.password_confirmation.errors.mustMatch">Contraseñas no coinciden.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4">
      <button type="submit" class="btn btn-primary btn-block">Registrarse</button>
    </div>
  </form>
</div>
