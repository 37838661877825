<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>{{titlePage}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-header d-block pb-0">
            <form class="">
              <div class="form-row col-12">
                <div class="form-group hidden">
                  <div class="input-group">
                    <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                      [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                      [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="row justify-content-start align-items-end">
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Nombre de Cliente</strong></label>
                    <input class="form-control form-filter" name="name" id="name" [(ngModel)]="searchFilter.name">
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Desde</strong></label>
                    <div class="input-group">
                      <input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Hasta</strong></label>
                    <div class="input-group">
                      <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <div class="input-group">
                      <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                        <i class="fa fa-search"></i>
                      </button>
                      <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="list.length; else nodata" class="card-body">
            <div class="row justify-content-center">
              <div class="col mt-3">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Foto</th>
                        <th scope="col">Nombre de Cliente</th>
                        <th scope="col">Fecha de Registro</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of list; let i=index">
                        <td>
                          <img [src]="item.main_image ? item.main_image : avatar" class="ln-avatar" [alt]="item.name">
                        </td>
                        <td>{{item.name}}</td>
                        <td>{{item.creation_date}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row mr-2 justify-content-end align-items-end">
                    <p><b>Número de Clientes:</b> {{info_object.total_clients}}</p>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-auto custom-div-pagination justify-content-center">
                <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                  [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="dataPagination.currentPage" aria-label="Paginación">
                </ngb-pagination>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
            </div>
          </div>
          <ng-template #nodata>
            <div class="alert m-3" role="alert">
              <strong>No hay data disponible</strong>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>
