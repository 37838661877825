<div class="authincation h-100">
	<div class="container vh-100">
		<div class="row justify-content-center h-100 align-items-center">
			<div class="col-lg-6 col-md-8">
				<div class="authincation-content">
					<div class="row no-gutters">
						<div class="col-xl-12">
							<div class="auth-form">
								<div class="text-center mb-3">
									<a [routerLink]="['/admin/index']">
										<img [src]="logo" alt="" class="w-25">
									</a>
								</div>
								<h4 class="text-center mb-4">Recuperar contraseña</h4>
								<form class="form-valide" action="#" method="post" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate
									[hidden]="hasToken">
									<div class="form-group">
										<label><strong>Email</strong></label>
										<input type="email" class="form-control" formControlName="email">
										<div *ngIf="submitted && f.email?.errors" class="text-danger fs-13 mt-1">
											<div *ngIf="f.email.errors.required">
												Email requerido.
											</div>
											<div *ngIf="f.email.errors.email">
												Email inválido
											</div>
										</div>
									</div>
									<div class="text-center">
										<button type="submit" class="btn btn-primary btn-block">Validar email</button>
									</div>
								</form>
								<form class="form-valide" action="#" method="post" [formGroup]="form2" (ngSubmit)="onSubmit2()"
									novalidate [hidden]="!hasToken">
									<div class="form-group">
										<label><strong>Ingrese token</strong></label>
										<input type="test" class="form-control" formControlName="token">
										<div *ngIf="submitted && g.token?.errors" class="text-danger fs-13 mt-1">
											<div *ngIf="g.token.errors.required">Token requerido.</div>
										</div>
									</div>
									<div class="form-group">
										<label><strong>Ingrese contraseña</strong></label>
										<input type="password" class="form-control" formControlName="new_password">
										<div *ngIf="submitted && g.password?.errors" class="text-danger fs-13 mt-1">
											<div *ngIf="g.new_password.errors.required">Contraseña requerida.</div>
											<div *ngIf="g.new_password.errors.minlength">Contraseña debe ser de mínimo 8 caracteres.</div>
										</div>
									</div>
									<div class="form-group">
										<label><strong>Repetir contraseña</strong></label>
										<input type="password" class="form-control" formControlName="new_password_confirmation">
										<div *ngIf="submitted && g.new_password_confirmation?.errors" class="text-danger fs-13 mt-1">
											<div *ngIf="g.new_password_confirmation.errors.required">Contraseña requerida.</div>
											<div *ngIf="g.new_password_confirmation.errors.mustMatch">Contraseñas no coinciden.</div>
										</div>
									</div>
									<div class="text-center">
										<button type="submit" class="btn btn-primary btn-block">
											Recuperar
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>