<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>Próximos Pagos</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body">
            <ng-container>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Nombre de Servicio</th>
                      <th scope="col">Fase actual</th>
                      <th scope="col">Próximo Pago</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of list; let i=index">
                      <td>{{item.service.name}}</td>
                      <td>{{item.current_phase}}</td>
                      <td>{{item.phase_to_paid.description}}</td>
                      <td>
                        <div>
                          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                            (click)="openModal(detailModal, item)" placement="top" ngbTooltip="Ver">
                            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                              visibility
                            </mat-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end mt-3">
                <div class="col-auto custom-div-pagination">
                  <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                    [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page" aria-label="Paginación">
                  </ngb-pagination>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #detailModal>
  <div class="modal-header">
    <h4 class="modal-title">Detalle de Próximo Pago</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <fieldset>
      <p class="ml-3 mb-2"><strong>Número de Trámite: </strong>{{currentItem.service.id}}</p>
      <p class="ml-3 mb-2"><strong>Nombre de Servicio: </strong>{{currentItem.service.name}}</p>
      <p class="ml-3 mb-2"><strong>Fases Culminadas: </strong>{{bill.completed_phases}}</p>
      <p class="ml-3 mb-2"><strong>Fase Actual: </strong>{{currentItem.phase_to_paid.description}}</p>
    </fieldset>
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <!-- <tr *ngFor="let item of lawyers; let i=index"> -->
          <tr>
            <td>Monto a Pagar:</td>
            <td>{{currentItem.phase_to_paid.sub_total}}</td>
          </tr>
          <tr>
            <td>ITBMS:</td>
            <td>{{currentItem.phase_to_paid.ITBMS}}</td>
          </tr>
          <tr>
            <td>Total a Pagar:</td>
            <td>{{currentItem.phase_to_paid.total}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-primary py-2 mr-3" *ngIf="currentItem" (click)="downloadItemPDF(currentItem.id)">Descargar</button>
        <button class="btn btn-success py-2 mr-3" *ngIf="currentItem" (click)="pagar(payM, currentItem)">Pagar</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #payM>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Métodos de Pagos</h4>
        <button type="button" class="close" aria-label="Close" (click)="closerModalPay()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-payment-method [data]="info"></app-payment-method>
</ng-template>

