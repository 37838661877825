import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LawyerCasesComponent } from '../../lawyer/cases/cases.component';
import { AdminService } from 'src/app/shared/services/admin.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { BankService } from '../../../shared/services/bank.service';
import { Bank } from '../../../shared/interfaces/common';

@Component({
  selector: 'app-accounts-payable',
  templateUrl: './accounts-payable.component.html',
  styleUrls: ['./accounts-payable.component.css']
})
export class AccountsPayableComponent implements OnInit {

  modal: any;

  md1 = {
    user_name: "Helen Velazco",
    process_code: "LM 3432",
    service: "Autenticación",
    expenses: "100$",
    date: "30/04/2021"
  }
  md2 = {
    banking_entity: "Banco General",
    account_type: "Ahorro",
    account_number: "221020830987"
  }
  md3 = {
    Lawyer: "Helen Velazco",
    bank: "General",
    reference_number: "39482"
  }

  branches = [];
  types = [];
  list = [];
  params = '';
  currentItem : any;
  showModal : any;
  info_object : any;
  myFormGroup;
  page; dataPagination;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    branch: null,
    type: null,
    service_name: null,
    lawyer_name: null
  }
  modalPay = {
    amount: '',
    bank: '',
    ref_num: '',
  };
  banks: Bank[] = [];
  hoveredDate: NgbDate | null = null;
  titlePage='Cuentas por Pagar';
  constructor(
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private adminService: AdminService,
    titlePageService: TitlePageService,
    private banksService: BankService,
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
   }

  ngOnInit(): void {
    this.loadData();
    this.loadFilters();
  }

  openModal(detailModal: any, item, modal) {
    this.currentItem = item;
    if (modal == 'detail') {
      this.adminService.getAccountPayableByLawyer(item.id).subscribe(
        response => {
          this.showModal = response;
          this.modal = this.modalService.open(detailModal, { backdrop: 'static', size: 'lg' });
        }
      );
    } else if (modal == 'bank') {
      this.adminService.getAccountBankLawyer(item.id).subscribe(
        response => {
          this.showModal = response;
          this.modal = this.modalService.open(detailModal, { backdrop: 'static', size: 'md' });
        }
      );
    } else if (modal == 'payment') {
      this.showModal = item;
      this.modal = this.modalService.open(detailModal, { backdrop: 'static', size: 'md' });
      console.log('aqui:', this.showModal);
    }
  }

  loadData(page = 1) {
    this.banksService.list().subscribe( res => this.banks = [ ...res.data ] );
    this.adminService.getAccountPayable(page, this.params).subscribe(
      response => {
        this.list = response.data;
        this.info_object = response.info_object;
        this.dataPagination = response.meta.page;
    });
  }

  loadFilters(page = 1) {
    this.adminService.listBranchs().subscribe(
      response => {
        this.branches = response.data;
    });
    this.adminService.listService().subscribe(
      response => {
        this.types = response.data;
    });
  }

  getTotal() {
    return this.info_object.Total;
  }

  downloadPDF() {
     this.adminService.getAccountPayable(null, this.params, true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Cuentas por Pagar.pdf";
        link.click();
    });
  }

  downloadItemPDF(id) {
    this.adminService.getAccountPayableByLawyer(id, 'pdf').subscribe(
     response => {
       var downloadURL = window.URL.createObjectURL( response );
       var link = document.createElement( 'a' );
       link.href = downloadURL;
       link.download = "Cuentas por Pagar - " +  id + ".pdf";
       link.click();
   });
 }

 downloadItemExcel(id) {
  this.adminService.getAccountPayableByLawyer(id, 'excel').subscribe(
   response => {
     var downloadURL = window.URL.createObjectURL( response );
     var link = document.createElement( 'a' );
     link.href = downloadURL;
     link.download = "Cuentas por Pagar - " +  id + ".xls";
     link.click();
 });
}

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.params='';
    this.searchFilter = {
      dateStart: null,
      dateEnd: null,
      branch: null,
      type: null,
      lawyer_name: null,
      service_name: null
    }
    this.toDate = null;
    this.fromDate = null;
    this.loadData();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search() {
    this.searchFilter.dateStart = this.formatter.format(this.fromDate);
    this.searchFilter.dateEnd = this.formatter.format(this.toDate);
    this.params = '';

    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }

    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.searchFilter.lawyer_name) {
      this.params += '&lawyer_name=' + this.searchFilter.lawyer_name;
    }

    if (this.searchFilter.service_name) {
      this.params += '&service_name=' + this.searchFilter.service_name;
    }

    if (this.searchFilter.type !== '') {
      this.params += '&type_service_id=' + this.searchFilter.type;
    }

    if (this.searchFilter.branch !== '') {
      this.params += '&branch_law_id=' + this.searchFilter.branch;
    }
    if (this.params !== '') {
      this.loadData(1);
    }
  }
}
