import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from '../../../shared/services/clients.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbCalendar,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { Options } from '@angular-slider/ngx-slider';
import * as moment from 'moment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { USER } from '../../../shared/data/constants';
import { User } from 'src/app/shared/interfaces/user';
import { StorageService } from 'src/app/shared/services/storage.service';
@Component({
  selector: 'app-regtram',
  templateUrl: './regtram.component.html',
  styleUrls: ['./regtram.component.css']
})
export class RegtramComponent implements OnInit {
  type_service_id = 1;
  form: FormGroup;
  formPay: FormGroup;
  formNewCard: FormGroup;
  isSubmitted = false;
  lisTypeServ = [
    { id: 1, name: "Asesoría" },
    { id: 2, name: "Cotización" }
  ];
  lisFormaAtt = [
    { id: 1, name: "Videollamada" },
    { id: 2, name: "Escrita" }
  ];
  lisAsigLaw = [
    { id: 1, name: "Automática" },
    { id: 2, name: "Manual" }
  ];
  lisTypePay = [
    { id: 1, name: "Tarjeta de Crédito", icon: "flaticon-381-id-card" },
    { id: 2, name: "Transferencia Bancaria", icon: "ti-money" },
  //  { id: 3, name: "Paypal", icon: "icon-paypal" }
  ];

  card_type_list = [
    { id: 1, name: "Visa" },
    { id: 2, name: "MasterCard" },
  ];

  condVerModTrans: boolean = false; modal: any; condVerModCard: boolean = false;
  payFormData: FormData;
  order_id; payment_method_id; statusPayVal: string = '0';


  lisLawyerForPrice = [];
  condFormAtt; condTypeAtt;
  value: number = 0;
  options: Options = {
    floor: 0,
    ceil: 20000
  };
  dataJson: any = [];
  condAsigLaw: boolean = false;
  service_id;
  dataTypeService;
  condSelectLaw: boolean = false; LawSelect; LawSelectDataUser; arrayLaw: any = [];
  condSelecPay: boolean = false; paySelect;
  dataResponse;
  arrayMultiLaw: any = [];


  radioSel: any;
  radioSelected: string;
  radioSelectedString: string;
  ItemExample: any = [];
  condFormAddCard: boolean = false;
  cardDataJson; responseCard; condAcceptCard: boolean = false;
  cardSelectPay;
  page; dataPagination; itemIdPagination; idBranhc;
  condNoPayReq: boolean = false;
  stepsArray: any = [];

  constructor(
    private modalService: NgbModal,
    private ClientsService: ClientsService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: StorageService
  ) {

    this.page = 1;
    this.form = this.formBuilder.group({
      descripcion: ['', [Validators.required]],
      typeServ: ['', [Validators.required]],
      FormaAtt: [''],
      available_date: [''],
      hora: [''],
      asigLaw: ['']
    });

    this.formPay = this.formBuilder.group({
      amount: ['', [Validators.required]],
      reference_number: ['', [Validators.required]],
      reference_file: ['', [Validators.required]]
    });

    this.formNewCard = this.formBuilder.group({
      card_type: ['', [Validators.required]],
      masked_card_number: ['', [Validators.required]],
      card_token: ['', [Validators.required]]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.dataTypeService = params['dataTypeService'];
      this.idBranhc = params['idBranhc'];
    });
  }
  get controls(): any {
    return this.form.controls;
  }
  get f(): any { return this.formPay.controls; }
  get p(): any { return this.formNewCard.controls; }
  ngOnInit(): void {
    this.getRagePrice();
  }

  getTypeServ() {
    this.condFormAtt = this.form.value.typeServ;
    if ( this.condFormAtt === 'Cotización' ) {
      this.condNoPayReq = true;
    }
  }
  getFormaAtt() {
    this.condTypeAtt = this.form.value.FormaAtt;

  }

  getAsigLaw() {
    if (this.form.value.asigLaw === 'Automática') {
      this.condAsigLaw = true;
    } else {
      this.condAsigLaw = false;
    }
    // console.log(this.form.value.asigLaw);
  }

  changePrice() {

    setTimeout(() => {
      // ejecutar filtro de abogados por rango de precios
      this.getLawyerForPrice(this.idBranhc, this.type_service_id, this.value);
    }, 500);
  }

  selectLaw(item) {
    this.condSelectLaw = true;
    this.LawSelect = item.id;
    this.LawSelectDataUser = item.user;
    this.arrayLaw.push(this.LawSelect);
  }


  chageLaw() {
    this.condSelectLaw = false;
    this.LawSelect = [];
  }

  private getLawyerForPrice(branch_law_id, type_service_id, price_end): void {
    this.lisLawyerForPrice = [];
    this.ClientsService.getLawyerForPrice( this.page, 0, price_end, this.dataTypeService).subscribe(response => {
      this.lisLawyerForPrice = response.data;
      this.dataPagination = response.meta.page;
    });
  }
  loadPage(page) {
    this.page = page;
    this.lisLawyerForPrice = [];
    this.getLawyerForPrice(this.idBranhc, this.type_service_id, this.value);

  }

  verifPayMode(payModal, item) {
    this.paySelect = item;
    this.modal = this.modalService.open(payModal);
    if (item.id === 2) {
      this.payment_method_id = 1;

    } else if (item.id === 1) {
      this.payment_method_id = 2;
      this.getTdc();
    } else {

    }
  }


  filterListLaw(item) {
    var i = this.arrayMultiLaw.indexOf(item.id);
    if (i !== -1) {
      this.arrayMultiLaw.splice(i, 1);
    } else {
      this.arrayMultiLaw.push(item.id)
    }
    this.arrayLaw = this.arrayMultiLaw;
  }

  sendData() {
    this.isSubmitted = true;
    const format2 = 'YYYY-MM-DD HH:mm';
    const datePicker = this.form.value.available_date;
    const hour = this.form.value.hora;
    this.form.value.available_date = datePicker.year + '-' + datePicker.month + '-' + datePicker.day + ' ' + hour;
    this.form.value.available_date = moment(this.form.value.available_date).format(format2);
    if (this.form.valid) {
      if (this.form.value.FormaAtt === 'Videollamada') {
        this.form.value.FormaAtt = 'Videollamada';
      }
      if (this.condFormAtt === 'Cotización') {
        this.dataJson = {
          "description": this.form.value.descripcion,
          "type": this.form.value.typeServ,
          "auto": this.condAsigLaw,
          "consulting_type": this.form.value.FormaAtt,
          "service_id": this.dataTypeService,
          "lawyers": this.arrayLaw
        }
      } else {

        if (this.form.value.FormaAtt === 'Videollamada') {
          this.dataJson = {
            "description": this.form.value.descripcion,
            "type": this.form.value.typeServ,
            "auto": this.condAsigLaw,
            "consulting_type": this.form.value.FormaAtt,
            "service_id": this.dataTypeService,
            "lawyers": this.arrayLaw,
            "available_date": this.form.value.available_date,
          }
        } else {
          this.dataJson = {
            "description": this.form.value.descripcion,
            "type": this.form.value.typeServ,
            "auto": this.condAsigLaw,
            "consulting_type": this.form.value.FormaAtt,
            "service_id": this.dataTypeService,
            "lawyers": this.arrayLaw,
          }
        }

      }

      this.ClientsService.regTram1(this.dataJson).subscribe(
        async (response) => {

          this.dataResponse = response;

          this.order_id = this.dataResponse.data.id;
          if ( this.condFormAtt === 'Asesoría') {


          if (this.payment_method_id === 1) {
            this.payFormData.append('order_id', this.order_id);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: this.dataResponse.message,
              showConfirmButton: true,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.isSubmitted = false;
                this.alertRegPay();
                //  this.router.navigate(['/person/process']);

              }
            });
          } else if (this.payment_method_id === 2) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: this.dataResponse.message,
              showConfirmButton: true,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.isSubmitted = false;
                this.alertSimularPay();
                //  this.router.navigate(['/person/process']);

              }
            });
          }
        } else {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: this.dataResponse.message,
            showConfirmButton: true,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.isSubmitted = false;
              this.router.navigate(['/person/foll-services']);

            }
          });
        }


        },
        (error) => {

        });

    }
  }

  alertRegPay() {

    Swal.fire({
      position: 'center',
      // icon: 'success',
      title: 'Registrando pago',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 3000,
      didOpen: () => {
        Swal.showLoading();
      }
    }).then((result) => {

      this.ClientsService.regPayment(this.payFormData).subscribe(
        async (response) => {

          this.alertRegPayOk(response);
        },
        (error) => {
          this.alertRegPayFail(error);
        }
      );

    });

  }

  alertSimularPay() {
    Swal.fire({
      position: 'center',
      title: 'Registrando pago',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 3000,
      didOpen: () => {
        Swal.showLoading();
      }
    }).then((result) => {
      this.router.navigate(['/person/foll-services']);
    });
  }

  alertRegPayOk(response) {

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: response.message,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/person/foll-services']);
      }
    });
  }

  alertRegPayFail(error) {

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Se generó un error al registrar el pago',
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/person/foll-services']);
      }
    });
  }

  onSubmit(): void { }

  upload() {

  }

  sendDataPay() {


    const formD = document.getElementById('formPay') as HTMLFormElement;
    this.payFormData = new FormData(formD);
    this.payFormData.append('payment_method_id', this.payment_method_id);
    this.payFormData.append('status', this.statusPayVal);

    this.modalService.dismissAll();
    this.condSelecPay = true;

  }

  closed() {
    //  this.submitted = false;
    this.modalService.dismissAll();
    //  this.case = [];
  }

  getSelecteditem(item) {
    this.radioSel = this.ItemExample.find(Item => Item.value === this.radioSelected);
    this.radioSelectedString = JSON.stringify(this.radioSel);
    if (item !== null) {
      this.condAcceptCard = true;
      this.cardSelectPay = item.card_token;
    }
  }

  onItemChange(item) {
    this.getSelecteditem(item);
  }

  addNewCard() {
    this.condFormAddCard = true;
    this.condAcceptCard = false;
  }

  closedaddCard() {
    this.condFormAddCard = false;
    if (this.cardSelectPay !== null && this.cardSelectPay !== undefined && this.cardSelectPay !== []) {
      this.condAcceptCard = true;
    } else {
      this.condAcceptCard = false;
    }

  }

  regNewCard() {
    this.cardDataJson = {
      "card_token": this.formNewCard.value.card_token,
      "masked_card_number": this.formNewCard.value.masked_card_number,
      "card_type": this.formNewCard.value.card_type,
    };

    this.ClientsService.tdc(this.cardDataJson).subscribe(
      async (response) => {
        this.condFormAddCard = false;
        this.alertRegCard(response);
      },
      (error) => {
        this.alertRegCardFail(error);
      }
    );
  }

  alertRegCardFail(error) {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: error.message.message,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.condFormAddCard = false;
      }
    });
  }

  alertRegCard(response) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: response.message,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.getTdc();
      }
    });
  }

  getTdc() {
    const user: User = this.storage.getItem(USER);
    this.ClientsService.getTdc(user.id).subscribe(response => {
      this.ItemExample = response.data;
    });
  }

  accepSelectCard() {
    this.condSelecPay = true;
    this.modalService.dismissAll();
  }
  getRagePrice() {
    this.ClientsService.getRagePrice(this.dataTypeService).subscribe(response => {
      for (let index = 0; index < response.data.range_values.length; index++) {
        var element = response.data.range_values[index];
        this.stepsArray.push(
          { value: Math.floor(element) }
        );
      }
      this.options = {
        showTicksValues: true,
        stepsArray:
          this.stepsArray
      };
    });
  }



}
