import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { DashboardService } from '../../../services/dashboard.service';

@Component( {
  selector: 'app-lawyer-marketplace',
  templateUrl: './lawyer-marketplace.component.html',
  styleUrls: [ './lawyer-marketplace.component.css' ]
} )
export class LawyerMarketplaceComponent implements OnInit {
  data: any;
  lawyerType: string;
  Datachart = {};
  Type = "Marketplace";
  parentSubject:Subject<any> = new Subject();
  constructor(
    private dashboadService: DashboardService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: 'Inicio'};
   }

 async ngOnInit() {
   await this.getDashboardInfo();
  }

  navigateTo(url) {
	  const newUrl = new URL(window.location.href).pathname = url;
	  window.location.href = newUrl;
  }

 async getDashboardInfo() {
    this.dashboadService.get().subscribe( response => {
      this.data =  response.data.marketplace; 

      //this.CalcPorcentChart();

      // console.log( this.Datachart);
    });
  }

  CalcPorcentChart(){
      let services  = {};
      let fordata = this.data.active_services;
      let total = this.data.total_cases;
      for (const key in fordata) {
        let name = fordata[key]['branch_law_name'];
        let value = fordata[key]['active_orders'];
        if(total!=0 && value!=0){
          // 
          let result = total/fordata[key]['active_orders'];
          services[name] = result;
        }
   
      }
      this.parentSubject.next(services);
      
  }
}
