import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { LAWYER_TYPE } from '../../data/constants';

@Component( {
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: [ './navigation.component.css' ],
} )
export class NavigationComponent implements OnInit {
  currentHref = '';
  toggleIcon = true;
  role: string;
  lawyerType: string;
  disable_menus: any;

  configArray = [
    'admin/config/banks',
    'admin/config/requirements',
    'admin/config/entities',
    'admin/config/services',
    'admin/config/branches-law',
  ];

  constructor(
    location: Location,
    router: Router,
    private auth: AuthService,
    private storage: StorageService,
  ) {
    this.role = this.auth.getRole();
    this.lawyerType = this.storage.getItem( LAWYER_TYPE );
    let disable_menus = this.storage.getItem('disable_menus');
    if(disable_menus){
      this.disable_menus = disable_menus.join(" - ");
    }else{
      this.disable_menus = '';
    }

    router.events.subscribe( ( val ) => {
      if ( location.path() !== '' ) {
        this.currentHref = location.path();
      } else {
        this.currentHref = 'Home';
      }
    } );
  }

  ngOnInit(): void { }

  toggleLoveIcon(): void {
    this.toggleIcon = !this.toggleIcon;
  }

  isDisable(val): boolean{
    return !(this.disable_menus.indexOf(val) > -1);
  }

  collapseList(){
    var aa = document.querySelectorAll("a.has-arrow");
    for(var i=0; i<aa.length; i++){
      if(aa[i].parentElement.classList.value.indexOf("mm-active") > -1){
        aa[i].parentElement.classList.remove("mm-active");
        aa[i].setAttribute("aria-expanded", "false");
        (aa[i] as any).parentElement.lastChild.classList.remove("mm-show");
      }
    }
  }
}
