
<div class="card">
    <div class="card-body" [hidden]="messagePay">

        <div class="card-text"><strong>Concepto:</strong> {{data.description}}</div>
        <div class="card-text mb-4"><strong>Total a Pagar:</strong> {{data.amount}}</div>
        <div class="card-text mb-4"><strong>Monto restante:</strong> {{data.phase_info.pending}}</div>

        <div [hidden]="payPaypal">
            <div *ngIf="pagesView==0">
                <ul  class="list-group">
                    <li class="list-group-item active">Selecciona el método de pago</li>
                    <li *ngFor="let pay of paymentM" class="list-group-item">
                        <div class="custom-control custom-radio">
                            <input [(ngModel)]="paymentSelected" [value]="pay" type="radio" id="customRadioPay{{pay.id}}" name="customRadioPay" class="custom-control-input">
                            <label class="custom-control-label pl-2" for="customRadioPay{{pay.id}}">{{pay.name}}</label>
                        </div>

                    </li>
                </ul>
                <div *ngIf="message" class="text-danger fs-13 mt-2">
                    Seleccione un método de pago 
                </div>
                <div class="text-center mt-4">
                    <a class="btn btn-primary text-white" (click)="next()">Siguiente</a>
                </div>
            </div>
            <div *ngIf="pagesView==1">
                <ul  class="list-group">
                    <li class="list-group-item active">Tarjeta de crédito</li>
                    <li  class="list-group-item">

                        <form class="form-valide" action="#" method="post" [formGroup]="cardFormGroup" (ngSubmit)="registerPayment()" novalidate>


                            <div class="form-row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Nombre</strong></label>
                                        <div class="input-group">
                                        <input class="form-control" type="text" formControlName="name" >
                                        </div>
                                        <div *ngIf="submitted && f.name?.errors" class="text-danger fs-13 mt-1">
                                        <div *ngIf="f.name.errors.required"> Campo requerido </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Apellido</strong></label>
                                        <div class="input-group">
                                        <input class="form-control" type="text" formControlName="last_name">
                                        </div>
                                        <div *ngIf="submitted && f.cvv?.errors" class="text-danger fs-13 mt-1">
                                        <div *ngIf="f.last_name.errors.required"> Campo requerido</div>
                                        <div *ngIf="f.last_name.pattern">Campo debe tener 3 números</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                <div class="form-group">
                                    <label class="mb-1"><strong>Número tarjeta</strong></label>
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text"><i class="fa fa-credit-card"></i></div>
                                    </div>
                                    <input type="text" maxlength="16" class="form-control" formControlName="ccnumber">
                                    </div>
                                    <div *ngIf="submitted && f.ccnumber?.errors" class="text-danger fs-13 mt-1">
                                    <div *ngIf="f.ccnumber.errors.required"> Campo requerido</div>
                                    <div *ngIf="f.ccnumber.errors.pattern">Campo debe tener 16 números</div>
                                    </div>
                                </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Fecha caducidad</strong></label>
                                        <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                                        </div>
                                        <input class="form-control" placeholder="MM/YY" maxlength="5"  formControlName="ccexp" >
                                        </div>
                                        <div *ngIf="submitted && f.ccexp?.errors" class="text-danger fs-13 mt-1">
                                        <div *ngIf="f.ccexp.errors.required"> Campo requerido </div>
                                        <div *ngIf="f.ccexp.errors.pattern">El formato debe ser MM/YY</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Código de seguridad</strong></label>
                                        <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="fa fa-key"></i></div>
                                        </div>
                                        <input type="text" maxlength="3" class="form-control" formControlName="cvv">
                                        </div>
                                        <div *ngIf="submitted && f.cvv?.errors" class="text-danger fs-13 mt-1">
                                        <div *ngIf="f.cvv.errors.required"> Campo requerido</div>
                                        <div *ngIf="f.cvv.errors.pattern">Campo debe tener 3 números</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Monto</strong></label>
                                        <div class="input-group">
                                        <input class="form-control" type="text" formControlName="amount" >
                                        </div>
                                        <div *ngIf="submitted && f.amount?.errors" class="text-danger fs-13 mt-1">
                                        <div *ngIf="f.amount.errors.required"> Campo requerido </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center mt-4 mb-3">
                                <button type="button" class="btn btn-outline-light" (click)="pagesView=0">Cancelar</button>
                                <button type="submit" class="btn btn-primary ml-2 ">Realizar Pago</button>
                            </div>

                        </form>

                    </li>
                </ul>
            </div>
            <div *ngIf="pagesView==2">
                <ul  class="list-group">
                    <li class="list-group-item active">Transferencia</li>
                    <li  class="list-group-item">


                        <div class="form-group">
                            <label class="mb-1"><strong>Referencia</strong></label>
                            <div class="input-group">
                            <input class="form-control" type="number" min="1" [(ngModel)]="refTransfer">
                            </div>
                            <div *ngIf="submittedTransfer && refTransfer==''" class="text-danger fs-13 mt-1">
                                    Campo requerido
                            </div>
                        </div>
                        <form class="form-valide" action="#" method="post" [formGroup]="transferFormGroup" enctype="multipart/form-data"
                        id="transferFormGroup" (ngSubmit)="registerTransfer()" novalidate>

                            <div class="form-group text-left">
                                <label class="mb-1"><strong>Documento</strong></label>
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input
                                        type="file"
                                        class="custom-file-input"
                                        formControlName="file_url" name="file_url"
                                        (change)="onTransferChange($event)"
                                        accept="application/pdf,image/jpeg,image/png"
                                        required
                                        />
                                        <label class="custom-file-label">{{ fileName }}</label>
                                    </div>
                                </div>
                                <div *ngIf="submittedTransfer && t.file_url?.errors" class="text-danger fs-13 mt-1">
                                    <div *ngIf="t.file_url.errors.required"> Campo requerido </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-1"><strong>Monto</strong></label>
                                <div class="input-group">
                                    <input class="form-control" type="number" min="1" formControlName="amount" >
                                </div>
                                <div *ngIf="submittedTransfer && t.amount?.errors" class="text-danger fs-13 mt-1">
                                    <div *ngIf="t.amount.errors.required"> Campo requerido </div>
                                </div>
                            </div>

                            <div class="text-center mt-5 mb-3">
                                <button type="button" class="btn btn-outline-light" (click)="pagesView=0">Cancelar</button>
                                <button type="submit" class="btn btn-primary ml-2 ">Realizar Pago</button>
                            </div>

                        </form>

                    </li>
                </ul>

            </div>
        </div>

        <div [hidden]="!payPaypal">
            <ul  class="list-group">
                <li class="list-group-item active">Paypal</li>
                <li  class="list-group-item">
                    <div class="card-body text-center">
                        <div #paypal></div>
                    </div>
                </li>
            </ul>

            <div class="text-center mt-4 mb-3">
                <button type="button" class="btn btn-outline-light" (click)="pagesView=0;payPaypal=false">Cancelar</button>
            </div>
        </div>

    </div>

    <div class="card-body" [hidden]="!messagePay">


        <div class="alert alert-info text-center my-4" role="alert">
            {{messageText}}
        </div>



    </div>
</div>



