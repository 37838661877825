import { Injectable, TemplateRef } from '@angular/core';

@Injectable( {
  providedIn: 'root'
} )
export class ToastsService {
  toasts: any[] = [];

  constructor() { }

  /**
   * 
   * @description Toast el cual recibe opcionalmente classname y delay(si no recibe delay por defecto queda en 5000 ms),
   *  bg-success text-white pasa success y bg-danger text-white para danger, bg-warning text-white para advertencias
   * @param textOrTpl
   * @param options {classname, delay}
   */
  show( textOrTpl: string | TemplateRef<any>, options: any = {} ): void {
    this.toasts.push( { textOrTpl, ...options } );
  }

  remove( toast ): void {
    this.toasts = this.toasts.filter( t => t !== toast );
  }

}
