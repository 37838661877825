import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { LawyerService } from 'src/app/shared/services/lawyer.service';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.css']
})
export class StatementComponent implements OnInit {
  list = [];
  params = '';
  page; dataPagination;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null
  }
  hoveredDate: NgbDate | null = null;

  constructor(
    private lawyerService: LawyerService,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData(page = 1) {
    this.lawyerService.getAccountStatement(page).subscribe(
      response => {
        console.log(response);
        this.list = response.data;
        this.dataPagination = response.meta.page;
    });
  }

  getTotal() {
    let total = 0;
    this.list.forEach(item => {
      total += +item.total_amount;
    })
    return total;
  }

  search(){
    this.searchFilter.dateStart = this.formatter.format(this.fromDate);
    this.searchFilter.dateEnd = this.formatter.format(this.toDate);
    this.params = '';
    
    if (this.searchFilter.dateStart!=='') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }
    
    if (this.searchFilter.dateEnd!=='') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.params !== '') {
       this.lawyerService.getAccountStatement(null, this.params).subscribe(response => {
        this.list = [...response.data];
      });
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.loadData();
    this.searchFilter = {
      dateStart: null,
      dateEnd: null
    }
    this.toDate = null;
    this.fromDate = null;
  }
  
  downloadPDF() {
    this.lawyerService.getAccountStatementPdf(this.params).subscribe(
      response => {
        const blob = new Blob( [ response ], { type: 'application/pdf' } );
        var downloadURL = window.URL.createObjectURL( blob );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Estado de cuenta.pdf";
        link.click();
      }
    );
  }
}
