import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';

@Component( {
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: [ './person.component.css' ]
} )
export class PersonComponent implements OnInit {

  title = 'Legal Nexus';
  navSidebarClass = true;
  hamburgerClass = false;

  constructor( public sharedService: SharedService ) { }

  ngOnInit(): void {
  }

}
