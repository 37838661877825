import { Injectable, Output } from '@angular/core';

@Injectable( {
  providedIn: 'root'
} )
export class SharedService {

  navSidebarClass = true;
  hamburgerClass = false;

  constructor() { }

  toggleSidebarClass(): boolean {
    return this.navSidebarClass = !this.navSidebarClass;
  }
  toggleHamburgerClass(): boolean {
    return this.hamburgerClass = !this.hamburgerClass;
  }


}
