<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>{{titlePage}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-header d-block pb-0">
            <form class="">
              <div class="form-row col-12">
                <div class="form-group hidden">
                  <div class="input-group">
                    <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                      [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                      [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end align-items-end">
                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="mb-1"><strong>Desde</strong></label>
                    <div class="input-group">
                      <input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="mb-1"><strong>Hasta</strong></label>
                    <div class="input-group">
                      <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="mb-1"><strong>Nombre de Abogado</strong></label>
                    <input class="form-control form-filter" name="name" id="name" [(ngModel)]="searchFilter.name">
                  </div>
                </div>
              </div>
              <div class="row justify-content-start align-items-end">
                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="mb-1"><strong>Rama</strong></label>
                    <select class="form-control form-filter" name="type" id="type" [(ngModel)]="searchFilter.branch">
                      <option selected value="">Todos</option>
                      <option [value]="branch.name" *ngFor="let branch of branches">{{branch.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="form-group">
                    <label class="mb-1"><strong>Planes</strong></label>
                    <select class="form-control form-filter" name="type" id="type" [(ngModel)]="searchFilter.plan">
                      <option selected value="">Todos</option>
                      <option [value]="plan.id" *ngFor="let plan of listPomotion">{{plan.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 col-md-2">
                  <div class="form-group">
                    <div class="input-group">
                      <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                        <i class="fa fa-search"></i>
                      </button>
                      <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <ng-container *ngIf="list.length; else nodata">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Foto</th>
                      <th scope="col">Nombre Completo</th>
                      <th scope="col">Rama</th>
                      <th scope="col">Plan Actual</th>
                      <th style="width: 165px;">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of list; let i=index">
                      <td class="col-auto">
                        <img [src]="item.main_image ? item.main_image : avatar" class="ln-avatar" [alt]="item.main_image">
                      </td>
                      <td>{{item.user.full_name}}</td>
                      <td><span class="mr-3" *ngFor="let branch of item.branch_laws;">{{branch.name}}</span></td>
                      <td>{{item?.plan?.name}}</td>
                      <td>
                        <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                          (click)="openModal(detailModal, item)" placement="top" ngbTooltip="Ver">
                          <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                            visibility
                          </mat-icon>
                        </button>
                        <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                          (click)="openLawyerPlanModal(lawyerPlanModal, item)" placement="top" ngbTooltip="Cambiar plan">
                          <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                            edit
                          </mat-icon>
                        </button>
                        <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                          (click)="disableLawyer(item.id)" placement="top" ngbTooltip="Desactivar">
                          <mat-icon aria-hidden="false" aria-label="Example home icon" style="color:#dc3545">
                            highlight_off
                          </mat-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div class="row justify-content-end mt-3">
                  <div class="col-auto custom-div-pagination justify-content-center">
                    <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                      [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="dataPagination.currentPage" aria-label="Paginación">
                    </ngb-pagination>
                  </div>
                </div>
              </div>
              <div class=" text-center">
                <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
              </div>
            </ng-container>
            <ng-template #nodata>
              <div class="alert alert-info" role="alert">
                <strong>No hay data disponible</strong>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #detailModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Perfil del Abogado</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="card-body">
      <div class="col-12 flex-column text-center p-0 mb-3">
        <img [src]="showLawyer.main_image ? showLawyer.main_image : avatar" class="modal-avatar-img" />
      </div>
      <h3 class="text-center mb-2">{{showLawyer.user.full_name}}</h3>
      <p class="text-center mb-3">{{showLawyer.description}}</p>
      <div class="ln-info-card">
        <h3 class="my-3">Datos Básicos</h3>
        <hr>
        <div class="col"><strong>Número de Idoneidad:</strong> {{showLawyer.suitability_number}}</div>
        <div class="col"><strong>Cédula:</strong> {{showLawyer.dni}}</div>
        <div class="col"><strong>Teléfono:</strong> {{showLawyer.phone}}</div>
        <br>
        <h3 class="my-3">Estudios</h3>
        <hr>
        <div class="row" *ngFor="let level of showLawyer.study_levels; let i=index">
          <div class="col"><strong>Nivel:</strong> {{level.name}}</div>
          <div class="col"><strong>Título obtenido:</strong> {{level.obtained_title}}</div>
          <div class="col"><strong>Institución:</strong> {{level.college}}</div>
        </div>
        <br>
        <h3 class="my-3">Dirección</h3>
        <hr>
        <div class="row">
          <div class="col"><strong>Provincia:</strong> {{showLawyer.address?.province.name}}</div>
          <div class="col"><strong>Distrito:</strong> {{showLawyer.address?.city.name}}</div>
          <div class="col"><strong>Corregimiento:</strong> {{showLawyer.address?.township.name}}</div>
        </div>
        <div class="row">
          <div class="col"><strong>Dirección:</strong> {{showLawyer.address?.address}}</div>
        </div><br>
        <h3 class="my-3">Datos Bancarios</h3>
        <hr>
        <div class="row">
          <div class="col"><strong>Titular de cuenta:</strong> {{showLawyer.bank?.owner_name}}</div>
          <div class="col"><strong>Cédula de Identidad:</strong> {{showLawyer.bank?.owner_dni}}</div>
        </div>
        <div class="row">
          <div class="col"><strong>Entidad Bancaria:</strong> {{showLawyer.bank?.bank_data.name}}</div>
          <div class="col"><strong>Tipo de Cuenta:</strong> {{showLawyer.bank?.account_type}}</div>
        </div>
        <div class="row">
          <div class="col"><strong>Número de Cuenta:</strong> {{showLawyer.bank?.account_number}}</div>
        </div>
      </div>
    </div>

  </div>
  <!--
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Perfil del Abogado</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="showLawyer" class="modal-body p-0">
    <img src="/assets/images/big/img9.jpg" class="modal-banner" alt="banner">
    <div class="modal-avatar text-center">
      <img [src]="showLawyer.main_image ? showLawyer.main_image : avatar" class="ln-avatar">
    </div>
    <div class="card-body pt-3">
      <h3 class="text-center mb-3">{{ showLawyer.user.full_name }}</h3>
      <p><strong>Número de Identidad:</strong> {{showLawyer.suitability_number}}</p>
      <div class="my-4">
        <h5>Estudios Realizados</h5>
        <div class="ml-5" *ngFor="let item of showLawyer.study_levels">
          <p>{{item.name}} : {{item.obtained_title}}</p>
        </div>
      </div>
      <div class="mb-4">
        <h5>Especialidades</h5>
          <p class="ml-5">
            <span *ngFor="let branch of showLawyer.branch_laws; let i = index">{{ branch.name }}
              <span *ngIf="showLawyer.branch_laws.length > i + 1"> - </span>
            </span>
          </p>
      </div>
    </div>
  </div>
-->
</ng-template>

<ng-template #lawyerPlanModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar plan del Abogado</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="card-body">
      <div class="row justify-content-center align-items-end mb-3 mt-3">
        <div class="col-md-8 text-center">
          <p *ngIf="isActive()" class="text-success m-0">Plan Activo</p>
          <p *ngIf="!isActive()" class="text-success m-0">Plan inactivo</p>
        </div>
        <div class="col-md-8 text-center" *ngIf="selected_lawyer?.plan_expired_at != null">
          <p class="m-0">Fecha de expiración: {{getDate(selected_lawyer.plan_expired_at)}}</p>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label class="mb-1"><strong>Planes</strong></label>
            <select class="form-control form-filter" name="type" id="type" [(ngModel)]="form_plan.plan_id" (ngModelChange)="form_plan.subscription_type=''">
              <option selected value="0">Seleccione</option>
              <option [value]="plan.id" [disabled]="plan.disabled" *ngFor="let plan of planList">{{plan.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label class="mb-1"><strong>Tipo de suscripción</strong></label>
            <select
              class="form-control form-filter"
              name="type"
              id="type"
              [(ngModel)]="form_plan.subscription_type"
              [disabled]="!('1-2-4'.indexOf(form_plan.plan_id) > -1)"
            >
              <option selected value="">Seleccione</option>
              <option value="biannual">Semestral</option>
              <option value="annual">Anual</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row justify-content-end">
      <div class="col-sm-12">
        <button
          class="btn btn-primary"
          (click)="saveLawyerPlan(lawyerPlanModal)"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</ng-template>
