<!--**********************************
    Footer start
***********************************-->
<div class="footer">
    <div class="copyright">
       <!-- <p>Copyright © Designed &amp; Developed by <a href="http://dexignzone.com/" target="_blank">DexignZone</a> 2021</p> -->
    </div>
</div>
<!--**********************************
    Footer end
***********************************-->
