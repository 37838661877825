import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { DataResponse } from '../interfaces/response';
import { Documents } from '../interfaces/common';


@Injectable( {
  providedIn: 'root'
} )
export class MyDocumentsService {

  constructor(
    private http: HttpService
  ) { }

 /**
   * @description Lista documentos
   * @returns Listado
   */
  myDocuments(page: number): Observable<any> {
    return this.http.get( `order-documents/getServices?page=${page}` );
  }

  /**
   * @description Subir documentos asociados a una orden
   */
  uploadDocuments(doc: any): Observable<Documents> {
    return this.http.post(`order-documents`, doc);
  }


  /**
   * @description Descargar documentos asociados a una orden
   */
   downloadDocuments(id: number): Observable<any> {
    return this.http.get( `order-documents/download/${id}` );
  }

  /**
   * @description Lista documentos filtrado por rama del derecho y servicio
   * @returns Listado
   */
   myDocumentsBS(branch_id: number, service_id: number): Observable<any> {
    return this.http.get( `order-documents/lawyer-ser?branch_law_id=${branch_id}&type_service_id=${service_id}&includes[]=orderDocuments` );
  }

  /**
   * @description Subir documentos asociados a una orden
   */
   uploadDocumentsLaywer(doc: any): Observable<Documents> {
    return this.http.post(`order-documents/lawyer-ser`, doc);
  }

  typeService(): Observable<any> {
    return this.http.get( `type-services` );
  }

  /**
   * @description Subir documentos del cliente por abogado de casos externos
   */
   uploadDocumentsLaywerClients(doc: any): Observable<Documents> {
    return this.http.post(`lawyer-clients-document`, doc);
  }

  /**
   * @description Lista documentos de un abogado externo
   * @returns Listado
   */
   documentsLaywer(): Observable<any> {
    return this.http.get( `lawyer-documents` );
  }

  /**
   * @description Lista documentos de un abogado externo filtrados
   * @returns Listado
   */
   documentsLaywerFilter(search: string): Observable<DataResponse<any[]>> {
    return this.http.get( `lawyer-documents?${search}` );
  }

  /**
   * @description Subir documentos del abogado externo
   */
  uploadDocumentsLaywerExternal(doc: any): Observable<Documents> {
    return this.http.post(`lawyer-documents`, doc);
  }

  /**
  * @description Elimina documentos independientes de un abogado externo
  */
  deleteDocumentsLaywerExternal(id: number ): Observable<any> {
    return this.http.delete(`lawyer-documents/${id}`);
  }


   
}

