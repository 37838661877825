
import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { CalendarService } from '../../services/calendar.service';
import { Calendar, CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import { NgxSpinnerService } from 'ngx-spinner';
import esLocale from '@fullcalendar/core/locales/es';
declare var gapi: any;
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/shared/services/storage.service';
import { User } from 'src/app/shared/interfaces/user';
import { USER, AVATAR } from 'src/app/shared/data/constants';
@Component({
  selector: 'app-my-calendar',
  templateUrl: './my-calendar.component.html',
  styleUrls: ['./my-calendar.component.css']
})
export class MyCalendarComponent implements OnInit {
  calendarItems: Array<{}> = [];
  firstFormGroup!: FormGroup;
  Date = {
    min: moment().format('YYYY-MM-DD'),
    now: moment().format('YYYY-MM-DD'),
    month: moment().format('MM'),
    year: moment().format('YYYY')
  }

  @ViewChild('serviceModal', { static: true }) Modal: ElementRef<HTMLElement>;
  @ViewChild('eventModal', { static: true }) EventModal: ElementRef<HTMLElement>;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarEl: HTMLElement = document.getElementById('calendar')!;
  calendarOptions: CalendarOptions;
  calendar: any;
  modal: any;
  DataModal = {};
  submitted = false;
  Title_Modal = 'Crear Evento';
  Edit = false;
  user: User;
  constructor(private CalendarService: CalendarService,
    private spinner: NgxSpinnerService,
    private storage: StorageService,
    private modalService: NgbModal,
    private fb: FormBuilder,) {

    this.user = this.storage.getItem(USER);
    if (this.user.roles[0].name !== 'person' && this.user.roles[0].name !== 'company') {
      this.calendarOptions = {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        editable: true,
        locale: esLocale,
        dateClick: this.handleDateClick.bind(this),
        eventClick: this.ShowEventModal.bind(this),
        dayMaxEvents: true,
        selectable: true,
        customButtons: {
          myCustomButton: {
            text: ' + ',
            click: () => this.ShowModalAddEvent()
          },
          next: {
            click: (e) => { this.ChangeDateGetCalendar('next'); this.calendarComponent.getApi().next() }
          },
          prev: {
            click: (e) => { this.ChangeDateGetCalendar('prev'); this.calendarComponent.getApi().prev() }
          },
          today: {
            text: 'Hoy',
            click: (e) => { this.ChangeDateGetCalendar('today'); this.calendarComponent.getApi().today() }
          }
        },
        headerToolbar: {
          right: 'myCustomButton today prev,next'
        }
      };
    } else {
      this.calendarOptions = {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        editable: false,
        locale: esLocale,
        dateClick: this.handleDateClick.bind(this),
        eventClick: this.ShowEventModal.bind(this),
        dayMaxEvents: true,
        selectable: true,
      };
    }


    this.CalendarInit();
    this.createForm();
  }

  ngOnInit(): void {

  }

  get f(): any {
    return this.firstFormGroup.controls;
  }

  async CalendarInit() {
    this.spinner.show();
    let init = await this.CalendarService.initClient()
    if (init == 'success') {
      if (this.CalendarService.isAuthenticatedCalendar()) {

        // setTimeout(function(){   document.getElementById("getcalendar").click(); }, 1000);
        this.GetCalendar();
      } else {

        setTimeout(function () { document.getElementById("logincalendar").click(); }, 1000);

      }
    }

  }

  async ClickLogin() {
    await this.CalendarService.login_calendar();
    this.GetCalendar();
  }

  GetCalendar() {


    this.calendarItems = [];

    this.CalendarService.getCalendar(this.Date).subscribe(
      Reponse => {

        let object = Reponse.data;
        console.log(object);
        for (const key in object) {
          if (Object.prototype.hasOwnProperty.call(object, key)) {
            const element = object[key];
            this.calendarItems.push({


              title: element.title,
              start: element.date,
              end: element.date_end,

              url_display: element.url,
              description: element.description,
              id_legal_nexus: element.id,
              id: element.google_id
            })
          }
        }



        this.calendarOptions.events = this.calendarItems;


      }
    );



    //   dateClick: this.handleDateClick.bind(this),
  }

  ChangeDateGetCalendar(event: string) {
    switch (event) {
      case "next":
        this.Date.month = moment(this.Date.month, 'MM').add(1, 'M').format("MM");

        break;
      case "prev":
        this.Date.month = moment(this.Date.month, 'MM').subtract(1, 'M').format("MM");
        break;
      case "today":
        this.Date.month = moment().format("MM");
        break;


    }

    this.GetCalendar();
  }

  handleDateClick(arg) {

    if (moment(arg.dateStr).isSameOrAfter(this.Date.now, 'day')) {
      this.firstFormGroup.get('dateinic').setValue(moment(arg.dateStr).format('YYYY-MM-DD'));
      this.firstFormGroup.get('dateend').setValue(moment(arg.dateStr).format('YYYY-MM-DD'));

      this.ShowModalAddEvent();
    }

  }




  ShowEventModal(arg: any): void {
    this.Title_Modal = 'Evento';
    this.Edit = true;
    this.LoadData(arg);
    this.openModal(this.EventModal, 'lg');
  }

  LoadData(arg: any) {
    let result = arg['event'];

    this.DataModal['Title'] = result.title;
    this.DataModal['DateInic'] = this.ReturnDate(result['start']);
    this.DataModal['DateEnd'] = this.ReturnDate(result['end']);
    this.DataModal['TimeInic'] = this.ReturnDate(result['start'], 'time');
    this.DataModal['TimeEnd'] = this.ReturnDate(result['end'], 'time');
    this.DataModal['Url'] = result.extendedProps.url_display;
    this.DataModal['description'] = result.extendedProps.description;
    this.DataModal['ID'] = result.extendedProps.id_legal_nexus;
    this.DataModal['GOOGLE_ID'] = result.id;

    this.firstFormGroup.patchValue({
      dateinic: this.DataModal['DateInic'],
      dateend: this.DataModal['DateEnd'],
      timeinic: this.DataModal['TimeInic'],
      timeend: this.DataModal['TimeEnd'],
      title: this.DataModal['Title'],
      description: this.DataModal['description'],
      url: this.DataModal['Url'],
    });
  }

  private openModal(content: any, size = null): void {
    let sizemodal = size == '' ? 'md' : size;
    this.modal = this.modalService.open(content, { backdrop: 'static', size: sizemodal });
  }

  ReturnDate(date, format = null) {
    let result = null;
    let date_ = date;
    if (format == 'time') {
      result = moment(date_, 'HH:mm:ss').format('HH:mm');
    } else {
      result = moment(date_, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }
    return result
  }

  ShowModalAddEvent() {
    if (this.user.roles[0].name !== 'person' && this.user.roles[0].name !== 'company') {
      this.openModal(this.EventModal, 'lg');
    }
    
  }


  SubmitEvent() {
    this.submitted = true;


    if (this.firstFormGroup.valid) {
      let data = this.firstFormGroup.value;
      data['date_google_inic'] = moment(data['dateinic'] + 'T' + data['timeinic']).format();
      data['date_google_end'] = moment(data['dateend'] + 'T' + data['timeend']).format();
      data['date_ln_inic'] = data['dateinic'] + ' ' + data['timeinic']
      data['date_ln_end'] = data['dateend'] + ' ' + data['timeend'];

      if (this.DataModal.hasOwnProperty("ID") && this.DataModal["ID"] != '') {
        this.CalendarService.EditEventCalendar(this.DataModal["ID"], this.DataModal["GOOGLE_ID"], data).then(
          Response => {
            // console.log(reponse); 

            Swal.fire({ text: Response['message'], icon: 'success' });
            this.CloseModal();
            this.GetCalendar();
          }
        );
      } else {
        this.CalendarService.insertEvent(data).then(
          Response => {
            // console.log(reponse); 

            Swal.fire({ text: Response['message'], icon: 'success' });
            this.CloseModal();
            this.GetCalendar();
          }
        );
      }

    }

  }

  private createForm(): void {
    this.firstFormGroup = this.fb.group({
      dateinic: [this.Date.now, [Validators.required]],
      dateend: [this.Date.now, [Validators.required]],
      timeinic: ['', [Validators.required]],
      timeend: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', []],
      url: ['', []],
    });




  }

  DeleteEvent() {
    Swal.fire({
      title: 'Desea Eliminar este Evento?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',

    }).then((result) => {
      if (result.isConfirmed) {
        this.CalendarService.DeleteEventCalendar(this.DataModal["GOOGLE_ID"], this.DataModal["ID"]).then(
          Response => {


            Swal.fire({ text: Response['message'], icon: 'success' });
            this.CloseModal();
            this.GetCalendar();
          }
        );
      }
    })
  }

  CloseModal() {
    this.modalService.dismissAll();
    if (this.Edit) {
      this.Edit = false;
      this.Title_Modal = 'Crear Evento';
      this.firstFormGroup.reset();
      this.firstFormGroup.get('dateinic').setValue(this.Date.now);
      this.firstFormGroup.get('dateend').setValue(this.Date.now)
      this.DataModal = {};
    }

  }

  ClickMeet() {
    window.open(this.firstFormGroup.get('url').value, "_blank", "noreferrer");
  }







}
