import { Component, OnInit } from '@angular/core';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
const GOLD = 'Clasificación oro';
const SILVER = 'Clasificación plata';
const BRONZE = 'Clasificación bronce';

@Component({
  selector: 'app-my-reviews',
  templateUrl: './my-reviews.component.html',
  styleUrls: ['./my-reviews.component.css']
})
export class MyReviewsComponent implements OnInit {
  calification : {};
  clientComments = [];
  lawyerId: number = 228;
  categoryClasses = {};

  constructor(
    private lawyerService: LawyerService
  ) {
    this.categoryClasses[GOLD] = 'category-gold';
    this.categoryClasses[SILVER] = 'category-silver';
    this.categoryClasses[BRONZE] = 'category-bronze';
  }

  ngOnInit(): void {
    this.getCalification();
    this.getClientsComments();
  }

  getCalification() {
    this.lawyerService.getCalificationLawyer(this.lawyerId).subscribe(
      response => {
        this.calification = response.data;
        console.log(this.categoryClasses);
      }
    );
  }

  getClientsComments() {
    this.lawyerService.getClientsComments(this.lawyerId).subscribe(
      response => {
        console.log(response);
        this.clientComments = response.data;
      }
    );
  }
}
