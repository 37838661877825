
<section class="content-body d-flex align-items-center" >
    <div class="container-fluid">
        <div class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
               <h3>Mis Documentos</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">

                <div class="card border-0">
                    <div class="card-header">
          
                        <form class="">
                            <div class="form-group hidden">
                                <div class="input-group">
                                <input name="datepicker"
                                        class="form-control"
                                        ngbDatepicker
                                        #datepicker="ngbDatepicker"
                                        [autoClose]="'outside'"
                                        (dateSelect)="onDateSelection($event)"
                                        [displayMonths]="2"
                                        [dayTemplate]="t"
                                        outsideDays="hidden"
                                        [startDate]="fromDate!"
                                        tabindex="-1">
                                <ng-template #t let-date let-focused="focused">
                                    <span class="custom-day"
                                        [class.focused]="focused"
                                        [class.range]="isRange(date)"
                                        [class.faded]="isHovered(date) || isInside(date)"
                                        (mouseenter)="hoveredDate = date"
                                        (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                    </span>
                                </ng-template>
                                </div>
                            </div>
                            <div class="row align-items-end">
                                <div class="col-auto">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Desde</strong></label>
                                      <div class="input-group">
                                        <input #dpFromDate
                                               class="form-control form-filter-calendar" placeholder=""
                                               name="dpFromDate" 
                                               [value]="formatter.format(fromDate)"
                                               (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                                        <div class="input-group-append">
                                          <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-group ml-2">
                                        <label class="mb-1"><strong>Hasta</strong></label>
                                        <div class="input-group">
                                            <input #dpToDate
                                               class="form-control form-filter-calendar" placeholder=""
                                               name="dpToDate"
                                               [value]="formatter.format(toDate)"
                                               (input)="toDate = validateInput(toDate, dpToDate.value)">
                                            <div class="input-group-append">
                                            <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="item-filter">
                                        <label class="mb-1"><strong>Nombre</strong></label>
                                        <input class="form-control form-filter" name="name" id="name" [(ngModel)]="searchFilter.name">
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                                                <i class="fa fa-search"></i>
                                            </button> 
                                            <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                                                <i class="fa fa-refresh"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>        
                                                    
                        <div>
                            Subir Documento
                            <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                               placement="top" ngbTooltip="Subir Documento" (click)="openUploadDocModal(uploadDocModal)">
                              <mat-icon aria-hidden="false" aria-label="Example home icon" style="color:#212F54">
                                add_circle_outline
                              </mat-icon>
                            </button>
                          </div>
                    </div>
                    <div class="card-body">
                        <ng-container *ngIf="List.length; else elseBlock">
                            <ul class="list-group">
                                <li *ngFor="let doc of List" class="list-group-item">
                                    
                                        <div class="row align-items-center justify-content-between">
                
                                            <div class="col">
                                                <h5 class="my-2"><strong>
                                                    {{doc.name}}</strong>
                                                </h5>
                                                <p>{{doc.creation_date}}</p>
                                            </div>
                                            <div class="col-auto text-right">
                                                <a [href]="doc.file_url" class="mx-2" [download]="doc.name" target="_black" type="application/pdf"  ngbTooltip="Descargar" download>  
                                                    <mat-icon aria-hidden="false" >
                                                        download
                                                    </mat-icon>
                                                </a>
                                                <a (click)="deleteDoc(doc.id)" class="cursor-pointer"  ngbTooltip="Eliminar">
                                                    <mat-icon aria-hidden="false" style="color: #dc3545;">
                                                    delete
                                                    </mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    
                                </li>
                            </ul>
                            </ng-container>
                            <ng-template #elseBlock>
                                <div class="alert alert-warning" role="alert">
                                    No hay documentos 
                                </div>
                            </ng-template> 

                    </div>
                </div>

            </div>
               
        </div>
    </div>
</section>



<ng-template #uploadDocModal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Subir Documento</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> 
    </div>
    <form class="form-valide" action="#" method="post" [formGroup]="formDoc" enctype="multipart/form-data"
                id="formDoc" (ngSubmit)="onSubmit()" novalidate>
                
        <div class="modal-body p-0">
            <div class="card-body pt-3">
                
                    <div class="form-group text-left">
                        <label class="mb-1"><strong>Documento</strong></label>
               
                            <div class="input-group">
                                <div class="custom-file">
                                    <input
                                    type="file"
                                    class="custom-file-input"
                                    formControlName="file_url" name="file_url"
                                    (change)="onDocChange($event)"
                                    accept=".pdf"
                                    required
                                    />
                                    <label class="custom-file-label">{{ fileName }}</label>
                                </div>
                            </div>
                     
                    </div>
                
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Subir Archivo</button>
        </div>
    </form> 
</ng-template>