import { Component, OnInit } from '@angular/core';
import { LOGO, AVATAR } from '../../shared/data/constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Country } from '../../shared/interfaces/country';
import { Province } from 'src/app/shared/interfaces/province';
import { City } from '../../shared/interfaces/city';
import { Township } from 'src/app/shared/interfaces/township';
import { CommonService } from '../../shared/services/common.service';
import { AuthService, UserTypeReg } from '../../shared/services/auth.service';
//import { ToastsService } from '../../shared/services/toasts.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component( {
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [ './register.component.css' ]
} )
export class RegisterComponent implements OnInit {

  logo = LOGO;
  avatar = AVATAR;
  companyForm: FormGroup;
  personalForm: FormGroup;
  submitted = false;
  countries: Country[] = [];
  provinces: Province[] = [];
  cities: City[] = [];
  townships: Township[] = [];
  type = 'lawyer';
  fileName = 'Seleccione';
  hasPhoto = false;
  pagesView = 1;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private common: CommonService,
    //private toastsService: ToastsService,
  ) {
    this.common.countries().subscribe( response => this.countries = [ ...response.data ] );
  }

  ngOnInit(): void {
  }

  onChange( type: string ): void {
    this.type = type;
    // console.log(type);
  }

  registerCompany( data: any ): void {
    if ( !this.hasPhoto ) {
      Swal.fire( { text: 'Debe cargar una foto', icon: 'warning' } );
      return;
    }
    data.main_image = this.avatar;
    this.register( 'companies', data );
  }

  registerPerson( data: any ): void {
    if ( !this.hasPhoto ) {
      Swal.fire( { text: 'Debe cargar una foto', icon: 'warning' } );
      return;
    }
    data.main_image = this.avatar;
    this.register( 'persons', data );
  }

  registerLawyer( data: any ): void {
      if ( !this.hasPhoto ) {
        Swal.fire( { text: 'Debe cargar una foto', icon: 'warning' } );
        return;
      }
      data.main_image = this.avatar;

      this.auth.registerLawyer1( data ).subscribe(response => {
      Swal.fire( { text: response.message, icon: 'success' } );
      this.router.navigateByUrl( 'login' );
    });
  }

  onImageChange( e ): void {
    const reader = new FileReader();
    if ( e.target.files && e.target.files.length ) {
     
      const [ file ] = e.target.files;
      this.fileName = file.name;
      reader.readAsDataURL( file );

      reader.onload = () => {
        this.avatar = reader.result as string;
        this.hasPhoto = true;
      };
    }
  }

  private register( type: UserTypeReg, data: any ): void {
    this.auth.register( type, data ).subscribe( response => {
      //this.toastsService.show( response.message, { classname: 'bg-success text-white' } );
      Swal.fire( { text: response.message, icon: 'success' } );
      this.router.navigateByUrl( 'login' );
    } );
  }

  switchType(page,type: string){
    this.pagesView = page;
    this.type = type;
  }


}
