import { Component, OnInit, ViewChild } from '@angular/core';
import { jsPDF } from "jspdf";
import { TimeTaskService } from '../../../shared/services/time-task.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import  html2canvas  from 'html2canvas';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-taks-time',
  templateUrl: './taks-time.component.html',
  styleUrls: ['./taks-time.component.css']
})
export class TaksTimeComponent implements OnInit {


  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate ;
  toDate: NgbDate | null;

  searchFilter = {
    case: '',
    task: '',
    dateStart: null,
    dateEnd: null
  }

  List=[];

  constructor(
    private timeTaskService: TimeTaskService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    titlePageService: TitlePageService
  ) { 
    titlePageService.titlePageObservableData = { name: 'Tiempos de ejecución'};
  }

  
  ngOnInit(): void {
    this.loadData();
  }

  stringFilter() {
    let start = this.formatter.format( this.fromDate ).replace('-','/');
    let end = this.formatter.format( this.toDate ).replace('-','/');
    this.searchFilter.dateStart = start.replace('-','/');
    this.searchFilter.dateEnd = end.replace('-','/');
    let cadena = '';


    if(this.searchFilter.task!=='') {
      cadena = cadena+'&search='+this.searchFilter.task;
    }
    
    if(this.searchFilter.case!=='') {
      cadena = cadena+'&case_name='+this.searchFilter.case;
    }
    
    if(this.searchFilter.dateStart!=='') {
      cadena = cadena+'&start_date='+this.searchFilter.dateStart;
    }
    
    if( this.searchFilter.dateEnd!=='') {
      cadena = cadena+'&end_date='+this.searchFilter.dateEnd;
    }

    return cadena;
  }

  search(){
    let cadena = this.stringFilter();
    
    //console.log(cadena);
    if (cadena !== '') {
      this.timeTaskService.listTimeTaskSearch(cadena).subscribe( response => {
        this.List = [ ...response.data ];
        //console.log(response.data);
      });
    }

  }

  clearFilter() {
    this.loadData();
    this.searchFilter = {
      case: '',
      task: '',
      dateStart: null,
      dateEnd: null
    }
    this.toDate = null;
    this.fromDate = null;
    //console.log(this.searchFilter);
  }

  loadData() {
    this.timeTaskService.listTimeTask().subscribe( response => {
      this.List = [ ...response.data ];
      //console.log(response.data);
    });
  }


  downloadPDF (): void {

    let cadena = this.stringFilter();

    this.timeTaskService.downloadTimeTask(cadena).subscribe( response => {

      const blob = new Blob( [ response ], { type: 'application/pdf' } );

      var downloadURL = window.URL.createObjectURL( response );
      var link = document.createElement( 'a' );
      link.href = downloadURL;
      link.download = "Tiempos de Ejecución de Tareas.pdf";
      link.click();
    });


  }

  makePDF(){
    const DATA = document.getElementById('content');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {
      const img = canvas.toDataURL('image/PNG');
      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_tareas.pdf`);
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


}
