import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GanttEditorComponent, GanttEditorOptions } from 'ng-gantt';
import { Validators, FormBuilder } from "@angular/forms";
import Swal from '../../../shared/helpers/sweetalert';
import { LawyerService } from '../../../shared/services/lawyer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.css']
})
export class PlanningComponent implements OnInit {
  @ViewChild("editor") editor: GanttEditorComponent;
  @ViewChild("content", { static: true }) content: ElementRef;
  public editorOptions: GanttEditorOptions;
  data: any = [];
  pocedures: any = [];
  modal: any; idCaseSelect; condSelectLaw;
  page; listCases: any; dataPagination;
  condViewGantt: boolean = true;
  dataCase_tasks: any = [];
  pGroup;
  pClass;

  constructor(
    public fb: FormBuilder,
    private lawyerService: LawyerService,
    private modalService: NgbModal,
  ) {
    this.page = 1;
  }

  ngOnInit(): void {
    this.getProceduresForLawyer(this.page);
    this.editorOptions = {
      vFormat: "day",
      vLang: 'es',
      vEditable: false,
      vShowRes: 0,
      vShowDur: 0,
      vShowComp: 0,
      vShowStartDate: 0,
      vShowEndDate: 0,
      vEventsChange: {
        taskname: () => {
          console.log("taskname");
        }
      }
    };
    this.openModalSelect(this.content);
  }



  changeData() {
    this.data = [... this.data,
    {
      pID: Math.random() * 100,
      pName: "new item",
    }];
  }

  initialData() {
    this.lawyerService.getListCasesGantt(this.idCaseSelect).subscribe(response => {
      this.dataCase_tasks = response.data;
      if (this.dataCase_tasks.case_tasks.length > 0) {
        for (let index = 0; index < this.dataCase_tasks.case_tasks.length; index++) {
          let element = this.dataCase_tasks.case_tasks[index];
          if (element.pParent !== 0) {
            this.pClass = "gtaskgreen";
          } else {
            this.pClass = "gtaskblue";
          }
          this.data.push({
            pID: element.pID,
            pName: element.pName,
            pStart: element.pStart,
            pEnd: element.pEnd,
            pClass: this.pClass,
            pGroup: element.pGroup,
            pParent: element.pParent,
            pLink: "",
            pMile: 0,
            pOpen: 1,
            pDepend: "",
            pCaption: "",
            pNotes: ""
          });
        }
        this.condViewGantt = true;

      } else {
        this.condViewGantt = false;
        Swal.fire({
          icon: 'warning',
          title: '',
          text: 'No hay planificacion registrada para este caso !',
        });
      }
    });
  }

  getProceduresForLawyer(page) {
    this.data = [];
    this.lawyerService.lawyergetCases(page).subscribe(response => {
      this.pocedures = [...response.data];
      this.dataPagination = response.meta.page;

    });
  }

  openModalSelect(content) {
    this.modalService.open(content);
  }

  selectCase(item) {
    this.idCaseSelect = item.id;
    this.initialData();
    this.condSelectLaw = true;
    this.modalService.dismissAll();
    this.condViewGantt = false;

  }

  loadPage(page) {
    this.page = page;
    this.pocedures = [];
    this.getProceduresForLawyer(this.page);
  }


}
