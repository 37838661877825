<div class="content-body d-flex">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>Entidades</h3>
        <div>
          Crear entidad
          <button
            mat-icon-button
            color="accent"
            aria-label="Example icon button with a menu icon"
            (click)="add(entityModal)"
            placement="top"
            ngbTooltip="Nueva entidad"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Example home icon"
              style="color: #212f54"
            >
              add_circle_outline
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="entities.length; else nodata">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Teléfono</th>
                  <th scope="col">Email</th>
                  <th scope="col">Dirección</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let entity of entities; let i = index">
                  <td class="text-capitalize">{{ entity.name }}</td>
                  <td>{{ entity.phone }}</td>
                  <td>{{ entity.email }}</td>
                  <td>{{ entity.address }}</td>
                  <td>
                    <button
                      mat-icon-button
                      color="accent"
                      aria-label="Example icon button with a menu icon"
                      (click)="edit(entityModal, entity)"
                      placement="top"
                      ngbTooltip="Editar"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        style="color: #212f54"
                      >
                        edit
                      </mat-icon></button
                    ><button
                      mat-icon-button
                      color="accent"
                      aria-label="Example icon button with a menu icon"
                      (click)="delete(entity.id)"
                      placement="top"
                      ngbTooltip="Eliminar"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        style="color: #dc3545"
                      >
                        delete
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="row justify-content-end"
            *ngIf="dataPagination?.lastPage > 1"
          >
            <div class="col-auto custom-div-pagination justify-content-center">
              <ngb-pagination
                [pageSize]="12"
                (pageChange)="loadPage($event)"
                [collectionSize]="dataPagination?.total"
                [maxSize]="5"
                [(page)]="page"
                aria-label="Default pagination"
              ></ngb-pagination>
            </div>
          </div>
        </ng-container>
        <ng-template #nodata>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #entityModal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ isEdit ? "Editar" : "Agregar" }} Entidad
      </h4>
    </div>
    <div class="modal-body">
      <form
        class="form-valide"
        action="#"
        method="post"
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        novalidate
      >
        <div class="form-group">
          <label class="mb-1"><strong>Nombre</strong></label>
          <input type="text" class="form-control" formControlName="name" />
          <div
            *ngIf="submitted && f.name?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="f.name.errors.required">Nombre requerido.</div>
          </div>
        </div>
        <div class="form-group">
          <label class="mb-1"><strong>Teléfono</strong></label>
          <input type="text" class="form-control" formControlName="phone" />
          <div
            *ngIf="submitted && f.phone?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="f.phone.errors.required">Teléfono requerido.</div>
          </div>
        </div>
        <div class="form-group">
          <label class="mb-1"><strong>Email</strong></label>
          <input type="email" class="form-control" formControlName="email" />
          <div
            *ngIf="submitted && f.email?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="f.email.errors.required">Email requerido.</div>
            <div *ngIf="f.email.errors.email">Email inválido</div>
          </div>
        </div>
        <div class="form-group">
          <label class="mb-1"><strong>Dirección</strong></label>
          <input type="text" class="form-control" formControlName="address" />
          <div
            *ngIf="submitted && f.address?.errors"
            class="text-danger fs-13 mt-1"
          >
            <div *ngIf="f.address.errors.required">Dirección requerido.</div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-outline-danger ml-2"
            (click)="modal.dismiss()"
          >
            Cancelar
          </button>
          <button type="submit" class="btn btn-primary mr-2">
            {{ isEdit ? "Editar" : "Guardar" }}
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
