import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AVATAR } from 'src/app/shared/data/constants';
import { AdvisoryLawyerService } from 'src/app/shared/services/advisory-lawyer.service';
import { MyLawyers } from 'src/app/shared/interfaces/user';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { CommonService } from 'src/app/shared/services/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-advisory-lawyer',
  templateUrl: './advisory-lawyer.component.html',
  styleUrls: ['./advisory-lawyer.component.css']
})
export class AdvisoryLawyerComponent implements OnInit {

  @ViewChild("layerProfile", { static: true }) layerProfile: ElementRef;
  avatar = AVATAR;
  params = '';
  list = [];
  branches = [];
  studies = [];
  dataPagination = null;
  Modal = {
    lawyer: ''
  }
  closeResult = '';
  filter = {
    name    : '',
    branch  : '',
    studies : '',
  };
  dataList = [];
  lawyerList: any = [];
  debounce = null;

  constructor(
    private modalService: NgbModal,
    private advisoryLawyerService: AdvisoryLawyerService,
    private LawyerService: LawyerService,
    private common: CommonService,
    titlePageService: TitlePageService
    ) {
      titlePageService.titlePageObservableData = { name: 'Directorio de abogados'};
    }


  ngOnInit(): void {
    this.loadData();
    this.loadFilters();
  }

  lawyerDetail(content, id: number, data): void {
    if (data.orders.isPayment) {
      this.advisoryLawyerService.showLawyers(id).subscribe(response => {
        this.Modal.lawyer = response.data;
        //console.log(this.Modal.lawyer);
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
      });
    } else {
      this.alrtNoPayment(data);
    }

  }

  alrtNoPayment(data) {

    Swal.fire({
      icon: 'warning',
      text: ' ',
      title: data.orders.paymentStatus == "No existen pagos" ?   "Este trámite se encuentra a la espera de su pago" : data.orders.paymentStatus,
    })
  }

  loadData(page = 1): void {
    //console.log(this.params);
    this.getParams();
    this.LawyerService.getLawyersForDirectory(page, this.params).subscribe(
      response => {
        this.list = response.data;
        this.dataPagination = response.meta.page;
    });
  }

  private loadFilters() {
    this.LawyerService.listBranch().subscribe(
      response => {
        this.branches = response.data;
    });
    this.common.studyLevel().subscribe(
      response => {
        this.studies = response.data.filter((item) => item.name != "");
    });
  }

  getParams(){
    this.params = "";
    if(this.filter.branch ) this.params +="&branch_Law_name="+this.filter.branch;
    if(this.filter.studies) this.params +="&study_level_id=" +this.filter.studies;
    if(this.filter.name   ) this.params +="&lawyer_name="    +this.filter.name;
  }

  changeFilter(){
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.loadData();
    }, 500);
  }

  goProfile(item): void {
    this.advisoryLawyerService.showLawyers(item.id).subscribe(response => {
      this.Modal.lawyer = response.data;
      this.modalService.open(this.layerProfile, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    });
  }
}
