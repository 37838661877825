import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { ChartComponent, ApexNonAxisChartSeries,  ApexResponsive, ApexChart, ApexDataLabels, ApexStroke, ApexLegend } from "ng-apexcharts";
import { Observable, Subject } from 'rxjs';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  colors: string[];
  legend: ApexLegend;
};


@Component({
  selector: 'app-detail-pie-chart',
  templateUrl: './detail-pie-chart.component.html',
  styleUrls: ['./detail-pie-chart.component.css']
})
export class DetailPieChartComponent implements OnInit {
  @Input() DataChild :Subject<any> = new Subject();
  @Input() TypeChart :string;
  @ViewChild("chart") chart: ChartComponent;
  Labels : String;
  ShowChart = false;
  DataObject = {};
  Serie1 = 0;  Serie2 = 0;  Serie3 = 0;  Serie4 = 0;  Serie5 = 0;Serie6 = 0;Serie7 = 0;
  public chartOptions: Partial<ChartOptions>;
// '#3A82EF', '#EE3CD2', '#FFB038', '#FF495F', '#5EE173'
  constructor() {
    // console.log(this.Datachart);
    this.chartOptions = {
      series:[ 0,0],
        chart: {
          type: 'donut',
          width:212,
        },
        legend: {
          show:false
        },
      labels: [ "Completado","Pendiente"],
      stroke: {
          width: 3,
      },
      colors:['#5EE173', '#3A82EF'],
      dataLabels: {
          enabled: false
        },
      responsive: [
        {
          breakpoint: 575,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom",
              show:false
            }
          }
        }
      ]
    };
  
  }

async  ngOnInit() {
     this.DataChild.subscribe(event => {
      // console.log(event);
  
    
        this.DataObject = event;
     
        this.PrintTypeChart();
      
    });

   
    
  }

  get CanInputObejct(){
    return this.DataObject.hasOwnProperty('Penal') && this.DataObject.hasOwnProperty('Mercantil')
    && this.DataObject.hasOwnProperty('Civil') && this.DataObject.hasOwnProperty('Familia.')
    && this.DataObject.hasOwnProperty('Migratorio') && this.DataObject.hasOwnProperty('Administrativo')
    && this.DataObject.hasOwnProperty('Sucesiones');
  }

  get CanInputObejct2(){
    return this.DataObject.hasOwnProperty('data_cases_complete') && this.DataObject.hasOwnProperty('data_cases_in_process');
  }

 

  PrintTypeChart(){
      let value = '';
      value = this.TypeChart;
  
      switch (value) {
        case 'Marketplace':
     
          if(this.CanInputObejct){
            this.Serie1 = this.DataObject["Penal"];
            this.Serie2 = this.DataObject["Mercantil"];
            this.Serie3 = this.DataObject["Civil"];
            this.Serie4 = this.DataObject["Familia."];
            this.Serie5 = this.DataObject["Migratorio"];
            this.Serie6 = this.DataObject["Administrativo"];
            this.Serie7 = this.DataObject["Sucesiones"];
               this.ShowChart = true;
          }
            this.InitChartMarket();
        break;
        case 'Platform':
          if(this.CanInputObejct2){
            this.Serie1 = this.DataObject["data_cases_complete"];
            this.Serie2 = this.DataObject["data_cases_in_process"];
               this.ShowChart = true;
          }
        
          this.InitChartPlaform();
        break;
        
      }
  }


  InitChartPlaform(){

    this.chartOptions = {
      series:[ this.Serie1, this.Serie2],
        chart: {
          type: 'donut',
          width:212,
        },
        legend: {
          show:false
        },
      labels: [ "Completado","Pendiente"],
      stroke: {
          width: 3,
      },
      colors:['#5EE173', '#3A82EF'],
      dataLabels: {
          enabled: false
        },
      responsive: [
        {
          breakpoint: 575,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom",
              show:false
            }
          }
        }
      ]
    };
   
  }

  InitChartMarket(){

    this.chartOptions = {
      series: [this.Serie1, this.Serie2,this.Serie3, this.Serie4,this.Serie5, this.Serie6,this.Serie7],
        chart: {
          type: 'donut',
		      width:212,
        },
        legend: {
          show:false
        },
      labels: ["Penal", "Mercantil","Civil","Familia","Migratorio","Administrativo","Sucesiones"],
      stroke: {
          width: 3,
      },
      colors:['#3A82EF', '#EE3CD2', '#FFB038', '#FF495F', '#5EE173','#212F54','#CABAAB'],
      dataLabels: {
          enabled: false
        },
      responsive: [
        {
          breakpoint: 575,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom",
              show:false
            }
          }
        }
      ]
    };
  }

}
