<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="text-center">
      <h3>Detalles de Expedientes</h3>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row ">
          <div class="col-6 d-flex justify-content-start align-items-center mb-2">
            <label for="" class="font-weight-bold mb-0">Expediente:</label>
            <p class="ml-2 mb-0">{{type.file}}</p>
          </div>
          <div class="col-6 d-flex justify-content-start align-items-center mb-2">
            <label for="" class="font-weight-bold mb-0">Abogado:</label>
            <p class="ml-2 mb-0">{{type.lawyer}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
