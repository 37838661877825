<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>{{titlePage}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-header d-block pb-0">
            <form class="">
              <div class="form-row col-12">
                <div class="form-group hidden">
                  <div class="input-group">
                    <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                      [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                      [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end align-items-end">
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Desde</strong></label>
                    <div class="input-group">
                      <input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Hasta</strong></label>
                    <div class="input-group">
                      <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Rama</strong></label>
                    <select class="form-control form-filter" name="branch" id="branch" [(ngModel)]="searchFilter.branch">
                      <option selected value="">Todos</option>
                      <option [value]="branch.id" *ngFor="let branch of branches">{{branch.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Tipo de Servicio</strong></label>
                    <select class="form-control form-filter" name="type" id="type"  [(ngModel)]="searchFilter.type">
                      <option selected value="">Todos</option>
                      <option [value]="type.id" *ngFor="let type of types">{{type.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row justify-content-start align-items-end">
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Nombre de Servicio</strong></label>
                    <input class="form-control form-filter" name="service_name" id="service_name" [(ngModel)]="searchFilter.service_name">
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <label class="mb-1"><strong>Abogado</strong></label>
                    <input class="form-control form-filter" name="lawyer_name" id="lawyer_name" [(ngModel)]="searchFilter.lawyer_name">
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="form-group">
                    <div class="input-group">
                      <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                        <i class="fa fa-search"></i>
                      </button>
                      <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <ng-container *ngIf="list.length; else nodata">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Abogado</th>
                      <th scope="col">Monto a Compensar</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of list">
                      <td>{{item.creation_date}}</td>
                      <td>{{item.name}}</td>
                      <td>$ {{item.amount}}</td>
                      <td>
                        <div>
                          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                            (click)="openModal(detailModal, item, 'detail')" placement="top" ngbTooltip="Detalle">
                            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                              list
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                            (click)="openModal(bankModal, item, 'bank')" placement="top" ngbTooltip="Datos Bancarios">
                            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                              account_balance
                            </mat-icon>
                          </button>
                          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                            (click)="openModal(paymentModal, item, 'payment')" placement="top" ngbTooltip="Registrar Pago">
                            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                              payments
                            </mat-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                      <td>Total: $ {{getTotal()}}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto custom-div-pagination justify-content-center">
                  <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                    [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="dataPagination.currentPage" aria-label="Paginación">
                  </ngb-pagination>
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
              </div>
            </ng-container>
            <ng-template #nodata>
              <div class="alert alert-info" role="alert">
                <strong>No hay data disponible</strong>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #detailModal>
  <div class="modal-header">
    <h4 class="modal-title text-capitalize" id="modal-basic-title">
      {{currentItem.name}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4>Listado</h4>
    <div class="row justify-content-center">
      <div class="col mt-3">
        <div class="table-responsive">
          <table *ngIf="showModal.data?.length" class="table">
            <thead>
              <tr>
                <th scope="col">Código</th>
                <th scope="col">Servicio</th>
                <th scope="col">Monto a Compensar</th>
                <th scope="col">Fecha</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of showModal.data; let i=index">
                <td>{{item.id}}</td>
                <td>{{item.service.name}}</td>
                <td>{{item.total_payments}}</td>
                <td>{{item.creation_date}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="form-row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-primary my-4 mx-2" *ngIf="showModal.data" (click)="downloadItemExcel(currentItem.id)">Excel</button>
        <button class="btn btn-primary my-4 mx-2" *ngIf="showModal.data" (click)="downloadItemPDF(currentItem.id)">Imprimir</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #bankModal>
  <div class="modal-header text-center">
    <h4 class="modal-title" id="modal-basic-title">
      Datos Cuenta Bancaria
    </h4>
  </div>
  <div class="modal-body">
    <h3>{{showModal.data.bank?.owner_name}}</h3>
    <div class="row justify-content-center">
      <div class="col mt-3">
        <fieldset>
          <p class="ml-3 mb-2"><strong>Entidad Bancaria: </strong>{{showModal.data.bank?.bank_data.name}}</p>
          <p class="ml-3 mb-2"><strong>Tipo de Cuenta: </strong>{{showModal.data.bank?.account_type}}</p>
          <p class="ml-3 mb-2"><strong>Número de Cuenta: </strong>{{showModal.data.bank?.account_number}}</p>
        </fieldset>
      </div>
    </div>
    <div class="form-row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-primary py-2" (click)="modal.dismiss()">Cerrar</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #paymentModal>
  <div class="modal-header text-center">
    <h4 class="modal-title" id="modal-basic-title">
      <strong>Abogado: </strong>{{showModal.name}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-8 m-auto p-0">
        <label class="mb-1 custom-line-label"><strong>Monto Pagado</strong></label>
        <input type="text" class="form-control" name="amount" [(ngModel)]="modalPay.amount"/>
        <div *ngIf="submitted && f.name?.errors" class="text-danger fs-13 mt-1">
          <div *ngIf="f.name.errors.required">
            Campo requerido.
          </div>
        </div>
        <label class="mb-1 custom-line-label"><strong>Banco</strong></label>
        <select class="form-control" name="lawyer_client_id"
          (change)="onChange($event.target.value, 'status')"
          [(ngModel)]="modalPay.bank"
        >
          <option disabled selected value=''>Seleccione una opción</option>
          <option [value]="bank.id" *ngFor="let bank of banks">
            {{bank.name}}
          </option>
        </select>
        <div *ngIf="submitted && f.lawyer_client_id?.errors" class="text-danger fs-13 mt-1">
          <div *ngIf="f.lawyer_client_id.errors.required">
            Campo requerido.
          </div>
        </div>
        <label class="mb-1 custom-line-label"><strong>Número de Referencia</strong></label>
        <input type="text" class="form-control" name="ref_number" [(ngModel)]="modalPay.ref_num"/>
        <div *ngIf="submitted && f.ref_number?.errors" class="text-danger fs-13 mt-1">
          <div *ngIf="f.ref_number.errors.required">
            Campo requerido.
          </div>
        </div>
      </div>
    </div>
    <div class="form-row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-primary py-2" (click)="modal.dismiss()">Registrar</button>
      </div>
    </div>
  </div>
</ng-template>
