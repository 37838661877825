import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/shared/services/admin.service';
import { AVATAR } from 'src/app/shared/data/constants';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.css']
})
export class CustomerReportComponent implements OnInit {
  list = [];
  params = '';
  info_object : any;
  page; dataPagination;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    name: null
  }
  hoveredDate: NgbDate | null = null;
  avatar = AVATAR;
  titlePage='Reporte de Clientes';
  constructor(
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private adminService: AdminService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
   }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(page = 1) {
     this.adminService.getClientsReport(page, this.params).subscribe(
      response => {
        this.list = response.data;
        this.info_object = response.info_object;
        this.dataPagination = response.meta.page;
    }); 
  }

  downloadPDF() {
     this.adminService.getClientsReport(null, this.params, true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Reporte de Clientes.pdf";
        link.click();
    });
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.params = '';
    this.searchFilter = {
      dateStart: null,
      dateEnd: null,
      name: null,
    }
    this.toDate = null;
    this.fromDate = null;
    this.loadData();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search() {
    this.searchFilter.dateStart = this.formatter.format(this.fromDate);
    this.searchFilter.dateEnd = this.formatter.format(this.toDate);
    this.params = '';

    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }

    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.searchFilter.name) {
      this.params += '&client_name=' + this.searchFilter.name;
    }
 
    if (this.params !== '') {
      this.loadData(1);
    }
  }
}
