<section class="content-body d-flex align-items-center">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>Estado de Cuenta</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-header d-block pb-0">
            <form class="">
              <div class="form-group hidden">
                <div class="input-group">
                  <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                    outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                  <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="row justify-content-end align-items-end">
                <div class="col-6 col-md-5">
                  <div class="form-group">
                    <label class="mb-1"><strong>Desde</strong></label>
                    <div class="input-group">
                      <input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-5">
                  <div class="form-group">
                    <label class="mb-1"><strong>Hasta</strong></label>
                    <div class="input-group">
                      <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                          type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <div class="input-group">
                      <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                        <i class="fa fa-search"></i>
                      </button>
                      <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <ng-container>
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Numero de Caso</th>
                      <th scope="col">Nombre de Cliente</th>
                      <th scope="col">Monto Pagado</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="list.length; else elseTemplate">
                    <tr *ngFor="let item of list; let i=index">
                      <td>{{item.create_date}}</td>
                      <td>{{item.assigned_number}}</td>
                      <td>{{item.client.client_name}}</td>
                      <td>{{item.total_amount}}</td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                      <td>Total</td>
                      <td>{{getTotal()}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row float-right">
                <div class="col-12 custom-div-pagination justify-content-center">
                  <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                    [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page" aria-label="Paginación">
                  </ngb-pagination>
                </div>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <div class="alert py-5" role="alert">
                <strong>No hay data disponible</strong>
              </div>
            </ng-template>
            <div class="text-center">
              <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
