import { Component, OnInit } from '@angular/core';
import { LawyerService } from '../../../shared/services/lawyer.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbModal, ModalDismissReasons, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent } from '@ng-bootstrap/ng-bootstrap';
import { MyDocumentsService } from 'src/app/shared/services/my-documents.service';
import Swal from '../../../shared/helpers/sweetalert';
import { filter } from 'rxjs/operators';
import { TitlePageService } from '../../../shared/services/title-page.service';



@Component({
  selector: 'app-my-clients-lawyer',
  templateUrl: './my-clients-lawyer.component.html',
  styleUrls: ['./my-clients-lawyer.component.css']
})
export class MyClientsLawyerComponent implements OnInit {
  List = [];
  active = 1;
  modal: any;
  selectedType = '';
  selectedFilter = '';
  selectedClient: any;
  fileName = 'Seleccione archivo';
  private _docFile = '';
  formDoc: FormGroup;
  docFormData: FormData;
  types = [
    {
      value: 'Natural', name: 'Natural'
    },
    {
      value: 'Jurídico', name: 'Jurídico'
    },
  ];
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    name: '',
    type: '',
    dateStart: null,
    dateEnd: null
  }
  page;
  dataPagination;
  constructor(
    private lawyerService: LawyerService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private myDocumentsService: MyDocumentsService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    titlePageService: TitlePageService,
  ) { 
    this.page = 1;
      titlePageService.titlePageObservableData = { name: 'Clientes'};
      this.formDoc = this.fb.group({
        file_url: ['', [Validators.required]],
      });
      this.loadData(this.page);
  }

  ngOnInit(): void {
  }

  navigateToCaseEdit(data) {
	  const url = `${window.location.origin}/lawyer/cases?case=${data.id}`;
	  window.location.href = url;
  }

  get d(): any { return this.formDoc.controls; } 

  onDocChange( e ): void {
    const reader = new FileReader();
    if ( e.target.files && e.target.files.length ) {
      const [ file ] = e.target.files;
      this.fileName = file.name;
      reader.readAsDataURL( file );
      reader.onload = () => {
        this._docFile = reader.result as string;
      };
    }
  }


  onSubmit() : void {

    if ( this.formDoc.valid ) {

      const formDoc = document.getElementById('formDoc') as HTMLFormElement;
      this.docFormData = new FormData(formDoc);
      this.docFormData.append('lawyer_client_id', this.selectedClient.id.toString() );
      this.docFormData.append('name', this.fileName);

      this.myDocumentsService.uploadDocumentsLaywerClients(this.docFormData).subscribe( response => {
          Swal.fire( { text: 'Registro de documento exitoso', icon: 'success' } );
          this.fileName = 'Seleccione archivo';
          this.formDoc = this.fb.group({
            file_url: ['', [Validators.required]],
          });
          this.modal.dismiss();
      });

    } else {
      Swal.fire( { text: 'Debe cargar un archivo', icon: 'warning' } );

    }
 
  }

  loadData(page) {
    this.lawyerService.clientsLawyer(page).subscribe( response => {
      this.List = [ ...response.data ];
      this.dataPagination = response.meta.page;
      //console.log(response.data);
    });
  }

  loadPage(page) {
    this.page = page;
    this.loadData(this.page);
  }

  openModalInfo(id: number, type: string, clientModal: any) {
    //console.log(id);
    this.selectedType = type;
    this.lawyerService.clientCasesLawyer(id).subscribe( response => {
      this.selectedClient = response.data;
      //console.log(this.selectedClient);
      this.modal = this.modalService.open( clientModal, { backdrop: 'static', size: 'lg' } );
    });
    
  }

  search(){
    this.searchFilter.dateStart = this.formatter.format( this.fromDate );
    this.searchFilter.dateEnd = this.formatter.format( this.toDate );
    let cadena = '';
 
    if(this.searchFilter.name!=='') {
      cadena = cadena+'&search='+this.searchFilter.name;
    }

    if(this.searchFilter.type!=='') {
      cadena = cadena+'&type='+this.searchFilter.type;
    }
    
    if(this.searchFilter.dateStart!=='') {
      cadena = cadena+'&start_date='+this.searchFilter.dateStart;
    }
    
    if( this.searchFilter.dateEnd!=='') {
      cadena = cadena+'&end_date='+this.searchFilter.dateEnd;
    }

    //console.log(cadena);
    if (cadena !== '') {
      this.lawyerService.clientsLawyerFilter(cadena).subscribe( response => {
        this.List = [ ...response.data ];
        //console.log(response.data);
      });
    }
  }

  showClient(id){
    this.lawyerService.clientCasesLawyer(id).subscribe( response => {
      this.selectedClient = response.data;
      //console.log(this.selectedClient);
    });
  }

  clearFilter() {
    this.searchFilter = {
      name: '',
      type: '',
      dateStart: null,
      dateEnd: null
    }
    this.toDate = null;
    this.fromDate = null;
    this.loadData(this.page);
  }


  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


}
