import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from '../../services/notifications.service';
import { TitlePageService } from '../../services/title-page.service';
import { User } from '../../interfaces/user';
import { StorageService } from '../../services/storage.service';
import { USER, AVATAR } from '../../data/constants';

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.css']
})
export class NotificationsPanelComponent implements OnInit {
  list = [];
  page; dataPagination;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null
  }
  hoveredDate: NgbDate | null = null;
  params = '&includes[]=order.service';
  titlePage='Panel de Notificaciones';
  user: User;
  constructor(
    private notificationsService: NotificationsService,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private storage: StorageService,
    titlePageService: TitlePageService
  ) {
    this.page = 1;
    titlePageService.titlePageObservableData = { name: this.titlePage};
  }

  ngOnInit(): void {
    
    this.user = this.storage.getItem( USER );
    let data ={
      "amount": null,
      "type":"Notificación",
      "user": this.user.id
    }
    console.log('antes de cargar panel de notificaciones', data)
    this.notificationsService.markAsRead(data).subscribe(response => {
      console.log('panel de notificaciones', data)
      this.loadData();
  });
  }

  loadData(page = 1) {
    this.notificationsService.getNotifications(page, this.params).subscribe(
      response => {
        this.list = response.data;
        this.dataPagination = response.meta.page;
    });
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.loadData();
    this.params = '';
    this.searchFilter = {
      dateStart: null,
      dateEnd: null
    }
    this.toDate = null;
    this.fromDate = null;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search() {
    this.searchFilter.dateStart = this.formatter.format( this.fromDate );
    this.searchFilter.dateEnd = this.formatter.format( this.toDate );
    this.params = '';

    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }

    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.params !== '') {
       this.loadData();
    }
  }
}
