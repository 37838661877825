<div class="content-body d-flex" >
    <div class="container-fluid">
<!-- gold : #E2CB5B -->
        <!--<div class="row justify-content-end">
            <div class="col-xl-6 col-sm-6 mb-3 mb-md-0">
                <div class="d-flex flex-row align-items-center justify-content-start">
                   
                    <div >
                         <button class="btn btn-primary ml-3">Ver Estado de Cuenta </button>
                    </div>
                </div>
              

            </div>
            <div class="col-xl-6 col-sm-6 ">
                <div class="d-flex flex-row align-items-center justify-content-end">
                     <div class="mr-3"><span>Temporizador</span></div> 
                    <div >
                        <button class="btn btn-primary mr-3" (click)="open(content)">+ </button>
                    </div>
                </div>
              

            </div>
        </div>

        <div class="row justify-content-center  my-3">
            <div class="col-12">
                <hr> 
            </div>
        
        </div>-->

     

        <div class="row justify-content-center">
                <div class="col-xl-12 col-sm-12">
                    <div class="card p-4">
                        <div class=" d-flex  justify-content-between ml-1">
                           <h3>{{titlePage}}</h3>
                           <div>
                            Crear Usuario
                            <button mat-icon-button color="accent" 
                            (click)="open(content)" placement="top" ngbTooltip="Nuevo usuario">
                              <mat-icon aria-hidden="false"  style="color:#212F54">
                                add_circle_outline
                              </mat-icon>
                            </button>
                          </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="filter">
                            <div class="search mx-2 d-flex align-items-center flex-row">
                              <button type="button " class="btn btn-filter" (click)="CleanFilter()"><i class="flaticon-381-search-2"></i></button>
                                <input type="text" class="form-control mx-1 border-right shadow-none"
                                 placeholder="Nombre de Usuario" aria-label="Nombre de Usuario" aria-describedby="basic-addon1"
                                 [(ngModel)]="Filters.name" ng-model-options="{debounce: 1000}" (keyup)="Filter();"   >
                                <!-- <input type="text" class="form-control mx-1 border-input-filter shadow-none" placeholder="Email" aria-label="Email" aria-describedby="basic-addon1"> -->
                                <!-- <input type="text" class="form-control mx-1 border-input-filter shadow-none" placeholder="Rol" aria-label="Rol" aria-describedby="basic-addon1"> -->
                                <select class="form-control mx-1  shadow-none" placeholder="Rol"
                                (change)="Filter(true)"
                              [(ngModel)]="Filters.rol">
                                    <option value="" selected disabled>Seleccione una opción</option>
                                    <option *ngFor="let item of RolList" [value]="item.name" >{{item.translated_name}}</option>
                                </select>
                            </div>
                          </div>
                          <div class="col-2" class="custom-icon-search">
                              <button (click)="CleanFilter()" class="btn btn-primary" href="javascript:void(0);" role="button"><i class="fa fa-refresh"></i></button>
                          </div>
                        </div>                        
                        <div>
                            <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Rol</th>
                                    <th scope="col">Acción</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let user of UsersList; index as i">
                                    <th scope="row">{{i+1}}</th>
                                    <td>{{user.name +' '+ user.last_name}}</td>
                                    <td>{{user.email}}  </td>
                                    <td>{{ReturnRolUser(user,'rol')}}</td>
                                    <td>
                                      <button mat-icon-button color="accent" aria-label="detail"
                                        (click)= "EditUserForm(user,content)" placement="top" ngbTooltip="Editar">
                                        <mat-icon aria-hidden="false" aria-label="detail" style="color: #212F54;">
                                          edit
                                        </mat-icon>
                                      </button>
                                      <button mat-icon-button color="accent" aria-label="detail"
                                        (click)= "DeleteUser(user)" placement="top" ngbTooltip="Desactivar">
                                        <mat-icon aria-hidden="false" aria-label="detail" style="color: #EF1622;">
                                          delete
                                        </mat-icon>
                                      </button>
                                     </td>
                                  </tr>                               
                                </tbody>
                              </table>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<!--**********************************
    Content body end
***********************************-->

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{TitleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');CleanForm()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="input1"><strong>Nombre</strong></label>
                <input type="text" class="form-control" id="input1" placeholder="Nombre" formControlName="name">
                      <!--  -->
                      <div *ngIf="submitted && f.name?.errors" class="text-danger fs-13 mt-1">
                        <div *ngIf="f.name.errors.required">
                          Campo requerido.
                        </div>
                    
                      </div>
                    <!--  -->
               
              </div>
              <div class="form-group col-md-6">
                <label for="input2"><strong>Apellido</strong></label>
                <input type="text" class="form-control" id="input2" placeholder="Apellido" formControlName="last_name">
                  <!--  -->
                  <div *ngIf="submitted && f.last_name?.errors" class="text-danger fs-13 mt-1">
                    <div *ngIf="f.last_name.errors.required">
                      Campo requerido.
                    </div>
                
                  </div>
                <!--  -->
              </div>
              <div class="form-group col-md-6">
                <label for="input3"><strong>Email</strong></label>
                <input type="email" class="form-control" id="input3" placeholder="Email" formControlName="email">
                <!--  -->
                  <div *ngIf="submitted && f.email?.errors" class="text-danger fs-13 mt-1">
                    <div *ngIf="f.email.errors.required">
                      Email requerido.
                    </div>
                    <div *ngIf="f.email.errors.email">
                      Email inválido
                    </div>
                  </div>
                <!--  -->
              </div>
              <div class="form-group col-md-6">
                <label for="input4"><strong>Contraseña</strong></label>
                <input type="password" class="form-control" id="input4" placeholder="Contraseña" formControlName="password">
                    <!--  -->
                    <div *ngIf="submitted && f.password?.errors" class="text-danger fs-13 mt-1">
                      <div *ngIf="f.password.errors.required">
                        Campo requerido.
                      </div>
                      <div *ngIf="f.password.errors.minlength">
												Contraseña debe ser de mínimo 8 caracteres.
											</div>
                    </div>
                  <!--  -->
              </div>
              <div class="form-group col-md-6">
                  <label for="input4"><strong>Rol</strong></label>
                  <select class="form-control" name="" id="" (change)="onChange($event.target.value)"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="RolValueSelected">
                      <option value="0" disabled selected>Seleccione un Rol</option>
                      <option *ngFor="let item of RolList" [value]="item.id" >{{item.translated_name}}</option>
                  </select>
                      <!--  -->
                      <div *ngIf="submitted && RolValueSelected ===''" class="text-danger fs-13 mt-1">
                        <div >
                          Campo requerido.
                        </div>
                     
                      </div>
                    <!--  -->
                </div>
            </div>
         
      
          </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="onSubmit('save')" *ngIf="ButtonTextModal ">Guardar</button>
      <button type="button" class="btn btn-outline-primary" (click)="onSubmit('edit')" *ngIf="!ButtonTextModal ">Editar</button>
    </div>
  </ng-template>