<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center mb-3">
        <h3>{{titlePage}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <ul ngbNav #nav="ngbNav" class="nav-tabs nav nav-pills nav-fill">
            <li [ngbNavItem]="1">
              <a ngbNavLink class="border-sup">Tramites</a>
              <ng-template ngbNavContent>
                <div class="col-12">
                  <div class="card-header d-block pb-0">
                    <form class="">
                      <div class="form-row col-12">
                        <div class="form-group hidden">
                          <div class="input-group">
                            <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                              [autoClose]="'outside'" (dateSelect)="onDateSelection('Tramite', $event)" [displayMonths]="2"
                              [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate['Tramite']!" tabindex="-1">
                            <ng-template #t let-date let-focused="focused">
                              <span class="custom-day" [class.focused]="focused" [class.range]="isRange('Tramite', date)"
                                [class.faded]="isHovered('Tramite', date) || isInside('Tramite', date)" (mouseenter)="hoveredDate['Tramite'] = date"
                                (mouseleave)="hoveredDate['Tramite'] = null">
                                {{ date.day }}
                              </span>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-end align-items-end">
                        <div class="col-6 col-md-3">
                          <div class="form-group">
                            <label class="mb-1"><strong>Desde</strong></label>
                            <div class="input-group">
                              <input #dpFromDate class="form-control form-filter-calendar" placeholder=""
                                name="dpFromDate" [value]="formatter.format(fromDate['Tramite'])"
                                (input)="fromDate['Tramite'] = validateInput(fromDate['Tramite'], dpFromDate.value)">
                              <div class="input-group-append">
                                <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="form-group">
                            <label class="mb-1"><strong>Hasta</strong></label>
                            <div class="input-group">
                              <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                                [value]="formatter.format(toDate['Tramite'])"
                                (input)="toDate['Tramite'] = validateInput(toDate['Tramite'], dpToDate.value)">
                              <div class="input-group-append">
                                <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="form-group">
                            <label class="mb-1"><strong>Caso</strong></label>
                            <input class="form-control form-filter" name="case" id="case"
                              [(ngModel)]="searchFilter['Tramite'].case_number">
                          </div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="form-group">
                            <div class="input-group">
                              <button class="btn btn-primary btn-filter mr-2" (click)="search('Tramite')">
                                <i class="fa fa-search"></i>
                              </button>
                              <button class="btn btn-primary btn-filter" (click)="clearFilter('Tramite')">
                                <i class="fa fa-refresh"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="card-body">
                    <div class="row justify-content-center">
                      <div class="col">
                        <div *ngIf="list['Tramite'].length" class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Fecha de Inicio</th>
                                <th scope="col">Número de Caso</th>
                                <th scope="col">Nombre de Cliente</th>
                                <th scope="col">Monto Facturado</th>
                                <th scope="col">Monto de Gastos</th>
                                <th></th>
                              </tr>
                            </thead>
                            <ng-container *ngIf="list['Tramite'].length; else nodata">
                              <tbody>
                                <tr *ngFor="let item of list['Tramite']; let i=index">
                                  <td>{{item.init_date}}</td>
                                  <td>{{item.assigned_number}}</td>
                                  <td>{{item.client?.client_name}}</td>
                                  <td>{{item.total_amount}}</td>
                                  <td>{{item.total_expenses}}</td>
                                  <td>
                                    <div>
                                      <button mat-icon-button color="accent"
                                        aria-label="Example icon button with a menu icon"
                                        (click)="openModal(detailModal, item)" placement="top" ngbTooltip="Ver">
                                        <mat-icon aria-hidden="false" aria-label="Example home icon"
                                          style="color: #212F54;">
                                          visibility
                                        </mat-icon>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr *ngIf="info_object">
                                  <td colspan="3"></td>
                                  <td><b>Total Facturado:</b> {{info_object['Tramite'].total_amount}}</td>
                                  <td><b>Total Gastos:</b> {{info_object['Tramite'].total_expenses}}</td>
                                </tr>
                              </tbody>
                            </ng-container>
                            <ng-template #nodata>
                              <div class="alert" role="alert">
                                <strong>No hay data disponible</strong>
                              </div>
                            </ng-template>
                          </table>
                        </div>
                        <div class="text-center">
                          <button class="btn btn-primary my-4" *ngIf="list['Tramite'].length"
                            (click)="downloadPDF('Tramite')">Descargar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink class="border-sup">Procesos</a>
              <ng-template ngbNavContent>
                <div class="col-12">
                  <div class="card-header d-block pb-0">
                    <form class="">
                      <div class="form-row col-12">
                        <div class="form-group hidden">
                          <div class="input-group">
                            <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                              [autoClose]="'outside'" (dateSelect)="onDateSelection('Proceso', $event)" [displayMonths]="2"
                              [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate['Proceso']!" tabindex="-1">
                            <ng-template #t let-date let-focused="focused">
                              <span class="custom-day" [class.focused]="focused" [class.range]="isRange('Proceso', date)"
                                [class.faded]="isHovered('Proceso', date) || isInside('Proceso', date)" (mouseenter)="hoveredDate['Proceso'] = date"
                                (mouseleave)="hoveredDate['Proceso'] = null">
                                {{ date.day }}
                              </span>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-end align-items-end">
                        <div class="col-6 col-md-3">
                          <div class="form-group">
                            <label class="mb-1"><strong>Desde</strong></label>
                            <div class="input-group">
                              <input #dpFromDate class="form-control form-filter-calendar" placeholder=""
                                name="dpFromDate" [value]="formatter.format(fromDate['Proceso'])"
                                (input)="fromDate['Proceso'] = validateInput(fromDate['Proceso'], dpFromDate.value)">
                              <div class="input-group-append">
                                <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="form-group">
                            <label class="mb-1"><strong>Hasta</strong></label>
                            <div class="input-group">
                              <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                                [value]="formatter.format(toDate['Proceso'])"
                                (input)="toDate['Proceso'] = validateInput(toDate['Proceso'], dpToDate.value)">
                              <div class="input-group-append">
                                <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()"
                                  type="button"></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="form-group">
                            <label class="mb-1"><strong>Numero de Caso</strong></label>
                            <input class="form-control form-filter" name="case" id="case"
                              [(ngModel)]="searchFilter.case">
                          </div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="form-group">
                            <div class="input-group">
                              <button class="btn btn-primary btn-filter mr-2" (click)="search('Proceso')">
                                <i class="fa fa-search"></i>
                              </button>
                              <button class="btn btn-primary btn-filter" (click)="clearFilter('Proceso')">
                                <i class="fa fa-refresh"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="card-body">
                    <div class="row justify-content-center">
                      <div class="col">
                        <ng-container *ngIf="list['Proceso'].length; else nodata">

                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Fecha de Inicio</th>
                                  <th scope="col">Número de Caso</th>
                                  <th scope="col">Nombre de Cliente</th>
                                  <th scope="col">Monto Facturado</th>
                                  <th scope="col">Monto de Gastos</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of list['Proceso']; let i=index">
                                  <td>{{item.init_date}}</td>
                                  <td>{{item.assigned_number}}</td>
                                  <td>{{item.client?.client_name}}</td>
                                  <td>{{item.total_amount}}</td>
                                  <td>{{item.total_expenses}}</td>
                                  <td>
                                    <div>
                                      <button mat-icon-button color="accent"
                                        aria-label="Example icon button with a menu icon"
                                        (click)="openModal(detailModal, item)" placement="top" ngbTooltip="Ver">
                                        <mat-icon aria-hidden="false" aria-label="Example home icon"
                                          style="color: #212F54;">
                                          visibility
                                        </mat-icon>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="3"></td>
                                  <td><b>Total Facturado:</b> {{info_object['Proceso'].total_amount}}</td>
                                  <td><b>Total Gastos:</b> {{info_object['Proceso'].total_expenses}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="text-center">
                            <button class="btn btn-primary my-4" *ngIf="list['Proceso'].length"
                              (click)="downloadPDF('Proceso')">Descargar</button>
                          </div>
                        </ng-container>
                        <ng-template #nodata>
                          <div class="alert" role="alert">
                            <strong>No hay data disponible</strong>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #detailModal>
  <div class="modal-header">
    <div class="col-6 d-flex text-left">
      <h4 class="modal-title" id="modal-basic-title">
        Caso:</h4>
      <p class="ml-2 mb-0"> {{currentItem.assigned_number}}</p>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 d-flex justify-content-start align-items-center mb-2">
        <label for="" class="font-weight-bold mb-0">Nombre de Caso:</label>
        <p class="ml-2 mb-0">{{currentItem.case_name}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-start align-items-center mb-2">
        <label for="" class="font-weight-bold mb-0">Nombre de Cliente:</label>
        <p class="ml-2 mb-0">{{currentItem.client?.client_name}}</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col mt-3">
		  <div *ngIf="currentItem.case_expenses.length" class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Descripción de Gasto</th>
                <th scope="col">Monto</th>
                <th scope="col">Fecha</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let payment of currentItem.case_expenses">
                <td>{{payment.description}}</td>
                <td>{{payment.amount}}</td>
                <td>{{payment.date | date: "dd/MM/yyyy"}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-primary my-4" *ngIf="currentItem" (click)="downloadExpensePDF(currentItem.id)">Descargar</button>
    </div>
  </div>
</ng-template>
