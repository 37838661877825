import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { TOKEN, USER } from '../data/constants';
import { HttpService } from './http.service';
import { Observable, Subject } from 'rxjs';
import {
  LoginResponse,
  BaseResponse,
  DataResponse,
} from '../interfaces/response';
import { UserType, User, LawyerProduct } from '../interfaces/user';
import { HttpParams } from '@angular/common/http';

import axios from 'axios'; // temporary import

const env = environment;
export type UserTypeReg = 'companies' | 'persons' | 'lawyers';
@Injectable( {
  providedIn: 'root',
} )
export class AuthService {
  private $user: Subject<User> = new Subject<User>();

  constructor( private http: HttpService, private storage: StorageService ) { }

  /**
   *
   * @param type admin | client | person
   * @param data Form email, password
   * @returns LoginResponse
   */
  login( type: UserType, data: any ): Observable<LoginResponse> {
    return this.http.post( `auth/login/${type}`, data );
  }

  getLawyerProducts(): Observable<DataResponse<LawyerProduct[]>> {
    return this.http.get( `products?order_by=name&order_direction=asc` );
  }

  verifyLawyerProduct( id: number ): Observable<any> {
    const product = { product_id: id };
    return this.http.post( 'verifyLawyer', product );
  }

  /**
   * @description Envio de email para recepción del token
   * @param data Email
   * @returns Token
   */
  recoveryPassword( data: any ): Observable<BaseResponse> {
    return this.http.post( `auth/recovery-password`, data );
  }

  /**
   * @description Cambio de la contraseña enviando token de verificación
   * @param data
   * @returns Cambio de contraseña
   */
  changePassword( data: any ): Observable<BaseResponse> {
    return this.http.post( `auth/new-password`, data );
  }

  /**
   * @description Registro de usuario natural o juridico
   * @param type Person | Company
   * @param data Formulario
   * @returns Validaciond e registro
   */
  register( type: UserTypeReg, data: any ): Observable<DataResponse<any>> {
    return this.http.post( `${type}`, data );
  }

  registerLawyer1( data: any ): Observable<DataResponse<any>> {
    return this.http.post( `lawyers`, data );
  }

  updateLawyer( data: any,id: number  ): Observable<DataResponse<any>> {
    return this.http.post( 'lawyers/'+id+'?_method=PUT', data );
  }

  registerLawyer( data: any ): Observable<DataResponse<any>> {
    const params = new HttpParams();
    // Temporary solution

    return Observable.create( ( observer ) => {
      axios
        .post( env.apiUrl + 'lawyers', data )
        .then( ( response ) => {
          observer.next( response.data );
          observer.complete();
        } )
        .catch( ( error ) => {
          observer.error( error );
        } );
    } );

    /* const options = {
      headers: {
        enctype: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      },
      params,
      reportProgress: true,
    };
    return this.http.post('lawyers', data, options);
    */
  }

  /**
   * @description Retorna true si el usuario esta autenticado
   * @returns boolean
   */
  isAuthenticated(): boolean {
    return this.storage.getItem( TOKEN ) ? true : false;
  }

  /**
   * @description Muestra los datos del usuario activo
   * @returns Usuario
   */
  getUserActive(): User {
    return this.storage.getItem( USER );
  }

  getRole(): string {
    const user: User = this.storage.getItem( USER );
    return user.roles[ 0 ].name;
  }

  /**
   * @description Genera el stream de eventos usando next() para crear el evento
   */
  authSubject( user: User ): void {
    this.$user.next( user );
    this.$user.complete();
  }

  /**
   * @description Creación del observer mediante el método asObserver(), el cual sera consumido por el componente
   * @returns Observable
   */
  authObserver(): Observable<User> {
    return this.$user.asObservable();
  }

  /**
   * @description Retorna el rol del usuario actual
   * @returns string
   */
  getCurrentUserRole(): string {
    if ( !this.isAuthenticated ) { return null; }

    return ( this.getUserActive() as User ).roles[ 0 ].name;
  }

  /**
   * @description Retorna el nombre semantico rol del usuario actual
   * @returns string
   */
  getCurrentUserRoleName(): string {
    if ( !this.isAuthenticated ) { return null; }
    const role = this.getCurrentUserRole();

    if ( role === 'person' ) {
      return "Persona Natural";
    } else if ( role === 'company' ) {
      return "Persona Jurídica";
    } else if ( role === 'lawyer' ) {
      return "Abogado";
    }

    return null;

  }

  /**
   * @description Retorna el id de la entidad del usuario
   * @returns number
   */
  getCurrentUserEntityId(): number {
    if ( !this.isAuthenticated ) { return null; }

    const role = this.getCurrentUserRole();

    if ( role === 'person' ) {
      return this.getUserActive().person.id;
    } else if ( role === 'company' ) {
      return this.getUserActive().company.id;
    } else if ( role === 'lawyer' ) {
      return this.getUserActive().lawyer.id;
    }
  }
}
