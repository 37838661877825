<div class="mt-4">
  <div class="form-row">
  <div class="col-6">
    
    </div>
    <div class="col-4">
      <input type="text" (keyup)="onKey($event)" [(ngModel)]="search" placeholder="Nombre de tramite" class="form-control" name="search" />
    </div>
    <div class="col-2" class="custom-icon-search">
      <i  (click)="GetData(1)" class="flaticon-381-search-1" href="javascript:void(0);" role="button"></i>
    </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Nombre del Tramite</th>
            <th scope="col">Nombre del Solicitante</th>
    
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of Data">
            <th scope="row">{{data.id}}</th>
            <td>{{data.service.name}}</td>
            <td class="text-capitalize">{{data.userApplicant.full_name}}</td>

            <td><button class="btn btn-primary"  (click)="add(serviceModal,data.id)">Detalle</button> </td>
          </tr>
      
        </tbody>
      </table>
    </div>  
    <div class="row justify-content-end">
      <div class="col-auto">
        <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
            [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
            aria-label="Default pagination"></ngb-pagination>
      </div>      
    </div>
</div>


<ng-template #serviceModal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title">Detalle del Servicio Activo</h4> -->
    <div class="col-12">
        <div class="row">
        <div class="col-6 d-flex ">
          <h4 class="modal-title" id="modal-basic-title" >Fecha de Solicitud:</h4>
         
          <p class="ml-2 mb-0"> {{DataDetail?.created_at}}</p>
        </div>
      
        <div class="col-6 d-flex justify-content-end text-right">
          <h4 *ngIf="DataDetail.type_service.id === 2" class="modal-title" id="modal-basic-title">
            Numero de Proceso:</h4>
          <h4 *ngIf="DataDetail.type_service.id === 3" class="modal-title" id="modal-basic-title">
            Numero de Asesoria:</h4>
          <h4 *ngIf="DataDetail.type_service.id === 1" class="modal-title" id="modal-basic-title">
            Numero de Trámite:</h4>
         
          <p class="ml-2 mb-0"> {{DataDetail?.service.id}}</p>
        </div>
       
      </div>
    </div>

  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>General</a>
        <ng-template ngbNavContent >
          <div id="content_1" class="mt-4 p-3">
                  <div class="row ">
                  
                    <div class="col-4 d-flex justify-content-start align-items-center mb-2">
                      <label for="" class="font-weight-bold mb-0">Rama:</label> 
                      <p class="ml-2 mb-0">{{DataDetail?.branch_law.name}}</p>
                    </div>
                      <div class="col-8 d-flex justify-content-start align-items-center mb-2">
                      <label for="" class="font-weight-bold mb-0">Numero de Expediente:</label>
                      <p class="ml-2 mb-0">{{DataDetail?.file_number != null ? DataDetail?.file_number :'N/A'}}</p>
                       <div class="col-6">
                        <input class="form-control form-control-sm " type="text" [value]="DataDetail?.file_number">
                      </div>
                     
                    </div>
                    <!--  -->
                    <div class="col-12 d-flex justify-content-start align-items-center mb-2">
                      <label for="" class="font-weight-bold mb-0">Nombre del Tramite:</label> 
                      <p class="ml-2 mb-0">{{DataDetail?.service.name}}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center mb-2">
                      <label for="" class="font-weight-bold mb-0">Nombre del Solicitante:</label>
                      <p class="ml-2 mb-0">{{DataDetail?.user.full_name}}</p>
                    </div>
                    <div class="col-8 d-flex justify-content-start align-items-center mb-2"
                  >
                      <label for="" class="font-weight-bold mb-0">Nombre de Contraparte:</label>
                      <p class="ml-2 mb-0">{{DataDetail?.counterpart_name}}</p>
                 
                     
                    </div>
                     <hr> 
                 
                </div>
                <!--  -->
                <div class="row mt-2">
                  <div class="col-6 d-flex justify-content-start align-items-center mb-2">
                    <label for="" class="font-weight-bold">Despacho:</label> 
                    <p class="ml-2 mb-2">{{DataDetail?.office}}</p>
                 
                   
                  </div>
                <!--  -->
                  <div class="col-6 d-flex justify-content-start align-items-center mb-2">
                    <label for="" class="font-weight-bold">Entidad:</label>
                    <p class="ml-2 mb-2">{{DataDetail?.entity }}</p>
                       
                    
                  </div>
                  <!--  -->
                  <div class="col-6 d-flex justify-content-start align-items-center mb-2">
                    <label for="" class="font-weight-bold">Jurisdicción:</label> 
                    <p class="ml-2 mb-2">{{DataDetail?.jurisdiction }}</p>
                 
                         
                  </div>
                  <div class="col-6 d-flex justify-content-start align-items-center mb-2">
                    <label for="" class="font-weight-bold mb-0">Costo del Tramite:</label>
                    <p class="ml-2  mb-0">
                      {{DataDetail?.price_service }}$
                    </p>
                  </div>
              
                  <div class="col-6 d-flex justify-content-start align-items-center mb-2">
                    <label for="" class="font-weight-bold m-0">Fecha Inicio:</label>
                    <div class="col-5">
                        <p class="ml-2  mb-0">
                          {{DataDetail?.created_at }}
                        </p>
                    </div>
                  
                  </div>

                  <div class="col-6 d-flex justify-content-start align-items-center mb-2">
                    <label for="" class="font-weight-bold m-0">Fecha de Culminacion:</label>
                    <div class="col-5">
                        <p class="ml-2  mb-0">
                          {{DataDetail?.closed_at }}
                        </p>
                    </div>
                  
                  </div>
          
              
            
                  
              </div>
          </div>
            
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Documentos</a>
        <ng-template ngbNavContent>
          <div id="content_2" class="mt-4 p-2">
              <div class="row ">
                  <div class="col-12 px-3 my-3 d-flex justify-content-center align-items-center ">

                  </div>
                  <div class="col-12 px-3 d-flex justify-content-start align-items-center ">
                      <div class="col-8 ">Ver Documentos:</div>
                  </div>
                  <div class="col-12 px-3 my-3  align-items-center ">
                      <div class="row d-flex justify-content-center">
                          <div class="col-6 mb-2" *ngFor="let document of ListDocument">
                              <div class="list-group">
                                  <a (click)="ShowPdf(document,document.upload)" href="javascript:void(0)"
                                      class="list-group-item list-group-item-action flex-column align-items-start p-2">
                                      <div class="d-flex w-100 justify-content-between">
                                          <h5 class="mb-1 text-capitalize">{{document.name}}</h5>
                                           <small>{{document.date}}</small> 
                                      </div>
                                      <div style="width: 100%;" class="d-flex justify-content-end">
                                        <small>{{document.user}}</small>
                                      </div>
                                  </a>
                              </div>
                          </div>
<!--                           <div *ngIf="documentListForService.length > 0" class="col-12 custom-div-pagination justify-content-center">
                              <ngb-pagination [pageSize]="12" (pageChange)="loadPage3($event)"
                                  [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page3"
                                  aria-label="Default pagination"></ngb-pagination>
                          </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Pagos</a>
        <ng-template ngbNavContent>
          <div class="mt-4 p-2" id="content_3">
            <div class="row ">
               
            
              <div class="col-12 px-3 d-flex justify-content-start align-items-center ">
                <div class="col-8 ">Pagos Realizados:</div>
              </div>
              <div class="col-12 px-3 my-3 d-flex justify-content-center align-items-center ">
                <div class="col-6 mb-2" *ngFor="let payment of DataDetail?.order_payments"> 
                  <div class="list-group">
                    <!--  *ngFor="let document of DataDetail?.order_payments"  -->
                    <a
                    class="list-group-item  flex-column align-items-start p-2">
                      <div class="d-flex w-100 justify-content-between">
                        <p class="mb-1">{{payment?.price}}$</p>
                        <small>{{payment?.created_at}}</small>
                      </div>
                  
                    
                    </a>
                
                  </div>
                </div>
            
              </div>
             
          </div>
          </div>
           
        </ng-template>
      </li>

     
    </ul>
    
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
    
  

  
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss('cancel click')">Cancelar</button>
    <button type="button" class="btn btn-outline-primary" (click)="modal.close('Ok click')">Aceptar</button>
  </div>
</ng-template>
