import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ClientsService } from 'src/app/shared/services/clients.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ConsultanciesService } from '../../../shared/services/consultancies.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { User } from 'src/app/shared/interfaces/user';
import { USER, AVATAR } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-next-payments',
  templateUrl: './next-payments.component.html',
  styleUrls: ['./next-payments.component.css']
})
export class NextPaymentsComponent implements OnInit {
  list = [];
  modal: any;
  page; dataPagination;
  consultancies = [];
  currentItem: any;
  info: unknown;
  user: User;
  modalPay: any;
  modalDetail: any;
  quoteList: any = [];

  bill:any = {
    tramit_number: "5270",
    tramit_name: "Divorcio",
    completed_phases: "Fase 1, Fase 2",
    current_phase: "Fase 3",
    amount_payable: "208$",
    tax: "20$",
    full_payment: "228$"
  }
  titlePage='Finanzas';
  constructor(
    private modalService: NgbModal,
    private clientService: ClientsService,
    titlePageService: TitlePageService,
    private storage: StorageService,
    private consultanciesService: ConsultanciesService,
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
    this.page  = 1;
   }

  ngOnInit(): void {
    this.loadData(this.page);
    this.user = this.storage.getItem(USER);
  }

  openModal(detailModal: any, item) {
    this.clientService.getUpcomingPayment(item.id).subscribe(
      response => {
        this.currentItem = response.data;
        this.modal = this.modalService.open( detailModal, { backdrop: 'static', size: 'md' } );
        this.modalDetail = detailModal;

        let phaseCurrency = this.currentItem.current_phase.split("-");
        phaseCurrency = phaseCurrency.shift();
        let  phases: Array<any> = this.currentItem.phases ;

        this.bill.completed_phases = ""

        for (let index = 0; index < phases.length; index++) {
          const phase = phases[index];

          console.log(phaseCurrency)
          console.log(phase.phase)

          if(phaseCurrency == phase.phase){
            break;
          }

          if(this.bill.completed_phases == "")
          this.bill.completed_phases = phase.description
          else
          this.bill.completed_phases += ", " + phase.description


        }

      }
    );
  }

  loadData(page) {
    this.clientService.getNextPayments(page).subscribe(
      response => {
        this.list = response.data;
        this.dataPagination = response.meta.page;
    });
  }

  downloadItemPDF(id) {
    this.clientService.getUpcomingPayment(id, true).subscribe(

      response => {

        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Proximos Pagos.pdf";
        link.click();
      }
    );
  }

  doPayment() {}

  loadPage(page) {
    this.page = page;
    this.loadData(this.page);
  }

  pagar(modal, data) {
    
    if(data.phase_to_paid.pending == 0)
    {
      Swal.fire({
        icon: 'info',
        text: 'No hay monto pendiente por pagar.',
      })

      return
    }

    this.clientService.getNextPayment(data.id).subscribe(
      response => {

        this.modal.dismiss();
        let payment = response.data.phase_to_paid
        this.info = {
          amount: payment.total,
          order: data.id,
          description: payment.description,
          phase_info: payment
        }
        this.modalPay = this.modalService.open(modal, { backdrop: 'static', size: 'md' });
    });

  }

  closerModalPay() {
    //cerrar el modal y recargar
    this.modalPay.dismiss();
    this.modal = this.modalService.open( this.modalDetail, { backdrop: 'static', size: 'md' } );
  }
}
