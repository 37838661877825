import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncomeService {

  constructor(private http: HttpService) {}

  index(wheres: object, params: object = {}): Observable<any> {
    let parameters = ""
    Object.keys(params).forEach(key => {
      parameters += `&${key}=${params[key]}`
    })
    return this.http.get('income?wheres='+JSON.stringify(wheres)+parameters);
  }

  store(data: object): Observable<any> {
    return this.http.post('income', data);
  }

  show(id: number, params: object = {}): Observable<any> {
    let parameters = ""

    Object.keys(params).forEach(key => {
      parameters += `&${key}=${params[key]}`
    })

    return this.http.get(`income/${id}?p=1${parameters}`);
  }

  update(id: number, data: object): Observable<any> {
    return this.http.put(`income/${id}`, data);
  }

  deleted(id: number): Observable<any> {
    return this.http.delete(`income/${id}`);
  }
}
