import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDate,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../shared/services/common.service';
import { Province } from '../../../shared/interfaces/province';
import { City } from '../../../shared/interfaces/city';
import { Township } from '../../../shared/interfaces/township';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
//import { ToastsService } from 'src/app/shared/services/toasts.service';
import { Country } from 'src/app/shared/interfaces/country';
import { AVATAR } from 'src/app/shared/data/constants';
import { ClientsService } from 'src/app/shared/services/clients.service';
import Swal from 'sweetalert2';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-company-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css'],
})
export class UpdateCompanyProfileComponent implements OnInit {
  form: FormGroup;
  isSubmitted = false;
  avatar = AVATAR;
  fileName: string = 'Seleccione archivo...';
  userRole: string = '';
  userRoleName: string = '';
  constitutionDate: any;
  provinces: Province[] = [];
  cities: City[] = [];
  townships: Township[] = [];
  countries: Country[] = [];
  ProvinceSelected = '0';
  CitySelected = '0';
  TownshipSelected = '0';
  CountrySelected = '';
  @Output() submitEvent = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private common: CommonService,
    private auth: AuthService,
    private profile: ProfileService,
    private clients: ClientsService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: 'Perfil'};
    this.userRole = this.auth.getCurrentUserRole();
    this.userRoleName = this.auth.getCurrentUserRoleName();
    this.loadCountries();
    this.loadProvinces();
    this.createForm();
  }

  get controls(): any {
    return this.form.controls;
  }

  ngOnInit(): void {}

  onSubmit(): void {
    this.isSubmitted = true;

    const id = this.auth.getCurrentUserEntityId();

    if (!this.form.valid) return;

    const { main_image, ...data } = this.form.value;
   
    if (main_image !== '' && main_image !== null) {
      data.main_image = main_image;
    }else{
      data.main_image = main_image;
    }

    data.constitution_date = this.formatter.format(this.constitutionDate);
    console.log(data);
    this.profile.updateLegalPerson(id, data).subscribe((response) => {
      /*this.toastsService.show(response.message, {
        classname: 'bg-success text-white',
      });*/
      Swal.fire( { text: response.message, icon: 'success' } );
    });
  }

  onChange(id: number, type: string): void {
    if (type === 'province') {
      this.loadCities(id);
    }
    if (type === 'city') {
      this.loadTownships(id);
    }
    if (type === 'township') {
      this.form.controls.township_id.patchValue(id);
    }

    if (type === 'country_of_constitution_id') {
      this.form.controls.country_of_constitution_id.patchValue(id);
    }
  }

  loadCountries(): void {
    this.common.countries().subscribe((response) => {
      this.countries = [...response.data];
      // this.loadCities( this.provinces[ 0 ].id );
    });
  }

  loadProvinces(): void {
    this.common.provinces(171).subscribe((response) => {
      this.provinces = [...response.data];
       //this.loadCities( this.provinces[ 0 ].id );
    });
  }

  loadCities(id: number): void {
    this.common.cities(id).subscribe((response) => {
      this.cities = [...response.data];
      //this.loadTownships( this.cities[ 0 ].id );
    });
  }

  loadTownships(id: number): void {
    this.common.townships(id).subscribe((response) => {
      this.townships = [...response.data];
      this.form.controls.township_id.patchValue(this.townships[0].id);
    });
  }

  onImageChange(e): void {
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      this.fileName = file.name;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.avatar = reader.result as string;
        this.form.patchValue({
          main_image: reader.result,
        });
      };
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      business_name: ['', [Validators.required]],
      business_reason: ['', [Validators.required]],
      name: ['', [Validators.required]],
      taxpayer: ['', [Validators.required]],
      operations_notice: ['', [Validators.required]],
      country_of_constitution_id: ['', [Validators.required]],
      constitution_date: [''],
      operation_countries: ['', [Validators.required]],
      dni: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      township_id: ['', [Validators.required]],
      main_image: [''],
      address: ['', [Validators.required]],
      legal_agent: ['', [Validators.required]],
    });
    this.clients
      .getLegalClient(this.auth.getCurrentUserEntityId())
      .subscribe(({ data }) => {
        this.form.patchValue( { ...data, address: data.address.address} );
        this.form.controls.name.setValue( data.user.name); 
        let addressselect = data;
        this.LoadAddressSelectCompany(addressselect);
        this.constitutionDate = this.loadConstitutionDate(data.constitution_date);
        if( this.form.value.main_image !="" && this.form.value.main_image !=null){
          this.avatar = this.form.value.main_image;
        }
      });
  }

  LoadAddressSelectCompany(data){
    this.ProvinceSelected = data.address.province.id;
    this.CitySelected = data.address.city.id;
    this.TownshipSelected = data.address.township.id;
    this.loadCities(parseInt( this.ProvinceSelected));
    this.loadTownships(parseInt( this.CitySelected));
    // 
    this.CountrySelected = data.country_of_constitution_id;
  }

  loadConstitutionDate(constitution_date){
    const [year, month, day] = constitution_date.split('-');
    const obj = { year: parseInt(year), month: parseInt(month), day: 
    parseInt(day.split(' ')[0].trim()) };
    return obj;
  }

  refreshAddress() {
    this.loadProvinces();
    this.TownshipSelected="";
    this.CitySelected="";
    this.ProvinceSelected="";
  }
}
