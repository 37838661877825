import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LawyerService } from '../../../shared/services/lawyer.service';
import Swal from 'src/app/shared/helpers/sweetalert';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { USER, AVATAR } from 'src/app/shared/data/constants';
import { User } from 'src/app/shared/interfaces/user';
import { StorageService } from 'src/app/shared/services/storage.service';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-new-quote-generator',
  templateUrl: './new-quote-generator.component.html',
  styleUrls: ['./new-quote-generator.component.css']
})
export class NewQuoteGeneratorComponent implements OnInit {
  @ViewChild("payModal", { static: true }) payModal: ElementRef;
  @ViewChild("fileModal", { static: true }) fileModal: ElementRef;
  @ViewChild("entityModal", { static: true }) entityModal: ElementRef;
  @ViewChild("detailModal", { static: true }) detailModal: ElementRef;
  user: User;
  priceHour; page; page2; page3; responseCase; listCases: any; dataPagination;
  dataPagination3;
  condSelectLaw: boolean = false;
  modal: any; caseSelected: any;
  addModal:any;
  dataPaginationQuotes;
  data = {
    listTaksData: [
      {
        name: "example comany",
        hour: 0,
        price: 0,
        percent: 0
      }
    ]
  };
  myForm: FormGroup;
  condFormTask: boolean = false;
  myFormValueChanges$; isActiveDiv = false; condLengthForm;
  acumPrice; id_quote;
  quotes: any = [];
  isEdit = false;
  totalHour: number = 0;
  totalPrice: number = 0;
  acumHour: number = 0;
  detailQuotes: any = [];
  active = 1;
  pdfSended = false;
  emailSended = false;
  titlePage='Listado de cotizaciones';
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private LawyerService: LawyerService,
    private fb: FormBuilder,
    private storage: StorageService,
    titlePageService: TitlePageService
  ) {
     titlePageService.titlePageObservableData = { name: this.titlePage};
    /**Simular listado de cotizaciones */
    this.myForm = this.fb.group({
      listTaksData: this.fb.array([])
    });
    this.page = 1;
    this.page2 = 1;
    this.page3 = 1;
  }

  ngOnInit(): void {
    this.user = this.storage.getItem(USER);
    this.lawyergetCases(this.page);
    //  this.getQuotes(this.page2);
    this.myFormValueChanges$ = this.myForm.controls["listTaksData"].valueChanges;
    this.myFormValueChanges$.subscribe(units =>
      this.updateFields(units)
    );
    this.getMyquoteForLawyer(this.user.lawyer.id, this.page3);
  }

  /**
   * Adds the entity modal.
   *
   * @param {any} entityModal - the entity modal to be added
   * @return {void}
   */
  add(entityModal: any): void {
    //if (this.quotes.length > 0) {
      // Verificar reset de formulario
      // this.docsForm.reset();
      this.reseteForm();
      this.isEdit = false;
      this.addModal = this.modalService.open(entityModal, { size: 'xl' });
    //}

  }

  showCase(modal, item) {

  }

  downloadPDF() {
    this.LawyerService.getMyquoteForLawyer(this.user.lawyer.id, null, true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Cotizaciones.pdf";
        link.click();
    });
  }

  downloadExcel() {
    this.LawyerService.getMyquoteForLawyer(this.user.lawyer.id, null, false,true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Cotizaciones.xls";
        link.click();
    });
  }

  resetPageQuote() {
    this.page = 1;
    this.page2 = 1;
    this.page3 = 1;
    this.getMyquoteForLawyer(this.user.lawyer.id, this.page3);
  }

  alertDelete(value) {
    Swal.fire({
      title: 'Atención !',
      text: 'Seguo que desea eliminar la cotizacion ' + value + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#212f54',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.LawyerService.myQuotesLawyerdestroy(value).subscribe(
          async (response) => {
            Swal.fire(
              '',
              'La cotización fue eliminada.',
              'success'
            )
          },
          (error) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'La cotización no pude ser eliminada, comuníquese con el administrador',
              showConfirmButton: false,
              timer: 3000
            })
          }
        )
      }
    })

  }

  selectCase(payModal) {
    this.modal = this.modalService.open(payModal);
  }

  getQuotes(page2) {
    this.LawyerService.getQuotes(page2).subscribe(
      async (response) => {
        this.quotes = response.data;
        this.dataPaginationQuotes = this.responseCase.meta.page;
      },
      (error) => {

      }
    );

  }
  getMyquoteForLawyer(id_lawyer, page) {
    this.LawyerService.getMyquoteForLawyer(id_lawyer, page).subscribe(
      async (response) => {
        this.quotes = response.data;
        this.dataPagination3 = response.meta.page;
      },
      (error) => {

      }
    );

  }

  loadPage(page) {
    this.page = page;
    this.getMyquoteForLawyer(this.user.lawyer.id, this.page);
  }

  lawyergetCases(page) {
    this.LawyerService.lawyergetCases(page).subscribe(
      async (response) => {
        this.responseCase = response;
        this.listCases = response.data;
        if (this.listCases.length > 0) {
          this.condSelectLaw = true;
        } else {
          this.condSelectLaw = false;
        }
        this.dataPagination = this.responseCase.meta.page;
      },
      (error) => {

      }
    );
  }

  selectCaseList(item) {
    this.modal.close(this.payModal);
    this.caseSelected = item;
  }

  addNewTask() {
    let control = <FormArray>this.myForm.controls.listTaksData;
    this.condFormTask = true;
    control.push(
      this.fb.group({
        name: ['', Validators.required],
        hour: ['', Validators.required],
        price: [''],
        percent: [''],
        date_start: ['', Validators.required],
        date_end: ['', Validators.required],
      })
    )
    this.condLengthForm = this.fb.group.length;
  }

  deleteTask(index) {
    let control = <FormArray>this.myForm.controls.listTaksData;
    control.removeAt(index);
    if(control.length==0) {
      this.condFormTask=false;
    }
  }

  sendQuote(fileModal) {
    var case_tasks = {"case_tasks":this.myForm.value.listTaksData};

    this.LawyerService.regCaseQuote(case_tasks, this.caseSelected.id).subscribe(
      async (response) => {
        this.id_quote = response.data.id;
        this.addModal.close(this.entityModal)
        this.alertSuccesReg(fileModal);
        this.resetPageQuote();
      },
      (error) => {

      }
    );
  }

  updateFields(units) {
    const control = <FormArray>this.myForm.controls["listTaksData"];
    this.acumPrice = 0;
    this.acumHour = 0;
    const defaultPercent = 100;
    for (let i in units) {
      let precio = units[i].hour * this.priceHour;
      this.acumPrice = this.acumPrice + precio;
      this.acumHour = this.acumHour + units[i].hour;
      // let porcentaje = (precio * defaultPercent) / this.acumPrice ;
      let porcentaje = 100;
      control
        .at(+i)
        .get("price")
        .setValue(precio, {
          onlySelf: true,
          emitEvent: false
        });
      //  console.log(control.controls[i].get('price').value);
      control
        .at(+i)
        .get("percent")
        .setValue(
          (control.controls[i].get('price').value * defaultPercent) / this.acumPrice
          , {
            onlySelf: true,
            emitEvent: true
          });

    }
    this.totalHour = this.acumHour;
    this.totalPrice = this.acumPrice;
  }

  /**
   * Resets the form and clears the selected case, pdf and email flags, price hour, condition length form, and form task.
   */
  reseteForm() {
    this.myForm.reset();
    this.caseSelected = '';
    this.pdfSended = false;
    this.emailSended = false;
    this.priceHour = '';
    this.condLengthForm = 0;
    this.condFormTask = false;
    const arr = <FormArray>this.myForm.controls.listTaksData;
    arr.controls = [];
  }

  alertSuccesReg(fileModal) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      text: 'Cotización registrada ',
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.loadModalFile(fileModal);
      }
    });
  }

  loadModalFile(fileModal) {
    this.modal = this.modalService.open(fileModal, {centered: true} );
  }

  getPdf(quote_id: number = null) {
    const idQuote = quote_id ? quote_id : this.id_quote;
    this.LawyerService.getQuotePdf(idQuote).subscribe(response => {
        const blob = new Blob( [ response ], { type: 'application/pdf' } );
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Cotización de Caso.pdf";
        link.click();
        this.getMyquoteForLawyer(this.user.lawyer.id, this.page3);
        this.pdfSended = true
      },
      (error) => {
          console.log(error);
      }
    );
    if(this.emailSended) {
      this.modal.close(this.fileModal);
      this.modalService.dismissAll();
      this.reseteForm();
    }
  }

  sendFile(quote_id: number = null) {
    // this.modalService.dismissAll();
    const idQuote = quote_id ? quote_id : this.id_quote;
    this.LawyerService.sendQuoteEmail(idQuote).subscribe(
      async (response) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Cotización envida vía email ',
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.reseteForm();
            this.getMyquoteForLawyer(this.user.lawyer.id, this.page3);
            this.emailSended = true
          }
        });
      },
      (error) => {

      }
    );
    if(this.pdfSended) {
      this.modal.close(this.fileModal);
      this.modalService.dismissAll();
      this.reseteForm();
    }
  }

  goDetail(modal, id) {
    this.getMyquoteDetail(id);
    this.modal = this.modalService.open(modal, { size: 'lg' });
  }

  getMyquoteDetail(quote_id: number) {
    this.LawyerService.getMyquoteDetail(this.user.lawyer.id, quote_id).subscribe(
      async (response) => {

		response.data[0].phases = response.data[0].phases ? JSON.parse(response.data[0].phases) : null;
        this.detailQuotes = response.data[0];

      },
      (error) => {

      }
    );
  }

  /**
   * Get the advance percentage of the task completion.
   *
   * @return {number} the advance percentage
   */
  get advancePercent(): number {
    let res = null
    if (this.detailQuotes?.id) {
      const case_tasks = this.detailQuotes.case_tasks
      const total = case_tasks?.length
      const completed = case_tasks.filter(task => task.status != 'Pendiente')?.length
      res = (completed / total) * 100
    }
    return res
  }

}
