import { Component, OnInit } from '@angular/core';
import { AVATAR, USER } from 'src/app/shared/data/constants';
import { ChatService } from '../../services/chat.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { User } from 'src/app/shared/interfaces/user';

@Component({
  selector: 'app-chat',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.css']
})
export class ChatsComponent implements OnInit {
  list = [];
  page; dataPagination; 
  avatar = AVATAR;
  user: User;
  typeRol: string = '';

  constructor(
    private chatService: ChatService,
    private storage: StorageService,
  ) { }

  ngOnInit(): void {
    this.user = this.storage.getItem( USER );
    this.typeRol = this.user.roles[0].name;
    this.loadData();
  }

  private loadData(page = 1): void {
    if ( this.typeRol === 'person') {
      this.chatService.myActiveProcedures(page).subscribe( response => {
        this.list = [ ...response.data ];
        this.dataPagination = response.meta.page;
      });
    } else {
      this.chatService.myActiveProceduresLawyer(page, this.user.lawyer.id).subscribe( response => {
        this.list = [ ...response.data ];
        this.dataPagination = response.meta.page;
      });
    }

  }

  private hasMessages(data): boolean{
    if(data.chat == null)
    {
      return false;
    }
    return data.chat.chat_messages.some((message) => message.view === 0 );
  }
}
