<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="text-center">
      <h3>Expedientes</h3>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <!-- <ul ngbNav #nav="ngbNav" [activeId]="active" class="nav-tabs nav nav-pills nav-fill"> -->
            <ul ngbNav #nav="ngbNav" class="nav-tabs nav nav-pills nav-fill">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Tramites</a>
                <ng-template ngbNavContent>
                  <div class="row justify-content-center p-4 mt-3">
                    <div class="col-4">
                      <div class="item-filter">
                        <label class="mb-1"><strong>Rama</strong></label>
                        <!-- <select class="form-control form-filter" name="type" id="type"  [(ngModel)]="searchFilter.type"> -->
                        <select class="form-control form-filter" name="type" id="type">
                          <option selected value="">Todos</option>
                          <!-- <option [value]="type.value" *ngFor="let type of types">{{type.rama}}</option> -->
                          <option>{{type.rama}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="item-filter">
                        <label class="mb-1"><strong>Nombre de Servicio</strong></label>
                        <!-- <select class="form-control form-filter" name="type" id="type"  [(ngModel)]="searchFilter.type"> -->
                        <select class="form-control form-filter" name="type" id="type">
                          <option selected value="">Todos</option>
                          <!-- <option [value]="type.value" *ngFor="let type of types">{{type.rama}}</option> -->
                          <option>{{type.service_name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="item-filter">
                        <label class="mb-1"><strong>Abogado</strong></label>
                        <!-- <select class="form-control form-filter" name="type" id="type"  [(ngModel)]="searchFilter.type"> -->
                        <select class="form-control form-filter" name="type" id="type">
                          <option selected value="">Todos</option>
                          <!-- <option [value]="type.value" *ngFor="let type of types">{{type.rama}}</option> -->
                          <option>{{type.lawyer}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div>
                      <fieldset>
                        <p class="ml-3 mb-0"><strong>Número de Trámite: </strong>{{type.tramit_number}}</p>
                        <p class="ml-3 mb-0"><strong>Nombre de Trámite: </strong>{{type.process_name}}</p>
                        <p class="ml-3 mb-0"><strong>Nombre de Solicitante: </strong>{{type.applicant_name}}</p>
                      </fieldset>
                    </div>
                    <div>

                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink>Procesos</a>
                <ng-template ngbNavContent>
                  <div class="row justify-content-center">
                    <div class="col">
                     
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink>Asesorias</a>
                <ng-template ngbNavContent>
                  <div class="row justify-content-center">
                    <div class="col">
                     
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</section>