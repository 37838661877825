import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbTimeStruct, NgbTimeAdapter, NgbModal, NgbDate, NgbCalendar,  NgbDateParserFormatter, ModalDismissReasons, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent} from '@ng-bootstrap/ng-bootstrap';
//import { BranchesLaw } from '../../../shared/interfaces/common';
import { ScheduleService } from 'src/app/shared/services/schedule.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import Swal from 'src/app/shared/helpers/sweetalert';
import { StorageService } from 'src/app/shared/services/storage.service';
import { User } from 'src/app/shared/interfaces/user';
import { USER, AVATAR } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-my-schedule',
  templateUrl: './my-schedule.component.html',
  styleUrls: ['./my-schedule.component.css']
})
export class MyScheduleComponent implements OnInit {
  listTask = [];
  task = {};
  active = 1;
  formTaskGroup: FormGroup;
  isEdit = true;
  modal: any;
  submitted = false;
  statusTask: any;
  timeTask:  {"hour": 13, "minute": 31};
  statusTypes = [{ value: 'Pendiente' }, { value: 'Culminado' }];
  taskSelected: any;

  hoveredDate: NgbDate | null = null;

  fromDateTask: NgbDate | null;
  toDateTask: NgbDate | null;
  user: User;
  condViewListTask: boolean = false;

  constructor(
    private scheduleService: ScheduleService, 
    private fb: FormBuilder,
    private storage: StorageService,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    titlePageService: TitlePageService
  ) { 
    titlePageService.titlePageObservableData = { name: 'Agenda'};
    this.loadTasks();
    this.createForm();

  }

  get f(): any { return this.formTaskGroup.controls; } 


  ngOnInit(): void {
    this.user = this.storage.getItem(USER);
    if (this.user.roles[0].name !== 'person' && this.user.roles[0].name !== 'company') {
      this.condViewListTask = true;
    } else {
      this.condViewListTask = false;
    }
  }

  createForm(): void {
    this.formTaskGroup = this.fb.group ({
      description: ['',  [Validators.required, Validators.minLength(5), Validators.maxLength(500)] ],
      type: ['Tasks'],
      date: ['',  Validators.required ],
      date_end: ['',  Validators.required ],
      status: ['' ]
    });
  }

  onSubmitTask() : void {
    this.submitted = true;
    this.formTaskGroup.value.status = 'Pendiente';
    if ( this.formTaskGroup.valid ) {
      ( this.isEdit ) ? this.editTask() : this.addTask();
    } 
  }

  private addTask(): void {
    console.log('add');
    let hoy = new Date();
    let hora = hoy.getHours() + ':' + hoy.getMinutes();
    this.formTaskGroup.value.date = this.formatter.format( this.fromDateTask ) +' '+ hora;
    this.formTaskGroup.value.date_end = this.formatter.format( this.toDateTask ) +' '+ hora;
    //console.log(this.formTaskGroup.value);
    this.scheduleService.addTask(this.formTaskGroup.value).subscribe( response => {
      Swal.fire( { text: 'Registro de tarea exitoso', icon: 'success' } );
      this.loadTasks();
      this.modal.dismiss();
      this.submitted = false;
    });
  
  }

  private editTask(): void {

    console.log('edit');
    let hoy = new Date();
    let hora = hoy.getHours() + ':' + hoy.getMinutes();
    this.formTaskGroup.value.date = this.formatter.format( this.fromDateTask ) +' '+ hora;
    this.formTaskGroup.value.date_end = this.formatter.format( this.toDateTask ) +' '+ hora;
    this.formTaskGroup.value.status = this.taskSelected.status;
    //console.log(this.formTaskGroup.value);
    this.scheduleService.editTask(this.formTaskGroup.value,this.taskSelected.id).subscribe( response => {
      this.modal.dismiss();
      this.loadTasks();
      Swal.fire( { text: 'Tarea actualizada', icon: 'success' } );
      this.submitted = false;
    });
    
  }

  deleteTask(id): void {
    console.log('delete');
    this.scheduleService.deleteTask(id).subscribe( response => {
      this.loadTasks();
      Swal.fire( { text: 'Tarea Eliminada', icon: 'success' } );
    });
  }

  loadTasks() {
    this.scheduleService.listTask().subscribe( response => {
      this.listTask = [ ...response.data ];
      // console.log(response.data);
    } );
  }

  open(content, doc): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  async editTaskModal( taskModal: any, task: any ): Promise<void> {
    //console.log(task);
    this.isEdit = true;
    this.taskSelected = task;
    
    this.formTaskGroup = this.fb.group ({
      description: [ task.description,  [Validators.required, Validators.minLength(5), Validators.maxLength(500)] ],
      type: ['Tasks'],
      date: [task.date_end,  Validators.required ],
      date_end: [task.date_end,  Validators.required ],
      status: [task.status, Validators.required ]
    });
    
 
    this.fromDateTask = NgbDate.from(this.formatter.parse(task.date));
    this.toDateTask = NgbDate.from(this.formatter.parse(task.date_end));


    this.openModal( taskModal );
  }

  addTaskModal( taskModal: any ): void {
    this.createForm();
    this.fromDateTask = null;
    this.toDateTask = null;
    this.isEdit = false;
    this.formTaskGroup.value.status = 'Pendiente';
    this.openModal( taskModal );
  }

  private openModal( taskModal: any ): void {
    this.modal = this.modalService.open( taskModal, { backdrop: 'static', size: 'lg' } );
  }


  onDateSelection(date: NgbDate) {
    if (!this.fromDateTask && !this.toDateTask) {
      this.fromDateTask = date;
      this.formTaskGroup.controls['date'].setValue(date);
    } else if (this.fromDateTask && !this.toDateTask && date && date.after(this.fromDateTask)) {
      this.toDateTask = date;
      this.formTaskGroup.controls['date_end'].setValue(date);
    } else {
      this.toDateTask = null;
      this.fromDateTask = date;
      this.formTaskGroup.controls['date'].setValue(date);
      this.formTaskGroup.controls['date_end'].setValue(null);
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDateTask && !this.toDateTask && this.hoveredDate && date.after(this.fromDateTask) &&
        date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) { return this.toDateTask && date.after(this.fromDateTask) && date.before(this.toDateTask); }

  isRange(date: NgbDate) {
    return date.equals(this.fromDateTask) || (this.toDateTask && date.equals(this.toDateTask)) || this.isInside(date) ||
        this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
