export const TOKEN = 'ln_token';
export const TOKEN_GOOGLE = 'ln_token_google';
export const USER = 'ln_user';
export const LAWYER_TYPE = 'ln_lawyer_type';
export const LOGO   = '/assets/images/ln/jpg/logo_mini.jpg';
export const LOGO_H = '/assets/images/ln/jpg/logo_expanded.jpg';
export const LOGO_V = '/assets/images/ln/jpg/logo1.jpg';
export const BRAND_TITLE = '/assets/images/ln/png/brand-title.png';
export const AVATAR = '/assets/images/ln/png/avatar.png';
export const CALENDARID = 'primary';
