import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable( {
  providedIn: 'root'
} )
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url;
    return this.checkUserLogin( next, url );
  }

  checkUserLogin( route: ActivatedRouteSnapshot, url: any ): boolean {
    if ( this.auth.isAuthenticated() ) {
      const role = this.auth.getRole();
      if ( route.data.role && route.data.role.indexOf( role ) === -1 ) {
        // this.router.navigate( [ '/admin/index' ] );
        if ( role === 'admin' ) { this.router.navigate( [ '/admin/index' ] ); }
        if ( role === 'company' ) { this.router.navigate( [ '/company/index' ] ); }
        if ( role === 'lawyer' ) { this.router.navigate( [ '/lawyer/index' ] ); }
        if ( role === 'person' ) { this.router.navigate( [ '/person/index' ] ); }
        return false;
      }
      return true;
    }

    this.router.navigate( [ '/login' ] );
    return false;
  }

}
