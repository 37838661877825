import { Component, OnInit, PipeTransform, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from '../../../shared/services/clients.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Options } from '@angular-slider/ngx-slider';
import { USER, AVATAR } from 'src/app/shared/data/constants';
import * as moment from 'moment';
import { TitlePageService } from '../../services/title-page.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { User } from 'src/app/shared/interfaces/user';
import { AdvisoryLawyerService } from 'src/app/shared/services/advisory-lawyer.service';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-service-request-advisory',
  templateUrl: './service-request-advisory.component.html',
  styleUrls: ['./service-request-advisory.component.css'],
  providers: [ {
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class ServiceRequestAdvisoryComponent implements OnInit {
  @ViewChild("layerProfile", { static: true }) layerProfile: ElementRef;
  Modal = {
    lawyer: ''
  }
  avatar = AVATAR;
  selectedType={id:' ', name: ' ', type: ''};
  typeService=[
    {id:'1', name: 'Trámite'},
    {id:'2', name: 'Proceso'},
    {id:'3', name: 'Asesoría'},
  ];
  lisTypeServ=[
    {
      id: 1,
      title:'Quiero una asesoría',
      img:'/assets/images/asesoria.png',
      txt:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident odio perferendis minima, nesciunt aperiam maiores',
      footer: 'Solicitar asesoría',
      service_type:1,
      name: "Asesoría",
    },
    {
      id: 2,
      title:'Quiero una cotización',
      img:'/assets/images/cotizacion.png',
      txt:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident odio perferendis minima, nesciunt aperiam maiores',
      footer: 'Solicitar cotización',
      service_type:2,
      name: "Cotización"
    }
  ]
  view: Boolean = false;
  viewServices: Boolean = false;
  listBranch: any = [];
  listServices: any = [];
  pagesView = 1;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  submitted = false;
  typeServ: any = {};
  titleBranch: string = '';
  lisFormaAtt = [
    { id: 1, name: "Videollamada" },
    { id: 2, name: "Escrita" }
  ];

  lawyerAuto: Boolean = false;
  listBranchServices: any = [];

  seletedLawyer=[];
  page; dataPagination; itemIdPagination; idBranhc; idServ;
  nameBranch:'';
  valuePrice: number = 0;
  options: Options = {
    floor: 0,
    ceil: 20000
  };
  lisLawyerForPrice = [];
  lisLawyerForPriceCopy = [];
  modal: any;
  abogados = [];
  abogadosCopy = [];
  filterTableL = '';
  dataJson: any = [];
  stepsArray: any = [];
  formaAtt=''; // Videollamada o Escrita
  dateToday = new Date();
  monthToday = this.dateToday.getMonth() + 1;
  dayToday = this.dateToday.getDate() + 1;
  yearToday = this.dateToday.getFullYear();
  user: User;
  serviceAuxID:any;

  //nuevas variables

  params = '';
  branches = [];
  studies = [];
  debounce = null;
  isFiltering = false;
  filter = {
    name    : '',
    studies : '',
  };

  constructor(
    private modalService: NgbModal,
    private ClientsService: ClientsService,
    private router: Router,
    private fb: FormBuilder,
    private advisoryLawyerService: AdvisoryLawyerService,
    private LawyerService: LawyerService,
    private common: CommonService,
    private rutaActiva: ActivatedRoute,
    private storage: StorageService,
    titlePageService: TitlePageService
  ) {
    this.page = 1;
    titlePageService.titlePageObservableData = { name: 'Solicitud de Servicios'};
   }

  get f(): any { return this.firstFormGroup.controls; }

  ngOnInit(): void {

    this.selectedType.id='3';
    this.selectedType.name='Asesoría';
    this.view=true;
    this.pagesView=2;
    this.typeServ={
      id: 1,
      title:'Quiero una asesoría',
      img:'/assets/images/asesoria.png',
      txt:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident odio perferendis minima, nesciunt aperiam maiores',
      footer: 'Solicitar asesoría',
      service_type:1,
      name: "Asesoría",
    };
    this.loadDataBrachForService(this.selectedType.id);


    this.user = this.storage.getItem( USER );
    if ( this.user.roles[ 0 ].name === 'person' ) {
      this.selectedType.type='person';
    }
    if ( this.user.roles[ 0 ].name === 'company' ) {
      this.selectedType.type='company';
    }

    this.loadFilters();


  }

  searchLawyer(event:any){
    let old =  [...this.lisLawyerForPriceCopy]

    this.lisLawyerForPrice =
     old.filter(e =>
          e.user.full_name.toUpperCase().includes(event.target.value.toUpperCase())
     )
  }

  searchLawyerForBranch(event:any){
    let old =  [...this.abogadosCopy]

    this.abogados =
     old.filter(e =>
          e.user.full_name.toUpperCase().includes(event.target.value.toUpperCase())
     )
  }

  createForm(){
    /* Asesoria */
    if(this.typeServ.id==1) {
      this.firstFormGroup = this.fb.group({
        typeS: ['', Validators.required],
        hour: ['', Validators.required],
        available_date: ['', Validators.required],
        descripcion: ['', Validators.required],
      });
    }

    /* Cotizacion */
    if(this.typeServ.id==2) {
      this.firstFormGroup = this.fb.group({
        descripcion: ['', Validators.required],
      });
    }


  }

  reset(){
    this.page  = 0 ;
  }

  nextStep(){
    this.submitted = true;
    if ( this.firstFormGroup.valid ) {
        this.submitted = false;
    }
  }

  private loadDataBrach(): void {
    this.ClientsService.getBranchLaw().subscribe(response => {
      this.listBranch = [...response.data];
    });
  }

  private loadDataBrachForService(id): void {
    this.ClientsService.getBrachForService(id, this.page).subscribe(response => {
      this.listBranchServices = [...response.data];
      //console.log(this.listBranchServices);
    });
  }

  private getLawyerForPrice(branch_law_id, type_service_id, price_end): void {
    this.lisLawyerForPrice = [];
    console.log(branch_law_id, type_service_id, price_end);
    this.ClientsService.getLawyerForPrice( this.page, 0, price_end, branch_law_id).subscribe(response => {
      this.lisLawyerForPrice = response.data;
      this.lisLawyerForPriceCopy = [...this.lisLawyerForPrice]
      //console.log(this.lisLawyerForPrice);
      this.dataPagination = response.meta.page;
    });
  }
  changePrice() {
    console.log('listado de abogados');
    setTimeout(() => {
      // ejecutar filtro de abogados por rango de precios
      this.getLawyerForPrice(this.idServ,this.idBranhc, this.valuePrice);
    }, 500);
  }

  // cambio de tipo de asesoria escrita 2 o Videollamada 1
  typeAsesoria(e){
    //console.log(e);
    this.formaAtt= e;

    if(e=='Escrita') {
      this.firstFormGroup.controls['available_date'].disable();
      this.firstFormGroup.controls['hour'].disable();

    } else {
      this.firstFormGroup.controls['available_date'].enable();
      this.firstFormGroup.controls['hour'].enable();

    }
  }

  selectLaw(item) {
    this.seletedLawyer=[];
    this.seletedLawyer.push(item);
  }
  deleteLaw(index,item) {
    this.seletedLawyer.splice(index, 1);
    const pos = this.lisLawyerForPrice.indexOf(item);
    (<HTMLInputElement>document.getElementById('customcheckbox'+pos)).checked  = false;


  }
  chanceSelectLawyer(e,item){
    if(e) {
      //true select
      if(this.seletedLawyer.indexOf(item)==-1){
        this.seletedLawyer.push(item);
      }
    } else {
      //false
      const pos = this.seletedLawyer.indexOf(item);
      this.seletedLawyer.splice(pos, 1);
    }

  }

  /* Selecciona la rama del derecho y pasa al widzar */
  async getBrachServ(branch) {
    try {
      this.seletedLawyer=[];
      if(branch.lawyer_count>0){

        this.idServ = 42; // inventado
        await this.getRagePrice(this.idServ); // problema porque el precio esta asociado al servicio

        this.createForm();
        this.idBranhc = branch.id;
        this.nameBranch = branch.name;
        this.stepsArray=[];
        this.pagesView=3;
      } else {
        Swal.fire({
          icon: 'warning',
          text: "Seleccione una rama del derecho que contenga abogados"
        })
      }
    } catch (error) {
      this.pagesView=2;
      console.error(error)
    }
    // aqui no se selecciona un servicio especifico
    // recomedacion tener un servicio fijo para agregar aqui
  }

  async getRagePrice(id) {
      const response$ = this.ClientsService.getRagePrice(id);
      const response = await lastValueFrom(response$)

      let prices = [0.01];
      for (let index = 0; index < response.data.range_values.length; index++) {
        prices.push(Math.floor(response.data.range_values[index]));
      }

      prices = prices.filter((item, index) => {
        return prices.indexOf(item) === index;
      });

      prices.forEach((price) => {
        let val = price;

        if (!isNaN(val)) {
          this.stepsArray.push({ value: val });
        }
      });

      if (prices.length > 1) {
        this.valuePrice = prices[1];
        this.changePrice();
      }

      this.options = {
        showTicksValues: true,
        stepsArray: this.stepsArray,
      };

  }


  getTypeServ(data) {
    this.typeServ=data;
    //this.firstFormGroup.value.typeS = data.id;
    this.pagesView=2;
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  getBack() {
    this.pagesView=1;
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  modalAbogados(lisAboModal: any, item): void {
    this.itemIdPagination = item.id;

    this.filter = {
      name    : '',
      studies : '',
    };

    this.getLawForBrach(this.selectedType.id, item.id, this.page);

    this.titleBranch=item.name;
    this.serviceAuxID = item.id;
    if(item.name == "Familia")
      this.titleBranch=`de ${item.name}`;
    this.modal = this.modalService.open(lisAboModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }

  private getLawForBrach(branch_law_id, type_service_id, page): void {

    this.getParams();
    if(this.params != "") this.isFiltering = true;
    this.LawyerService.getLawForBranch(type_service_id, branch_law_id, page, this.params).subscribe(
      response => {

        this.abogados = [... response.data.sort(function(a, b) {
        const nameA = a.user.name.toUpperCase();
        const nameB = b.user.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })];

      this.abogadosCopy = [...this.abogados];

      //console.log(this.abogados);
      this.dataPagination = response.meta.page;

      if (this.abogados.length === 0 && !this.isFiltering) {
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Atención!',
          text: "No hay abogados asociados",
          icon: 'warning',
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }

    });

    /*
    this.ClientsService.getLawForBrach(type_service_id, branch_law_id, page).subscribe(response => {

      this.abogados = [... response.data.sort(function(a, b) {
        const nameA = a.user.name.toUpperCase();
        const nameB = b.user.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })];

      this.abogadosCopy = [...this.abogados];



      //console.log(this.abogados);
      this.dataPagination = response.meta.page;



      if (this.abogados.length === 0) {
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Atención!',
          text: "No hay abogados asociados",
          icon: 'warning',
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }

    });
    */
  }

  loadPage(page) {
    this.page = page;
    this.lisLawyerForPrice = [];
    if(!this.idServ &&  !this.idBranhc &&  this.valuePrice < 1 )
      this.getLawForBrach(this.selectedType.id,  this.serviceAuxID,  this.page);
    else
      this.getLawyerForPrice(this.idServ,this.idBranhc, this.valuePrice);
  }


  onCancel(){
    this.pagesView=2;
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  filterListLaw(array) {
    let cadena=[];
    array.filter(function(item) {
      cadena.push(item.id);
    });
    return cadena;
  }

  sendData() {
    //this.isSubmitted = true;

    if (this.firstFormGroup.valid) {


      if(this.lawyerAuto==false && this.seletedLawyer.length==0) {
          Swal.fire({
            title: 'Atención!',
            text: "Debe seleccionar al menos un abogado",
            icon: 'warning',
            showConfirmButton: true
          });
      } else {

        let lawyer = this.filterListLaw(this.seletedLawyer);

        if (this.typeServ.id==1) { // Asesoria


          if (this.formaAtt === 'Videollamada') {

            const format2 = 'YYYY-MM-DD HH:mm';
            const datePicker = this.firstFormGroup.value.available_date;
            const hour = this.firstFormGroup.value.hora;
            this.firstFormGroup.value.available_date = datePicker.year + '-' + datePicker.month + '-' + datePicker.day + ' ' + hour;
            this.firstFormGroup.value.available_date = moment(this.firstFormGroup.value.available_date).format(format2);


            this.dataJson = {
              "description": this.firstFormGroup.value.descripcion,
              "type": this.typeServ.name,
              "auto": this.lawyerAuto,
              "consulting_type": "Videollamada",
              "service_id": this.idServ,
              "lawyers": lawyer,
              "available_date": this.firstFormGroup.value.available_date,
            }
          } else {
            this.dataJson = {
              "description": this.firstFormGroup.value.descripcion,
              "type": this.typeServ.name,
              "auto": this.lawyerAuto,
              "consulting_type": "Escrita",
              "service_id": this.idServ,
              "lawyers": lawyer,
            }
          }




        }
        if (this.typeServ.id==2) { // Cotizacion

          this.dataJson = {
            "description": this.firstFormGroup.value.descripcion,
            "type": this.typeServ.name,
            "auto": this.lawyerAuto,
            "consulting_type": "Escrita",
            "service_id": this.idServ,
            "lawyers":lawyer
          }

        }




        this.ClientsService.regTram1(this.dataJson).subscribe(
          async (response) => {

            console.log(response);

            Swal.fire("Registrado exitosamente" , "" , 'success');



              this.router.navigate(['/'+this.selectedType.type+'/foll-services']);








          },
          (error) => {

          });


      }

    }
  }

//  goProfile(item) {
 //   this.router.navigate(['/'+this.selectedType.type+'/lawyer-profile'], { queryParams: { dataLawyer: item.id } });
   // this.modalService.dismissAll();
  //}
  goProfile(item, content, id: number, data): void {
    this.advisoryLawyerService.showLawyers(item.id).subscribe(response => {
      this.Modal.lawyer = response.data;
      this.modalService.open(this.layerProfile, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    });

  }

  private loadFilters() {
    this.LawyerService.listBranch().subscribe(
      response => {
        this.branches = response.data;
    });
    this.common.studyLevel().subscribe(
      response => {
        this.studies = response.data.filter((item) => item.name != "");
    });
  }

  changeFilter(){
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.getLawForBrach(this.selectedType.id,  this.serviceAuxID, 1);
    }, 500);
  }

  getParams(){
    this.params = "";
    if(this.filter.studies && this.filter.studies != null) this.params +="&study_level_id="+this.filter.studies;
    if(this.filter.name   ) this.params +="&lawyer_name="+this.filter.name;
  }

}
