import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { TOKEN_GOOGLE,CALENDARID } from '../data/constants';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

declare var gapi: any;
const env = environment;
const hoursFromNow = (n) => new Date(Date.now() + n * 1000 * 60 * 60 ).toISOString();
@Injectable({
  providedIn: 'root'
})
export class CalendarService {
 
  constructor( private storage: StorageService,private http:HttpService) { 
  
  }

 async initClient() {
  return new Promise((resolve, reject) => {
    gapi.load('client', () => {
      console.log('loaded client')

      // It's OK to expose these credentials, they are client safe.
      gapi.client.init({
        apiKey: env.calendar.apiKey,
        clientId:  env.calendar.clientId,
        discoveryDocs: env.calendar.discoveryDocs,
        scope: env.calendar.scope,
      })

      gapi.client.load('calendar', 'v3', () => {console.log('loaded calendar'); resolve('success')});

    });
  });
  



  }

  async login_calendar() {
 
    const googleAuth = gapi.auth2.getAuthInstance()
    const googleUser = await googleAuth.signIn();

    const token = googleUser.getAuthResponse().id_token;
    this.storage.setItem( TOKEN_GOOGLE, token );
    return  this.storage.getItem( TOKEN_GOOGLE );
    // angular firebase
    // const credential = auth.GoogleAuthProvider.credential(token);

    // await this.afAuth.auth.signInAndRetrieveDataWithCredential(credential);


    // Alternative approach, use the Firebase login with scopes and make RESTful API calls

    // const provider = new auth.GoogleAuthProvider()
    // provider.addScope('https://www.googleapis.com/auth/calendar');

    // this.afAuth.auth.signInWithPopup(provider)
    
  }

   getCalendar(date): Observable<any> {
    // const events = await gapi.client.calendar.events.list({
    //   calendarId: 'primary',
    //   timeMin: new Date().toISOString(),
    //   showDeleted: false,
    //   singleEvents: true,
    //   // maxResults: 10,
    //   orderBy: 'startTime'
    // })
    let month = date['month'];
    let year = date['year'];
    // console.log(events)
    return this.http.get(`schedule?type=Event&month=${month}&year=${year}`);
    // return events.result.items;
  
  }

    /**
   * @description Retorna true si el usuario esta autenticado
   * @returns boolean
   */
     isAuthenticatedCalendar(): boolean {
      return this.storage.getItem( TOKEN_GOOGLE ) ? true : false;
    }

    async insertEvent(data:any) {
      return new Promise(async (resolve, reject) => {
        const insert = await gapi.client.calendar.events.insert({
          calendarId: CALENDARID,
          conferenceDataVersion: 1,
          resource: {
            end: {
         
              dateTime: data.date_google_end,
              timeZone: 'America/Panama'
            }, 
            start: {
              dateTime: data.date_google_inic,
          
              timeZone: 'America/Panama'
            }, 
         
            summary: data.title,
            description: data.description,
            conferenceData: {
              createRequest: {
                conferenceSolutionKey: {
                  type: "hangoutsMeet"
                },
                requestId: this.makeid(15)
              }
            }
          }
      
        
        });
    
         this.AddEventCalendarLegalNexus(insert['result'],data).subscribe(
            Response =>{
              resolve(Response);
            }
        );
        
      });
    
    }

    AddEventCalendarLegalNexus(element:any,add_element:any){
      // console.log(element);
    
        let data ={
          "title":element.summary,
          "description": add_element.description,
          "type": "Event",
          "status": 'Pendiente',
          "date": add_element.date_ln_inic,
          "date_end": add_element.date_ln_end,
          "url":element.hangoutLink,
          "google_id" : element.id
        } 

        return this.http.post('schedule',data);
      
       
    }

    DeleteEventCalendar(id_event:string,id:string){
      return new Promise(async (resolve, reject) => {
        const result = await gapi.client.calendar.events.delete({
          calendarId: 'primary',
          eventId: id_event,
        })
        // console.log(result);
        this.DeleteEventLegalnexus(id).subscribe(
          Response =>{
            resolve(Response);
          }
        );
      });
    }

    DeleteEventLegalnexus(id:string){
      return this.http.delete('schedule/'+id);
    }

    EditEventCalendar(id:string,id_google:string,data){
      return new Promise(async (resolve, reject) => {
        const edit = await gapi.client.calendar.events.update({
          calendarId: CALENDARID,
          eventId : id_google,
        
          resource: {
            end: {
         
              dateTime: data.date_google_end,
              timeZone: 'America/Panama'
            }, 
            start: {
              dateTime: data.date_google_inic,
          
              timeZone: 'America/Panama'
            }, 
         
            summary: data.title,
            description: data.description,
           
          }
      
        
        });
    
         this.EditEventCalendarLegalNexus(id,edit['result'],data).subscribe(
            Response =>{
              resolve(Response);
            }
        );
        
      });
    }


    EditEventCalendarLegalNexus(id:string,element:any,add_element:any){
      console.log(element);
    
      let data ={
        "title":element.summary,
        "description": add_element.description,
        "type": "Event",
        "status": 'Pendiente',
        "date": add_element.date_ln_inic,
        "date_end": add_element.date_ln_end
      
      
      } 

      return this.http.put('schedule/'+id,data);
    }




   

    makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
          }
          return result;
    }

  
}
