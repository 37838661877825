import { Component, OnInit, ViewChild } from '@angular/core';
import { jsPDF } from "jspdf";
import { TimeTaskService } from '../../../shared/services/time-task.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import  html2canvas  from 'html2canvas';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-execution-time',
  templateUrl: './execution-time.component.html',
  styleUrls: ['./execution-time.component.css']
})
export class ExecutionTimeComponent implements OnInit {

  cases = [];

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate ;
  toDate: NgbDate | null;

  searchFilter = {
    case: '',
    task: '',
    dateStart: null,
    dateEnd: null
  }

  isCase = true;
  selectedTask = {};

  List=[];
  page; dataPagination: any = [];

  constructor(
    private timeTaskService: TimeTaskService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    titlePageService: TitlePageService,
  ) { 
    this.page = 1;
    titlePageService.titlePageObservableData = { name: 'Control de Ejecución'};
  }

  
  ngOnInit(): void {
    this.loadCases(this.page);
    
  }

  search(){
    /*
    let start = this.formatter.format( this.fromDate ).replace('-','/');
    let end = this.formatter.format( this.toDate ).replace('-','/');
    this.searchFilter.dateStart = start.replace('-','/');
    this.searchFilter.dateEnd = end.replace('-','/');
    let cadena = '';


    if(this.searchFilter.task!=='') {
      cadena = '&search='+this.searchFilter.task;
    }
    
    if(this.searchFilter.dateStart!=='') {
      cadena = cadena+'&start_date='+this.searchFilter.dateStart;
    }
    
    if( this.searchFilter.dateEnd!=='') {
      cadena = cadena+'&end_date='+this.searchFilter.dateEnd;
    }
    
    console.log(cadena);

    this.timeTaskService.listExecutionTimeSearch(cadena).subscribe( response => {
      this.List = [ ...response.data ];
      console.log(response.data);
    });
    */
    
  }

  refreshTask () {
    this.isCase = true;
    this.selectedTask = {};
  }

  viewTask(id: number) {
    this.timeTaskService.listExercisesExecutionTime(id).subscribe( response => {
      this.selectedTask = response.data;
      this.isCase = false;
      console.log(this.selectedTask);
    });
  }

  downloadPDF(id: number): void {
    this.timeTaskService.downloadExecutionTime(id).subscribe( response => {

      const blob = new Blob( [ response ], { type: 'application/pdf' } );

      var downloadURL = window.URL.createObjectURL( response );
      var link = document.createElement( 'a' );
      link.href = downloadURL;
      link.download = "Lista de Actividades.pdf";
      link.click();

    });
  }

  loadCases(page) {
    this.timeTaskService.listCasesExecutionTime(page).subscribe( response => {
      this.cases = [ ...response.data ];
      this.dataPagination = response.meta.page;
      //console.log(response.data);
    });
  }

  loadPage(page) {
    this.page = page;
    this.loadCases(this.page);
  }


  makePDF(){

      const DATA = document.getElementById('content');

      const doc = new jsPDF('p', 'pt', 'a4');
 
      const options = {
        background: 'white',
        scale: 3
      };
      html2canvas(DATA, options).then((canvas) => {
  
        const img = canvas.toDataURL('image/PNG');
  
        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        return doc;
      }).then((docResult) => {
        docResult.save(`${new Date().toISOString()}_tareas.pdf`);
      });

  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.search();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


}
