import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Country } from 'src/app/shared/interfaces/country';
import { LawyerService } from '../../../shared/services/lawyer.service';
import { LawyerClient } from 'src/app/shared/interfaces/user';
import { CommonService } from '../../../shared/services/common.service';
import { Province } from 'src/app/shared/interfaces/province';
import { City } from 'src/app/shared/interfaces/city';
import { Township } from 'src/app/shared/interfaces/township';
import Swal from 'src/app/shared/helpers/sweetalert';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component( {
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: [ './clients.component.css' ]
} )
export class ClientsComponent implements OnInit {

  form: FormGroup;
  formNatural: FormGroup;
  submitted = false;
  isEdit = true;
  modal: any;
  clients: LawyerClient[] = [];
  client: LawyerClient = {};
  provinces: Province[] = [];
  cities: City[] = [];
  cityId = 0;
  townshipId = 0;
  provinceId = 0;
  countryConstitutionId = 171;
  operationCountries = [];
  townships: Township[] = [];
  countries: Country[] = [];
  constitutionDate: any;
  selectedType = 'Natural';
  types = [
    {
      value: 'Natural', name: 'Natural'
    },
    {
      value: 'Jurídico', name: 'Jurídico'
    },
  ];


  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private modalService: NgbModal,
    private lawyerService: LawyerService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    titlePageService: TitlePageService,
  ) {
    titlePageService.titlePageObservableData = { name: 'Clientes'};
    this.loadProvinces();
    this.createForm();
    this.loadData();
    this.common.countries().subscribe( response => this.countries = [ ...response.data ] );
    this.constitutionDate = this.calendar.getToday();
  }

  get n(): any { return this.formNatural.controls; }
  get f(): any { return this.form.controls; }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
  

    if ( this.selectedType == 'Natural') {

      if ( this.formNatural.valid ) {
        ( this.isEdit ) ? this.editClientN() : this.addClientN();
      }
 
    } else if( this.selectedType == 'Jurídico') {
      this.form.value.constitution_date = this.formatter.format( this.constitutionDate );

      if ( this.form.valid ) {
        ( this.isEdit ) ? this.editClientJ() : this.addClientJ();
      }
    }
  }

  onChangeType(): void {
    this.createForm();
  }

  add( clientModal: any ): void {

    this.createForm();
    this.loadProvinces();
    this.cities.length = 0;
    this.townships.length = 0;
    this.isEdit = false;
    this.openModal( clientModal );
  }

  async edit( clientModal: any, client: any ): Promise<void> {

    this.isEdit = true;
    this.client = { ...client };
    //console.log(this.client);
    this.selectedType = client.type;
    this.cityId = client.township.city.id;
    this.townshipId = client.township.township.id;
    this.provinceId = client.township.province.id;;

    await this.loadProvinces();
    await this.loadCities(client.township.province.id);
    await this.loadTownships(client.township.city.id);

    if ( this.selectedType == 'Natural') {

      this.formNatural = this.fb.group(
        {
          township_id: [ client.township.township.id, [ Validators.required ] ],
          type: [ client.type, [ Validators.required ] ],
          full_name: [ client.data_client.full_name, [ Validators.required, Validators.minLength( 3 ) ] ],
          dni: [ client.data_client.dni, [ Validators.required ] ],
          email: [ client.data_client.email, [ Validators.required ] ],
          address: [ client.township.address, [ Validators.required ] ],
          phone: [ client.data_client.phone, [ Validators.required ] ]
        }
      );
    } else if( this.selectedType == 'Jurídico') {

      //this.countryConstitutionId = client.data_client.country_of_constitution.id;
      for (let i = 0; i < client.operation_countries.length; i++) {
        this.operationCountries.push(client.operation_countries[i].id);
      }
  
      this.form = this.fb.group(
        {
          township_id: [ client.township.township.id, [ Validators.required ] ],
          type: [ client.type, [ Validators.required ] ],
          dni: [ client.data_client.dni, [ Validators.required, Validators.minLength( 7 ) ] ],
          email: [ client.data_client.email, [ Validators.required, Validators.email ] ],
          address: [ client.township.address, [ Validators.required, Validators.minLength( 5 ) ] ],
          phone: [ client.data_client.phone, [ Validators.required, Validators.minLength( 7 ) ] ],
          business_name: [ client.data_client.business_name, [ Validators.required, Validators.minLength( 5 ) ] ],
          business_reason: [ client.data_client.business_reason, [ Validators.required, Validators.minLength( 5 ) ] ],
          taxpayer: [ client.data_client.taxpayer, [ Validators.required, Validators.minLength( 5 ) ] ],
          operations_notice: [ client.data_client.operations_notice, [ Validators.required, Validators.minLength( 5 ) ] ],
          country_of_constitution_id: [ 171 ],
          constitution_date: [ client.data_client.constitution_date, [ Validators.required ] ],
          operation_countries: [ client.operation_countries, [ Validators.required ] ],
          representative: [ client.data_client.representative, [ Validators.required, Validators.minLength( 5 ) ] ],
          authorized_person:[ client.data_client.authorized_person, [ Validators.required, Validators.minLength( 5 ) ] ]
        }
      );
    }

    this.openModal( clientModal );
  }

  delete( id: number ): void {
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea eliminar este cliente?',
      icon: 'warning',
      showCancelButton: true,
	  confirmButtonColor: '#212F54',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, eliminar!',
      cancelButtonText: 'Cancelar'
    } ).then( ( result ) => {
      if ( result.isConfirmed ) { this.deleteClient( id ); }
    } );
  }

  onChange( id: number, type: string ): void {
    if ( type === 'province' ) { this.loadCities( id ); }
    if ( type === 'city' ) { this.loadTownships( id ); }
    if ( type === 'township' ) { 
      if ( this.selectedType == 'Natural') {
        this.formNatural.controls.township_id.patchValue( id ); 
      } else if( this.selectedType == 'Jurídico') {
        this.form.controls.township_id.patchValue( id ); 
      }
    }
  }

  async loadProvinces(): Promise<void> {
    return new Promise( resolve => {
      this.common.provinces( 171 ).subscribe( response => {
        this.provinces = [ ...response.data ];
        this.loadCities( this.provinces[ 0 ].id );
        resolve();
      } );
    } );
  }

  async loadCities( id: number ): Promise<void> {
    return new Promise( resolve => {
      this.common.cities( id ).subscribe( response => {
        this.cities = [ ...response.data ];
        this.townships = [];
        if ( this.isEdit ) {
          this.loadTownships( this.cityId );
        } else {
          this.loadTownships( this.cities[ 0 ].id );
        }
        resolve();
      } );
    } );
  }

  async loadTownships( id: number ): Promise<void> {
    return new Promise( resolve => {
      this.common.townships( id ).subscribe( response => {
        this.townships = [ ...response.data ];
        resolve();
      } );
    } );
  }



  private openModal( clientModal: any ): void {
    this.modal = this.modalService.open( clientModal, { backdrop: 'static', size: 'lg' } );
  }

  private createForm(): void {
    if ( this.selectedType == 'Natural') {
      this.formNatural = this.fb.group(
        {
          township_id: [ '', [ Validators.required ] ],
          type: [ '', [ Validators.required ] ],
          full_name: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          dni: [ '', [ Validators.required, Validators.minLength( 7 ) ] ],
          email: [  '', [ Validators.required, Validators.email ] ],
          address: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          phone: [ '', [ Validators.required, Validators.minLength( 7 ) ] ]
        }
      );
    } else if( this.selectedType == 'Jurídico') {
      this.form = this.fb.group(
        {
          township_id: [  '', [ Validators.required ] ],
          type: [ '', [ Validators.required ] ],
          dni: [ '', [ Validators.required, Validators.minLength( 7 ) ] ],
          email: [ '', [ Validators.required, Validators.email ] ],
          address: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          phone: [ '', [ Validators.required, Validators.minLength( 7 ) ] ],
          business_name: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          business_reason: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          taxpayer: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          operations_notice: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          country_of_constitution_id: [ 171 ],
          constitution_date: [ '', [ Validators.required ] ],
          operation_countries: [ [] , [ Validators.required ] ],
          representative: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          authorized_person:[ '', [ Validators.required, Validators.minLength( 5 ) ] ]
        }
      );
    }


  }


  private addClientN(): void {
    this.lawyerService.addClient( this.formNatural.value ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { title: 'Cliente creado', icon: 'success', showConfirmButton: true, confirmButtonColor: '#212F54' } );
    } );
  }

  private addClientJ(): void {
    this.lawyerService.addClient( this.form.value ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { title: 'Cliente creado', icon: 'success', showConfirmButton: true, confirmButtonColor: '#212F54'} );
    } );
  }

  private editClientN(): void {
    this.lawyerService.updateClient( this.formNatural.value, this.client.id ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Cliente actualizado', icon: 'success', confirmButtonColor: '#212F54'} );
    } );
  }

  private editClientJ(): void {
    this.lawyerService.updateClient( this.form.value, this.client.id ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Cliente actualizado', icon: 'success', confirmButtonColor: '#212F54'} );
    } );
  }

  private deleteClient( id: number ): void {
    this.lawyerService.deleteClient( id ).subscribe( () => {
      this.loadData();
      Swal.fire( { text: 'Cliente eliminado', icon: 'success',showConfirmButton: false, confirmButtonColor: '#212F54'} );
    } );
  }

  private loadData(): void {
    this.lawyerService.clients().subscribe( response => {
      this.clients = [ ...response.data ];
    } );
  }

  refreshAddress() {
    this.loadProvinces();
    this.provinceId=0;
    this.cityId=0;
    this.townshipId = 0;
  }


}
