// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  apiUrl: 'https://api-test.legal-nexus.net/api/',
  //apiUrl: 'http://localhost:8000/api/',
  assetsUrl: 'https://api-test.legal-nexus.net',
  //assetsUrl: 'http://localhost:8000',
  calendar : {
    apiKey: 'AIzaSyAIroE1Xcq5DLe0Fqt1YfQek-L046c6ETs',
    clientId: '138255978197-mev75llrrpr5ejieo9t8rdl0pfojf6oe.apps.googleusercontent.com',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    scope: 'https://www.googleapis.com/auth/calendar'
  }
};