import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LawyerService } from '../../services/lawyer.service';
import { StorageService } from '../../services/storage.service';
import { TOKEN, USER } from '../../data/constants';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-service-completed-template',
  templateUrl: './service-completed-template.component.html',
  styleUrls: ['./service-completed-template.component.css']
})
export class ServiceCompletedTemplateComponent implements OnInit {
  search;
  @Input() TypeTab ='';
  Data : any;
  DataDetail :any;
  IdLawyer = '';
  NameLawyer = '';
  Type_service_id ='';
  modal: any;
  active = 1;
  ListDocument = [];
  IsPublic = '0';
  docsFormData!: FormData;
  page;
  dataPagination;
  constructor(private LawyerService:LawyerService,private StorageService:StorageService, private modalService: NgbModal) {
    this.page = 1;
    this.GetIdLawyer();
   }

  ngOnInit(): void {
    this.docsFormData = new FormData();
    this.SetTypeService();
    this.GetData(this.page);
  }

  GetIdLawyer(){
      let datauser = this.StorageService.getItem(USER);
      this.IdLawyer = 'lawyer_id='+datauser['lawyer']['id'];
      this.NameLawyer = datauser['name'];
  }

  SetTypeService(){
    let id = this.TypeTab;
    // console.log(id);
 
    this.Type_service_id = '&type_service_id='+id;
  }


  GetData(page){
    var search = this.search;
    if (search === null || search === undefined) {
      search = '';
    } else {
      search = this.search;
    }
    
    let filter =  this.IdLawyer + this.Type_service_id ;
      this.LawyerService.servicescompleted(filter, search, page).subscribe(
        reponse =>{
          console.log(reponse)
          this.Data = reponse.data;
          this.dataPagination = reponse.meta.page;
        }
      );
  }

  loadPage(page) {
    this.page = page;
    this.GetData(this.page);
  }

  
  GetDataDetail(id_order:string){
  
    // let filter =  this.IdLawyer + this.Type_service_id ;
      this.LawyerService.servicesdetail(id_order).subscribe(
        reponse =>{
          console.log(reponse)
          this.DataDetail = reponse.data;
          this.LoadFileDocument();
        }
      );
  }

  LoadFileDocument(){
    let object = this.DataDetail.order_documents;
    if(object){
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const elements = object[key];
              let index = parseInt(key)+1;
              let item = {
                date : elements['created_at'],
                user : elements['user']['full_name'],
                name : 'Document ' + String(index),
                // is_public : elements['user']['is_public'],
                file_url :elements['file_url'],
                upload : 'no'
            }
          this.ListDocument.push(item);
        }
      }
    }
   
  }



  add( serviceModal: any ,id_order): void {
    this.GetDataDetail(id_order);
    this.openModal( serviceModal );
  }

  private openModal( serviceModal: any ): void {

    this.modal = this.modalService.open( serviceModal, { backdrop: 'static',size: 'lg' } );
  }

  onKey(event: any) { // with type info
    if ( event.target.value.length === 0) {
      this.GetData(this.page);
    }
  }



 
}
