<div class="content-body d-flex">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>{{titlePage}}</h3>
        <div>
          Crear entidad
          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
            (click)="add(bankModal)" placement="top" ngbTooltip="Nuevo banco">
            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color:#212F54">
              add_circle_outline
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="banks.length; else noData">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Banco</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let bank of banks; let i=index">
                  <td class="text-capitalize">{{bank.id}}</td>
                  <td class="text-capitalize">{{bank.name}}</td>
                  <td>
                    <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                      (click)="openEditModal(bankModal, bank)" placement="top" ngbTooltip="Editar">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                        edit
                      </mat-icon>
                    </button><button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                      (click)="delete(bank.id)" placement="top" ngbTooltip="Eliminar">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #dc3545;">
                        delete
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-template #noData>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #bankModal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{isEdit ? 'Editar' : 'Agregar'}} Banco</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="">Nombre</label>
        <input type="text" class="form-control" name="" id="" aria-describedby="helpId" placeholder=""
          [value]="bank.name" [(ngModel)]="bank.name">
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-2" (click)="modal.close()">{{isEdit ? 'Editar' :
        'Agregar'}}</button>
      <button type="button" class="btn btn-outline-danger ml-2" (click)="modal.dismiss()">Cancelar</button>
    </div>
  </ng-template>