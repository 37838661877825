<div class="card b-d-shadow-h">
    <div class="card-body p-c-body">
        <h4 class="card-title mb-4">Estadísticas del mes</h4>
        <div   class="d-grid-1">
            <div (click)="navigateTo('lawyer/requests/quotes')" class="clickable b-d-shadow p-w-card">
                <div class="d-grid-2">
                    <div class="text-left">
                        <p class="mb-1">Cotizaciones por aprobar</p>
                        <h2>{{data?.quote_count}}</h2>
                    </div>
                    <span class="">
                        <i class="las la-hand-holding-usd f-size-36"></i>
                    </span>
                </div>
            </div>
            <div (click)="navigateTo('lawyer/requests/consultancies')" class="clickable b-d-shadow p-w-card">
                <div class="d-grid-2">
                    <div class="text-left">
                        <p class="mb-1">Asesorías por aprobar</p>
                        <h2>{{data?.advisory_count}}</h2>
                    </div>
                    <span class="">
                        <i class="las la-clipboard-list f-size-36"></i>
                    </span>
                </div>
            </div>
            <div (click)="navigateTo('lawyer/finances')" class="clickable b-d-shadow p-w-card">
                <div class="d-grid-2">
                    <div class="text-left">
                        <p class="mb-1">Cuentas por Cobrar</p>
                        <h2>${{data?.not_payment | number : '1.2-2':'en-US'}}</h2>
                    </div>
                    <span class="">
                        <i class="la la-dollar f-size-36"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xl-12">
        <div class="card b-d-shadow-h">
            <div class="card-body p-c-body">
                <h4 class="card-title mb-4">Próximos Eventos Agendados</h4>
                <div class="card o-hidden b-d-shadow m-0 h-auto">
                    <div class="card-body p-0">
                        <div class="table-responsive recentOrderTable">
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Evento</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Inicio</th>
                                        <th scope="col">Fin</th>
                                        <th scope="col">link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="data?.events.length<=0">
                                      <td colspan="5">Sin eventos agendados</td>
                                    </tr>
                                    <tr *ngFor="let d of data?.events; let i = index" class="{{ (i%2 == 0 || i==0) ? 'tr-darker' : '' }}">
                                        <td>{{d.title}}</td>
                                        <td>{{d.description}}</td>
                                        <td>{{d.date_start | date: 'd/M/yy  h:mma'}}</td>
                                        <td>{{d.date_end | date: 'd/M/yy  h:mma '}}</td>
                                        <td>
                                            <a href="{{d.url}}" target="_blank" mat-icon-button color="accent"  placement="top" ngbTooltip="Ir al evento">
                                                <mat-icon aria-hidden="false"  style="color: #212F54;">
                                                    launch
                                                </mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="row justify-content-start mt-4">
  <div class="col-xl-6 col-sm-6">
    <div class="card">

      <div class="p-3 pb-0 d-flex flex-column ">

        <label for="" class="mb-3">Porcentaje de Avance</label>



        <p><ngb-progressbar type="primary" textType="white" [value]="data?.percentage_cases_in_process" [showValue]="true"></ngb-progressbar></p>

      </div>

    </div>
  </div>
</div> -->

<!-- <div class="row justify-content-center">
  <div class="col-xl-3 col-sm-6">

    <div class="card">
      <div class="ln-dashboard-card">
        Servicios
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6">

    <div class="card">
      <div class="ln-dashboard-card">
        Solicitudes
      </div>
    </div>
  </div>
</div> -->



<!-- <div class="row justify-content-start">


  <div class="col-xl-6 col-sm-6">
    <div class="card">

      <div class="d-flex flex-column p-3">
          <label for="" class="mb-3">Porcentaje de Avance del Caso</label>

          <div class="col">
            <app-detail-pie-chart></app-detail-pie-chart>
          </div>
      </div>


    </div>
  </div>
</div> -->
