<div class="content-body d-flex align-items-center">
  <div class="container-fluid">
    <div *ngIf="calification" class="row justify-content-center mb-3">
      <div class="col-xl-12 col-sm-12 text-center">
        <h3>{{calification['Categoria']}}
          <i class="flaticon-381-star-1" [(ngClass)]="categoryClasses[calification['Categoria']]"></i>
        </h3>
      </div>
    </div>
    <div class="p-3">
      <h4>Comentarios de tus Clientes:</h4>
    </div>
    <div class="row justify-content-start">
      <ng-container *ngIf="clientComments.length">
        <div class="col-12 mb-2" *ngFor="let data of clientComments">
          <div class="card p-3 p-lg-4">
            <div class="row">
              <div class="col">
                <div class="ml-2">{{data.comment}}</div>
              </div>
              <div class="col-auto">
                <div class="ml-2 font-italic text-right">
                  <small>{{data.user.full_name}}<br>
                    {{data.creation_date}}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noData>
        <div class="alert alert-info text-center my-4" role="alert">
          No hay registros disponibles
        </div>
      </ng-template>
    </div>
  </div>
</div>
