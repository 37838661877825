import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advisory-lawyer-person',
  templateUrl: './advisory-lawyer-person.component.html',
  styleUrls: ['./advisory-lawyer-person.component.css']
})
export class AdvisoryLawyerPersonComponent implements OnInit {
  Object = []
  constructor() { }

  ngOnInit(): void {
  }




}
