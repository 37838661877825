import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { Province } from '../../../shared/interfaces/province';
import { City } from '../../../shared/interfaces/city';
import { Township } from '../../../shared/interfaces/township';
import { CommonService } from '../../../shared/services/common.service';
import {
  BranchesLaw,
  StudyLevel,
  Bank,
  Product,
} from '../../../shared/interfaces/common';

import { MustMatch } from '../../../shared/helpers/must-match.validator';
import { forkJoin } from 'rxjs';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import Swal from 'src/app/shared/helpers/sweetalert';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { AVATAR } from 'src/app/shared/data/constants';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { identifierModuleUrl } from '@angular/compiler';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-lawyer-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateLawyerProfileComponent implements OnInit {
  submitted = false;
  isLinear = false;
  bankId = null;
  filename = '';
  pdfSource = '';
  lawyerProduct: any[] = [];
  buttonshowregister = false;
  cities: City[] = [];
  provinces: Province[] = [];
  townships: Township[] = [];
  products: Product[] = [];
  ProvinceSelected = '0';
  CitySelected = '0';
  TownshipSelected = '0';
  avatar = AVATAR;
  fileName: string = 'Seleccione archivo...';
  isSubmitted = false;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  // Requeriedthird : boolean = false;
  formData: FormData;

  banks: Bank[] = [];
  branchLaw: BranchesLaw[] = [];
  studylevel: StudyLevel[] = [];

  typePass = 'password';
  iconPass = 'visibility_off';

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  private _file: File;

  @Output() submitEvent = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private common: CommonService,private auth: AuthService,
    private router: Router,
    private profile: ProfileService,private lawyerService: LawyerService, titlePageService: TitlePageService) {

    titlePageService.titlePageObservableData = { name: 'Perfil'};
  	forkJoin( [
      this.common.brachLawyer(),
      this.common.studyLevel(),
      this.common.banks(),
      this.common.products(),
    ] ).subscribe( ( [ branchRes, levelRes, bankRes, productRes ] ) => {
      this.branchLaw = [ ...branchRes.data ];
      const levelsData = levelRes.data.filter( level => level?.name );
      this.studylevel = [ ...levelsData ];
      this.banks = [ ...[ { id: null, name: 'Seleccione' } ], ...bankRes.data ];
      this.products = [ ...productRes.data ];

      this.configDropdownList();

    } );

    this.loadProvinces();
    this.createForm();
  }

  ngOnInit(): void {
    this.formData = new FormData();
  }


  onSubmit(): void {
    this.submitted = true;

    const form = this.firstFormGroup;
    this.saveLawyer(form,1);
  }

  onSubmit2(): void {
    this.submitted = true;
    const form = this.secondFormGroup;
    const form2 = this.firstFormGroup;
    this.saveLawyer2(form, form2, 2);
  }

  saveLawyer2(form, form2, tab): void {

    const id = this.auth.getCurrentUserEntityId();
    const formValid = form.valid;

    if ( formValid ) {

      const { main_image, ...data } = form.value;
      const {...data2 } = form2.value;

      // data.branch_laws = this.convertBranchLawsArrToArrIds(data2.branch_laws);
      if (main_image !== '' && main_image !== null&& tab==1) {
        data.main_image = main_image;
      }

      for ( const key in data ) {
        if ( Object.prototype.hasOwnProperty.call( data, key ) ) {
          const value: string = data[ key ];
          if ( Array.isArray( value ) ) {
            this.formData.delete( `${key}[]` );
            value.forEach( ( element, index ) => {
              if ( key === 'study_levels' ) {
                Object.keys( element ).forEach( ( el ) => {
                  this.formData.append(
                    `${key}[${index}][${el}]`,
                    element[ el ]
                  );
                } );
              } else {
                this.formData.append( `${key}[]`, element );
              }
            } );
          } else {
            this.formData.set( key, value );
          }
        }
      }

     this.formData.set( "bank", "true" );

     this.auth.updateLawyer( this.formData, id ).subscribe(response => {
        Swal.fire( { text: response.message, icon: 'success' } );
        //this.router.navigateByUrl( 'login' );

        /*  this.profile.getLawyer(id).subscribe(response => {
          console.log(response);
          this.storage.setItem( USER, response.user );
        })*/


      });
    }else{
      Swal.fire( { text: "Complete todos los campos.", icon: 'error' } );
    }
  }

  saveLawyer(form,tab): void {

    const id = this.auth.getCurrentUserEntityId();
    const formValid = form.valid;
  
    if ( formValid ) {

      const { main_image, ...data } = form.value;

      data.branch_laws = this.convertBranchLawsArrToArrIds(data.branch_laws);
      if (main_image !== '' && main_image !== null&& tab==1) {
        data.main_image = main_image;
      }

      for ( const key in data ) {
        if ( Object.prototype.hasOwnProperty.call( data, key ) ) {
          const value: string = data[ key ];
          if ( Array.isArray( value ) ) {
            this.formData.delete( `${key}[]` );
            value.forEach( ( element, index ) => {
              if ( key === 'study_levels' ) {
                Object.keys( element ).forEach( ( el ) => {
                  this.formData.append(
                    `${key}[${index}][${el}]`,
                    element[ el ]
                  );
                } );
              } else {
                this.formData.append( `${key}[]`, element );
              }
            } );
          } else {
            this.formData.set( key, value );
          }
        }
      }

     this.auth.updateLawyer( this.formData, id ).subscribe(response => {
        Swal.fire( { text: response.message, icon: 'success' } );
        //this.router.navigateByUrl( 'login' );

        /*  this.profile.getLawyer(id).subscribe(response => {
          console.log(response);
          this.storage.setItem( USER, response.user );
        })*/


      });
    }else{
      Swal.fire( { text: "Complete todos los campos.", icon: 'error' } );
    }
  }

  get f(): any {
    return this.firstFormGroup.controls;
  }

  get s(): any {
    return this.secondFormGroup.controls;
  }

  get study_levels(): FormArray {
    return this.firstFormGroup.get( 'study_levels' ) as FormArray;
  }

  addGrades(id, studyLevel_id, obtainedTitle, college): void {
    const studies = this.fb.group( {
      id: id,
      study_level_id: [ studyLevel_id, [ Validators.required ] ],
      obtained_title: [ obtainedTitle, [ Validators.required ] ],
      college: [ college, [ Validators.required ] ],
      delete: 0,
    } );

    this.study_levels.push( studies );
  }

  deleteGrade(i) {
    //this.study_levels.removeAt(i);
    this.study_levels.controls[i].value.delete=1;
  }

  onChange( id: number, type: string ): void {
    if ( type === 'province' ) {
      this.loadCities( id );
    }
    if ( type === 'city' ) {
      this.loadTownships( id );
    }
    if ( type === 'township' ) {
      this.secondFormGroup.controls.township_id.patchValue( id );
    }
  }

  loadProvinces(): void {
    this.common.provinces( 171 ).subscribe( response => {
      this.provinces = [ ...response.data ];
    } );
  }

  loadCities( id: number ): void {
    this.common.cities( id ).subscribe( ( response ) => {
      this.cities = [ ...response.data ];
    });
  }

  loadTownships( id: number ): void {
    this.common.townships( id ).subscribe( ( response ) => {
      this.townships = [ ...response.data ];
      this.secondFormGroup.controls.township_id.patchValue(id);
    } );
  }

  onFileSelect( e: any ): void {
    const reader = new FileReader();
    if ( e.target.files && e.target.files.length ) {
      const [ file ] = e.target.files;

      this._file = file;

      this.filename = file.name;

      reader.readAsDataURL( file );

      reader.onload = () => {
        this.pdfSource = reader.result as string;
      };
    }
  }

  refreshAddress() {
    this.loadProvinces();
  }

  onImageChange(e): void {
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      this.fileName = file.name;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.avatar = reader.result as string;
        this.firstFormGroup.patchValue({
          main_image: reader.result,
        });
      };
    }
  }

  private createForm(): void {
    this.firstFormGroup = this.fb.group( {
      email: [ '', [ Validators.required, Validators.email ] ],
      name: [ '', [ Validators.required, Validators.minLength( 3 ) ] ],
      last_name: [ '', [ Validators.required, Validators.minLength( 3 ) ] ],
      phone: [ '', [ Validators.required, Validators.pattern('[0-9]{7,20}') ] ],
      dni: [ '', [ Validators.required ] ],
      suitability_number: [ '', [ Validators.required ] ],
      branch_laws: [ '', [ Validators.required ] ],
      study_levels: this.fb.array( [] ),
      main_image: [ '' ]
    } );

    this.secondFormGroup = this.fb.group( {
      township_id: [ '0' ],
      address: [ '', [ Validators.required,  Validators.minLength( 5 ) ] ],
      bank_id: [ null, [ Validators.required ] ],
      account_type: [ 'Corriente', [ Validators.required ] ],
      account_number: [ '', [ Validators.required ] ],
      owner_dni: [ '', [ Validators.required ] ],
      owner_name: [ '', [ Validators.required, Validators.minLength( 3 ) ] ],
    } );

    this.thirdFormGroup = this.fb.group( {
      lawyer_products: [ '', [ Validators.required ] ],
    } );

    this.fourthFormGroup = this.fb.group(
      {
        password: [ '', [ Validators.required, Validators.minLength( 8 ) ] ],
        password_confirmation: [ '', [ Validators.required ] ],
      },
      {
        validator: MustMatch( 'password', 'password_confirmation' ),
      }
    );

     this.profile.getLawyer(this.auth.getCurrentUserEntityId()).subscribe( ({ data }) => {
       // data.branch_laws = this.convertBranchLawsArrToArrIds(data.branch_laws);
        let p = data.study_levels;
        let s_levels =[];
        p.forEach((currentValue, index) => {
          s_levels.push(Object.assign(currentValue, {delete:0}));
        });

        this.loadStudyLevels(s_levels);

        if(data.main_image=='' || data.main_image==null){
          data.main_image = this.avatar;
        }

        this.firstFormGroup.patchValue({ ...data, ...data.user, ...data.branch_laws });
        this.secondFormGroup.patchValue({ ...data, ...data.address as {} , ...data.bank });
        if( this.firstFormGroup.value.main_image !="" && this.firstFormGroup.value.main_image !=null){
          this.avatar = data.main_image;
        }
        this.loadAddressSelect(data);
      })
  }

  loadAddressSelect(data){
    this.ProvinceSelected = data.address.province.id;
    this.CitySelected = data.address.city.id;
    this.TownshipSelected = data.address.township.id;
    this.loadCities(parseInt( this.ProvinceSelected));
    this.loadTownships(parseInt( this.CitySelected));
  }

  convertBranchLawsArrToArrIds(branchLaws): any[] {
    let bl = [];
    for (let item of branchLaws) {
      bl.push(item.id);
    }
    return bl;
  }

  loadStudyLevels(studyLevels): void {
    for (let item of studyLevels) {
      this.addGrades(item.id, item.obtained_title, item.college, item.delete);
    }
  }

  configDropdownList(){
    this.dropdownList = [];
    for(var i=0; i < this.branchLaw.length; i++){
      this.dropdownList.push({ id: this.branchLaw[i].id, text: this.branchLaw[i].name });
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }

}
