import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class ChatService {

  constructor(
    private http: HttpService
  ) { }

 /**
   * @description Lista de Trámites
   * @returns Listado
   */
  myActiveProcedures(page = 1): Observable<any> {
    return this.http.get(`monitoring-service?includes[]=chat.chatMessages&includes[]=lawyer.user&includes[]=service&order_status=Activo&lawyer_id_not_null=true&page=${page}`);
  }

  myActiveProceduresLawyer(page = 1, lawyer_id : number): Observable<any> {
    return this.http.get(`monitoring-service?includes[]=chat.chatMessages&includes[]=userApplicant&includes[]=lawyer.user&includes[]=service&order_status=Activo&lawyer_id_not_null=true&page=${page}&lawyer_id=${lawyer_id}`);
  }

  getChatHistory(id: number): Observable<any> {
    return this.http.get(`chats/chatHistory/${id}?includes[]=order&includes[]=chatMessages.user`);
  }

  sendMessage(params: any) {
    return this.http.post(`chat-messages`, params);
  }

  getMessages(): Observable<any> 
  {
    return this.http.get(`chat-messages/count`);
  }
  
}

