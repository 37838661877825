

<section class="content-body d-flex align-items-center" style="padding-top: 0;margin-left: 0;">
    <div class="container-fluid" style="padding-top: 0px;">
        <div class="row mt-2">
            <div class="col-4 d-none">
             
                <div class="card p-3">
                  <button class="btn btn-primary" #logincalendar id="logincalendar" (click)="ClickLogin()">click here</button>
            
            
                   
                </div>
              </div>
              <div class="col-12 p-2">
                <full-calendar   #calendar id="calendar" [options]="calendarOptions"></full-calendar>
              </div>
          </div>
    
    </div>
</section>



<ng-template #eventModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{Title_Modal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="CloseModal();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="firstFormGroup">
      <div class="row">
        <div class="col-6 ">
          <div class="form-group">
            <label for="exampleFormControlInput1">Titulo:</label>
            <input type="text" class="form-control" 	formControlName="title" placeholder="Titulo">
          </div>
          <div *ngIf="submitted && f.title.errors">
            <label for="" style="color: red;" *ngIf="f.title.errors.required" class="ml-2">Campo Requerido</label>
          </div>
          
        </div>
        <div class="col-6  "></div>
        <div class="col-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">Fecha de Inicio:</label>
            <input type="date" class="form-control" [min]="Date.min"  formControlName="dateinic">
          </div>
          <div *ngIf="submitted && f.dateinic.errors">
            <label for="" style="color: red;" *ngIf="f.dateinic.errors.required" class="ml-2">Campo Requerido</label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">Fecha Final:</label>
            <input type="date" class="form-control" [min]="Date.min"  formControlName="dateend">
          </div>
          <div *ngIf="submitted && f.dateend.errors">
            <label for="" style="color: red;" *ngIf="f.dateend.errors.required" class="ml-2">Campo Requerido</label>
          </div>
        </div>
        <!-- time -->
        <div class="col-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">Hora de Inicio:</label>
            <input type="time" class="form-control" formControlName="timeinic">
          </div>
          <div *ngIf="submitted && f.timeinic.errors">
            <label for="" style="color: red;" *ngIf="f.timeinic.errors.required" class="ml-2">Campo Requerido</label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">Hora Final:</label>
            <input type="time" class="form-control" formControlName="timeend" >
          </div>
          <div *ngIf="submitted && f.timeend.errors">
            <label for="" style="color: red;" *ngIf="f.timeend.errors.required" class="ml-2">Campo Requerido</label>
          </div>
        </div>
        <!-- info -->
      
        <div class="col-6">
          <div class="form-group">
            <label for="exampleFormControlInput1">Descripcion:</label>
            <textarea name="" id="" cols="5" rows="5" class="form-control" formControlName="description"></textarea>
          </div>
        </div>

        <div class="col-6 pt-4">
          <div *ngIf="f.url.value != '' && f.url.value != null">
            <button class="btn btn-primary" (click)="ClickMeet()">Ir al Meet</button>
          </div>
       
            
        </div>
   </div>
    </form>
  
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"  (click)="SubmitEvent()">{{ !Edit ?' Crear Evento' : 'Editar Evento'}}</button>
    <button type="button" class="btn btn-outline-danger" *ngIf="Edit" (click)="DeleteEvent()">Eliminar Evento</button>
  </div>
</ng-template>