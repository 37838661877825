import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AdminService } from '../../../shared/services/admin.service';
import { BranchesLaw } from '../../../shared/interfaces/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component( {
  selector: 'app-branches-law',
  templateUrl: './branches-law.component.html',
  styleUrls: [ './branches-law.component.css' ]
} )
export class BranchesLawComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  isEdit = false;
  modal: any;

  branchs: BranchesLaw[] = [];
  branch: BranchesLaw;
  titlePage='Ramas del Derecho';
  page; dataPagination;
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private adminService: AdminService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
    this.page = 1; 
    this.createForm();
    this.loadData();
  }

  get f(): any { return this.form.controls; }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    if ( this.form.valid ) {
      if ( this.isEdit ) {
        this.updateBranch();
        return;
      }
      this.addBranch();
    }
  }

  add( branchModal: any ): void {
    this.form.reset();
    this.isEdit = false;
    this.modal = this.modalService.open( branchModal, { backdrop: 'static' } );
  }

  edit( branchModal: any, branch: BranchesLaw ): void {
    this.isEdit = true;
    this.branch = { ...branch };
    this.createForm();
    this.modal = this.modalService.open( branchModal, { backdrop: 'static' } );
  }

  delete( id: number ): void {
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea eliminar esta rama?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, eliminar!'
    } ).then( ( result ) => {
      if ( result.isConfirmed ) { this.deleteBranch( id ); }
    } );
  }

  private loadData(): void {
    this.adminService.listBranchsPage(this.page).subscribe( response => {
      this.branchs = [ ...response.data ];
      this.dataPagination = response.meta.page;
    } );
  }

  private addBranch(): void {
    this.adminService.addBranchs( this.form.value ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Rama creada', icon: 'success' } );
    } );
  }

  private updateBranch(): void {
    this.adminService.updateBranchs( this.form.value, this.branch.id ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Rama actualizada', icon: 'success' } );
    } );
  }

  private deleteBranch( id: number ): void {
    this.adminService.deleteBranchs( id ).subscribe( (response) => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Rama eliminada', icon: 'success' } );
      console.log(response);
    },
    (error) => {
      Swal.fire( { text: error.error.text, icon: 'warning' } );
    });
  }

  private createForm(): void {
    this.form = this.fb.group( {
      name: [ this.branch ? this.branch.name : '', [ Validators.required ] ],
      commission: [ this.branch ? this.branch.commission : '', [ Validators.required, Validators.min(0), Validators.max(100) ] ]
    } );
  }

  loadPage(page) {
    this.page = page;
    this.loadData();
  }

}
