
<section class="content-body d-flex align-items-center" >
    <div class="container-fluid">
        <div class="row justify-content-start mb-3">
            <div class="col-xl-12 col-sm-12 text-start">
               <h3>Servicios Activos</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-12">

                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs nav nav-pills nav-fill">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Tramites</a>
                        <ng-template ngbNavContent >
                           <app-service-active-template [TypeTab]="'1'"></app-service-active-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                      <a ngbNavLink>Procesos</a>
                      <ng-template ngbNavContent>
                        <app-service-active-template [TypeTab]="'2'"></app-service-active-template>
                    </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>Asesorias</a>
                        <ng-template ngbNavContent>
                            <app-service-active-template [TypeTab]="'3'"></app-service-active-template>
                        </ng-template>
                      </li>
                  </ul>
                  <div [ngbNavOutlet]="nav"></div>
            </div>

        </div>
    </div>
</section>