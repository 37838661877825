import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';

@Component( {
  selector: 'app-lawyer',
  templateUrl: './lawyer.component.html',
  styleUrls: [ './lawyer.component.css' ]
} )
export class LawyerComponent implements OnInit {

  title = 'Legal Nexus';
  navSidebarClass = true;
  hamburgerClass = false;

  constructor( public sharedService: SharedService ) { }

  ngOnInit(): void {
  }

}
