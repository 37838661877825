import { Component, OnInit } from '@angular/core';
import { LOGO_V } from '../../shared/data/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/shared/helpers/must-match.validator';
import { AuthService } from '../../shared/services/auth.service';
//import { ToastsService } from '../../shared/services/toasts.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component( {
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [ './forgot-password.component.css' ]
} )
export class ForgotPasswordComponent implements OnInit {

  logo = LOGO_V;
  form: FormGroup;
  form2: FormGroup;

  submitted = false;
  hasToken = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    //private toastService: ToastsService,
  ) {
    this.createForm();
  }

  get f(): any { return this.form.controls; }
  get g(): any { return this.form2.controls; }

  ngOnInit(): void {
    const token = sessionStorage.getItem( 'token' );
    if ( token ) { this.hasToken = true; }
  }

  onSubmit(): void {
    this.submitted = true;
    if ( this.form.valid ) {
      this.auth.recoveryPassword( this.form.value ).subscribe( response => {
        this.submitted = false;
        this.hasToken = true;
        sessionStorage.setItem( 'token', 'token' );
        //this.toastService.show( response.message, { classname: 'bg-success text-white' } );
        Swal.fire( { text: response.message, icon: 'success' } );
      } );
    }
  }

  onSubmit2(): void {
    this.submitted ||= true;
    if ( this.form2.valid ) {
      this.auth.changePassword( this.form2.value ).subscribe( response => {
        sessionStorage.clear();
        //this.toastService.show( response.message, { classname: 'bg-success text-white' } );
        Swal.fire( { text: response.message, icon: 'success' } );
        this.router.navigateByUrl( 'login' );
      } );
    }
  }

  private createForm(): void {
    this.form = this.fb.group(
      {
        email: [ '', [ Validators.required, Validators.email ] ]
      }
    );

    this.form2 = this.fb.group(
      {
        token: [ '', [ Validators.required ] ],
        new_password: [ '', [ Validators.required, Validators.minLength( 8 ) ] ],
        new_password_confirmation: [ '', Validators.required ],
      }, {
      validator: MustMatch( 'new_password', 'new_password_confirmation' )
    } );

  }

}
