<div class="container-fluid">
  <form class="form-valide" action="#" method="post" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>

    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Nombre(s)</strong></label>
          <input type="text" class="form-control" formControlName="name">
          <div *ngIf="submitted && f.name?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.name.errors.required">Campo requerido.</div>
            <div *ngIf="f.name.errors.minlength">Nombre debe tener al menos 3 caracteres.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Apellido(s)</strong></label>
          <input type="text" class="form-control" formControlName="last_name">
          <div *ngIf="submitted && f.last_name?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.last_name.errors.required"> Campo requerido.</div>
            <div *ngIf="f.last_name.errors.minlength">Apellido debe tener al menos 3 caracteres.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Cédula de Identidad</strong></label>
          <input type="text" class="form-control" formControlName="dni">
          <div *ngIf="submitted && f.dni?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.dni.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Teléfono</strong></label>
          <input type="number" class="form-control" formControlName="phone">
          <div *ngIf="submitted && f.phone?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.phone.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Email</strong></label>
          <input type="text" class="form-control" formControlName="email">
          <div *ngIf="submitted && f.email?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.email.errors.required">Campo requerido.</div>
            <div *ngIf="f.email.errors.email">Email inválido.</div>
            <div *ngIf="f.email.errors.pattern">Email inválido.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row align-items-end">
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Provincias</label>
          <select  class="form-control" name="" id="" (change)="onChange($event.target.value, 'province')">
            <option value=" " disabled selected>Seleccione una opción</option>
            <option [value]="p.id" *ngFor="let p of provinces">{{p.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Distritos</label>
          <select  class="form-control" name="" id="" (change)="onChange($event.target.value, 'city')">
             <option  disabled selected>Seleccione una opción</option>
            <option [value]="city.id" *ngFor="let city of cities">{{city.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">Corregimiento</label>
          <select  class="form-control" name="township_id" id="township_id"
            (change)="onChange($event.target.value, 'township')">
             <option  disabled selected>Seleccione una opción</option>
            <option [value]="township.id" *ngFor="let township of townships">{{township.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-group">
          <button class="btn btn-primary btn-refresh"  (click)="refreshAddress()" placement="top" ngbTooltip="refrescar">
            <mat-icon aria-hidden="false">
              refresh
            </mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="mb-1"><strong>Dirección</strong></label>
          <input type="text" class="form-control" formControlName="address" required>
          <div *ngIf="submitted && f.address?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.address.errors.required">
              Campo requerido.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Contraseña</strong></label>
          <!-- <input type="password" class="form-control" formControlName="password"> -->
          <div class="group-btn-left">
            <input class="form-control" [type]="typePass" formControlName="password">
            <button class="btn btn-link btn-visible" (click)="onChangePass()" type="button">
              <mat-icon aria-hidden="false">
                {{iconPass}}
                </mat-icon>
            </button>
          </div>
          <div *ngIf="submitted && f.password?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.password.errors.required">Campo requerido.</div>
            <div *ngIf="f.password.errors.minlength">Contraseña debe ser de mínimo 8 caracteres.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1"><strong>Confirmar Contraseña</strong></label>
          <!-- <input type="password" class="form-control" formControlName="password_confirmation"> -->
          <div class="group-btn-left">
            <input class="form-control" [type]="typePass" formControlName="password_confirmation">
            <button class="btn btn-link btn-visible" (click)="onChangePass()" type="button">
              <mat-icon aria-hidden="false">
                {{iconPass}}
                </mat-icon>
            </button>
          </div>
          <div *ngIf="submitted && f.password_confirmation?.errors" class="text-danger fs-13 mt-1">
            <div *ngIf="f.password_confirmation.errors.required">Campo requerido.</div>
            <div *ngIf="f.password_confirmation.errors.mustMatch">Contraseñas no coinciden.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4">
      <button type="submit" class="btn btn-primary btn-block">Registrarse</button>
    </div>
  </form>
</div>
