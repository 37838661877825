import { Component, OnInit } from '@angular/core';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-pending-accounts',
  templateUrl: './pending-accounts.component.html',
  styleUrls: ['./pending-accounts.component.css']
})
export class PendingAccountsComponent implements OnInit {
  listT = [];
  listP = [];
  active = 1;
  info_objectT : any;
  info_objectP : any;
  titlePage='Cuentas por Cobrar';
  constructor(
    private lawyerService: LawyerService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
   }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.lawyerService.getIncomes('Tramite').subscribe(
      response => {
        this.listT = response.data;
        this.info_objectT = response.info_object;
    });
    this.lawyerService.getIncomes('Procesos').subscribe(
      response => {
        this.listP = response.data;
        this.info_objectP = response.info_object;
    });
  }

  getTotalT() {
    let total = 0;
    this.listT.forEach(item => {
      total += +item.price;
    })
    return total;
  }

  getTotalP() {
    let total = 0;
    this.listP.forEach(item => {
      total += +item.price;
    })
    return total;
  }

  downloadPdfT() {
    this.lawyerService.getIncomes('Tramite', true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Cuentas por Cobrar - Tramites.pdf";
        link.click();
    });  
  }
  downloadPdfP() {
    this.lawyerService.getIncomes('Procesos', true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Cuentas por Cobrar - Procesos.pdf";
        link.click();
    });  
  }
}
