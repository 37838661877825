<div class="authincation h-100">
	<div class="container-fluid vh-100">
		<div class="row justify-content-center h-100 align-items-center">
			<div class="col-lg-8 col-md-11">
				<div class="authincation-content">
					<div class="row no-gutters">
						<div class="col-xl-12">
							<div class="auth-form p-4">
								<div class="text-center mb-3">
									<a [routerLink]="['/admin/index']">
										<img [src]="logo" alt="" class="w-20">
									</a>
								</div>

                <hr/>
                <br>
                <br>

								<container-element [ngSwitch]="pagesView">
									<some-element *ngSwitchCase="1">

										<div class="row justify-content-center">
											<div class="col-lg-5">

												<!-- h4 class="mb-4">Quiero registrarme como...</!-- -->
                        <h4 class="mb-4">Quiero registrarme como... </h4>

												<div class="card-register p-3 mb-4" (click)="switchType(4,'lawyer')">
													<h4 class="">Abogado</h4>
													<p class="card-text">Prestaré mis servicios como abogado</p>
													<i class="las la-angle-right"></i>
												</div>
												<div class="card-register p-3 mb-4" (click)="switchType(2,'person')">
													<h4 class="">Persona natural</h4>
													<p class="card-text">Para adquirir los servicios de un abogado</p>
													<i class="las la-angle-right"></i>
												</div>
												<div class="card-register p-3" (click)="switchType(3,'company')">
													<h4 class="">Persona jurídica</h4>
													<p class="card-text">Para adquirir los servicios de un abogado para mi empresa</p>
													<i class="las la-angle-right"></i>
												</div>

											</div>
										</div>

									</some-element>
									<some-element *ngSwitchCase="2"><!-- Persona Natural -->
										<div class="row justify-content-center mb-3">
											<div class="col-md-6 col-sm-12 text-center">
												<h4 class="mb-3">Persona Natural</h4>
												<img [src]="avatar" alt="" srcset="" class="ln-avatar border">
											</div>
											<div class="col-12"></div>
											<div class="col-md-6 col-sm-12">
												<div class="form-group">
													<label class="mb-1"><strong>Foto</strong></label>
													<div class="input-group">
														<div class="custom-file">
															<input type="file" class="custom-file-input" (change)="onImageChange($event)"
																accept="image/*">
															<label class="custom-file-label">{{fileName}}</label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<ng-container *ngIf="type === 'person'">
											<!-- Formulario personal -->
											<app-person-form (submitEvent)="registerPerson($event)"></app-person-form>
										</ng-container>
										<p class="text-center text-primary cursor-pointer mt-3" (click)="switchType(1,'')">Regresar</p>
									</some-element>
									<some-element *ngSwitchCase="3"><!-- Persona Juridica -->
										<div class="row justify-content-center mb-3">
											<div class="col-md-6 col-sm-12 text-center">
												<h4 class="mb-3">Persona Jurídica</h4>
												<img [src]="avatar" alt="" srcset="" class="ln-avatar border">
											</div>
											<div class="col-12"></div>
											<div class="col-md-6 col-sm-12">
												<div class="form-group">
													<label class="mb-1"><strong>Foto</strong></label>
													<div class="input-group">
														<div class="custom-file">
															<input type="file" class="custom-file-input" (change)="onImageChange($event)"
																accept="image/*">
															<label class="custom-file-label">{{fileName}}</label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<ng-container *ngIf="type === 'company'">
											<!-- Formulario Juridico -->
											<app-company-form [countries]="countries" (submitEvent)="registerCompany($event)"></app-company-form>
										</ng-container>
										<p class="text-center text-primary cursor-pointer mt-3" (click)="switchType(1,'')">Regresar</p>
									</some-element>
									<some-element *ngSwitchCase="4"><!-- Abogado -->
										<div class="row justify-content-center mb-3">
											<div class="col-md-6 col-sm-12 text-center">
												<h4 class="mb-3">Abogado</h4>
												<img [src]="avatar" alt="" srcset="" class="ln-avatar border">
											</div>
											<div class="col-12"></div>
											<div class="col-md-6 col-sm-12">
												<div class="form-group">
													<label class="mb-1"><strong>Foto</strong></label>
													<div class="input-group">
														<div class="custom-file">
															<input type="file" class="custom-file-input" (change)="onImageChange($event)"
																accept="image/*">
															<label class="custom-file-label">{{fileName}}</label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<ng-container *ngIf="type === 'lawyer'">

											<app-lawyer-form [avatar]="avatar" (submitEvent)="registerLawyer($event)"></app-lawyer-form>
										</ng-container>
										<p class="text-center text-primary cursor-pointer mt-3" (click)="switchType(1,'')">Regresar</p>
									</some-element>
								</container-element>


								<div class="new-account text-center mt-4">
									<p>¿Ya tienes una cuenta? <a class="text-primary" [routerLink]="['/login']">Inicie sesión</a></p>


								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
