import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-op-reports',
  templateUrl: './op-reports.component.html',
  styleUrls: ['./op-reports.component.css']
})
export class OpReportsComponent implements OnInit {

  statuses = [];
  list = [];
  params = '';
  page; dataPagination; 
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    client: null,
    status: null
  }
  hoveredDate: NgbDate | null = null;
  modal: any;
  titlePage='Reporte Operativo';

  constructor(
    private lawyerService: LawyerService,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    titlePageService: TitlePageService
  ) { 
    titlePageService.titlePageObservableData = { name: this.titlePage};
    this.page=1;
  }

  ngOnInit(): void {
    this.loadData(this.page);
    this.loadFilters(this.page);
  }

  loadData(page) {
    this.lawyerService.getOperationalCases(page, this.params).subscribe(response => {
      this.list = response.data;
      this.dataPagination = response.meta.page;
    });
  }

  loadFilters(page) {
    this.lawyerService.listCaseStatuses().subscribe(
      response => {
        this.statuses = response.data;
    });
  }

  downloadPDF() {
    this.lawyerService.getOperationalCases(null, this.params, true).subscribe(
      response => {
        const blob = new Blob( [ response ], { type: 'application/pdf' } );
        var downloadURL = window.URL.createObjectURL( blob );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Reporte Operativo.pdf";
        link.click();
      }
    );
  }

  getTotal(type = 'culminados') {
    let culminados = 0;
	let total = 0;

    this.list.forEach(item => {
		if(item.case_statuses == 'Culminado')  { culminados ++; }
		total++;
    })

    return type == 'culminados' ? culminados : total;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.params = '',
    this.loadData(this.page);
    this.searchFilter = {
      dateStart: null,
      dateEnd: null,
      client: null,
      status: null
    }
    this.toDate = null;
    this.fromDate = null;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search(){
    this.searchFilter.dateStart = this.formatter.format(this.fromDate);
    this.searchFilter.dateEnd = this.formatter.format(this.toDate);
    this.params = '';
    
    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }
    
    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.searchFilter.client) {
      this.params += '&client_name=' + this.searchFilter.client;
    }

    if (this.searchFilter.status) {
      this.params += '&case_status_id=' + this.searchFilter.status;
    }

    if (this.params !== '') {
       this.loadData(this.page);
    }
  }

  loadPage(page) {
    this.page = page;
    this.loadData(this.page);
  }
}
