import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AVATAR } from 'src/app/shared/data/constants';
import { AdminService } from 'src/app/shared/services/admin.service';
import Swal from 'sweetalert2';
import { Chart } from 'chart.js';
import { DashboardService } from '../../../shared/services/dashboard.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class AdminDashboardComponent implements OnInit {

  avatar = AVATAR;
  modal: any;
  params = '';
  branches = [];
  types = [];
  list = [];
  showLawyer: any;
  page; dataPagination;
  chart: any;
  chartsm: any;
  chart2: any;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    name: null,
    branch: null
  }
  hoveredDate: NgbDate | null = null;

  dataDashboard: any;
  openCases: any;
  closeCases: any;
  lawNetwork: any;
  virtualDsk: any;
  monthlyIncome: any;
  label_months: any;
  titlePage='Inicio';

  constructor(
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private adminService: AdminService,
    private DashboardService: DashboardService,
    titlePageService: TitlePageService
  ) { 
    this.label_months = [
      'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
      'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
    ];
    titlePageService.titlePageObservableData = { name: this.titlePage};
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(page = 1) {
    this.DashboardService.getDashboardAdmin().subscribe(response => {
      this.dataDashboard = response.data;
      this.openCases     = this.dataDashboard.case.in_process;
      this.closeCases    = this.dataDashboard.case.culminated;
      this.lawNetwork    = this.dataDashboard.lawyers.red_Legal;
      this.virtualDsk    = this.dataDashboard.lawyers.virtual_office;
      this.monthlyIncome = this.dataDashboard.orders.gross_monthly_income.map((month) => Math.floor(month.sum) );
      this.chart = new Chart('canvas', {
        type: 'doughnut',
        data: {
          labels: ['Activos', 'Cerrados'],
          datasets: [
            {
              data: [this.openCases, this.closeCases],
              backgroundColor: ["#212F54", "#5b7edc"],
              fill: false
            },
          ]
        },
        options: {
          legend: {
            position: 'bottom',
            align: 'start',
            display: false,
          },
          tooltips: {
            enabled: true
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      });
      this.chart2 = new Chart('canvas2', {
        type: 'line',
        data: {
          labels: this.label_months,
          datasets: [
            {
              data: this.monthlyIncome,
              backgroundColor: ['#212F54'],
              borderColor: '#212F54',
              fill: false
            },
          ]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            enabled: true
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      });
    });
  }
}




