
<section class="content-body d-flex align-items-center" >
    <div class="container-fluid">
        <div class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
               <h3>Control de Ejecución</h3>
            </div>
        </div>
        <div class="row" *ngIf="isCase">
            <div class="col-12">

                <div class="card border-0">
                    <!--
                    <div class="card-header">
                        <div class="col">
                            <form class="">
                            <div class="form-group hidden">
                                <div class="input-group">
                                <input name="datepicker"
                                        class="form-control"
                                        ngbDatepicker
                                        #datepicker="ngbDatepicker"
                                        [autoClose]="'outside'"
                                        (dateSelect)="onDateSelection($event)"
                                        [displayMonths]="2"
                                        [dayTemplate]="t"
                                        outsideDays="hidden"
                                        [startDate]="fromDate!"
                                        tabindex="-1">
                                <ng-template #t let-date let-focused="focused">
                                    <span class="custom-day"
                                        [class.focused]="focused"
                                        [class.range]="isRange(date)"
                                        [class.faded]="isHovered(date) || isInside(date)"
                                        (mouseenter)="hoveredDate = date"
                                        (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                    </span>
                                </ng-template>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-md-3">
                                    <div class="item-filter">
                                        <label class="mb-1"><strong>Actividades</strong></label>
                                        <input [(ngModel)]="searchFilter.task" class="form-control form-filter" name="taks" id="taks" (change)="search()"/>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Desde</strong></label>
                                    <div class="input-group">
                                        <input #dpFromDate
                                            class="form-control form-filter-calendar" placeholder=""
                                            name="dpFromDate"
                                            [value]="formatter.format(fromDate)"
                                            (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                                        <div class="input-group-append">
                                        <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="form-group ml-2">
                                        <label class="mb-1"><strong>Hasta</strong></label>
                                        <div class="input-group">
                                            <input #dpToDate
                                            class="form-control form-filter-calendar" placeholder=""
                                            name="dpToDate"
                                            [value]="formatter.format(toDate)"
                                            (input)="toDate = validateInput(toDate, dpToDate.value)">
                                            <div class="input-group-append">
                                            <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    -->
                    <div class="card-body">
                        <h4 class="mb-4">Lista de Casos</h4>
                        <ng-container *ngIf="cases.length; else nodatacase">
                            <div class="table-responsive">
                                <table class="table table-striped" >
                                    <thead>
                                    <tr>
                                        <th scope="col">Caso</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col" class="text-center">Ver Actividades</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let c of cases; let i=index">
                                        <td>{{c.case_name}}</td>
                                        <td>{{c.status}}</td>
                                        <td>{{c.client.client_name}}</td>
                                        <td class="text-center">
                                            <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                                            (click)="viewTask(c.id)" placement="top" ngbTooltip="Ver">
                                            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                                              visibility
                                            </mat-icon>
                                          </button>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="col-12 custom-div-pagination justify-content-center">
                                    <ngb-pagination [pageSize]="12" (pageChange)="loadPage($event)"
                                        [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="page"
                                        aria-label="Default pagination"></ngb-pagination>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #nodatacase>
                            <div class="alert alert-warning" role="alert">
                            <strong>No hay registros de casos disponibles</strong>
                            </div>
                        </ng-template>

                    </div>

                </div>

            </div>
        </div>

        <div class="row" *ngIf="!isCase">
            <div class="col-12">

                <div class="card border-0">
                    <div class="card-header d-block">
                        <div class="row align-items-center justify-content-end">
                            <div class="col-auto">
                                <button class="btn btn-link"  style="color:#212F54" (click)="refreshTask()" placement="top" ngbTooltip="Regresar">
                                <i class="fa fa-arrow-left"></i> Volver
                                </button>
                            </div>
                        </div>


                    </div>
                    <div class="card-body">

                        <ng-container *ngIf="selectedTask.case_tasks.length; else nodata">
                            <div class="table-responsive" id="content" #content>
                            <h5 class="text-center my-3">Lista de Actividades</h5>

                            <p class="mb-0"><strong>Caso:</strong> {{selectedTask.name}}</p>
                            <p class="mb-4"><strong>Cliente:</strong> {{selectedTask.client.client_name}}</p>

                            <table class="table table-striped" >
                                <thead>
                                <tr class="text-center">
                                    <th scope="col">Actividades</th>
                                    <th scope="col">Horas <br> Planificadas</th>
                                    <th scope="col">Horas de <br>Ejecución</th>
                                    <th scope="col">Diferencial <br>de Horas</th>
                                    <th scope="col">Ganancias de Horas<br> Planificadas</th>
                                    <th scope="col">Ganancias de Horas <br>Ejecutadas</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let time of selectedTask.case_tasks; let i=index">
                                    <td>{{time.name}}</td>
                                    <td class="text-center">{{time.estimated_hours}}</td>
                                    <td class="text-center">{{time.hours_employed}}</td>
                                    <td class="text-center">{{time.deferent_hours}}</td>
                                    <td class="text-center">{{time.amount_for_planned_hours}}</td>
                                    <td class="text-center">{{time.amount_for_hours_executed}}</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </ng-container>
                        <ng-template #nodata>
                            <div class="alert alert-warning" role="alert">
                            <strong>No hay actividades registrados para este caso</strong>
                            </div>
                        </ng-template>

                        <div class="text-center" *ngIf="selectedTask.case_tasks.length">
                          <button class="btn btn-primary my-4" (click)="downloadPDF(selectedTask.id)">Descargar</button>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</section>
