import { Component, OnInit,Input } from '@angular/core';

import { LawyerService } from '../../services/lawyer.service';
import { StorageService } from '../../services/storage.service';
import { TOKEN, USER } from '../../data/constants';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-service-active-template',
  templateUrl: './service-active-template.component.html',
  styleUrls: ['./service-active-template.component.css']
})
export class ServiceActiveTemplateComponent implements OnInit {
  search;
  @Input() TypeTab ='';
  Data : any;
  DataDetail :any;
  IdLawyer = '';
  NameLawyer = '';
  Type_service_id ='';
  modal: any;
  active = 1;
  ListDocument = [];
  page;
  dataPaginationComment;
  order_id:any;
  comment: string;
  listComment = [];
  dataPagination;
  pageComment;
  dataHistory: any[] = [];
  selectedHistoryChanges: any;
  dataHistoryPagination: any;

  IsPublic = '3';
  docsFormData!: FormData;
  constructor(private LawyerService:LawyerService,private StorageService:StorageService,
    private modalService: NgbModal) {
      this.page = 1;
    this.GetIdLawyer();

   }

  ngOnInit(): void {
    this.docsFormData = new FormData();
    this.SetTypeService();
    this.GetData(this.page);

  }

  GetIdLawyer(){
      let datauser = this.StorageService.getItem(USER);
      this.IdLawyer = 'lawyer_id='+datauser['lawyer']['id'];
      this.NameLawyer = datauser['name'];
  }

  SetTypeService(){
    let id = this.TypeTab;
    // console.log(id);

    this.Type_service_id = '&type_service_id='+id;
  }


  GetData(page){
    var search = this.search;
    if (search === null || search === undefined) {
      search = '';
    } else {
      search = this.search;
    }

    let filter =  this.IdLawyer + this.Type_service_id + "&approved_by_lawler=1&is_payment=1";
      this.LawyerService.servicesactive(filter, search, page).subscribe(
        reponse =>{
          console.log(reponse)
          this.Data = reponse.data;
          this.dataPagination = reponse.meta.page;
        }
      );
  }

  loadPage(page) {
    this.page = page;
    this.GetData(this.page);
  }

  GetDataDetail(id_order:string , serviceModal){

    // let filter =  this.IdLawyer + this.Type_service_id ;
    this.order_id = id_order
    this.refreshComment();
    this.loadDataHistory();

    this.LawyerService.servicesdetail(id_order).subscribe(
        reponse =>{

          this.DataDetail = reponse.data;

          let valid_payments =  this.DataDetail.order_payments.filter(e=> e.status == "Aprobado")


          if(valid_payments.length < 1){


              Swal.fire({
                icon: 'warning',
                text: ' ',
                title: "En espera de la aprobación del pago del Trámite",
              })

          }else{
            this.LoadFileDocument();
            this.openModal( serviceModal );
          }


        }
      );
  }


  LoadFileDocument(){
    let object = this.DataDetail.order_documents;
    if(object){
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const elements = object[key];
              let index = parseInt(key)+1;
              let item = {
                date : elements['created_at'],
                user : elements['user']['full_name'],
                name : 'Document ' + String(index),
                // is_public : elements['user']['is_public'],
                file_url :elements['file_url'],
                upload : 'no'
            }
          this.ListDocument.push(item);
        }
      }
    }

  }


  add( serviceModal: any ,id_order): void {
    this.GetDataDetail(id_order , serviceModal);

  }

  private openModal( serviceModal: any ): void {

    console.log(serviceModal)

    this.modal = this.modalService.open( serviceModal, { backdrop: 'static',size: 'lg' } );
  }

  ReturnNamePhase(id_phase){
    let name = `Fase ${id_phase}`;

    return name;
  }

  onFileSelect(e: any): void {
		const reader = new FileReader();
		if (e.target.files && e.target.files.length) {
			const [file] = e.target.files;
      let name = file['name'].split('.');

          let item = {
            date : Date.now(),
            user : this.NameLawyer,
            name : name[0],
            is_public : this.IsPublic,
            file_url :file,
            order_id :this.DataDetail?.id,
            'includes[]' : 'user',
            upload : 'yes'
        }
      this.ListDocument.push(item);
      this.IsPublic = '3';

		}


	}


  async UploadInfo(){
    console.log('load');
    try {
     await this.LoadDocument();
     await this.UpdateInfoGeneral();
    } catch (error) {
        console.log(error);
    } finally {
      // console.log('finish');
      this.modalService.dismissAll();
      this.ListDocument = [];
      Swal.fire( { text: 'Actualizacion exitosa', icon: 'success' } );
    }

  }

  CancelModal(){
    this.modalService.dismissAll();
    this.ListDocument = [];
  }

 async UpdateInfoGeneral(){
    let data = {
      "file_number":this.DataDetail.file_number,
      "counterpart_name":this.DataDetail.counterpart_name,
      "office":this.DataDetail.office,
      "jurisdiction":this.DataDetail.jurisdiction,
      "entity":this.DataDetail.entity,
      "current_phase":this.DataDetail.current_phase
    }
    // console.log(data);
    this.LawyerService.servicesdetailUpdate(this.DataDetail?.id,data).subscribe(
      Response =>{
        console.log(Response);
      },
      error =>{
        console.log(error);
      }
    );

  }


  async  LoadDocument(){

    let object = this.ListDocument;
    // console.log(object);
        for (const key in object) {
          if (Object.prototype.hasOwnProperty.call(object, key)) {
            const documents = object[key];
            let upload = object[key]['upload'];
            if(upload === 'yes'){
              for (const keyd in documents) {
                if (Object.prototype.hasOwnProperty.call(documents, keyd)) {
                  const value = documents[keyd];
                  // console.log(keyd,value);
                  if(keyd != 'date' && keyd != 'user'){
                    this.docsFormData.set(keyd, value);
                  }

                }
              }
              // console.log( this.docsFormData);
              this.DocumentServiceData();
            }

          }
        }

  }

  openModalDetailHistory(detailModal: any, historyData: any) {
    let data = []
    const keys = Object.keys(historyData.changes);
    keys.forEach((key, idx) => {
      data[idx] = {
        // key: key,
        ...historyData.changes[key]
      }
    });
    this.selectedHistoryChanges = data
    this.modal = this.modalService.open( detailModal, { backdrop: 'static', size: 'lg', centered: true } );
  }


  DocumentServiceData(){
    this.LawyerService.uploadodcument_serviceactive( this.docsFormData).subscribe(
      Response =>{
        console.log(Response);
      },
      error =>{
        console.log(error);
      }
    );
  }
  //
  ShowPdf(document,upload){
    if(upload == 'no'){
      window.open(document['file_url'], '_blank');
    }else{
      this.viewpdf(document['file_url'],document['name']);
    }
}

      viewpdf(downloadedFile,name){
            // create a download anchor tag
            var downloadLink      = document.createElement('a');
            downloadLink.target   = '_blank';
            downloadLink.download = name+'.pdf';

            // convert downloaded data to a Blob
            var blob = new Blob([downloadedFile], { type: 'application/pdf' });

            // create an object URL from the Blob
            var URL = window.URL ;
            var downloadUrl = URL.createObjectURL(blob);

            // set object URL as the anchor's href
            downloadLink.href = downloadUrl;

            // append the anchor to document body
            document.body.appendChild(downloadLink);

            // fire a click event on the anchor
            downloadLink.click();

            // cleanup: remove element and revoke object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(downloadUrl);
      }

      onKey(event: any) { // with type info
        if ( event.target.value.length === 0) {
          this.GetData(this.page);
        }
      }


      addComment(){

        this.LawyerService.addComment({
          order_id : this.order_id,
          comment : this.comment
        }).subscribe( response =>{
            let data = response.data
            this.comment = ""
            this.refreshComment()
            Swal.fire("Registro exitoso", "Se ha registrado un comentario" , 'success')
        })

      }

      deleteComment(id: number){

        this.LawyerService.deleteComment( id).subscribe( response =>{
            let data = response.data
            this.comment = ""
            this.refreshComment()
            Swal.fire("Comentario eliminado", "" , 'success')
        })

      }

      refreshComment(){

        this.loadDataComment()
      }


      loadDataComment(page = 1) {
        this.LawyerService.listComment(page, this.order_id).subscribe(
          response => {
            this.listComment = response.data;
            this.dataPaginationComment = response.meta.page;
        });
      }

      loadDataHistory(page = 1) {
        this.LawyerService.servicesdetailHistory(this.order_id, page).subscribe(
          reponse =>{
            console.log('debug-history', reponse)
            this.dataHistory = reponse?.data;
            this.dataHistoryPagination = reponse?.meta?.page ?? 1;
          }
        );
      }

      dateLastPayment(payments): string
      {
        let payment = payments[payments.length-1];        
        return payment.created_at;
      }

}
