import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class NotificationsService {

  constructor(
    private http: HttpService
  ) { }

 /**
   * @description Lista de Notificaciones
   * @param data cantidad de entradas a limpiar
   * @returns Listado
   */
  getNotifications(page = 1, params = '') : Observable<any> {
    return this.http.get(`notification?page=${page}${params}`);
  }
  
 /**
   * @description Lista de Notificaciones
   * @returns Listado
   */
  markAsRead(data: any): Observable<any>{
    return this.http.post('notification/mark-as-read', data);
  }
}

