import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { Province } from '../../../interfaces/province';
import { City } from '../../../interfaces/city';
import { Township } from '../../../interfaces/township';
import { CommonService } from '../../../services/common.service';
import {
  BranchesLaw,
  StudyLevel,
  Bank,
  Product,
} from '../../../interfaces/common';
import { MustMatch } from '../../../helpers/must-match.validator';
import { forkJoin } from 'rxjs';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import Swal from 'sweetalert2';
@Component( {
  selector: 'app-lawyer-form',
  templateUrl: './lawyer-form.component.html',
  styleUrls: [ './lawyer-form.component.css' ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
} )
export class LawyerFormComponent implements OnInit {
  submitted = false;
  isLinear = false;
  bankId = null;
  filename = '';
  pdfSource = '';
  lawyerProduct: any[] = [];
  buttonshowregister = false;
  cities: City[] = [];
  provinces: Province[] = [];
  townships: Township[] = [];
  products: any = [];

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  // Requeriedthird : boolean = false;
  formData: FormData;

  banks: Bank[] = [];
  branchLaw: BranchesLaw[] = [];
  studylevel: StudyLevel[] = [];

  typePass = 'password';
  iconPass = 'visibility_off';

  private _file: File;

  @Output() submitEvent = new EventEmitter<any>();
  @Input() avatar: string;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  constructor( private fb: FormBuilder, private common: CommonService ) {
    forkJoin( [
      this.common.brachLawyer(),
      this.common.studyLevel(),
      this.common.banks(),
      this.common.products(),
    ] ).subscribe( ( [ branchRes, levelRes, bankRes, productRes ] ) => {
      this.branchLaw = [ ...branchRes.data ];



      this.studylevel = [ ...levelRes.data ].filter( e => e.name != '' );



      this.banks = [ ...[ { id: null, name: 'Seleccione' } ], ...bankRes.data ];

      let p = [ ...productRes.data ];

      p.forEach((currentValue, index) => {
        this.products.push(Object.assign(currentValue, {collapse:false}));
      });

      this.configDropdownList();

    } );
    this.createForm();
    this.loadProvinces();


  }

  get f(): any {
    return this.firstFormGroup.controls;
  }
  get s(): any {
    return this.secondFormGroup.controls;
  }
  get t(): any {
    return this.thirdFormGroup.controls;
  }
  get fo(): any {
    return this.fourthFormGroup.controls;
  }
  get study_levels(): FormArray {
    return this.firstFormGroup.get( 'study_levels' ) as FormArray;
  }

  ngOnInit(): void {
    this.addGrades();
    this.formData = new FormData();
  }

  addGrades(): void {
    const studies = this.fb.group( {
      study_level_id: [ '0', [ Validators.required ] ],
      obtained_title: [ '', [ Validators.required ] ],
      college: [ '', [ Validators.required ] ],
    } );

    this.study_levels.push( studies );
  }

  deleteGrade(i) {
    this.study_levels.removeAt(i);
  }

  onChange( id: number, type: string ): void {
    if ( type === 'province' ) {
      this.loadCities( id );
    }
    if ( type === 'city' ) {
      this.loadTownships( id );
    }
    if ( type === 'township' ) {
      this.secondFormGroup.controls.township_id.patchValue( id );
    }
  }


  onSubmit(): void {
    this.submitted = true;
    const formValid =
      this.firstFormGroup.valid &&
      this.secondFormGroup.valid &&
      this.thirdFormGroup &&
      this.fourthFormGroup.valid;

    if ( formValid ) {
      const data: any = {
        ...this.firstFormGroup.value,
        ...this.secondFormGroup.value,
        ...this.thirdFormGroup.value,
        ...this.fourthFormGroup.value,
      };

      data.main_image = this.avatar;

      data.lawyer_products = this.products.map((item) => item.id);

      try {
        for(var i=0; i < this.selectedItems.length; i++){
          data.branch_laws[i] = this.selectedItems[i].id;
        }
      } catch (error) {
        this.selectedItems = [];
        Swal.fire( { text: 'Debe seleccionar una rama del derecho', icon: 'warning' } );
        return;
      }

      for ( const key in data ) {
        if ( Object.prototype.hasOwnProperty.call( data, key ) ) {
          const value: string = data[ key ];
          // console.log(key,value);
          if ( Array.isArray( value ) ) {
            this.formData.delete( `${key}[]` );
            value.forEach( ( element, index ) => {
              if ( key === 'study_levels' ) {
                Object.keys( element ).forEach( ( el ) => {
                  this.formData.append(
                    `${key}[${index}][${el}]`,
                    element[ el ]
                  );
                } );
              } else {
                this.formData.append( `${key}[]`, element );
              }
            } );
          } else {
            this.formData.set( key, value );
          }
        }
      }

      this.submitEvent.emit( this.formData );
    }
  }

  loadProvinces(): void {
    this.common.provinces( 171 ).subscribe( response => {
      this.provinces = [ ...response.data ];
      this.loadCities( this.provinces[ 0 ].id );
    } );
  }

  loadCities( id: number ): void {
    this.common.cities( id ).subscribe( ( response ) => {
      this.cities = [ ...response.data ];
      this.townships = [];
      this.loadTownships( this.cities[ 0 ].id );
      //this.secondFormGroup.controls.township_id.patchValue( 0 );
    } );
  }

  loadTownships( id: number ): void {
    this.common.townships( id ).subscribe( ( response ) => {
      this.townships = [ ...response.data ];
    } );
  }

  onFileSelect( e: any ): void {
    const reader = new FileReader();
    if ( e.target.files && e.target.files.length ) {
      const [ file ] = e.target.files;

      this._file = file;

      this.filename = file.name;

      reader.readAsDataURL( file );

      reader.onload = () => {
        this.pdfSource = reader.result as string;
      };
    }
  }

  // tslint:disable-next-line: typedef
  async onProductCheck( event: any ) {
    const val = event.target.value;
    const checked = event.target.checked;
    if ( checked ) {
      if ( val === '2' ) {
        const result = await this.OpenAlertProduct();
        //   ||
        if ( result === 'confirm' ) {
          this.lawyerProduct.push( val );

        } else {
          event.target.checked = !event.target.checked;
        }
      } else {
        this.lawyerProduct.push( val );
      }
    }

    if ( !checked ) {
      const index = this.lawyerProduct.findIndex( ( _val ) => _val === val );
      this.lawyerProduct.splice( index, 1 );
    }


    if ( this.lawyerProduct.length === 0 ) {
      this.thirdFormGroup.controls.lawyer_products.setValue( null );
    }

  }

  private createForm(): void {
    this.firstFormGroup = this.fb.group( {
      email: [ '', [ Validators.required, Validators.email , Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$") ] ],
      description: [ '', [ Validators.required ] ],
      name: [ '', [ Validators.required, Validators.minLength( 3 ) ] ],
      last_name: [ '', [ Validators.required, Validators.minLength( 3 ) ] ],
      phone: [ '', [ Validators.required, Validators.pattern('[0-9]{7,20}') ] ],
      dni: [ '', [ Validators.required ] ],
      suitability_number: [ '', [ Validators.required ] ],
      branch_laws: [ '', [ Validators.required ] ],
      study_levels: this.fb.array( [] ),
      main_image: [ '' ]
    } );

    this.secondFormGroup = this.fb.group( {
      township_id: [ '0' ],
      address: [ '', [ Validators.required,  Validators.minLength( 5 ) ] ],
      //bank_id: [ null, [ Validators.required ] ],
     // account_type: [ 'Corriente', [ Validators.required ] ],
     // account_number: [ '', [ Validators.required ] ],
     // owner_dni: [ '', [ Validators.required ] ],
     // owner_name: [ '', [ Validators.required, Validators.minLength( 3 ) ] ],
    } );

    this.thirdFormGroup = this.fb.group( {
      lawyer_products: [ '', [ Validators.required ] ],
    } );

    this.fourthFormGroup = this.fb.group(
      {
        password: [ '', [ Validators.required, Validators.minLength( 8 ) ] ],
        password_confirmation: [ '', [ Validators.required ] ],
      },
      {
        validator: MustMatch( 'password', 'password_confirmation' ),
      }
    );

  }

  // tslint:disable-next-line: typedef
  async OpenAlertProduct() {
    return Swal.fire( {
      text: 'El Despacho Virtual conlleva a pago por suscripción mensual.',
      showDenyButton: true,
      width: 350,
      showCancelButton: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      showClass: {
        popup: 'animate__animated animate__fadeInUp'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutDown'
      },
      customClass: {
        confirmButton: 'btn light btn-primary btn-sm',
        denyButton: 'btn light btn-dark btn-sm'
      },
    } ).then( ( result ) => {
      /* Read more about isConfirmed, isDenied below */
      if ( result.isConfirmed ) {
        return 'confirm';
      } else if ( result.isDenied ) {
        return 'cancel';
      }
    } );
  }

  // tslint:disable-next-line: typedef
  async ShowRegister( button: string ) {

    switch ( button ) {
      case 'back':
        this.buttonshowregister = !this.buttonshowregister;
        break;

      case 'next':
        this.buttonshowregister = !this.buttonshowregister;
        break;
    }
  }

  onChangePass(){
    if(this.typePass=='password') {
      this.typePass = 'text';
      this.iconPass = 'visibility';
    } else if(this.typePass=='text') {
      this.typePass = 'password';
      this.iconPass = 'visibility_off';
    }
  }

  nextStep(step){
    this.submitted = true;
    switch ( step ) {
      case 1:

        if ( this.firstFormGroup.valid ) {
          this.submitted = false;
        }
        break;

      case 2:
        if ( this.secondFormGroup.valid ) {
          this.submitted = false;
          this.ShowRegister('next');
        }
        break;

      case 3:
        if ( this.thirdFormGroup.valid ) {
          this.submitted = false;

          //this.ShowRegister('next');

        }
        break;

      //case 4:
      //  if ( this.fourthFormGroup.valid ) {
      //    this.submitted = false;
      //  }
      //  break;
    }

  }

  refreshAddress() {
    this.loadProvinces();
  }

  configDropdownList(){
    this.dropdownList = [];
    for(var i=0; i < this.branchLaw.length; i++){
      this.dropdownList.push({ id: this.branchLaw[i].id, text: this.branchLaw[i].name });
    }

    this.selectedItems = [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      itemsShowLimit: 5,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }
}
