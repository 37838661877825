<div class="authincation vh-100">
    <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-md-5">
                <div class="form-input-content text-center error-page">
                    <h1 class="error-text  font-weight-bold">403</h1>
                    <h4><i class="fa fa-times-circle text-danger"></i> Error prohibido!</h4>
                    <p>No tienes permiso para ver este recurso.</p>
                    <div>
                        <a class="btn btn-primary" [routerLink]="['/admin/index']">Volver al inicio</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>