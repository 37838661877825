import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-details',
  templateUrl: './file-details.component.html',
  styleUrls: ['./file-details.component.css']
})
export class FileDetailsComponent implements OnInit {

  type = {
    file: "A-223",
    lawyer: "Luisa Jimenez"
  }

  constructor() { }

  ngOnInit(): void {
  }

}
