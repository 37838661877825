import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { DataResponse } from '../interfaces/response';
//import { Task } from '../interfaces/common';
import { Observable } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class TimeTaskService {

  constructor(
    private http: HttpService
  ) { }

  /**
   * @description listado de tareas
   * @returns Listado 
   */
  listTimeTask(): Observable<DataResponse<any[]>> {
    return this.http.get( `case-task?&includes[]=cases&includes[]=taskExecutionTimes` );
  }

  /**
   * @description listado de tareas filtradas
   * @returns Listado 
   */

  listTimeTaskSearch( search: string): Observable<DataResponse<any[]>> {
    return this.http.get( `case-task?&includes[]=cases&includes[]=taskExecutionTimes${search}` );
  }
  
  /**
   * @description Descarga de pdf de tareas
   * @returns pdf
   */

   downloadTimeTask(search: string): Observable<Blob> {
    return this.http.get( `case-task/execution-times-activities-pdf?includes[]=cases&includes[]=taskExecutionTimes${search}` , { responseType: 'blob' });
  }

  /* control de ejecucion */

  /**
   * @description listado de casos
   * @returns Listado 
   */

   listCasesExecutionTime(page = 1): Observable<DataResponse<any[]>> {
    return this.http.get( `cases?includes[]=lawyer_client&includes[]=case_statuses&includes[]=case_documents&includes[]=case_tasks&page=${page}` );
  }

  /**
   * @description listado de actividades por caso
   * @returns Listado 
   */

   listExercisesExecutionTime(id: number ): Observable<any> {
    return this.http.get( `cases/execution/${id}?includes[]=case_tasks&includes[]=lawyer_client` );
  }

  /**
   * @description Descarga de pdf de actividades en control de ejecucion
   * @returns pdf
   */

   downloadExecutionTime(id: number ): Observable<Blob> {
    return this.http.get( `cases/control-execution-pdf/${id}?includes[]=case_tasks&includes[]=lawyer_client`, { responseType: 'blob' } );
  }
  


 /**
   * @description listado de actividades filtradas
   * @returns Listado 
   */

  listExecutionTimeSearch( search: string): Observable<DataResponse<any[]>> {
    return this.http.get( `case-task/indexActivity${search}` );
  }

 
 
}
