import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { DataResponse } from '../interfaces/response';
import { Entity, Lawyer, LawyerClient } from '../interfaces/user';
import { ServiceLawyer } from '../interfaces/common';
import { HttpParams } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LawyerService {
  constructor(private http: HttpService) {}

  removeNullProperties(obj) {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === 'all') {
        delete obj[key];
      }
    }

    return obj;
  }

  list(): Observable<DataResponse<Lawyer[]>> {
    return this.http.get('lawyers');
  }

  /**
   * @description Aprobación de abogados
   * @param id Id del abodado a aprobar
   * @returns Respuesta
   */
  toPass(data: any, id: number): Observable<any> {
    return this.http.put(`lawyers/${id}`, data);
  }

  /**
   * @description Listado de abogados para aprobar
   * @returns Respuesta
   */
  toBeApproved(page: number): Observable<any> {
    return this.http.get(
      `lawyers?includes[]=user&get_lawyers_for_approve=true&includes[]=branchLaws&includes[]=studyLevels&includes[]=township&includes[]=bank.bankData&page=${page}`
    );
  }

  entitites(page = 1): Observable<DataResponse<Entity[]>> {
    return this.http.get(`lawyer-entities?includes[]=lawyer&page=${page}`);
  }

  lawyerClients(): Observable<DataResponse<Entity[]>> {
    return this.http.get(
      `lawyer-clients?iincludes[]=lawyer&includes[]=township`
    );
  }

  lawyergetCases(page: number | null = null, filters = {}): Observable<DataResponse<Entity[]>> {
    filters = this.removeNullProperties(filters);
    const httpParams = new HttpParams({ fromObject: filters });
    let url = `cases?includes[]=lawyer_client&includes[]=case_statuses&includes[]=case_documents&includes[]=case_tasks`;
    if (page) url += `&page=${page}`;

    return this.http.get(url,
      { params: httpParams }
    );
  }

  lawyerCasesSimple(includes: string[] = []): Observable<DataResponse<Entity[]>> {
    let params = '';
    if (includes.length > 0) {
      includes.forEach(include => {
        params += `&includes[]=${include}`;
      });
    }
    return this.http.get(`cases?p=1${params}`);
  }
  lawyergetCasesSearch(id: number): Observable<DataResponse<Entity[]>> {
    return this.http.get(
      `cases?includes[]=lawyer_client&includes[]=case_statuses&includes[]=case_documents&includes[]=case_tasks&id=${id}`
    );
  }

  getTaskCases(
    case_id: number,
    page: number
  ): Observable<DataResponse<Entity[]>> {
    return this.http.get(`case-task?case_id=${case_id}&page=${page}`);
  }

  getTaskCasesByLawyer(
    lawyer_id: number,
    page: number
  ): Observable<DataResponse<Entity[]>> {
    return this.http.get(`case-task?lawyer_id=${lawyer_id}&page=${page}&includes[]=cases`);
  }

  caseTaskShedule(params: any, id_task: number): Observable<any> {
    return this.http.post(`case-task-schedule/end/${id_task}`, params);
  }

  regCases(params: any): Observable<any> {
    return this.http.post('cases', params);
  }

  deleteCases(id: number): Observable<any> {
    return this.http.delete(`cases/${id}`);
  }

  updateCases(params: any, id: number): Observable<any> {
    return this.http.post(`cases/${id}?_method=PUT`, params);
  }

  showCases(id: number): Observable<DataResponse<Entity[]>> {
    return this.http.get(
      `cases/${id}?includes[]=lawyer&includes[]=case_statuses&includes[]=case_documents&includes[]=lawyer_client&includes[]=lawyer_entity`
    );
  }

  /**
   * @description Creación de una nueva entidad
   * @param data
   * @returns Respuesta
   */
  addEntities(data: any): Observable<DataResponse<Entity>> {
    return this.http.post(`lawyer-entities`, data);
  }

  /**
   *
   * @param id Id de la entidad a mostrar
   * @returns Entidad
   */
  showEntities(id: number): Observable<any> {
    return this.http.get(`lawyer-entities/${id}?includes[]=lawyer`);
  }

  /**
   * @description Actualización de entidad
   * @param data Data a actualizar
   * @param id Id de la entidad
   * @returns Entidad actualizada
   */
  editEntity(data, id): Observable<DataResponse<Entity>> {
    return this.http.put(`lawyer-entities/${id}`, data);
  }

  /**
   *
   * @param id Id de la entidad a eliminar
   * @returns Respuesta
   */
  deleteEntities(id: number): Observable<any> {
    return this.http.delete(`lawyer-entities/${id}`);
  }
  getLawyer(id: number): Observable<DataResponse<Lawyer>> {
    return this.http.get(`lawyer-services/${id}`);
  }
  updateLawyer(lawyer: Lawyer): Observable<DataResponse<Lawyer>> {
    return this.http.put(`lawyer-services/${lawyer.id}`, lawyer);
  }

  /**
   * @description Lista los servicios del abogado
   * @returns Listado
   */
  services(page: number, search: string): Observable<DataResponse<ServiceLawyer[]>> {
    return this.http.get(
      `lawyer-services?includes[]=lawyer&includes[]=service&page=${page}&${search}`
    );
  }

  /**
   * @description Agrega un nuevo servicio de abogado
   * @returns Respuesta
   */
  addService(data: LawyerService): Observable<DataResponse<ServiceLawyer>> {
    return this.http.post(`lawyer-services`, data);
  }

  /**
   * @description Actualiza los servicio del abogado
   * @param data Data actualizada
   * @param id Id del servicio
   * @returns Respuesta
   */
  updateService(
    data: any,
    id: number
  ): Observable<DataResponse<ServiceLawyer>> {
    return this.http.put(`lawyer-services/${id}`, data);
  }

  /**
   * @description Elimina un servicio
   * @param id Id del servicio a eliminar
   * @returns Respuesta
   */
  deleteService(id: number): Observable<DataResponse<ServiceLawyer>> {
    return this.http.delete(`lawyer-services/${id}`);
  }

  /**
   * @description Lista los Client del abogado
   * @returns Listado
   */
  clients(): Observable<DataResponse<LawyerClient[]>> {
    return this.http.get(
      `lawyer-clients?includes[]=lawyer&includes[]=township`
    );
  }

  clientsSimple(): Observable<DataResponse<LawyerClient[]>> {
    return this.http.get(
      `lawyer-clients`
    );
  }

  /**
   * @description Agrega un nuevo Client de abogado
   * @returns Respuesta
   */
  addClient(data: LawyerService): Observable<DataResponse<LawyerClient>> {
    return this.http.post(`lawyer-clients`, data);
  }

  /**
   * @description Actualiza los Client del abogado
   * @param data Data actualizada
   * @param id Id del Client
   * @returns Respuesta
   */
  updateClient(data: any, id: number): Observable<DataResponse<LawyerClient>> {
    return this.http.put(`lawyer-clients/${id}`, data);
  }

  /**
   * @description Elimina un Client
   * @param id Id del Client a eliminar
   * @returns Respuesta
   */
  deleteClient(id: number): Observable<DataResponse<LawyerClient>> {
    return this.http.delete(`lawyer-clients/${id}`);
  }

  /**
   * @description Lista los servicios activo del abogado
   * @returns Listado
   */
  servicesactive(
    filter = '',
    search: string,
    page: number
  ): Observable<DataResponse<[]>> {
    return this.http.get(
      `monitoring-service?includes[]=service&includes[]=userApplicant&page=${page}&search=${search}&order_status=Activo&` +
        filter
    );
  }

  /**
   * @description Detalle del servicio activo del abogado
   * @returns Listado
   */
  servicesdetailHistory(id_order: string, page: number = 1): Observable<DataResponse<[]>> {
    return this.http.get(
      `order-history/${id_order}?includes[]=user&page=${page}`
    );
  }

  /**
   * @description Detalle del servicio activo del abogado
   * @returns Listado
   */
  caseDetailHistory(id_case: number, page: number = 1): Observable<DataResponse<[]>> {
    return this.http.get(
      `case-history/${id_case}?includes[]=user&page=${page}`
    );
  }

  /**
   * @description Detalle del servicio activo del abogado
   * @returns Listado
   */
  servicesdetail(id_order: string): Observable<DataResponse<[]>> {
    return this.http.get(
      `orders/${id_order}?includes[]=lawyerService&includes[]=userApplicant&includes[]=orderDocuments.user&includes[]=orderPayments&includes[]=service.branchLaw`
    );
  }

  /**
   * @description Edicion Detalle del servicio activo del abogado
   * @returns Listado
   */
  servicesdetailUpdate(
    id_order: string,
    data: any
  ): Observable<DataResponse<[]>> {
    return this.http.put(`orders/${id_order}`, data);
  }

  /**
   * @description Actualizacion de los documentos en el servicio activo
   * @returns Listado
   */
  uploadodcument_serviceactive(documents: any): Observable<any> {
    // console.log(documents);
    return this.http.post(`order-documents/lawyer-ser`, documents);
  }

  /**
   * @description Lista los servicios activo del abogado
   * @returns Listado
   */
  servicescompleted(
    filter = '',
    search: string,
    page: number
  ): Observable<DataResponse<[]>> {
    return this.http.get(
      `monitoring-service?includes[]=service&includes[]=userApplicant&page=${page}&search=${search}&order_status=Culminado&` +
        filter
    );
  }
  /**listado de tramites para abogado en sesion */
  getProcedurefoLawyer(): Observable<any> {
    return this.http.get(`cases?includes[]=case_tasks&page=1`);
  }

  /**
   * @description Lista los Clientes del abogado externo
   * @returns Listado
   */
  clientsLawyer(page: number): Observable<any> {
    return this.http.get(`lawyer-clients`);
  }

  /**
   * @description Informacion de un cliente con sus casos y documentos
   * @param id Id del Cliente
   * @returns Cliente
   */
  clientCasesLawyer(id: number): Observable<any> {
    return this.http.get(
      `lawyer-clients/${id}?includes[]=lawyerClientDocuments&includes[]=township&includes[]=cases`
    );
  }

  /**
   * @description Lista los Clientes del abogado externo filtrados
   * @returns Listado
   */
  clientsLawyerFilter(search: string): Observable<any> {
    return this.http.get(`lawyer-clients?${search}`);
  }

  upload_document_cases(documents: any) {
    return this.http.post(`case-document`, documents);
  }

  remove_document_cases(cases: any) {
    return this.http.delete(`case-document/` + cases);
  }

  get_document_cases(param: string) {
    return this.http.get(`case-document?` + param);
  }

  getCalificationLawyer(lawyerId: Number): Observable<any> {
    return this.http.get(`comment-lawyer/calification/${lawyerId}`);
  }

  getClientsComments(lawyerId: Number): Observable<any> {
    return this.http.get(
      `comment-lawyer?includes[]=user&lawyer_id=${lawyerId}`
    );
  }

  getAccountStatementMarketplace(
    page = 1,
    params = '',
    pdf = false
  ): Observable<any> {
    if (pdf == true) {
      return this.http.get(
        `order-payments/statement-account-by-lawyer?includes[]=order&is_pdf_report=true${params}`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(
        `order-payments/statement-account-by-lawyer?includes[]=order&page=${page}${params}`
      );
    }
  }

  getStatement(page = 1,params = '',pdf = false): Observable<any> {
    if (pdf == true) {
      return this.http.get(`income/statement?is_pdf=true`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(`income/statement?page=${page}${params}`);
    }
  }

  getBills(page = 1, params = '', pdf = false): Observable<any> {
    if (pdf == true) {
      return this.http.get(`income/bills?is_pdf=true`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(`income/bills?page=${page}${params}`);
    }
  }

  print(id: number): Observable<any> {
    return this.http.get(`income/print-bill/${id}`,
      { responseType: 'blob' as 'json' }
    );
  }

  getInvoicesMarketplace(page = 1, params = '', pdf = false): Observable<any> {
    if (pdf == true) {
      return this.http.get(
        `lawyer-payments?&includes[]=lawyer&includes[]=bankData&includes[]=order&is_pdf_report=true${params}`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(
        `lawyer-payments?&includes[]=lawyer&includes[]=bankData&includes[]=order&page=${page}${params}`
      );
    }
  }

  getAccountStatement(page = 1, params = ''): Observable<any> {
    return this.http.get(
      `cases/statement-acount?includes[]=case_payments&includes[]=lawyer_client&page=${page}${params}`
    );
  }

  getAccountStatementPdf(params = '') {
    return this.http.get(`cases/statement-acount-pdf?${params}`, {
      responseType: 'blob' as 'json',
    });
  }

  getIssuedInvoices(page = 1, params = ''): Observable<any> {
    return this.http.get(
      `case-payment?issue_invoice=true&includes[]=case.lawyer_client&info=1&page=${page}${params}`
    );
  }

  getIssuedInvoicesPdf(params = ''): Observable<any> {
    return this.http.get(
      `case-payment?issue_invoice=true&is_pdf_report=true&includes[]=case.lawyer_client${params}`,
      { responseType: 'blob' as 'json' }
    );
  }

  getIssuedInvoicesCasePdf(id: string): Observable<any> {
    return this.http.get(`case-payment/generate-case-invoice-pdf/${id}`, {
      responseType: 'blob' as 'json',
    });
  }

  getOperationalCases(page = 1, params = '', pdf = false): Observable<any> {
    if (pdf == true) {
      return this.http.get(
        `cases?includes[]=lawyer_client&is_pdf_report=true${params}`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(
        `cases?includes[]=lawyer_client&page=${page}${params}`
      );
    }
  }

  getIncomes(type, pdf = false) {
    if (pdf == true) {
      return this.http.get(
        `cases/receivables?includes[]=lawyer_client&includes[]=lawyer&is_pdf_report=true&type_tramite=${type}`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(
        `cases/receivables?includes[]=lawyer_client&includes[]=lawyer&type_tramite=${type}`
      );
    }
  }

  getExpenses(type, page = 1, params = '', pdf = false) {
    if (pdf == true) {
      return this.http.get(
        `cases/income-expenses-report?is_pdf_report=true&type=${type}${params}`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(
        `cases/income-expenses-report?type=${type}&page=${page}${params}`
      );
    }
  }

  getExpense(id, pdf = false) {
    if (pdf == true) {
      return this.http.get(
        `cases/${id}?is_pdf_report=true&includes[]=lawyer_client&includes[]=case_payments&includes[]=case_expenses`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(
        `cases/${id}?includes[]=lawyer_client&includes[]=case_payments&includes[]=case_expenses`
      );
    }
  }

  listCaseStatuses(): Observable<any> {
    return this.http.get(`case-statuses`);
  }

  listService(): Observable<any> {
    console.log('esecutando desde servicio');
    return this.http.get(`services`);
  }

  listBranch(): Observable<any> {
    return this.http.get(`branch-laws`);
  }

  getCase(id) {
    return this.http.get(
      `cases/${id}?includes[]=lawyer&includes[]=case_statuses&includes[]=case_documents&includes[]=lawyer_client&includes[]=lawyer_entity&includes[]=case_payments`
    );
  }

  getApprovedQuotes(page = 1, params = '', pdf = false) {
    if (pdf == true) {
      return this.http.get(
        `orders/approved_quotes?is_pdf_report=true&includes[]=orderPayments&includes[]=userApplicant&includes[]=service.typeService${params}`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(
        `orders/approved_quotes?&includes[]=orderPayments&includes[]=userApplicant&includes[]=service.typeService&page=${page}${params}`
      );
    }
  }

  getApprovedQuote(id, pdf = false) {
    if (pdf == true) {
      return this.http.get(
        `orders/approved-quotations-report/${id}?is_report_pdf=true&includes[]=orderPayments&includes[]=userApplicant&includes[]=service&includes[]=lawyerService&includes[]=lawyer.user`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      return this.http.get(
        `orders/approved-quotations-report/${id}?includes[]=userApplicant&includes[]=orderPayments&includes[]=service&includes[]=lawyerService&includes[]=lawyer.user`
      );
    }
  }

  regCaseQuote(params: any, id_case: number): Observable<any> {
    return this.http.put(`cases/${id_case}`, params);
  }

  getQuotePdf(quote_id: number): Observable<Blob> {
    return this.http.get(
      `cases/my-case-quote-pdf/${quote_id}?includes[]=case_tasks&includes[]=lawyer_client`,
      { responseType: 'blob' }
    );
  }

  sendQuoteEmail(quote_id: number): Observable<DataResponse<Entity[]>> {
    return this.http.get(
      `cases/send-case-quote-pdf/${quote_id}?includes[]=case_tasks&includes[]=lawyer_client`
    );
  }

  getQuotes(page: number): Observable<DataResponse<Entity[]>> {
    return this.http.get(`cases?includes[]=case_tasks&page=${page}`);
  }

  getListCasesGantt(id_case: number): Observable<DataResponse<Entity[]>> {
    return this.http.get(
      `cases/${id_case}?is_gantt_view=true&includes[]=case_tasks&includes[]=lawyer_client`
    );
  }

  getMyquoteForLawyer(
    lawyer_id: number,
    page: number = null,
    pdf = false,
    excel = false
  ): Observable<DataResponse<Entity[]> | Blob | MediaSource> {
    let params = '';
    if (pdf == true) {
      return this.http.get(`myquote-lawyer?lawyer_id=${lawyer_id}&is_pdf=true`,
        { responseType: 'blob' as 'json' }
      );
    } else if (excel == true) {
      return this.http.get(`myquote-lawyer?lawyer_id=${lawyer_id}&is_excel=true`,
        { responseType: 'blob' as 'json' }
      );
    } else {
      if (page) {
        params = `&page=${page}`;
      }
      return this.http.get(`myquote-lawyer?lawyer_id=${lawyer_id}${params}`);
    }
  }

  getMyquoteDetail(
    lawyer_id: number,
    quote_id: number
  ): Observable<DataResponse<Entity[]>> {
    return this.http.get(
      `myquote-lawyer?lawyer_id=${lawyer_id}&quote_id=${quote_id}&detail=1&includes[]=case_tasks`
    );
  }

  myQuotesLawyerdestroy(id: number): Observable<DataResponse<Entity[]>> {
    return this.http.delete(`myquote-lawyer/${id}`);
  }

  verifPayLawyer(lawyer_id) {
    return this.http.get(`lawyer-product?lawyer_id=${lawyer_id}`);
  }

  getSubscriptionPlans() {
    return this.http.get(`promotion`);
  }

  /**
   * @description Lista gastos por caso
   * @returns Listado
   */
  indexCaseExpenses(id: string, page: number): Observable<any> {
    return this.http.get(`case-expenses?case_id=${id}&page=${page}`);
  }

  /**
   * @description Gasto
   */
  showCaseExpenses(id: string): Observable<any> {
    return this.http.get(`case-expenses/${id}`);
  }

  /**
   * @description Anade un gasto a un caso
   */
  addCaseExpenses(expenses: any): Observable<any> {
    return this.http.post(`case-expenses`, expenses);
  }
  /**
   * @description edita un gasto de un caso
   */
  updateCaseExpenses(id: number, expenses: any): Observable<any> {
    return this.http.post(`case-expenses/${id}?_method=PUT`, expenses);
  }
  /**
   * @description elimina un gasto de un caso
   * @returns gasto eliminado
   */
  deleteCaseExpenses(id: string): Observable<any> {
    return this.http.delete(`case-expenses/${id}`);
  }

  addComment(data: any): Observable<DataResponse<ServiceLawyer>> {
    return this.http.post(`order-comments`, data);
  }

  updateComment(data: any, id: number): Observable<DataResponse<Comment>> {
    return this.http.put(`order-comments/${id}`, data);
  }

  deleteComment(id: number): Observable<DataResponse<Comment>> {
    return this.http.delete(`order-comments/${id}`);
  }
  listComment(page, order_id): Observable<DataResponse<Comment[]>> {
    return this.http.get(
      `order-comments?order_id=${order_id}&page=${page}&take=5`
    );
  }

  addCommentCase(data: any): Observable<DataResponse<ServiceLawyer>> {
    return this.http.post(`case-comments`, data);
  }

  updateCommentCase(data: any, id: number): Observable<DataResponse<Comment>> {
    return this.http.put(`case-comments/${id}`, data);
  }

  deleteCommentCase(id: number): Observable<DataResponse<Comment>> {
    return this.http.delete(`case-comments/${id}`);
  }
  listCommentCase(page, case_id): Observable<DataResponse<Comment[]>> {
    return this.http.get(
      `case-comments?case_id=${case_id}&page=${page}&take=5`
    );
  }

  /*--- nuevo método ---*/

  getLawyersForDirectory(page = 1, params = ''): Observable<any> {
    return this.http.get(
      `lawyers?includes[]=user&includes[]=branchLaws&includes[]=studyLevels&take=9&page=${page}${params}`
    );
  }

  getLawForBranch(
    branch_law_id: number,
    type_service_id: number,
    page: number,
    params: string
  ): Observable<any> {
    return this.http.get(
      `lawyers?includes[]=user&includes[]=branchLaws&branch_law_id=${branch_law_id}&type_service_id=${type_service_id}&page=${page}${params}`
    );
  }

  async loadStatusesCases(): Promise<Observable<any[]>> {
    return await lastValueFrom(
      this.http.get(`case-statuses`).pipe(
        map((response: any) => response.data) // Ajusta esto según la estructura de tu respuesta
      )
    );
  }
}
