import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../shared/services/storage.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LawyerService } from '../../shared/services/lawyer.service';
import { USER, AVATAR } from 'src/app/shared/data/constants';

@Component({
  selector: 'app-lawyer-payment',
  templateUrl: './lawyer-payment.component.html',
  styleUrls: ['./lawyer-payment.component.css']
})
export class LawyerPaymentComponent implements OnInit {
  @ViewChild("payM", { static: true }) payM: ElementRef;
  debt: boolean = false;
  pagesView = 1;
  info: any = [];
  lisTypeServ: any = [];
  modalPay: any;
  idOderQuote: number = 0;
  user;
  descripQuote: string = '';
  detailQuoteModal: any;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private LawyerService: LawyerService,
    private storage: StorageService) {

    // aqui consumo el endpoint si no debe inicio normal , pero si debe se queda aqui para que page



    this.lisTypeServ = [
      {
        id: 1,
        name: 'Plan Anual',
        descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ',
        price: 50,
        imagen: 'https://cdn-icons-png.flaticon.com/512/5571/5571536.png'
      },
      {
        id: 2,
        name: 'Plan Semestral',
        descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ',
        price: 100,
        imagen: 'https://cdn-icons-png.flaticon.com/512/5571/5571536.png'
      }
    ];

  }

  ngOnInit(): void {
    this.user = this.storage.getItem( USER );
    this.getSubscriptionPlans();
    this.verifPayLawyer();



  }

  verifPayLawyer() {
    this.LawyerService.verifPayLawyer(this.user.lawyer.id).subscribe( (response) => {

      //response[0].enabled = 0;

      this.debt = (response[0].enabled === 1) ? false : true;

      if (!this.debt) {
        this.router.navigateByUrl('lawyer/index');
      } else {
        this.alertPayment();
      }
    } );
  }

  getSubscriptionPlans() {
    this.LawyerService.getSubscriptionPlans().subscribe( (response) => {
      this.lisTypeServ = [...response.data];


        for (let index = 0; index < this.lisTypeServ .length; index++) {
          const element = this.lisTypeServ[index]
          this.lisTypeServ[index]['total_service'] =  parseFloat( element.amount ) + ( parseFloat(element.amount ) * parseFloat( element.ITBMS ) / 100);

        }

        this.lisTypeServ.sort(this.compare_type_serv);
    } );
  }

  compare_type_serv( a, b )
  {
  if ( parseFloat(a.total_service) < parseFloat( b.total_service ) ){
    return -1;
  }
  if ( parseFloat(a.total_service) > parseFloat( b.total_service )  ){
    return 1;
  }
  return 0;
}


  logout(): void {
    this.storage.clearAll();
    this.router.navigateByUrl('login');
  }

  alertPayment() {
    Swal.fire({
      /*  icon: 'warning',*/
      title: 'Estimado usuario',
      text: '',
      html:
        'Para empezar a disfrutar de las  funcionalidades que ofrece:</b>, ' +
        'Despacho virtual  es necesario que se realice el pago concerniente a ' +
        'este servicio.',
    });
  }


  openPay(modal, data) {

    this.idOderQuote = 200;
    this.descripQuote ='Descripcion';
    this.info = {
      amount:  parseFloat( data.amount ) + ( parseFloat(data.amount ) * parseFloat( data.ITBMS ) / 100),
      order: data.id,
      description: data.name,
      type: 'Subscription'
    }

    this.modalPay = this.modalService.open(modal);

  }

  closerModalPay() {
    this.modalPay.dismiss();
  }

}
