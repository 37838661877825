<div class="authincation vh-100">
    <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-md-5">
                <div class="form-input-content text-center error-page">
                    <h1 class="error-text font-weight-bold">503</h1>
                    <h4><i class="fa fa-times-circle text-danger"></i> Servicio no disponible</h4>
                    <p>¡Lo sentimos, estamos en mantenimiento.!</p>
                    <div>
                        <a class="btn btn-primary" [routerLink]="['/admin/index']">Volver al inicio</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>