<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center">
        <h3>Facturas</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-header d-block pb-0">
            <form class="">
              <div class="form-row col-12">
                <div class="form-group hidden">
                  <div class="input-group">
                  </div>
                </div>
              </div>
              <div class="row justify-content-end align-items-end">
                <div class="col-6 col-md-5">
                  <div class="form-group">
                    <label class="mb-1"><strong>Desde</strong></label>
                    <div class="input-group dp-group">
                      <input class="form-control form-filter-calendar" placeholder="yyyy-mm-dd" name="dpFromDate" [(ngModel)]="fromDate" ngbDatepicker #dfrom="ngbDatepicker">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100 dp-btn" (click)="dfrom.toggle()" type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-5">
                  <div class="form-group">
                    <label class="mb-1"><strong>Hasta</strong></label>
                    <div class="input-group dp-group">
                      <input class="form-control form-filter-calendar" placeholder="yyyy-mm-dd" name="dpFromDate" [(ngModel)]="toDate" ngbDatepicker #dto="ngbDatepicker">
                      <div class="input-group-append">
                        <button class="btn form-filter calendar h-100 dp-btn" (click)="dto.toggle()" type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <div class="input-group">
                      <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                        <i class="fa fa-search"></i>
                      </button>
                      <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <ng-container *ngIf="list.length; else nodata">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Numero de Factura</th>
                      <th scope="col">Tramite Asociado</th>
                      <th scope="col">Fecha de Emision de Factura</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of list">
                      <td>{{item.reference_number}}</td>
                      <td>{{item.service.name}}</td>
                      <td>{{item.creation_date}}</td>
                      <td>
                        <div>
                          <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                            (click)="openModal(detailModal, item)" placement="top" ngbTooltip="Ver">
                            <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                              visibility
                            </mat-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end mt-3">
                <div class="col-auto custom-div-pagination">
                  <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                    [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="dataPagination.currentPage" aria-label="Paginación">
                  </ngb-pagination>
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
              </div>
            </ng-container>
            <ng-template #nodata>
              <div class="alert" role="alert">
                <strong>No hay data disponible</strong>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #detailModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h4>{{currentItem.lawyer.name}}</h4>
      <p>{{currentItem.lawyer.suitability_number}}</p>
      <p>{{currentItem.lawyer.address}}</p>
    </div>
    <fieldset>
      <p class="ml-3 mb-2"><strong>Nombre Cliente: </strong>{{currentItem.client?.name}}</p>
      <p class="ml-3 mb-2"><strong>Doc. Identidad: </strong>{{currentItem.client?.dni}}</p>
      <p class="ml-3 mb-2"><strong>Servicio: </strong>{{currentItem.service.name}}</p>
    </fieldset>
    <div class="text-center">
      <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadItemPDF(currentItem.id)">Descargar</button>
    </div>
  </div>
</ng-template>
