import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdTimerComponent, TimeInterface } from 'angular-cd-timer';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-cronometer',
	templateUrl: './cronometer.component.html',
	styleUrls: ['./cronometer.component.css']
})
export class CronometerComponent implements OnInit {
	@Input() showMode: boolean = false;
	@ViewChild('basicTimer', { static: true }) basicTimer: CdTimerComponent;

	condTimer: boolean = false;
	condStart: boolean = false;
	timerInfo = '';
	sendTimeTask: any;
	actualTimerSeconds: number | undefined = undefined;
	idTaskSelect: any;
	taskSelect: any;
	taskSelectDataUser: any;
	condStop: boolean = false;
	modal: any;
	lisTask: any;
	condSelectLaw: boolean;
	LawSelect: any[];
	arrayCase: any[];
	condSelecTask: boolean;
	condListTask: boolean;
	arrayTask: any[];
	LawSelectDataUser: any;
	responseTask: any;
	noTask: boolean;
	dataPaginationTask: any;
	pageTask: number = 1;
	idCaseSelect: any;
	responseCase: any;
	listCases: any[];
	dataPagination: { total: number; lastPage: number; perPage: number; currentPage: number; };
	page: any;

	constructor(
		private LawyerService: LawyerService,
		private modalService: NgbModal,
		private storage: StorageService,
	) {
		this.page = 1;
		this.pageTask = 1;
		this.timerInfo = '';
	}

	ngOnInit(): void {
		this.condTimer = false;
		this.lawyergetCases(this.page);
		this.setTimerStorage();
	}

	goTimer() {
		this.condTimer = true;
		this.modalService.dismissAll();
		this.condStart = true;
	}

	onTick(data: TimeInterface) {
		const timerData = this.storage.getItem('lg_counter_timer_data') ?? null;
		this.timerInfo = 'In Progress [' + data.tick_count.toString().padStart(4, '0') + ']';
		console.log('timer', data)
		console.log('timer-data-storage', timerData)
		this.sendTimeTask = data.hours + ':' + data.minutes + ':' + data.seconds;
		if (timerData?.active && timerData?.taskId) {
			timerData.actualTimerSeconds = data.tick_count
			this.storage.setItem('lg_counter_timer_data', timerData)
		}
	}

	openModalSelect(payModal) {
		this.modal = this.modalService.open(payModal);
	}

	setTimerStorage() {
		const timerData = this.storage.getItem('lg_counter_timer_data') ?? null;
		if (timerData?.active && timerData?.taskId) {
			this.actualTimerSeconds = timerData?.actualTimerSeconds ?? 0;
			this.idTaskSelect = timerData.taskId;
			this.taskSelect = timerData?.taskName ?? '';
			this.taskSelectDataUser = timerData?.taskDataUser ?? '';
			this.goTimer();
			setTimeout(() => {
				this.basicTimer.start();
			}, 2000);
			this.condStop = true;
			this.condStart = false;
		}
	}

	chageTask() {
		this.condSelecTask = false;
		this.taskSelect = [];
		this.arrayTask = [];
	}

	cancel() {
		this.modalService.dismissAll();
		this.condSelectLaw = false;
		this.LawSelect = [];
		this.arrayCase = [];
		this.condSelecTask = false;
		this.taskSelect = [];
		this.arrayTask = [];
		this.condListTask = false;
		this.chageCase();
	}

	doActionBasicTimer(action: String) {
		switch (action) {
			case 'start':
				this.goTimer();
				this.basicTimer.start();
				this.condStop = true;
				this.condStart = false;
				this.storage.setItem('lg_counter_timer_data', { active: true, taskId: this.idTaskSelect, actualTimerSeconds: 0, taskName: this.taskSelect, taskDataUser: this.taskSelectDataUser })
				break;
			case 'resume':
				this.basicTimer.resume();
				break;
			case 'reset':
				this.basicTimer.reset();
				this.condStop = false;
				break;
			default:
				this.basicTimer.stop();
				this.condStart = true;
				this.condStop = false;
				this.condTimer = false;
				this.chageCase();
				this.sendTime();
				break;
		}
	}

	sendTime() {
		var jsonData = {
			"hours": this.sendTimeTask
		}
		this.LawyerService.caseTaskShedule(jsonData, this.idTaskSelect).subscribe(
			async (response) => {
				this.alertRegTime(this.taskSelect);
				this.actualTimerSeconds = 0;
				this.storage.removeItem('lg_counter_timer_data')
			},
			(error) => {

			}
		);
		this.basicTimer.reset();

	}

	selectTask(item) {
		this.idTaskSelect = item.id;
		this.condSelecTask = true;
		this.taskSelect = item.name;
		this.taskSelectDataUser = item.name;
	}


	chageCase() {
		this.condSelectLaw = false;
		this.LawSelect = [];
		this.arrayCase = [];
		this.condSelecTask = false;
		this.condListTask = false;
		this.arrayTask = [];
	}

	alertRegTime(task) {
		Swal.fire({
			position: 'center',
			title: 'Registrando tiempo a la actividad ' + task,
			allowEscapeKey: false,
			allowOutsideClick: false,
			timer: 3000,
			didOpen: () => {
				Swal.showLoading();
			}
		}).then((result) => {
			this.alertConfirmTime(task);
		})
	}

	alertConfirmTime(task) {
		Swal.fire({
			position: 'center',
			icon: 'success',
			text: 'Tiempo registrado a la actividad ' + task,
			showConfirmButton: true,
		}).then((result) => {
			if (result.isConfirmed) {

			}
		});
	}

	selectCase(item) {
		this.idCaseSelect = item.id;
		this.getTaskCase(item.id, this.pageTask);
		this.condSelectLaw = true;
		this.LawSelect = item.name;
		this.LawSelectDataUser = item.case_name;
	}

	getTaskCase(case_id, page) {
		this.LawyerService.getTaskCases(case_id, page).subscribe(
			async (response) => {
				this.condListTask = true;
				this.responseTask = response;
				this.lisTask = response.data;
				if (this.lisTask.length === 0) {

					this.noTask = true;
				} else {
					this.noTask = false;
				}
				this.dataPaginationTask = this.responseTask.meta.page;
			},
			(error) => {

			}
		);
	}

	lawyergetCases(page) {
		this.LawyerService.lawyergetCases(page).subscribe(
			async (response) => {
				this.responseCase = response;
				this.listCases = response.data;
				this.dataPagination = this.responseCase.meta.page;
			},
			(error) => {

			}
		);
	}

	loadPageTask(page) {
		this.pageTask = page;
		this.lisTask = [];
		this.getTaskCase(this.idCaseSelect, this.pageTask);
	}

	loadPage(page) {
		this.page = page;
		this.listCases = [];
		this.lawyergetCases(this.page);

	}

}
