import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedService } from './shared/services/shared.service';
import { NgbdSortableHeader } from './shared/directives/sortable.directive';

import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NestableModule } from 'ngx-nestable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LightboxModule } from 'ngx-lightbox';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';


FullCalendarModule.registerPlugins( [
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
] );

import { MetismenuAngularModule } from '@metismenu/angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import {
  MatStep,
  MatStepper,
  MatStepperModule,
} from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

/* #########################  SITE PAGES COMPONENT ###################*/

import { AdminComponent } from './pages/admin/admin.component';

import { LoadingComponent } from './shared/elements/loading/loading.component';
import { NavHeaderComponent } from './shared/elements/nav-header/nav-header.component';
import { HeaderComponent } from './shared/elements/header/header.component';
import { NavigationComponent } from './shared/elements/navigation/navigation.component';
import { FooterComponent } from './shared/elements/footer/footer.component';

import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { Error400Component } from './pages/errors/error400/error400.component';
import { Error403Component } from './pages/errors/error403/error403.component';
import { Error404Component } from './pages/errors/error404/error404.component';
import { Error500Component } from './pages/errors/error500/error500.component';
import { Error503Component } from './pages/errors/error503/error503.component';
import { HttpInterceptor } from './shared/interceptor/http.interceptor';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { CommonModule, registerLocaleData } from '@angular/common';
import { Index1Component } from './pages/dashboard/index1/index1.component';
import { ElementsComponent } from './pages/forms/elements/elements.component';
import { FormValidateComponent } from './pages/forms/form-validate/form-validate.component';
import { ToastsContainer } from './shared/components/toasts-container/toasts-container.component';
import { CompanyFormComponent } from './shared/components/register/company-form/company-form.component';
import { PersonFormComponent } from './shared/components/register/person-form/person-form.component';
import { LawyerFormComponent } from './shared/components/register/lawyer-form/lawyer-form.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DashboardComponent } from './pages/lawyer/dashboard/dashboard.component';
import { DetailPieChartComponent } from './pages/lawyer/detail-pie-chart/detail-pie-chart.component';
import { UpdatePersonProfileComponent } from './pages/person/update-profile/update-profile.component';
import { UpdateCompanyProfileComponent } from './pages/company/update-profile/update-profile.component';
import { ServiceConfigurationComponent } from './pages/lawyer/services/service-configuration.component';
import { LawyerCasesComponent } from './pages/lawyer/cases/cases.component';
import { ApprovalsComponent } from './pages/approvals/approvals.component';
import { PersonComponent } from './pages/person/person.component';
import { CompanyComponent } from './pages/company/company.component';
import { LawyerComponent } from './pages/lawyer/lawyer.component';
import { RoleDirective } from './shared/directives/role.directive';
import { BanksComponent } from './pages/config/banks/banks.component';
import { EntitiesComponent } from './pages/config/entities/entities.component';
import { ProcessComponent } from './pages/person/process/process.component';
import { RequirementsComponent } from './pages/config/requirements/requirements.component';
import { ServicesComponent } from './pages/config/services/services.component';
import { BranchesLawComponent } from './pages/config/branches-law/branches-law.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LawyerMarketplaceComponent } from './shared/components/dashboard/lawyer-marketplace/lawyer-marketplace.component';
import { LawyerFileManagementPlatformComponent } from './shared/components/dashboard/lawyer-file-management-platform/lawyer-file-management-platform.component';
import { ClientsComponent } from './pages/lawyer/clients/clients.component';
import { ManagementUserComponent } from './pages/admin/management-user/management-user.component';
import { AdvisoryLawyerCompanyComponent } from './pages/company/advisory-lawyer-company/advisory-lawyer-company.component';
import { AdvisoryLawyerPersonComponent } from './pages/person/advisory-lawyer-person/advisory-lawyer-person.component';
import { AdvisoryLawyerComponent } from '././shared/components/advisory-lawyer/advisory-lawyer/advisory-lawyer.component';
import { ProcServicesComponent } from './pages/person/proc-services/proc-services.component';
import { AdvisoryComponent } from './pages/person/advisory/advisory.component';
import { RegtramComponent } from './pages/person/regtram/regtram.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { LawyerProfileComponent } from './pages/person/lawyer-profile/lawyer-profile.component';
import { RegprocComponent } from './pages/person/regproc/regproc.component';
import { MyDocumentsCompanyComponent } from './pages/company/my-documents-company/my-documents-company.component';
import { MyDocumentsPersonComponent } from './pages/person/my-documents-person/my-documents-person.component';
import { MyDocumentsComponent } from './shared/components/my-documents/my-documents.component';
import { RegadvisoryComponent } from './pages/person/regadvisory/regadvisory.component';
import { FollServicesComponent } from './pages/person/foll-services/foll-services.component';
import { DetailservicesComponent } from './pages/person/detailservices/detailservices.component';
import { ConsultanciesComponent } from './pages/lawyer/requests/consultancies/consultancies.component';
import { QuotesComponent } from './pages/lawyer/requests/quotes/quotes.component';
import { ServiceActiveComponent } from './pages/lawyer/services/service-active/service-active.component';
import { ServiceActiveTemplateComponent } from './shared/components/service-active-template/service-active-template.component';
import { ServiceCompletedComponent } from './pages/lawyer/services/service-completed/service-completed.component';
import { ServiceCompletedTemplateComponent } from './shared/components/service-completed-template/service-completed-template.component';
import { MyDocumentsMarketplaceComponent } from './pages/lawyer/my-documents-marketplace/my-documents-marketplace.component';
import { MyDocumentsPlatformComponent } from './pages/lawyer/my-documents-platform/my-documents-platform.component';
import { MyScheduleComponent } from './pages/lawyer/my-schedule/my-schedule.component';
import { MyClientsLawyerComponent } from './pages/lawyer/my-clients/my-clients-lawyer.component';
import { TaksTimeComponent } from './pages/lawyer/taks-time/taks-time.component';
import { ExecutionTimeComponent } from './pages/lawyer/execution-time/execution-time.component';
import { CdTimerModule } from 'angular-cd-timer';
import { RegproccoComponent } from './pages/company/regprocco/regprocco.component';
import { ProcServicesCoComponent } from './pages/company/proc-services-co/proc-services-co.component';
import { LawyerProfileCoComponent } from './pages/company/lawyer-profile-co/lawyer-profile-co.component';
import { MyCalendarComponent } from './shared/components/my-calendar/my-calendar.component';
import { ProcessCoComponent } from './pages/company/process-co/process-co.component';
import { RegtramCoComponent } from './pages/company/regtram-co/regtram-co.component';
import { AdvisoryCoComponent } from './pages/company/advisory-co/advisory-co.component';
import { RegadvisoryCoComponent } from './pages/company/regadvisory-co/regadvisory-co.component';
import { FollServicesCoComponent } from './pages/company/foll-services-co/foll-services-co.component';
import { ScheduleCoComponent } from './pages/company/schedule-co/schedule-co.component';
import { UpdateLawyerProfileComponent } from './pages/lawyer/update-profile/update-profile.component';
import { ChatsComponent } from './shared/components/chats/chats.component';
import { ChatComponent } from './shared/components/chat/chat.component';
import { NotificationsPanelComponent } from './shared/components/notifications-panel/notifications-panel.component';
import { MyFinancesComponent } from './pages/lawyer/my-finances/my-finances.component';
import { MyReviewsComponent } from './pages/lawyer/my-reviews/my-reviews.component';
import { StatementComponent } from './pages/lawyer/statement-account/statement.component';
import { ReportsComponent } from './pages/lawyer/reports/reports.component';
import { OpReportsComponent } from './pages/lawyer/op-reports/op-reports.component';
import { InvoicesComponent } from './pages/person/invoices/invoices.component';
import { NextPaymentsComponent } from './pages/person/next-payments/next-payments.component';
import { ServiceRequestComponent } from '././shared/components/service-request/service-request.component';
import { ServiceRequestAdvisoryComponent } from '././shared/components/service-request-advisory/service-request-advisory.component';
import { PlanningComponent } from './pages/lawyer/planning/planning.component';
import { NgGanttEditorModule } from 'ng-gantt';
import { NewQuoteGeneratorComponent } from './pages/lawyer/new-quote-generator/new-quote-generator.component' ;
import { ApprovedQuotesComponent } from './pages/lawyer/my-finances/approved-quotes/approved-quotes.component';
import { PendingAccountsComponent } from './pages/lawyer/pending-accounts/pending-accounts.component';
import { ExpensesComponent } from './pages/lawyer/expenses/expenses.component';
import { ServiceMonitoringComponent } from './pages/admin/service-monitoring/service-monitoring.component';
import { FinancialStateComponent } from './pages/admin/financial-state/financial-state.component';
import { AccountsPayableComponent } from './pages/admin/accounts-payable/accounts-payable.component';
import { FilesComponent } from './pages/admin/files/files.component';
import { LawyersReportComponent } from './pages/admin/lawyers-report/lawyers-report.component';
import { CustomerReportComponent } from './pages/admin/customer-report/customer-report.component';
import { FileDetailsComponent } from './pages/admin/file-details/file-details.component';
import { InvoicesMarketplaceComponent } from './pages/lawyer/my-finances/invoices-marketplace/invoices-marketplace.component';
import { AccountStatementComponent } from './pages/lawyer/my-finances/account-statement/account-statement.component';
import { PaymentMethodComponent } from './shared/components/payment-method/payment-method.component';
import { LawyerPaymentComponent } from './pages/lawyer-payment/lawyer-payment.component';
import { ApprovalsPersonComponent } from './pages/person/approvals-person/approvals-person.component';
import { ApprovalsCompanyComponent } from './pages/company/approvals-company/approvals-company.component';
import { InvoicesCompanyComponent } from './pages/company/invoices-company/invoices-company.component';
import { NextPaymentsCompanyComponent } from './pages/company/next-payments-company/next-payments-company.component';

import { InvoicesAdminComponent } from './pages/admin/invoices-admin/invoices-admin.component';
import { PaymentsAppoveComponent } from './pages/admin/payments-appove/payments-appove.component';
import { PromotionComponent } from './pages/admin/promotion/promotion.component';
import { TaskStatusComponent } from './pages/lawyer/new-quote-generator/task-status/task-status.component';
import { IncomeComponent } from './pages/lawyer/income/income.component';
import { CronometerComponent } from './shared/components/dashboard/cronometer/cronometer.component';
registerLocaleData(localeEn);
registerLocaleData( localeEs );

@NgModule( {
  declarations: [
    UpdatePersonProfileComponent,
    UpdateCompanyProfileComponent,
    ServiceConfigurationComponent,
    AppComponent,
    AdminComponent,
    Index1Component,
    DetailPieChartComponent,
    LoadingComponent,
    NavHeaderComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    NavHeaderComponent,
    // NgbdSortableHeader,
    NgbdSortableHeader,
    ElementsComponent,
    FormValidateComponent,
    RegisterComponent,
    LoginComponent,
    LockScreenComponent,
    ForgotPasswordComponent,
    Error400Component,
    LawyerCasesComponent,
    Error403Component,
    Error404Component,
    Error500Component,
    Error503Component,
    ToastsContainer,
    CompanyFormComponent,
    PersonFormComponent,
    LawyerFormComponent,
    DashboardComponent,
    ApprovalsComponent,
    PersonComponent,
    CompanyComponent,
    LawyerComponent,
    RoleDirective,
    BanksComponent,
    EntitiesComponent,
    RequirementsComponent,
    ServicesComponent,
    BranchesLawComponent,
    LawyerMarketplaceComponent,
    LawyerFileManagementPlatformComponent,
    ClientsComponent,
    ManagementUserComponent,
    AdvisoryLawyerCompanyComponent,
    AdvisoryLawyerPersonComponent,
    AdvisoryLawyerComponent,
    ProcessComponent,
    ProcServicesComponent,
    AdvisoryComponent,
    RegtramComponent,
    LawyerProfileComponent,
    RegprocComponent,
    MyDocumentsComponent,
    MyDocumentsPersonComponent,
    MyDocumentsCompanyComponent,
    RegadvisoryComponent,
    FollServicesComponent,
    DetailservicesComponent,
    ConsultanciesComponent,
    QuotesComponent,
    ServiceActiveComponent,
    ServiceActiveTemplateComponent,
    ServiceCompletedComponent,
    ServiceCompletedTemplateComponent,
    MyDocumentsMarketplaceComponent,
    MyDocumentsPlatformComponent,
    MyScheduleComponent,
    MyClientsLawyerComponent,
    TaksTimeComponent,
    ExecutionTimeComponent,
    RegproccoComponent,
    ProcServicesCoComponent,
    LawyerProfileCoComponent,
    ExecutionTimeComponent,
    MyCalendarComponent,
    ProcessCoComponent,
    RegtramCoComponent,
    AdvisoryCoComponent,
    RegadvisoryCoComponent,
    FollServicesCoComponent,
    ScheduleCoComponent,
    UpdateLawyerProfileComponent,
    ChatsComponent,
    ChatComponent,
    NotificationsPanelComponent,
    MyFinancesComponent,
    MyReviewsComponent,
    StatementComponent,
    ReportsComponent,
    OpReportsComponent,
    InvoicesComponent,
    NextPaymentsComponent,
    ApprovedQuotesComponent,
    ServiceRequestComponent,
    ServiceRequestAdvisoryComponent,
    PlanningComponent,
    NewQuoteGeneratorComponent,
    PendingAccountsComponent,
    ExpensesComponent,
    ServiceMonitoringComponent,
    FinancialStateComponent,
    AccountsPayableComponent,
    FilesComponent,
    LawyersReportComponent,
    CustomerReportComponent,
    FileDetailsComponent,
    InvoicesMarketplaceComponent,
    AccountStatementComponent,
    PaymentMethodComponent,
    LawyerPaymentComponent,
    ApprovalsPersonComponent,
    ApprovalsCompanyComponent,
    InvoicesCompanyComponent,
    NextPaymentsCompanyComponent,
    InvoicesAdminComponent,
    PaymentsAppoveComponent,
    PromotionComponent,
    TaskStatusComponent,
    IncomeComponent,
    CronometerComponent

  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    NgGanttEditorModule,
    PdfViewerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    NgApexchartsModule,
    NestableModule,
    NgxSpinnerModule,
    LightboxModule,
    FullCalendarModule,
    MetismenuAngularModule,
    PerfectScrollbarModule,
    NgxDropzoneModule,
    CarouselModule,
    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTabsModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    DragDropModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatSelectModule,
    MatGridListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTooltipModule,
    MatTreeModule,
    NgxSliderModule,
    CdTimerModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    SharedService,
    NgbActiveModal,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
  ],
  bootstrap: [ AppComponent ]
} )
export class AppModule { }
