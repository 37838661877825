import { Component, OnInit } from '@angular/core';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-my-finances',
  templateUrl: './my-finances.component.html',
  styleUrls: ['./my-finances.component.css']
})
export class MyFinancesComponent implements OnInit {
  active = 1;
  constructor(
    titlePageService: TitlePageService
  ) { 
    titlePageService.titlePageObservableData = { name: 'Finanzas'};
  }

  ngOnInit(): void {
  }
}
