import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-documents-company',
  templateUrl: './my-documents-company.component.html',
  styleUrls: ['./my-documents-company.component.css']
})
export class MyDocumentsCompanyComponent implements OnInit {
  Object = []
  constructor() { }

  ngOnInit(): void {
  }




}
