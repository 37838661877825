import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/shared/services/admin.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payments-appove',
  templateUrl: './payments-appove.component.html',
  styleUrls: ['./payments-appove.component.css']
})
export class PaymentsAppoveComponent implements OnInit {
  titlePage: string = 'Pagos por aprobar';
  payList: any = [];
  page;
  dataPagination;

  constructor(
    titlePageService: TitlePageService,

    private _adminService: AdminService
  ) {

    titlePageService.titlePageObservableData = { name: this.titlePage};
    this.page = 1;
  }

  ngOnInit(): void {
    this.loadData()

  }

  loadData(page = 1) {

    this.page = page
    this.loadPaymentsForApprove()


  }


  approveOrder(order_id){
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea aprobar este pago?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, aprobar!',
      cancelButtonText : 'Cancelar',
    } ).then( ( result ) => {
      if ( result.isConfirmed ) {
         this._adminService.updatePayments(order_id , {status : 'Aprobado'}).subscribe(rsp =>{

          Swal.fire({title: "Pago Confirmado",  icon: "success"})
          this.loadPaymentsForApprove()
         })
        }
    });


  }

  messageError(){
    Swal.fire({
      title: "Archivo no encontrado" ,
      text: "El archivo adjunto al pagado no ha sido encontrado" ,
      icon: "error",
      timer: 3000,
    })

  }

  cancelOrder(order_id){
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea Rechazar este pago?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si!',
      cancelButtonText : 'Cancelar',
    } ).then( ( result ) => {
      if ( result.isConfirmed ) {

         this._adminService.updatePayments(order_id , {status : 'Denegado'}).subscribe(rsp =>{
          Swal.fire({title: "Pago Rechazado",  icon: "success"})
          this.loadPaymentsForApprove()

         })
        }
    });
  }



  loadPaymentsForApprove(){
    this.payList = []
    this._adminService.getPaymentsForApprove(this.page, "&take=6").subscribe(rsp =>{
      const elements = [...rsp.data]
      elements.forEach(e =>{
        this.payList.push({
          id : e.id ,
          nameService: e.service.name ,
          code: e.order_id,
          clientName: e.client.full_name ,
          referenceCode: e.reference_number ,
          amount: e.amount,
          fileTransaccion: e.reference_file,
          statusPay: e.status
      })
      })

      this.dataPagination = rsp.meta.page;
      console.log(this.dataPagination)

    });


  }

}
