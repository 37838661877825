import { Component, OnInit } from '@angular/core';
import { Entity } from '../../../shared/interfaces/user';
import { LawyerService } from '../../../shared/services/lawyer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import Swal from 'src/app/shared/helpers/sweetalert';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component( {
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: [ './entities.component.css' ]
} )
export class EntitiesComponent implements OnInit {

  form: FormGroup;
  modal: any;
  isEdit = false;
  entities: Entity[] = [];
  entity: Entity | null;
  submitted = false;
  dataPagination: any = [];
  page;

  constructor(
    private fb: FormBuilder,
    private lawyerService: LawyerService,
    private modalService: NgbModal,
    titlePageService: TitlePageService,
    private adminService: AdminService,
  ) {
    this.page=1;
    titlePageService.titlePageObservableData = { name: 'Entidades'};
    this.loadData(this.page);
    this.createForm();
  }

  get f(): any { return this.form.controls; }

  ngOnInit(): void {
  }

  add( entityModal: any ): void {
    this.form.reset();
    this.isEdit = false;
    this.modal = this.modalService.open( entityModal );
  }

  onSubmit(): void {
    this.submitted = true;
    if ( this.form.valid ) {
      ( this.isEdit ) ? this.editEntity() : this.addEntity();
    }
  }

  edit( entityModal: any, entity: Entity ): void {
    this.isEdit = true;
    this.entity = { ...entity };
    this.createForm();
    this.modal = this.modalService.open( entityModal, { backdrop: 'static' } );
  }

  delete( id: number ): void {
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea eliminar esta entidad?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, eliminar!',
      cancelButtonText: 'Cancelar'
    } ).then( ( result ) => {
      if ( result.isConfirmed ) { this.deleteEntity( id ); }
    } );
  }

  private addEntity(): void {
    this.lawyerService.addEntities( this.form.value ).subscribe( () => {
      this.modal.dismiss();
      this.loadData(this.page);
      Swal.fire( { text: 'Entidad creada', icon: 'success' } );
    } );
  }

  private deleteEntity( id: number ): void {
    this.adminService.deleteService( id ).subscribe( () => {


      this.loadData(this.page);
      Swal.fire( { text: 'Entidad eliminada', icon: 'success' } );


    },
    (error) => {
      Swal.fire( { text: error.error.text, icon: 'warning' } );
    });
  }

  private editEntity(): void {
    this.lawyerService.editEntity( this.form.value, this.entity.id ).subscribe( () => {
      this.modal.dismiss();
      this.loadData(this.page);
      Swal.fire( { text: 'Entidad actualizada', icon: 'success' } );
    } );
  }

  private loadData(page): void {
    this.lawyerService.entitites(page).subscribe( response => {
      this.entities = [ ...response.data ];
      this.dataPagination = response.meta.page;
    } );
  }

  loadPage(page) {
    this.page = page;
    this.loadData(this.page);
  }

  private createForm(): void {
    this.form = this.fb.group( {
      name: [ this.entity ? this.entity.name : '', [ Validators.required ] ],
      email: [ this.entity ? this.entity.email : '', [ Validators.required, Validators.email ] ],
      phone: [ this.entity ? this.entity.phone : '', [ Validators.required ] ],
      address: [ this.entity ? this.entity.address : '', [ Validators.required ] ],
    } );
  }
}
