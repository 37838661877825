import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbCalendar, NgbDate, NgbDateParserFormatter  } from '@ng-bootstrap/ng-bootstrap';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/shared/services/admin.service';
import { LawyerService } from 'src/app/shared/services/lawyer.service';

@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.css']
})
export class AccountStatementComponent implements OnInit {

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  hoveredDate: NgbDate | null = null;

  modal: any;
  branches = [];
  types = [];
  list = [];
  params = '';
  info_object: any;
  page; dataPagination;
  searchFilter = {
    branch: null,
    service_type: null,
    service_name: null,
    dateStart: null,
    dateEnd: null,
    client_id: null
  }
  clients: any[];
  typesService = [];
  constructor(
    private modalService: NgbModal,
    private lawyerService: LawyerService,
    private adminService: AdminService,
    private calendar: NgbCalendar,

    public formatter: NgbDateParserFormatter

  ) {
    this.page = 1;
  }

  ngOnInit(): void {
    this.loadData();
    this.loadFilters();
  }

  downloadPDF() {
    this.lawyerService.getStatement(null, this.params, true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Estado de Cuenta.pdf";
        link.click();
    });
  }

  openModal(detailModal: any) {
    this.modal = this.modalService.open(detailModal, { backdrop: 'static', size: 'md' });
  }

  loadData(page = 1) {
    this.lawyerService.getStatement(page, this.params).subscribe(
      response => {
        this.list = response.data;
        this.info_object = response.info_object;
        this.dataPagination = response.meta.page;
      }
    );
  }

  loadFilters(page = 1) {
    // this.lawyerService.listBranch().subscribe(
    //   response => {
    //     this.branches = response.data;
    // });
    // this.lawyerService.listService().subscribe(
    //   response => {
    //     this.types = response.data;
    // });
    this.lawyerService.clientsSimple().subscribe(
      response => {
        this.clients = response.data;
      }
    )
    this.adminService.listService().subscribe(
      response => {
        this.typesService = response?.data?.length ? response.data.filter(( type: any ) => type.name != 'Asesorías') : [];
      }
    )
  }

  getTotal() {
    return this.info_object;
  }

  clearFilter() {
    this.params = '';
    this.searchFilter = {
      dateStart: null,
      dateEnd: null,
      branch: null,
      service_type: null,
      service_name: null,
      client_id: null,
    }
    this.toDate = null;
    this.fromDate = null;
    this.loadData();
   }

  search() {
    this.params = '';
    this.searchFilter.dateStart = this.formatter.format( this.fromDate );
    this.searchFilter.dateEnd = this.formatter.format( this.toDate );


    if (this.searchFilter.client_id) {
      this.params += '&lawyer_client_id=' + this.searchFilter.client_id;
    }

    if (this.searchFilter.service_type) {
      this.params += '&type_service=' + this.searchFilter.service_type;
    }

    if (this.searchFilter.dateStart!=='') {
      this.params = this.params+'&start_date='+this.searchFilter.dateStart;
    }

    if (this.searchFilter.dateEnd!=='') {
      this.params = this.params+'&end_date='+this.searchFilter.dateEnd;
    }


    if (this.params !== '') {
      this.loadData(1);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  get total_charges() {
    return this.list.map(item => item.charge).reduce((a, b) => a + b, 0)
  }

  get total_amount() {
    return this.list.map(item => item.amount).reduce((a, b) => a + b, 0)
  }

  get total_remaining() {
    return this.list.map(item => item.remaining).reduce((a, b) => a + b, 0)
  }
}
