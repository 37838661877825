import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from '../../../shared/services/admin.service';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Requirement } from '../../../shared/interfaces/common';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component( {
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: [ './requirements.component.css' ]
} )
export class RequirementsComponent implements OnInit {

  form: FormGroup;
  modal: any;
  requirements: any[] = [];
  isEdit = false;
  submitted = false;
  requirement: Requirement;
  titlePage= 'Requisitos';
  page; dataPagination;
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private adminService: AdminService,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: this.titlePage};
    this.createForm();
    this.page=1;
    this.loadData();
  }

  get f(): any { return this.form.controls; }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    if ( this.form.valid ) {
      if ( this.isEdit ) {
        this.editRequirement();
        return;
      }
      this.addRequirement();
    }
  }

  add( requirementModal: any ): void {
    this.form.reset();
    this.isEdit = false;
    this.modal = this.modalService.open( requirementModal, { backdrop: 'static' } );
  }

  edit( entityModal: any, req: Requirement ): void {
    this.isEdit = true;
    this.requirement = { ...req };
    this.createForm();
    this.modal = this.modalService.open( entityModal, { backdrop: 'static' } );
  }

  delete( id: number ): void {
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea eliminar este Requisito?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, eliminar!'
    } ).then( ( result ) => {
      if ( result.isConfirmed ) { this.deleteRequirement( id ); }
    } );
  }

  private addRequirement(): void {
    this.adminService.addRequirement( this.form.value ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Requisito creado', icon: 'success' } );
    } );
  }

  private editRequirement(): void {
    this.adminService.updateRequirement( this.form.value, this.requirement.id ).subscribe( () => {
      this.modal.dismiss();
      this.loadData();
      Swal.fire( { text: 'Requisito actualizado', icon: 'success' } );
    } );
  }

  private createForm(): void {
    this.form = this.fb.group( {
      name: [ this.requirement ? this.requirement.name : '', [ Validators.required ] ]
    } );
  }

  private deleteRequirement( id: number ): void {
    this.adminService.deleteRequirement( id ).subscribe( response => {
      Swal.fire( { text: 'Requisito eliminado', icon: 'success' } );
      this.loadData();
    },
    (error) => {
      Swal.fire( { text: error.error.text, icon: 'warning' } );
    });
  }

  private loadData(): void {
    this.adminService.listRequirementPage(this.page).subscribe( response => {
      this.requirements = [ ...response.data ];
      this.dataPagination = response.meta.page;
    } );
  }

  loadPage(page) {
    this.page = page;
    this.loadData();
  }

}
