<div id="top" class="content-body d-flex align-items-center">
  <div class="container-fluid" *ngIf="view">
    <container-element [ngSwitch]="pagesView">
      <some-element *ngSwitchCase="2">
        <div class="row justify-content-center mb-3">
          <div class="col-xl-12 col-sm-12 text-center">
            <h3 class="mb-0">{{ selectedType.name }}</h3>
            <p>Seleccione la rama del derecho para la {{ typeServ.name }}</p>
          </div>
        </div>

        <div class="row justify-content-center">
          <div
            class="col-md-5"
            *ngFor="let item of listBranchServices; let i = index"
          >
            <div class="card card-item">
              <div class="row align-items-center p-2">
                <div class="col" (click)="getBrachServ(item)">
                  {{ item.name }}
                </div>
                <div class="col-auto">
                  <span
                    (click)="modalAbogados(lisAboModal, item)"
                    class="text"
                    *ngIf="item.lawyer_count > 0"
                    placement="top"
                    ngbTooltip="Ver Abogados asociados"
                    ><i class="flaticon-381-user-9"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </some-element>
      <some-element *ngSwitchCase="3">
        <div class="row justify-content-center mb-3">
          <div class="col-xl-12 col-sm-12 text-center">
            <h3 class="mb-0">Información del {{ selectedType.name }}</h3>
            <p>Texto informativo del {{ selectedType.name }}</p>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="card card-lawyer-stepper">
              <div class="card-header">
                <h5 class="card-title">
                  Asignación de Abogado - Derecho de {{ nameBranch }}
                </h5>
              </div>
              <div class="card-body default-mt-stepper">
                <p class="card-text text-justify">
                  <mat-horizontal-stepper linear #stepper>
                    <mat-step
                      [stepControl]="firstFormGroup"
                      errorMessage="Faltan Datos por llenar"
                    >
                      <form
                        class="form-valide"
                        (ngSubmit)="nextStep()"
                        [formGroup]="firstFormGroup"
                      >
                        <ng-template matStepLabel></ng-template>

                        <div
                          class="form-row align-items-end"
                          *ngIf="typeServ.id == 1"
                        >
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="mb-1"
                                ><strong>Tipo de asesoria</strong></label
                              >
                              <select
                                class="form-control"
                                formControlName="typeS"
                                (change)="typeAsesoria($event.target.value)"
                              >
                                <option disabled selected value>
                                  Seleccione una opción
                                </option>
                                <option
                                  [value]="option.name"
                                  *ngFor="let option of lisFormaAtt"
                                >
                                  {{ option.name }}
                                </option>
                              </select>
                              <div
                                *ngIf="submitted && f.typeS?.errors"
                                class="text-danger fs-13 mt-1"
                              >
                                <div *ngIf="f.typeS.errors.required">
                                  Campo requerido.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="form-row align-items-end"
                          *ngIf="typeServ.id == 1"
                        >
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="mb-1"><strong>Fecha</strong></label>
                              <div class="input-group">
                                <input
                                  class="form-control"
                                  name="available_date"
                                  ngbDatepicker
                                  #di="ngbDatepicker"
                                  formControlName="available_date"
                                  [minDate]="{
                                    year: yearToday,
                                    month: monthToday,
                                    day: dayToday
                                  }"
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn-outline-secondary calendar"
                                    (click)="di.toggle()"
                                    type="button"
                                  ></button>
                                </div>
                              </div>
                              <div
                                *ngIf="submitted && f.available_date?.errors"
                                class="text-danger fs-13 mt-1"
                              >
                                <div *ngIf="f.available_date.errors.required">
                                  Campo requerido.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="mb-1"><strong>Hora</strong></label>
                              <div class="input-group">
                                <input
                                  class="form-control"
                                  type="time"
                                  formControlName="hour"
                                />
                              </div>
                              <div
                                *ngIf="submitted && f.hour?.errors"
                                class="text-danger fs-13 mt-1"
                              >
                                <div *ngIf="f.hour.errors.required">
                                  Campo requerido.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="col">
                            <div class="form-group">
                              <label class="mb-1"
                                ><strong
                                  >Descripción de
                                  {{ selectedType.name }}</strong
                                ></label
                              >
                              <textarea
                                type="text"
                                class="form-control"
                                formControlName="descripcion"
                                rows="3"
                              ></textarea>
                              <div
                                *ngIf="submitted && f.descripcion?.errors"
                                class="text-danger fs-13 mt-1"
                              >
                                <div *ngIf="f.descripcion.errors?.required">
                                  Campo requerido.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row justify-content-between pt-3">
                          <div class="col-auto">
                            <a
                              (click)="onCancel()"
                              class="btn btn-outline-danger text-danger cursor-pointer"
                              >Cancelar</a
                            >
                          </div>
                          <div class="col-auto">
                            <button
                              type="submit"
                              matStepperNext
                              class="btn btn-primary"
                              click="reset()"
                            >
                              Siguiente
                            </button>
                          </div>
                        </div>
                      </form>
                    </mat-step>
                    <mat-step
                      [stepControl]="secondFormGroup"
                      errorMessage="Selecciona datos de abogados"
                    >
                      <!--<form [formGroup]="secondFormGroup">-->
                      <form>
                        <ng-template matStepLabel></ng-template>

                        <div class="row justify-content-center">
                          <div class="col-lg-7">
                            <div class="form-group">
                              <label class="mb-1"
                                ><strong
                                  >Seleccione precio a pagar</strong
                                ></label
                              >

                              <!-- <input type="text" class="form-control" formControlName="price" /> -->

                              <ngx-slider
                                [(value)]="valuePrice"
                                [options]="options"
                                (userChangeEnd)="changePrice()"
                              >
                              </ngx-slider>

                              <div
                                *ngIf="submitted && f.price?.errors"
                                class="text-danger fs-13 mt-1"
                              >
                                <div *ngIf="f.price.errors.required">
                                  Campo requerido.
                                </div>
                              </div>
                            </div>

                            <div *ngIf="lisLawyerForPriceCopy.length > 1">
                              <mat-form-field
                                appearance="fill"
                                style="width: 100%"
                              >
                                <mat-label>Buscar Abogado por Nombre</mat-label>
                                <input
                                  (keyup)="searchLawyer($event)"
                                  matInput
                                  placeholder="Nombre del Abogado"
                                  value=""
                                />
                              </mat-form-field>
                            </div>

                            <div *ngIf="lisLawyerForPrice.length > 0">
                              <div class="custom-control custom-checkbox my-4">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheckBox1"
                                  [(ngModel)]="lawyerAuto"
                                  [ngModelOptions]="{ standalone: true }"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customCheckBox1"
                                  >¿Deseas que Legal Nexus asigne un abogado por
                                  ti ?</label
                                >
                              </div>

                              <div
                                class="card-lawyer p-3 mb-4"
                                *ngIf="!lawyerAuto"
                              >
                                <!--<h5 class="mb-2"><small>Abogados dísponibles</small></h5>
                                   <input type="text" name="filterTableL" class="form-control"  [(ngModel)]="filterTableL"> -->

                                <div
                                  class="mt-2 card-s pt-2 pl-2 mb-2"
                                  *ngIf="seletedLawyer.length > 0"
                                >
                                  <a
                                    class="badge badge-l mr-2 mb-2"
                                    *ngFor="
                                      let l of seletedLawyer;
                                      let i = index
                                    "
                                  >
                                    <span
                                      *ngIf="typeServ.id == 2"
                                      class="cursor-pointer"
                                      (click)="deleteLaw(i, l)"
                                    >
                                      x <span class="mx-2">|</span></span
                                    >
                                    {{ l.user.full_name }}
                                  </a>
                                </div>
                                <p
                                  class="card-text my-2"
                                  *ngIf="seletedLawyer.length == 0"
                                >
                                  Escoge un abogado para tu
                                  {{ selectedType.name }}
                                </p>

                                <div class="table-responsive">
                                  <table
                                    class="table table-hover table-sm table-borderless table-lawyer mb-0"
                                  >
                                    <tbody>
                                      <tr
                                        *ngFor="
                                          let l of lisLawyerForPrice;
                                          index as i
                                        "
                                      >
                                        <td class="col-auto">
                                          <img
                                            [src]="
                                              l.main_image == null
                                                ? avatar
                                                : l.main_image
                                            "
                                            class="img-card"
                                            [alt]="l.name"
                                          />
                                        </td>
                                        <td class="col">
                                          <small>{{ l.user.full_name }}</small>
                                        </td>
                                        <td class="col-auto">
                                          <div
                                            class="custom-control custom-radio"
                                            *ngIf="typeServ.id == 1"
                                          >
                                            <!-- Asesoria-->
                                            <input
                                              type="radio"
                                              name="holaradio"
                                              class="custom-control-input"
                                              id="customradio{{ i }}"
                                              (click)="selectLaw(l)"
                                            />
                                            <label
                                              class="custom-control-label"
                                              for="customradio{{ i }}"
                                            ></label>
                                          </div>

                                          <div
                                            class="custom-control custom-checkbox"
                                            *ngIf="typeServ.id == 2"
                                          >
                                            <!-- Cotizacion -->
                                            <input
                                              type="checkbox"
                                              class="custom-control-input"
                                              id="customcheckbox{{ i }}"
                                              (change)="
                                                chanceSelectLawyer(
                                                  $event.target.checked,
                                                  l
                                                )
                                              "
                                              [ngModelOptions]="{
                                                standalone: true
                                              }"
                                            />
                                            <label
                                              class="custom-control-label"
                                              for="customcheckbox{{ i }}"
                                            ></label>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div
                                    class="mt-3 d-flex justify-content-center"
                                  >
                                    <ngb-pagination
                                      [pageSize]="10"
                                      (pageChange)="loadPage($event)"
                                      [collectionSize]="dataPagination?.total"
                                      size="sm"
                                      [maxSize]="5"
                                      [(page)]="page"
                                      aria-label="Default pagination"
                                    ></ngb-pagination>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row justify-content-between pt-3">
                          <div class="col-auto">
                            <button
                              matStepperPrevious
                              class="btn btn-primary mr-2"
                            >
                              Atras
                            </button>
                            <a
                              (click)="onCancel()"
                              class="btn btn-outline-danger text-danger cursor-pointer"
                              >Cancelar</a
                            >
                          </div>
                          <div class="col-auto">
                            <button
                              *ngIf="lisLawyerForPrice.length > 0"
                              (click)="sendData()"
                              class="btn btn-primary"
                            >
                              Realizar Solicitud
                            </button>
                          </div>
                        </div>
                      </form>
                    </mat-step>
                    <!--<mat-step>
                      <ng-template matStepLabel></ng-template>
                      <p>As</p>
                      <div>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button (click)="stepper.reset()">Reset</button>
                      </div>
                    </mat-step>-->
                  </mat-horizontal-stepper>
                </p>
              </div>
            </div>
          </div>
        </div>
      </some-element>
    </container-element>
  </div>
</div>

<ng-template #lisAboModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Abogados - Derecho {{ titleBranch }} 2
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center mb-3">
      <div class="col-md-4 col-sm-6">
        <label class="fw-bolder" for="f_name">Nombre</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="filter.name"
          (ngModelChange)="changeFilter()"
          placeholder="Nombre..."
          id="f_name"
        >
      </div>
      <div class="col-md-4 col-sm-6">
        <label class="fw-bolder" for="f_studies">Estudios</label>
        <select
          class="form-control"
          [(ngModel)]="filter.studies"
          (ngModelChange)="getLawForBrach(selectedType.id, serviceAuxID, 1)"
          id="f_studies"
        >
          <option selected value="">Seleccione</option>
          <option *ngFor="let study of studies" [value]="study.id">{{study.name}}</option>
        </select>
      </div>
    </div>
    <!--
    <div *ngIf="abogadosCopy.length > 1">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Buscar Abogado por Nombre</mat-label>
        <input
          (keyup)="searchLawyerForBranch($event)"
          matInput
          placeholder="Nombre del Abogado"
          value=""
        />
      </mat-form-field>
    </div>
    -->
    <div *ngIf="abogados.length > 0">
      <div class="row justify-content-around pr-5 pl-5 hidden-1024">
        <div class="col-sm-4">
          <span>Nombre</span>
        </div>
        <div class="col-sm-4">
          <span>Teléfono</span>
        </div>
        <div class="col-sm-4">
          <span>Email</span>
        </div>
      </div>
      <mat-list>
        <div>
          <mat-list-item (click)="goProfile(item)" *ngFor="let item of abogados" class="mb-2">
            <div class="lawyer-container w-100 p-2">
              <div class="lawyer-name"> <!-- firts col -->
                <img matListAvatar [src]="item.main_image ? item.main_image : avatar" [alt]="item.user.name" class="lawyer-img" />
                <div>
                  <p matLine class="m-0 text-nowrap">{{item.user.name}} {{item.user.last_name}}</p>
                  <p matLine class="m-0 text-nowrap">
                    <span *ngFor="let branch of item.branch_laws; let i = index;">
                      {{branch.name}}
                      <span *ngIf="i<item.branch_laws.length - 1"> - </span>
                    </span>
                  </p>
                </div>
              </div>
              <div> <!-- second col -->
                <p class="m-10-0 text-nowrap">{{item?.phone}}</p>
              </div>
              <div> <!-- Third col -->
                <p class="m-10-0 text-nowrap">{{item?.user?.email}}</p>
              </div>
            </div>
          </mat-list-item>
        </div>
      </mat-list>
    </div>
    <div *ngIf="!abogadosCopy.length" class="w-100 justify-content-center">
      <p>No se han encontrado resultados.</p>
    </div>
  </div>
  <div class="modal-footer mx-auto" *ngIf="abogados.length > 0">
    <ngb-pagination
      [pageSize]="10"
      (pageChange)="loadPage($event)"
      [collectionSize]="dataPagination?.total"
      [maxSize]="5"
      size="sm"
      [(page)]="page"
      aria-label="Default pagination"
    ></ngb-pagination>
  </div>
</ng-template>

<!--
<ng-template #lisAboModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Abogados - Derecho {{ titleBranch }} 4
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="abogadosCopy.length > 1">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Buscar Abogado por Nombre</mat-label>
        <input
          (keyup)="searchLawyerForBranch($event)"
          matInput
          placeholder="Nombre del Abogado"
          value=""
        />
      </mat-form-field>
    </div>

    <mat-list>
      <div *ngIf="abogados.length > 0">
        <mat-list-item (click)="goProfile(item)" *ngFor="let item of abogados">
          <img
            matListAvatar
            [src]="Modal.lawyer.main_image ? Modal.lawyer.main_image : avatar"
            class="rounded border"
            [alt]="item.user.name"
          />
          <a matLine href="javascript:void(0)">
            {{ item.user.name }} {{ item.user.last_name }}</a
          >
        </mat-list-item>
      </div>
    </mat-list>
  </div>
  <div class="modal-footer mx-auto">
    <ngb-pagination
      class="pagination pagination-gutter pagination-primary pagination-sm no-bg"
      [pageSize]="10"
      (pageChange)="loadPage($event)"
      [collectionSize]="dataPagination?.total"
      [maxSize]="5"
      size="sm"
      [(page)]="page"
      aria-label="Default pagination"
    ></ngb-pagination>
  </div>
</ng-template>
-->

<ng-template #layerProfile let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Perfil del Abogado</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="card-body">
      <div class="col-12 flex-column text-center p-0 mb-3">
        <img
          [src]="Modal.lawyer.main_image ? Modal.lawyer.main_image : avatar"
          class="img-avatar-bg"
        />
        <img
          [src]="Modal.lawyer.main_image ? Modal.lawyer.main_image : avatar"
          class="ln-avatar img-avatar"
        />
      </div>
      <h3 class="text-center mb-2">{{ Modal.lawyer.user.full_name }}</h3>
      <p class="text-center mb-3">{{ Modal.lawyer.description }}</p>
      <div class="ln-info-card">
        <h3 class="my-3">Datos Básicos</h3>
        <hr />
        <div class="col">
          <strong>Número de Idoneidad:</strong>
          {{ Modal.lawyer.suitability_number }}
        </div>
        <div class="col"><strong>Cédula:</strong> {{ Modal.lawyer.dni }}</div>
        <div class="col">
          <strong>Teléfono:</strong> {{ Modal.lawyer.phone }}
        </div>
        <br />
        <h3 class="my-3">Estudios</h3>
        <hr />
        <div
          class="row"
          *ngFor="let level of Modal.lawyer.study_levels; let i = index"
        >
          <div class="col"><strong>Nivel:</strong> {{ level.name }}</div>
          <div class="col">
            <strong>Título obtenido:</strong> {{ level.obtained_title }}
          </div>
          <div class="col">
            <strong>Institución:</strong> {{ level.college }}
          </div>
        </div>
        <br />
        <h3 class="my-3">Dirección</h3>
        <hr />
        <div class="row">
          <div class="col">
            <strong>Provincia:</strong>
            {{ Modal.lawyer.address?.province.name }}
          </div>
          <div class="col">
            <strong>Distrito:</strong> {{ Modal.lawyer.address?.city.name }}
          </div>
          <div class="col">
            <strong>Corregimiento:</strong>
            {{ Modal.lawyer.address?.township.name }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Dirección:</strong> {{ Modal.lawyer.address?.address }}
          </div>
        </div>
        <br />
        <h3 class="my-3">Datos Bancarios</h3>
        <hr />
        <div class="row">
          <div class="col">
            <strong>Titular de cuenta:</strong>
            {{ Modal.lawyer.bank?.owner_name }}
          </div>
          <div class="col">
            <strong>Cédula de Identidad:</strong>
            {{ Modal.lawyer.bank?.owner_dni }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Entidad Bancaria:</strong>
            {{ Modal.lawyer.bank?.bank_data.name }}
          </div>
          <div class="col">
            <strong>Tipo de Cuenta:</strong>
            {{ Modal.lawyer.bank?.account_type }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Número de Cuenta:</strong>
            {{ Modal.lawyer.bank?.account_number }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
