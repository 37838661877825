import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { User } from '../../interfaces/user';
import { StorageService } from '../../services/storage.service';
import { USER, AVATAR } from '../../data/constants';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TitlePage, TitlePageService } from '../../services/title-page.service';
import { NotificationsService } from '../../services/notifications.service';
import { ChatService } from '../../services/chat.service';
import Swal from 'sweetalert2';

@Component( {
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.css' ]
} )
export class HeaderComponent implements OnInit {

  toggleChat = true;
  toggleSingle = true;
  user: User;
  avatar = AVATAR;
  titlePage$: Observable<TitlePage>;
  notifications = [];
  notificationsLink = '';
  link='';
  hasUnreadNotifications = false;
  hasUnreadMessages = false;
  NotificationIntervalId:any;
  MessageIntervalId:any;
  enablCronometer=false;

  constructor(
    public router: Router,
    private auth: AuthService,
    private storage: StorageService,
    private titlePageService: TitlePageService,
    private notificationService: NotificationsService,
    private chatService: ChatService,
  ) {
    this.titlePage$ = titlePageService.titlePageObservable;
  }

  ngOnInit(): void {

    this.user = this.storage.getItem( USER );
    if ( this.user.roles[ 0 ].name === 'person' ) {
      this.avatar = this.user.person.main_image;
      this.notificationsLink = '/person/notifications-panel';
      this.link='person';
    }
    if ( this.user.roles[ 0 ].name === 'lawyer' ) {
      this.avatar = this.user.lawyer.main_image;
      this.notificationsLink = '/lawyer/notifications-panel';
      this.link='lawyer';
	  this.enablCronometer=true;
    }
    if ( this.user.roles[ 0 ].name === 'admin' ) {
      this.notificationsLink = '/admin/notifications-panel';
      this.link='admin';
    }
    if ( this.user.roles[ 0 ].name === 'company' ) {
      this.avatar = this.user.company.main_image;
      this.link='company';
    }

    this.getNotificacions();
    this.getNewMessages();

    this.NotificationIntervalId = setInterval(() => {
        this.getNotificacions();
    }, 50000); // Cada 30 segundoss

    this.MessageIntervalId = setInterval(() => {
        this.getNewMessages();
    }, 100000); // Cada 60 segundoss
  } 



  getNotificacions(): void{
    let hasSession = this.storage.getItem( USER );
    if (hasSession  === null ) { return null; }
    
      this.notificationService.getNotifications(1, '&readed=0').subscribe(response => {
        console.log('-- check notifications --');
        this.notifications = response.data;
        this.hasUnreadNotifications = this.notifications.some((notification) => notification.readed === 0 )
      });
  }

  getNewMessages(): void
  {
    let hasSession = this.storage.getItem( USER );
    if (hasSession  === null ) { return null; }
    
      this.chatService.getMessages().subscribe(response => {
        console.log('-- check new message --');
        this.hasUnreadMessages = response
      });
  }

  redirectChat(notification)
  {
    this.router.navigate(['/'+this.link+'/chat/'+notification.order_id]);
  }

  togglechatbar(): void {
    this.toggleChat = !this.toggleChat;
  }

  singleChatWindow(): void {
    this.toggleSingle = !this.toggleSingle;
  }

  logout(): void {
    this.storage.clearAll();
    this.router.navigateByUrl( 'login' );
  }

  onChat(){
    this.router.navigate(['/'+this.link+'/chat']);
  }

  onProfile(){
    this.router.navigate(['/'+this.link+'/update-profile']);
  }

  redirect

  markAsRead():void
  {
    Swal.fire({
      title: '¡Atención!',
      text: "¿Seguro que desea continuar?",
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true
     }).then((result) => {
       if (result.isConfirmed) {
          let data ={
            "amount":5,
            "type":"Notificación",
            "user": this.user.id
          }
    
          this.notificationService.markAsRead(data).subscribe(response => {
              this.getNotificacions();
          });
       }
     }
    );
  }

}
