<div class="content-body d-flex align-items-center" >
    <div class="container-fluid">
        <div class="row justify-content-center mb-3">
            <div class="col-xl-12 col-sm-12 text-center">
               <h3>Directorio de Abogados</h3>
            </div>
        </div>
        <div class="row justify-content-center mb-3">
            <div class="col-md-3 col-sm-4">
                <label class="fw-bolder" for="f_name">Nombre</label>
                <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="filter.name"
                    (ngModelChange)="changeFilter()"
                    placeholder="Nombre..."
                    id="f_name"
                >
            </div>
            <div class="col-md-3 col-sm-4">
                <label class="fw-bolder" for="f_branch">Rama</label>
                <select
                    class="form-control"
                    [(ngModel)]="filter.branch"
                    (ngModelChange)="loadData()"
                    id="f_branch"
                >
                    <option selected value=''>Seleccione</option>
                    <option *ngFor="let branch of branches" [value]="branch.name">{{branch.name}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4">
                <label class="fw-bolder" for="f_studies">Estudios</label>
                <select
                    class="form-control"
                    [(ngModel)]="filter.studies"
                    (ngModelChange)="loadData()"
                    id="f_studies"
                >
                    <option selected value=''>Seleccione</option>
                    <option *ngFor="let study of studies" [value]="study.id">{{study.name}}</option>
                </select>
            </div>
        </div>
        <div *ngIf="list.length justify-content-center">
            <div class="row">
                <div class="col-md-4 col-sm-6" *ngFor="let data of list">
                    <div class="card p-2 p-lg-3 lawyer-card" (click)="goProfile(data)">
                        <h4 class="m-0">{{data?.user?.full_name}}</h4>
                        <p class="m-0">
                            <span *ngFor="let branch of data.branch_laws; let i = index;">
                                {{branch.name}}
                                <span *ngIf="i<data.branch_laws.length - 1"> - </span>
                            </span>
                        </p>
                        <div class="lawyer-img-container d-flex justify-content-center mb-1">
                            <img
                                class="lawyer-background rounded"
                                [src]="data?.main_image ? data?.main_image : avatar"
                            >
                        </div>
                        <p class="m-0">
                            Email:
                            <br>
                            <span style="color: #000">&nbsp;&nbsp;&nbsp;{{data?.user?.email}}</span>
                        </p>
                        <p class="m-0">
                            Teléfono:
                            <br>
                            <span style="color: #000">&nbsp;&nbsp;&nbsp;{{data?.phone}}</span>
                        </p>
                        <p class="m-0">
                            Nivel de estudio:
                            <br>
                            <span *ngIf="data?.study_levels.length">
                                <span style="color: #000" *ngFor="let study of data?.study_levels">
                                    <span>
                                        &nbsp;&nbsp;&nbsp;-&nbsp;{{study?.name}}: {{study?.obtained_title}}
                                    </span>
                                    <br>
                                </span>
                            </span>
                            <span style="color: #000" *ngIf="!data?.study_levels.length">
                                &nbsp;&nbsp;&nbsp;N/A
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end mt-3">
                <div class="col-auto custom-div-pagination justify-content-center">
                    <ngb-pagination
                        [pageSize]="dataPagination?.perPage"
                        (pageChange)="loadData($event)"
                        [collectionSize]="dataPagination?.total"
                        [maxSize]="5"
                        [(page)]="dataPagination.currentPage"
                        aria-label="Paginación">
                    </ngb-pagination>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" *ngIf="!list.length">
            <div class="alert alert-info text-center my-4" role="alert">
                No hay registros disponibles
            </div>
        </div>
        <!--
        <div class="row justify-content-center">
            <ng-container *ngIf="List.length; else noData">
                <div class="col-lg-10 mb-2" *ngFor="let data of List">
                    <div class="card p-3 p-lg-4">
                       <div class="row">
                           <div class="col">
                                <div class="content-document mb-4">
                                    <label for="" class="font-weight-bold">Nombre del Trámite:</label>
                                    <span class="ml-2">{{data.orders?.service_name }} </span>
                                </div>
                                <div class="content-lawyer">
                                    <label for="" class="font-weight-bold mb-0">Abogado Asesor: </label>
                                    <span class="ml-2">{{data.name}}</span>
                                </div>
                           </div>
                           <div class="col-auto">
                                <a class="btn btn-link text-center" (click)="lawyerDetail(content,data.id, data)">
                                    <div class="avatar">
                                        <img [src]="this.avatar" class="ln-avatar" [alt]="data.name">
                                    </div>
                                    Ver Perfil del Abogado
                                </a>
                           </div>
                       </div>

                    </div>
                </div>
            </ng-container>
            <ng-template #noData>
            <div class="alert alert-info text-center my-4" role="alert">
                No hay registros disponibles
            </div>
            </ng-template>
        </div>
        -->

    </div>
</div>

<!--

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Perfil del Abogado</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
        <img src="/assets/images/big/img9.jpg" class="modal-banner" alt="banner">
        <div class="modal-avatar text-center">
            <img [src]="this.avatar" class="ln-avatar">
        </div>
        <div class="card-body pt-3">

            <h4 class="text-center mb-3">{{Modal.lawyer.user.full_name}}</h4>

            <p><strong>Número de Identidad:</strong> {{Modal.lawyer.suitability_number}}</p>
            <div *ngIf="Modal.lawyer.description" class="my-4">
                <h5>Breve perfil profesional</h5>
                <p>{{Modal.lawyer.description}}</p>
            </div>
            <div class="my-4">
                <h5>Estudios Realizados</h5>
                <div class="ml-5" *ngFor="let studies of Modal.lawyer.study_levels;index as i">
                    <p><strong>{{studies.name}}</strong>
                        {{studies?.obtained_title}}

                        <span *ngIf="studies.collage"> - <strong>Institución</strong> {{studies.collage}}</span>
                    </p>

                </div>
            </div>


            <div class="mb-4" *ngIf="Modal.lawyer.comments?.length">
                <h5>Comentarios de otros Usuarios</h5>
                <div class="ml-5" *ngFor="let comment of Modal.lawyer.comments;index as i">
                    <p><strong>{{comment.user.full_name}}: </strong> {{comment.comment}}</p>
                </div>
            </div>


        </div>



    </div>

  </ng-template>

-->

<ng-template #layerProfile let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Perfil del Abogado</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card-body">
            <div class="col-12 flex-column text-center p-0 mb-3">
                <img
                    [src]="Modal.lawyer.main_image ? Modal.lawyer.main_image : avatar"
                    class="img-avatar-bg"
                />
                <img
                    [src]="Modal.lawyer.main_image ? Modal.lawyer.main_image : avatar"
                    class="ln-avatar img-avatar"
                />
            </div>
            <h3 class="text-center mb-2">{{Modal.lawyer.user.full_name}}</h3>
            <p class="text-center mb-3">{{Modal.lawyer.description}}</p>
            <div class="ln-info-card">
                <h3 class="my-3">Datos Básicos</h3>
                <hr>
                <div class="col"><strong>Número de Idoneidad:</strong> {{Modal.lawyer.suitability_number}}</div>
                <div class="col"><strong>Cédula:</strong> {{Modal.lawyer.dni}}</div>
                <div class="col"><strong>Teléfono:</strong> {{Modal.lawyer.phone}}</div>
                <div class="col"><strong>Correo:</strong> {{Modal.lawyer.user.email}}</div>
                <br>
                <h3 class="my-3">Estudios</h3>
                <hr>
                <div class="row" *ngFor="let level of Modal.lawyer.study_levels; let i=index">
                    <div class="col"><strong>Nivel:</strong> {{level.name}}</div>
                    <div class="col"><strong>Título obtenido:</strong> {{level.obtained_title}}</div>
                    <div class="col"><strong>Institución:</strong> {{level.college}}</div>
                </div>
                <br>
                <h3 class="my-3">Dirección</h3>
                <hr>
                <div class="row">
                    <div class="col"><strong>Provincia:</strong> {{Modal.lawyer.address?.province.name}}</div>
                    <div class="col"><strong>Distrito:</strong> {{Modal.lawyer.address?.city.name}}</div>
                    <div class="col"><strong>Corregimiento:</strong> {{Modal.lawyer.address?.township.name}}</div>
                </div>
                <div class="row">
                    <div class="col"><strong>Dirección:</strong> {{Modal.lawyer.address?.address}}</div>
                </div>
                <div *ngIf="false">
                    <br>
                    <h3 class="my-3">Datos Bancarios</h3>
                    <hr>
                    <div class="row">
                      <div class="col"><strong>Titular de cuenta:</strong> {{Modal.lawyer.bank?.owner_name}}</div>
                      <div class="col"><strong>Cédula de Identidad:</strong> {{Modal.lawyer.bank?.owner_dni}}</div>
                    </div>
                    <div class="row">
                      <div class="col"><strong>Entidad Bancaria:</strong> {{Modal.lawyer.bank?.bank_data.name}}</div>
                      <div class="col"><strong>Tipo de Cuenta:</strong> {{Modal.lawyer.bank?.account_type}}</div>
                    </div>
                    <div class="row">
                      <div class="col"><strong>Número de Cuenta:</strong> {{Modal.lawyer.bank?.account_number}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>
